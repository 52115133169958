
import { getStreamURL } from '../Util';
import axios from 'axios';

/**
 * 
 * @returns 
 */
export function useClipDownloader(  dbInstance
                                    , callback= () => {console.log("Warning no callback defined for download complete")}
                                  ){

  const handleLoad = async (_details,_bLoad) => {
     // console.log("Handle load triggered:" ,_details,this.state.videosByAsset);
     if(!dbInstance || !dbInstance.loads){ console.log("Local IndexDB failed to load " ); return _details;}
     // console.log("Called add on : ",this.state.dbTable)
 
     //Load the 
     let addedPromise = null;
     if(!_bLoad){
       //Update the SQL table:
       try {
         addedPromise = dbInstance.loads.add({
           infractionid: _details.infractionid,
           assetid:_details.assetid,
           streamURL:_details.streamURL,
           blob: null,
           loaded: true,
           loading: true,
           viewed: false,
         })
       } catch (error) {
         // console.log("Fail to add to db:" ,error);
         _details.loaderror = true;
       }
       //download the clip, don't just load it into the database
       }else{
         if(!_details.blob ){ //check to make sure this isn't already downloaded, or currently downloading
           // console.log("Add clip: ",_details.infractionid)
           // let addStart = new Date();
           try {
             addedPromise= dbInstance.loads.add({
               infractionid : _details.infractionid,
               assetid:_details.assetid,
               streamURL:_details.streamURL,
               blob: 'pending',
               loaded: false,
               loading: true,
               viewed: false,
             })
           } catch (error) {
             // console.log("Failure to add to db: ",error);
           }
             // return {videosByAsset: videosByAsset,playerKey: prevState.playerKey+1};
   
         }//end if no blob check
       }
       //Handle what happens after the db is updated
       addedPromise.then(res => {
         let getStart = new Date();
         dbInstance.loads.toArray().then(data =>{
           let getEnd = new Date();
 
           if(!_bLoad){ //Are we just loading the streamURL to save memory?
             _details.loaded = true;
           }
         })//end handling the toArray call
       })//end handling the added promise
       addedPromise.catch(error => {
         // console.log("Failed to added with error: ",error);
         _details.loaderror = true;

       })

       await Promise.resolve(addedPromise);
       return _details;
  }
  const fetchFile = async (_details,_bLoad) => {
    // go fetch the blob
      let fetchPromise = axios({
        url: _details.streamURL,
        method: 'GET',
        responseType: 'blob', // important
        data: {
          infractionid: _details.infractionid,
          assetid: _details.assetid,
        },
      })
      .then(res => {
        try {
          const url2 = window.URL.createObjectURL(new Blob([res.data]));
          let configData = JSON.parse(res.config.data);
          if(_details.infractionid !== configData.infractionid){
            console.error("Race condition on: ",_details,configData);
          }
          // this.firstTab(configData.assetid);
          let updateStart = new Date();
          if(!dbInstance || !dbInstance.loads){return;}
          let modifyPromise = dbInstance.loads.where('infractionid')
                              .equals(configData.infractionid)
                              .modify({
                                blob: url2,
                                loaded:true,
                              });
          //Wait until the db instance has been updated
          modifyPromise.then(data=>{
            //Get the updated values from the database and return them with the download data:
            let toArrayPromise = dbInstance.loads.where('infractionid').equals(configData.infractionid).toArray();
            //Get the data to save in the state variables
            // let toArrayPromise = dbInstance.loads.toArray()
            toArrayPromise.then(data2 =>{
                try {
                  // console.log("Modified, ",data2[0]);
                  _details = Object.assign(_details,data2[0]);  
                } catch (error) {
                  console.log("Failed to get updated value from db: ",_details);
                }
                callback(_details); //Return to the download caller
            })
            toArrayPromise.catch(dbErr =>{
              // console.log("to array failed, ",dbErr)
            })
          })//end modify return
          modifyPromise.catch(modErr => {
            // console.log("Modify error: ",modifyPromise)
          })
        } catch (error) {
          console.error("Error on axios return ",error);
        }
        
      })
      .catch(err=>{
        console.error("Error on download from axios ",err,_details.infractionid,_details.streamURL);
      })

      // await Promise.resolve(fetchPromise);
      // console.log("End wait on ",_details.infractionid);
      // return _details;
  }

  const downloadFile = async (clipData,_bLoad = true) => {

     //Assumed to be unique
     let basicClipData = {
      chosenName: null
      ,streamURL:  null
      ,viewed: false    
      ,loaded: false
      ,loading: false
      ,playerRef: null
      // ,
    }
    //Merge the base types with the received data:
    let clip = Object.assign(basicClipData,clipData);//keep anything in the clipData that is overlapping with the base type
    //Update details:
    clip.chosenName = clipData.assetid; //set the id as the chosenName
    clip.streamURL =  getStreamURL(clipData.streamURL); //convert the stream format

    try {
      // console.log("Start download on clip: ",clip);
       const response = await handleLoad(clip,_bLoad);
       if(response && response.loaderror===true){ //error possible duplicate-> abort)
        return;
       }
       //Passed the load check, need to download the file
       if(response && response.loaded===false){ //Download clip
          let downloadResponse = await fetchFile(response); //trigger the callback from the fetch when complete
       }else{ //just use the stream
        callback(clip);
       }
    } catch (error) {
      // console.log("Error on hook: ",error);
    } finally {
    }
  };

  return {
    downloadFile,    
  };


}