
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';

import './siteConfigCard.css';
import 'react-widgets/styles.css';

import {formatSpeed } from './map-utils.js';
import { Auth, API } from 'aws-amplify';

// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/
export class SiteConfigCard extends PureComponent {
    constructor(props) {
        super(props);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.prepareExitData = this.prepareExitData.bind(this);
        // this.handleCommentEdit = this.handleCommentEdit.bind(this, 'comment');
        this.state = {
            actionList: this.props.events_monitored||[],
            shown: false,
            timestamp:null,
            currentUsername:null,
            startTime : new Date(),
            regionName: this.props.name,
            id:this.props.id,
            addFormOpen: false,
            dataToEdit: null,
            speedConfig:{
                speedlimit:null,
                speedtime:null,
            },
            config:{alias:null},
        };
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    componentDidMount() {
        //  console.log("Open Site Config: ",this.props );
        if(this.props.config && this.props.config[0]){
            this.setState({
                    config: this.props.config[0],
                    speedConfig:{
                        speedlimit: formatSpeed(this.props.config[0],this.props.config[0].speedlimit),
                        speedtime: this.props.config[0].speedtime,
                    }
                });
        }
        window.setTimeout(() => {
            this.setState({shown: true});
        }, EXPANDED_CARD_SHOW_LATENCY);

        
    }

    prepareExitData(_data){
        let returnData = JSON.parse(JSON.stringify(_data));
        let speedlimit = null;
        //Check if the speedlimit is not 0 or empty
        if(_data.speedConfig.speedlimit>0 ){
            //Convert the speed back to KM/H if we are in tempe of pintovalley            
            if(_data.config.units.includes("imperial")){
                speedlimit = _data.speedConfig.speedlimit * 1.60934; // convert to kmph to save in SQL;   
            }else{
                speedlimit = _data.speedConfig.speedlimit;
            }
        }    
        
        returnData.speedConfig.speedlimit = speedlimit; //return to the map
        return returnData;
    }
    
    //callback when a new action has been added
    handleSubmit(){
        // console.log("actions submitted: ",this.state);
        let returnData = this.prepareExitData(this.state);
        // JSON.parse(JSON.stringify(this.state));
        // let speedlimit = null;
        // //Check if the speedlimit is not 0 or empty
        // if(this.state.speedConfig.speedlimit>0 || this.state.speedConfig.speedlimit.length>0){
        //     //Convert the speed back to KM/H if we are in tempe of pintovalley            
        //     if(this.state.config.units.includes("imperial")){
        //         speedlimit = this.state.speedConfig.speedlimit * 1.60934; // convert to kmph to save in SQL;   
        //     }else{
        //         speedlimit = this.state.speedConfig.speedlimit;
        //     }
        // }
        // returnData.speedConfig.speedlimit = speedlimit; //return to the map
        
        
        // console.log("Setting speedlimit: ",speedlimit,this.state.config.speedtime);

        if(this.state.config.clientid){
            Auth.currentSession().then(
                (auth) => {
                  let myInit = {
                    body: {
                      token: auth.idToken.jwtToken,
                      apiName: "handleSiteConfig",
                      mode: "updateSpeedLimit",
                      clientid: this.state.config.clientid,
                      speedlimit: returnData.speedConfig.speedlimit,
                      speedtime: returnData.speedConfig.speedtime,
                      site:this.state.config.site,
                    }
                  };
                  return API.post("AuthLambda", "/apiRouter", myInit);
            });
        }
        this.props.handleClose(returnData,true);
    }
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        
        //Handle what happens when the user click off the card
        const confirmClose = () => {            
            this.props.handleClose(this.prepareExitData(this.state));
        }//end confirmClose
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        let speedUnits = 'km/h';
        //If the site is specified, change the units
        try {
            if(this.state.config.units){
                //Display the speed as MPH in tempe of pintovalley
                if( this.state.config.units.includes("imperial") ){
                    speedUnits = 'mph'
                }  
            }  
        } catch (error) {}
        
       
        return (
            <div className="siteconfig-card" style={style}>
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog siteconfig-card-modal"
                            shouldCloseOnOverlayClick={false}
                            // onRequestClose={confirmClose}
                >
                    <div className="siteconfig-modal" style={style} >
                        {/* <div className='c'>Card Data</div> */}
                        <div className = 'sc-title'>
                            {this.state.config.alias } Site Configuration                                                       
                        </div>

                        <div className="settings-grid">
                                <div className="title-type">Site Level Speed Limit</div>
                                <input type="text" className="entry-field" value={this.state.speedConfig.speedlimit}  placeholder="Speed"
                                    onChange={(e) => {
                                        console.log("onChange: ",e.target.value);    
                                        const speedConfig_ = Object.assign({},this.state.speedConfig);
                                        speedConfig_.speedlimit = e.target.value;                         
                                        this.setState({speedConfig:speedConfig_})                                    
                                    }}
                                /> 
                                <div className="title-units">{speedUnits}</div>  
                                <input type="text" className="entry-field" value={this.state.speedConfig.speedtime}  placeholder="Time"
                                    onChange={(e) => {
                                        console.log("onChange: ",e.target.value);
                                        const speedConfig_ = Object.assign({},this.state.config);
                                        speedConfig_.speedtime = e.target.value;                         
                                        this.setState({speedConfig:speedConfig_})
                                    }}
                                /> 
                                <div className="title-units">seconds</div>  
                                   
                        </div>
                
                        
                        <div className="sc-footer">
                                <button className="btn btn-danger" onClick={
                                    confirmClose}>Cancel</button>
                                <button className="btn btn-primary" 
                                    onClick={this.handleSubmit}
                                >Submit</button>
                            {/* {this.state.addFormOpen?null:<div className="blank-spacer"  />} */}
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    }
}
