
import React, { Component, PureComponent } from 'react';

import { VideoLister } from './VideoLister.js';
import { VideoHighlight as DVRVideoHighlight } from './VideoReviewDVR/DVRVideoHighlight.js';
 import { VideoHighlight } from './VideoReview/VideoHighlight.js';

import * as moment from 'moment';
import { ALL_DRIVERS, displayInfraction} from './Util.js';
import { VIDEOREVIEW_NOREPORT } from './Util-access.js';


/*
* @brief Display the overall Analytics view for the website
*/
class AnalyticsView extends Component {
  constructor(props) {
    super(props);
    this.chooseVideo = this.chooseVideo.bind(this);
    this.onNavEvents = this.onNavEvents.bind(this);
    this.videoClicked = this.videoClicked.bind(this);
    this.eval24HrFlag = this.eval24HrFlag.bind(this);
    this.child = React.createRef();
    this.state = {
      currentVideo: props.video,
      showHighlight:false,
      flag24Hr:0,
    };
    // console.log("AnalyticsView filter:", this.props.filter);
    // console.log("AnalyticsView props:", this.props);
    // this.props.navEvents && this.props.navEvents(this.onNavEvents, "AnalyticsView");
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    this.chooseVideo(newProps.video);
  }
  /*
  * @brief Method called when the page is loaded
  */
  componentDidMount() {

    this.chooseVideo(this.props.video);
    
  } //end compenentDidMount

  eval24HrFlag(video){
    if(!video){return 0;}
    let allowedGroupList = ['DEVGROUP','TESTINGGROUP','REVIEWGROUP','BETAGROUP'];
    if(allowedGroupList.includes(this.props.groupconfig.group.toUpperCase())){
      if(video.vehicleType==='24Review'){
        // console.log("24HR video found: ",video)
        return 2;
      }
    }
    return 1;
  }
  /*
  * @brief Used as a callback to set the video we should display, either the VideoLister or the VideoHighlights
  */
  chooseVideo(video) {
    // console.log("Video:" ,video);
    let flag24Hr = 0;
    if(video){
      flag24Hr = this.eval24HrFlag(video);      
    }
    
    // console.log(video);
    this.setState({currentVideo: video, showHighlight: !!video, flag24Hr:flag24Hr});
  }

  videoClicked(video) {
    this.props.eventNotify({
      type: 'link',
      data: {
        href: this.props.mainView,
        extra: {
          groupconfig: this.props.groupconfig,
          video: video,
          filter: this.props.filter,
        }
      },
    });
  }
  /*
  * @brief Handle any nav events that happen
  * 
  * This event just resets the video to none chosen, this allows the user to press the 'Analytics'
  * menu item to go back from the VideoHighlights view.
  */
  onNavEvents(e) {
    // console.log("NavEVent: ",e);
    //this.chooseVideo(null); ? why is this here, doesn't seem to be necessary?
  }
  /*
  * @brief Called to generate the visual elements on the page. Items declared inside the return() are displayed.
  */
  render() {
    const filterDisplays = [];
    let  m_videoHighlights = null;
    
    const filter = this.props.filter;

    if (filter && filter.driverID && filter.driverID !== ALL_DRIVERS) {
        filterDisplays.push(<FilterDisplay type="DriverID" text={filter.driverID} />);
    }

    //If the time period is set, then add it to the list of displayed filters at the top of the table
    if (filter && filter.timePeriod && filter.timePeriod !== 'all') {
      let timeText = null;
      let timeValue = null;
      if (filter.timePeriod.startDate) {
        const day = moment(this.props.filter.timePeriod.startDate);
        timeText = 'Date';
        timeValue = day.format('YYYY-MM-DD');
      } else {
        timeText = 'Time Period';
        switch (filter.timePeriod) {
          case 'week':
          default:
            timeValue = 'Last Week';
            break;
          case 'month':
            timeValue = 'Last Month';
            break;
          case 'quarter':
            timeValue = 'Last Quarter';
            break;
        };
      }
      filterDisplays.push(<FilterDisplay type={timeText} text={timeValue} />);
    }

    //If the type of clip is set, then add it to the list of displayed filters at the top of the table
    if (filter && filter.infractionType) {
      filterDisplays.push(<FilterDisplay type="Clips" text={displayInfraction(this.props.groupconfig.displayNameSet,filter.infractionType)} />);
    }

    // console.log("Fitlers for the query: ",filter);

    //If the type of graph is set, then add it to the list of displayed filters at the top of the table
    if (filter && filter.graph && filter.graph !== 'both') {
      filterDisplays.push(<FilterDisplay type="Graph" text={filter.graph} />);
    }

    const currentVideo = this.state.currentVideo;
    let flag24Hr = this.state.flag24Hr;
    if(flag24Hr === 0){
      flag24Hr = this.eval24HrFlag(currentVideo);
    }

   

    
    if (currentVideo && this.props.groupconfig.bLoaded) {    //add the wait to clear the groupconfig outside the call to VideoHighlights
      // console.log("Load video:" ,currentVideo);
      if(flag24Hr===2){
        // console.log("Load up the DVR hour video")
        m_videoHighlights = <DVRVideoHighlight video={currentVideo}
                                    eventNotify = {this.props.eventNotify}
                                    filter={this.props.filter}
                                    cardChange={VIDEOREVIEW_NOREPORT?null:this.props.cardChange}
                                    groupconfig={this.props.groupconfig}
                                    possibleFilters={this.props.possibleFilters}
                                    flag24Hr = {true}
                            />

      }else{
        m_videoHighlights = <VideoHighlight video={currentVideo}
                                          eventNotify = {this.props.eventNotify}
                                          filter={this.props.filter}
                                          cardChange={VIDEOREVIEW_NOREPORT?null:this.props.cardChange}
                                          groupconfig={this.props.groupconfig}
                                          possibleFilters={this.props.possibleFilters}
                                          flag24Hr = {false}
                          />  
      }

      
    }else{
      m_videoHighlights = 
          <VideoLister filter={this.props.filter} videoClicked={this.videoClicked}  groupconfig={this.props.groupconfig} filterDisplays = {filterDisplays} possibleFilters={this.props.possibleFilters} /> 
    }
    //console.log("Show: ",this.state.showHighlight);
    //if (currentVideo) {
        return (
        
        <React.Fragment>
            {m_videoHighlights}
        </React.Fragment>
        
      )
    
    //} //end if current video
  }
}

/*
* @brief This component display a type and value being filtered by, for the video list table
*
* @param type The type of filter, for example 'Date' or 'Time Period'
* @param text The actual filter value, for example '2018-05-05'
*
* Parameters are free-form, they are just displayed as text unchanged.
*/
class FilterDisplay extends PureComponent {
  render() {
    return (
      <div className="table-filter alert alert-info">
        <strong>{this.props.type}</strong>
        <code className={"filter-text filter-" + this.props.type}>{this.props.text}</code>
      </div>
    );
  }
}

export { AnalyticsView };
