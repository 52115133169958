import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import {Spinner } from '../ApiCaller.js';
import './map-utils.css';
import moment from 'moment';

import { getDistance,getGreatCircleBearing } from 'geolib';
import {MultiLineString,LineString} from 'ol/geom';
import Feature from 'ol/Feature';


export const COORDTYPE_GPS = 'EPSG:4326'; // (World Geodetic System 1984) Standardized GPS coordinate system
export const COORDTYPE_MERCATOR = 'EPSG:3857'; // (Pseudo-Mercator -- Spherical Mercator) Projected coordinate system used for rendering maps in GoogleMaps/OpenStreetMap on tiles
/*
* @brief Modal popup to show the help contents for the groups actions
*/
export class GroupsHelpPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }
     /*
    * @brief Render the content of the card:
    */
     render() {
        return (
            <div className="groups-help-popup" >
                
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog groups-help-popup-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{
                                this.props.handleClose()
                            }  }
                >
                    <div className="groups-help-popup-content" >
                        <div className = "titleTop">Geofence grouping actions reference</div>
                        <div className = 'section'>
                            <div className = "sectionTitle">Hide Groups</div>
                            <div className = "sectionContent">Disable all Geofence region group color coding. Display all Geofence regions on the map.</div>
                        </div>
                        <div className = 'section'>
                            <div className = "sectionTitle">Show Groups</div>    
                            <div className = "sectionContent">Enable color coding of the Geofence region groups. Display all Geofence regions assigned to the group in the same color.</div>
                        </div>
                        <div className = 'section'>
                            <div className = "sectionTitle">List Groups</div>
                            <div className = "sectionContent">Open the <span style={{fontStyle:'italic'}}>Configured Geofence regions and groups</span> table for the site" popup.</div>
                            <div className = "sectionContent">Each Geofence region is listed with its associated Group and the Geofence actions that are currently enabled on the region.</div>
                        </div>
                        <div className = 'section'>
                            <div className = "sectionTitle">Configure Groups</div>
                            <div className = "sectionContent">Configure the settings that are applied to all regions in the Geofence group.</div>
                            <div className = "sectionContent">The following steps may be followed to configure the Geofence group settings:</div>
                            <div className = "sectionContent">1. Select "Configure Groups" from the <span style={{fontStyle:'italic'}}>Groups</span> dropdown menu.</div>
                            <div className = "sectionContent">2. Click on any region from the group on the map.</div>
                            <div className = "sectionContent">3. Click on the <span style={{color:'green',fontSize:'18px'}}>+</span> icon to add a new speed policy to the group.</div>
                            <div className = "sectionContent">4. Configure the policy to match the desired category, vehicle type, delta speed, and time threshold.</div>
                            <div className = "sectionContent">5. Optionally, click on the <span style={{color:'red'}}>X</span> icon to remove any policy from the group.</div>
                            <div className = "sectionContent">6. Click on submit to save all changes.</div>
                        </div>
                        <div className = 'section'>
                            <div className = "sectionTitle">Add to Groups</div>
                            <div className = "sectionContent">Add one or more Geofence regions to a Geofence group</div>
                            <div className = "sectionContent">The following steps may be followed to add regions to a Geofence group:</div>
                            <div className = "sectionContent">1. Select "Add to Group" from the <span style={{fontStyle:'italic'}}>Groups</span> dropdown menu.</div>
                            <div className = "sectionContent">2. Press and hold the SHIFT key.</div>
                            <div className = "sectionContent">3. Click on one or more regions on the map. The selected regions will be highlighted in yellow.</div>
                            <div className = "sectionContent">4. Release the SHIFT key. The <span style={{fontStyle:'italic'}}>Assign regions to a Group</span> popup will be opened automatically. </div>
                            <div className = "sectionContent">5. Enter a new Geofence group name, or choose an existing region name from the menu.</div>
                            <div className = "sectionContent">6. Note: All regions that are currently assigned to a Geofence group will be listed in red.</div>                            
                            <div className = "sectionContent">7. Click on submit to save all changes.</div>
                        </div>
                        
                        
                        
                        
                                                
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end of UserRoleHelpPopup 


/*
* @brief Return the median of a sorted array
*/
export const median = arr => {
    const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export class MapFetchingDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,            
        }
    }
     /*
    * @brief Render the dialog:
    */
     render() {
         let progressVal = 0;
         try {
            progressVal = Math.round((this.props.fetched/this.props.toFetch)*100);    
            progressVal = Math.min(progressVal,100);
            progressVal = Math.max(progressVal,0);
         } catch (error) {
         }

         let progressStyle = {
            width: "0%", 
            height: "15px"
        }
        progressStyle.width = progressVal+"%";

        let titleText = 'Journey';
        let pluralText = 'Journeys'
        if(this.props.stringType.includes("event")){
            titleText = 'Event';
            pluralText = 'Clips'
        }

        let fetchingString = "Fetching "+this.props.toFetch+ " "+pluralText;
        //Apply the details if available
        if(this.props.fetchDetails){
            // console.log("Add details: ",this.props.fetchDetails);
            fetchingString ="Fetching"
            let bAddComma = false;
            if(this.props.fetchDetails.events){
                fetchingString +=" "+this.props.fetchDetails.events+ " "+pluralText;
                bAddComma = true;
            }
            if(this.props.fetchDetails.alerts){
                if(bAddComma){fetchingString+=","}
                fetchingString +=" "+this.props.fetchDetails.alerts+ " Alerts";
            }
            
        }

        //Define the layout for the fetching stage
        let fetchingRender = <div>
                                {/*Show the number of journeys that are being retrieved versus total to retreive */}
                                <div>
                                    {"Fetching "+this.props.fetched+" out of "+this.props.toFetch}                                
                                </div>
                                    {/*Render a progress bar, set the progress as the width of the bar*/}
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-success progress-bar-striped" 
                                            role="progressbar" aria-valuenow={progressVal} aria-valuemin="0" aria-valuemax="100" 
                                            style={progressStyle}>
                                            <span className="sr-only">40% Complete (success)</span> 
                                        </div>
                                    </div>
                            </div>
        //Don't show a progress bar unless specified by the instance
        if(!this.props.progressBar){
            fetchingRender =    <div>
                                     {fetchingString}                                
                                </div>
        }

        let messageDiv = <div></div>;
        //Switch the dialog based on witch state we are displaying
        switch(this.props.dialogState){
            default:
            case 0:break; //Closed, or fetching            
            case 1: //finding
                messageDiv = <div>
                                <div>Finding {pluralText}...</div>
                                <Spinner />
                                {this.props.onCancel && <button className="btn btn-danger" 
                                    onClick={this.props.onCancel}                                                    
                                >Cancel</button>}
                            </div>
            break;
            case 2: //empty params
                messageDiv = <div>
                                <div>No {pluralText} available, please modify the filters </div>
                                <button className="btn btn-primary" 
                                    onClick={this.props.onApply}                                                    
                                >Close</button>
                            </div>
            break;
            case 3: //bad date range
                messageDiv = <div>
                                <div>Can only fetch journeys for a period up to 14 days . Please reduce your date range to less than 14 days </div>
                                <button className="btn btn-primary" 
                                    onClick={this.props.onApply}                                                    
                                >Close</button>
                            </div>
            break;
        }
        // console.log("Query promise: ",this.props.queryPromise)
        // console.log("Fetching state: ",this.props.dialogState)

        return (
            <ReactModal isOpen={(this.props.toFetch!=this.props.fetched)||this.props.dialogState>0} className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{titleText +" Fetch"}</h5>
                        </div>
                        <div className="modal-body">
                            <div className={"api-caller-message api-caller-state-" + this.state.apiState}>
                                {this.props.dialogState>0?
                                    <div>
                                         {messageDiv}
                                     </div>
                                :
                                    <div>
                                        {fetchingRender}
                                        {this.props.onCancel && <button className="btn btn-danger" 
                                            onClick={this.props.onCancel}                                                    
                                        >Cancel</button>}
                                    </div>
                                    
                                }
                                
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                        </div>
                    </div>
                    </ReactModal>

        )
     }//end render
}
 
export function hsv2rgb(h,s,v) 
{                              
    let f= (n,k=(n+h/60)%6) => v - v*s*Math.max( Math.min(k,4-k,1), 0);     
    return [f(5),f(3),f(1)];       
}  
 // Get the line color at dh
export function getColor(dh) {
    let dhScale = Math.min(1.0,(dh/255));
    if(dh<255){
        // console.log("Less 255: ",dh,dhScale,Math.floor(dh/17),Math.floor(dh/17)*17 )
         dhScale = Math.min(1.0,((Math.floor(dh/(16.428))*16.428)/255.0));
    }
    let hVal = 300-(300*dhScale); //invert the scale so that we start at purple and end at red

    let rgb = hsv2rgb(hVal,1,0.85);
    rgb[0] *= 255;
    rgb[1] *= 255;
    rgb[2] *= 255;
    rgb[3] = 0.6;
    return rgb
    // else return [ 2*(dh-128), 255-(2*(dh-128)), 0 ];
}

export function getTimeFromCoordinates(_feature, _coord){
    try {
        //Get the set of routeData from the MultiLineString
        var routes = _feature.get('routeData');
        
        let dist = 1e10;
        let distIndex = 0;
        let routeIndex = 0;
        //Iterate over the route and find the closest match to the current point
        for(let idx =0; idx < routes.length; idx++){
            var coords = routes[idx].coords;
            for (var i=1; i<coords.length; i++) {
                //Compute distance as a simple manhattan comparison
                let distTemp = Math.abs(coords[i][0] - _coord[0])
                distTemp += Math.abs(coords[i][1] - _coord[1])
                //Find the match, compare against the distance
                if (distTemp < dist){
                    dist = distTemp;
                    distIndex = i; //Save the index in the route
                    routeIndex = idx; //the the route index
                }
            }
        }
        //Return the closest speed and coordinate to the current location of the mouse pointer
        return {
            speed: routes[routeIndex].timeArray[distIndex],
            coord: routes[routeIndex].coords[distIndex],
        }
    } catch (error) {
        return -1;
    }
}
export function getSpeedFromCoordinates(_feature, _coord){
    // console.log("Hover: ",_coord, _feature)
    //get the coordinate array:
    try {
        //Get the set of routeData from the MultiLineString
        var routes = _feature.get('routeData');
        
        let dist = 1e10;
        let distIndex = 0;
        let routeIndex = 0;
        //Iterate over the route and find the closest match to the current point
        for(let idx =0; idx < routes.length; idx++){
            var coords = routes[idx].coords;
            for (var i=1; i<coords.length; i++) {
                //Compute distance as a simple manhattan comparison
                let distTemp = Math.abs(coords[i][0] - _coord[0])
                distTemp += Math.abs(coords[i][1] - _coord[1])
                //Find the match, compare against the distance
                if (distTemp < dist){
                    dist = distTemp;
                    distIndex = i; //Save the index in the route
                    routeIndex = idx; //the the route index
                }
            }
        }
        //Return the closest speed and coordinate to the current location of the mouse pointer
        return {
            speed: routes[routeIndex].speedArray[distIndex],
            coord: routes[routeIndex].coords[distIndex],
        }
    } catch (error) {
        return -1;
    }
}

/** 
 * Helper function to compute a simple Euclidian distance between two points
 * This is a faster approximation of the distance between GPS points but is
 * less accurate due to not compensating for the curvature of the earth
*/
export const distEuclid = (p1, p2) =>{
    try {
        var dx = p1[0]-p2[0];
        var dy = p1[1]-p2[1];
        return Math.sqrt(dx*dx+dy*dy);
    } catch (error) {return 0;}
}//end distEuclid

/** 
 * Helper function to read the csv entry and convert it to a JSON object
 * The GPS data entry consists of a timestamp, speed, longitude and latitude 
*/
const parseDataEntry=(_entry)=>{
    let dataValues = _entry.d.split(",");
    return {
        time: _entry.t,
        speed: parseFloat(dataValues[0]),        
        longitude: dataValues[1],
        latitude: dataValues[2],
    }
}//end parseDataEntry

/** 
 * Process the GPS data downloaded for the Journey. This is handled in two steps:
 *  1: Filter out spurious GPS data
 *  2: Group the remaining GPS data into routes that can be added to the OpenLayers map 
 *      *all data is taken from a single journey. Non consecutive data is shown as separated paths
 * Return: Arraay of continous paths found in the Journey
*/
export const processJourney = (_gpsData,_journey) =>{
    //===============================================================
    //Filter the received GPS data to remove outlier
    // The Journey is broken into routes based on continuous GPS data
    //===============================================================
    let journeyDetails = null;

    try {
         journeyDetails = filterGPS(_gpsData,_journey);    
    } catch (error) {
        console.log("fail on filter: ",error);
    }
    // console.log("Time to FilterGPS: ",new Date() - startFilterTime);
    // console.log("routes:" ,journeyDetails.routes.length)

    //===============================================================
    //Create a Journey object and add all the routes to the Journey
    //===============================================================
    let journeysToAdd = [];
    try {
        journeysToAdd= addRoutesToJourney(journeyDetails,_journey);
    } catch (error) {
        console.log("Fail add routes:" ,error)
    }
    // console.log("Return length: ",journeysToAdd.length);
    return journeysToAdd;
}//end processJourney


/**
 * Process the GPS data downloaded for the Journey. To be used ot create a road
 * The largest n number of continous segments are returned from the files, decreasing 
 * by size 
 * @param {*} _gpsData : set of GPS data saved by the DMS application
 * @param {*} _segments : number of segments to allow to return
 * @returns 
 */
export const processRoad = (_gpsData, _segments) =>{
    //===============================================================
    //Filter the received GPS data to remove outlier
    // The Journey is broken into routes based on continuous GPS data
    //===============================================================
    // let startFilterTime = new Date();
    let filteredReturn = null;

    try {
        let filtered = filterGPS(_gpsData); 

        //Apply segments limit
        if(_segments){            
            //Sort them in descending order 
            filtered.routes.sort((a, b) => {
                if (a.gps.length < b.gps.length) {  return 1;  }
                if (a.gps.length > b.gps.length) { return -1;}
                return 0;
            });
            //Take the first X available from the list
            filteredReturn = filtered.routes.slice(0,_segments);//requested count can be higher than available
        }else{
            filteredReturn = filteredReturn.routes
        }
    } catch (error) {
        console.log("fail on filter: ",error);
    }
    // console.log("Time to FilterGPS: ",new Date() - startFilterTime);
    // console.log("roads:" ,filteredReturn.length)

    return filteredReturn;
}//end processJourney

/** 
 * Apply the outlier rejection rules considering the current data entry and the previous entry 
 *   added to a displayed path
 * Rules applied:
 *   1: Speed cannot be negative
 *   2: Longitude/Latitude cannot be 0
 *   3: Consecutive points should not accelerate faster than 10km/h/s
 *          *if they do, then make sure the consecutive points are within 100 meters 
 * Return: Object with keys:
 *  -skipCount: (integer) Number of consecutive points rejected by the SpeedDelta/Distance rule
 *  -isRejected: (boolean) flag indicating if the entry should be rejected from the path
 *  
*/
function rejectOutliers(_currentEntry, _previousEntry, _timeDelta, _skippedPoints){
    let rejectPoint = false;
    //Remove the point from no GPS signal: -1 speed
    if(_currentEntry.speed < 0 ){  rejectPoint=true;} 
    //Remove a point if the the longitude is set to 0;
    if(_currentEntry.longitude===0 || _currentEntry.latitude===0){    rejectPoint=true;}

    //Look for outlier points that a momentarily outside the normal path
    //Filter based on instantaneous speed and distance
    //-----------------------------------------------------------------
    //If the acceleration is too high then reject the point
    let speedCurrent = _currentEntry.speed;
    let speedPrev = _previousEntry.speed;
    let accelVal = Math.abs(speedCurrent-speedPrev)/(Math.min(5,_timeDelta));        
    if(accelVal>10 ){ //Possible bad point, check the distance (only do this for potential issue points, since it costs more computation)        
        try {
            //Get the distance between the two points in meters
            var distanceMeters = getDistance({ latitude:_currentEntry.latitude, longitude:_currentEntry.longitude },{ latitude:_previousEntry.latitude, longitude:_previousEntry.longitude },0.5);       
            let distanceChange = distanceMeters/(Math.min(5,_timeDelta));        
            //Check if the distance is over the threshold for error detection
            if(distanceChange>100){ //100 meters per sample (1 second sampling rate)
                // timePrev = timeCurrent;
                _skippedPoints++;
                if(_skippedPoints<5){rejectPoint=true;} //allow up to 5 points to be rejected before starting a new route
            }else{_skippedPoints=0;}    
        } catch (error) {}
    }

    //Return the result of the outlier checks:
    return {skipCount: _skippedPoints,isRejected:rejectPoint}
}//end rejectOutliers
/** 
 * Filter the GPS data to remove outliers and group the remaining points into continuous 
 * routes. Each route of the Journey will consist of the following:
 *  gps: Array of consecutive GPS points (Longitude,Latitude)
 *  speed: Array of corresponding speed values recorded at each GPS point
 *  time: Array of timestamps marking when the GPS point was recorded
 * Return: Array of routes of continuous GPS data from the Journey 
*/
function filterGPS(_points,_journey){

    //Make sure the data is sorted chronologically:
    let sortedData = _points.sort(  (a,b) =>  (a.t > b.t) ? 1 : -1 );
    
    //Define persistant variables to accumulate over the iteration of the data array
    let minTimestamp = 100000000;
    let maxTimestamp =0;
    let skippedPoints = 0; //Track the number of consecutive points rejected by the outlier filter
    let gpsPoints = [];  //Array of consecutive GPS points 
    let speedPoints = []; //Array of consecutive speed values 
    let timePoints = []; //Array of consecutive timestamps
    

    //Define a return data structure
    let returnData ={
        routes:[],
        startTime: null,
        endTime: null,
        startDate: null,
        timeDuration: 0,
    }

    //Return if there is no data to parse
    if(sortedData.length <=1){ return returnData;  }

    //Initialize the previous time point:    
    let prevEntry = parseDataEntry(sortedData[0]); //this should hold the same value as the last value in the type arrays
    let currentEntry = parseDataEntry(sortedData[0]);

    //Iterate through all data
    for(var i= 1; i< sortedData.length; i++){
        currentEntry = parseDataEntry(sortedData[i])//Extract the current timestamp, speed, lat and lon from the data set
        //Record the min/max of the timestamps
        minTimestamp = Math.min(minTimestamp,currentEntry.time);  maxTimestamp = Math.max(maxTimestamp,currentEntry.time);

        //Compute the point to point time difference between samples points
        let timeDelta = currentEntry.time - prevEntry.time;  //prevEntry is the last point added to a displayed path
        timeDelta /=1000; // convert to seconds from ms;

        //Sanity checks the data, remove a point if the speed value is <0
        const {skipCount,isRejected} = rejectOutliers(currentEntry,prevEntry,timeDelta,skippedPoints);
        //update the skippedPoints counts based on the outlier return:
        skippedPoints = skipCount;

        //If the point was rejected from the outlier detection then do not add it to the path
        //Skip to the next point to analyze:
        if(isRejected === true){ continue; }
        
        //Check if we had temporal jump, or if we have had to discard a many points
        if(timeDelta > 5 || skippedPoints>5 || gpsPoints.length>3600){ //limit the linestrings to 1hour of coordinates
            //Time skipped, start a new route of the Journey:
            returnData.routes.push({ //add the current points to the returned route 
                gps: [...gpsPoints],
                speed: [...speedPoints],
                time: [...timePoints]
            });
            //Clear the building arrays:
            gpsPoints=[];
            speedPoints=[];
            timePoints=[];
            skippedPoints = 0;
        }

        //Add the current GPS data to arrays to be used in the route
        gpsPoints.push([currentEntry.longitude,currentEntry.latitude]); //create the array of GPS points
        speedPoints.push(parseFloat(currentEntry.speed));               
        timePoints.push(currentEntry.time);
        //Track the previous point in the route for delta comparisons, use a JSON serialization to make sure the data is copied
        // and not just linked 
        prevEntry = JSON.parse(JSON.stringify(currentEntry)); //record the last entry that was added to the arrays
    }//end loop over all entries in the gpsData JSON file
    
    //Push the last of the data onto a route
    returnData.routes.push({
        gps: [...gpsPoints],
        speed: [...speedPoints],
        time: [...timePoints]
    });

    //Get the date details from the Journey:
    returnData.timeDuration = maxTimestamp - minTimestamp; //Timespan covered by the GPS log    
    if(_journey){
        let dateString = _journey.timestart.replace('T',' ');
        dateString = dateString.replace('Z','');
        returnData.startTime =moment(dateString).format("HH:mm:ss");
        returnData.endTime = moment(dateString).add(returnData.timeDuration/1000,'seconds').format("HH:mm:ss") //add the time duration to the start time 
        returnData.startDate = moment(dateString).format("YYYY-MMM-DD");
    }
    //Return details extracted from the data
    return returnData;
}//end filterJourneyGPS


export const routeBuilder = (_filteredDetails, _lineString, _iRouteIdx) =>{
    const route = {
        speedArray: _filteredDetails.speed, //Add the speed value from the GPS coordinates
        timeArray: _filteredDetails.time, //Add the timestamps from the GPS coordinates
        routeIdx: _iRouteIdx++,
        gps: _filteredDetails.gps,
        coords: _lineString.getCoordinates(),//Coordinates on the route:
        distArray: null,
        bearingArray: null,
        lineLength:_lineString.getLength(),//projected length of the line
    }

    //Precompute data for the rendering of custom styles:
    //---------------------------------------------------
    //Compute the distances between coordinates:
    route.distArray=[];
    route.distArray[0] = 0;
    for (let i=1; i<route.coords.length; i++) {
        route.distArray.push(distEuclid(route.coords[i-1], route.coords[i]));
    }
    //Compute the bearing between two coordinates:
    route.bearingArray = [];
    for (let i=1; i<_filteredDetails.gps.length; i++) {
        let bearing = getGreatCircleBearing({ latitude:_filteredDetails.gps[i-1][1], longitude:_filteredDetails.gps[i-1][0] }
                            ,{latitude:_filteredDetails.gps[i][1],longitude:_filteredDetails.gps[i][0] });
        bearing *=  (Math.PI/180);
        bearing += Math.PI/2;
        route.bearingArray.push(bearing);
    }
    return route;
}


 /** Get the coordinate at a distance from the start
 * @param {number} r distance from the start 
 * @param {Array<Array<coordinate>>} seg if provided fill the segment concerned
 * @return {ol.coordinate}
 */

/** 
 * Create a Journey that can be added to OpenLayers from the continuous routes of data. 
 * The GPS points are converted into a PolyLine that can be displayed on the map. Each 
 * route is added to the Journey object so that it can be referenced from the OpenLayers feature 
 *  
 * Return: Array of route features that can be added to an OpenLayers map
*/
function addRoutesToJourney(_journeyDetails){
    let returnJourneys =[];

    // console.log("Add routes:" ,_journeyDetails);

    //Check if no GPS routes were found, remove the Journey if nothing is available
    if(!_journeyDetails.routes || _journeyDetails.routes.length ===0){
        return returnJourneys;
    }

     //Create the feature to add to the map:                 
     const journeyProperties = {
        //Display settings:
        type: 'route_arrows',//'route_speed_arrows','route',
        startpoint:true,
        endpoint:true,
        startLoc:null,
        endLoc:null,
        //General information about the route
        startDate: _journeyDetails.startDate,
        timeStart: _journeyDetails.startTime,
        timeEnd: _journeyDetails.endTime,
        timeDuration: _journeyDetails.timeDuration/1000,
        
        //Add arrays to hold precomputed data for each route
        routes:0,
        routeData:[],
    } //end journey properties
    let multiLine = new MultiLineString([]);

    try {
        let iRouteIdx = 0;
        //Iterate over the detected routes and format them to be added to the map display
        for(const route_ of _journeyDetails.routes){
            // console.log("Render route:" ,route_);
            //Add to the line and project to map coordinates:
            let lineString = new LineString(route_.gps)
            // console.log("Line String: ",lineString);
            //Transform to the mercator projection for the display
            lineString.transform(COORDTYPE_GPS, COORDTYPE_MERCATOR);
            //Add the LineString to the Journey's MultiLineString:
            multiLine.appendLineString(lineString);

            // //Add the line to the polyLine
            //  var pathInput = new Polyline({factor: 1e6}).writeGeometry(lineString);
            // //Extract the polyline
            // const path = new Polyline({factor: 1e6,}).readGeometry(pathInput, {dataProjection: COORDTYPE_GPS, featureProjection: COORDTYPE_MERCATOR,});

            //Define the precompute data set for the Route, these are used during the custom styles to return locations,
            // speed, time, and angle so that the render doesn't need to recompute the values on each pass            
            const route = {
                speedArray: route_.speed, //Add the speed value from the GPS coordinates
                timeArray: route_.time, //Add the timestamps from the GPS coordinates
                routeIdx: iRouteIdx++,
                gps: route_.gps,
                coords: lineString.getCoordinates(),//Coordinates on the route:
                distArray: null,
                bearingArray: null,
                lineLength:lineString.getLength(),//projected length of the line
            }

            //Precompute data for the rendering of custom styles:
            //---------------------------------------------------
            //Compute the distances between coordinates:
            route.distArray=[];
            route.distArray[0] = 0;
            for (let i=1; i<route.coords.length; i++) {
                route.distArray.push(distEuclid(route.coords[i-1], route.coords[i]));
            }
            //Compute the bearing between two coordinates:
            route.bearingArray = [];
            for (let i=1; i<route_.gps.length; i++) {
                let bearing = getGreatCircleBearing({ latitude:route_.gps[i-1][1], longitude:route_.gps[i-1][0] },{latitude:route_.gps[i][1],longitude:route_.gps[i][0] });
                bearing *=  (Math.PI/180);
                bearing += Math.PI/2;
                route.bearingArray.push(bearing);
            }
            journeyProperties.routeData.push(route);//add to the journey
            //Count the number of routes in the Journey
            journeyProperties.routes++;

            //Set the start location from the first route
            if(iRouteIdx === 1){ journeyProperties.startLoc=route.coords[0]}
        
            //Add the last location to the endLoc, let this overwrite so the last route to process remains
            journeyProperties.endLoc=route.coords[route.coords.length-1];

        }//end processing the journeys

        //Create the feature and return to the map:
        const journeyToAdd = new Feature(journeyProperties);
        journeyToAdd.setGeometry(multiLine);
        returnJourneys.push(journeyToAdd);
    } catch (error) {
        console.log("No routes? ",error,_journeyDetails);
    }
    return returnJourneys;
}//end addRoutesToJourney


/** 
 * Convert the input speed to a display unit. Given the site configuration the 
 * method will automatically convert between km/h and mph. All values are evaluated as km/h 
 * by default in the system
 * Return: Formatted speed value, with optional km/h or mph appended as a string
*/
export const formatSpeed = (_site,_speed,_withUnits) =>{
    let displayUnits = _withUnits || false;

    if(!_site){displayUnits = '';}
    let outSpeed = _speed;
    let outUnits = "km/h";    
    try {
        //Does the site specify the type of units to display?
        if(_site && _site.units){
            if(_site.units.includes("imperial")){ //If the units are imperial (USA) then convert to MPH
                outSpeed = Math.round(_speed*0.621371); //convert to MPH
                if(displayUnits){outUnits = "mph";}
            }
        }
        if(displayUnits){ //If we are showing a unit string, add it now
            return `${Number(outSpeed).toFixed(displayUnits.precision||0)} ${outUnits}`;
        }else{ //no display, then just return the speed
            // console.log("No Display selected? ",_withUnits,_site,displayUnits);
            return `${Number(outSpeed).toFixed(displayUnits.precision||0)}`
            // return outSpeed;
        }
    } catch (error) {
        console.log("Failed to convert: ",error);
        return null;
    }
}//end formatSpeed


/*
* Remove an infraction marker from the map given the infractionid
*/
export const removeMarker = (_infractionid, _overlaySource) =>{

    if(!_infractionid){return;}

    //Iterate over all the layers:
    const overlaySource = _overlaySource;
    Object.entries(overlaySource).forEach(([name_, layer_]) => {
        //Don't handle special layers:
        if(name_==='asset'){return;}
        if(name_==='geofence'){return;}
        // if(name_==='boundary'){return;}
        if(name_==='SpeedLimit'){return;}
        // this.state.overlayLayers['geofence'].setVisible(true);
        // console.log("Layers",_name,name_,layer_, layer_.getVisible())
        try {
            let featureSet = layer_.getFeatures();
            (featureSet || []).forEach( (feature_) =>{
                let featureInfractionID = feature_.get("infractionid");
                if(!featureInfractionID){return;} //don't compare against an empty feature

                if( featureInfractionID === _infractionid){
                    layer_.removeFeature(feature_);
                }

            });//end feature for each

        } catch (error) {
        }
    });//end layer for each
}//end removeMarker



