
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';

import './Site-Utils.css';

import { Combobox } from 'react-widgets'

import 'react-widgets/styles.css';

import { generateUniqueID } from '../Util.js';
import { getRegionDesc,drawPolygon } from '../UtilOpenLayers.js';

import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from '../Map/map-utils.js'

//OpenLayers
import {Map,View } from 'ol'
import { Tile, Vector } from "ol/layer";
import {  Vector as VectorSource, XYZ as XYZSource} from "ol/source";
import { fromLonLat,transformExtent} from 'ol/proj';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import { defaults as defaultControls} from 'ol/control';
import {Draw,Modify}  from 'ol/interaction';
import {  Stroke, Style } from 'ol/style';

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";

//Add the icon files
import rolesBis  from '../assets/userroles-bis.PNG';


/*
* @brief This is an modal dialog to be opened when addding or editing a user
*/
export class AddSite extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSiteNameUpdate = this.handleSiteNameUpdate.bind(this);
        // this.handleRegionUpdate = this.handleRegionUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleClear = this.handleClear.bind(this);
        // this.handleCommentEdit = this.handleCommentEdit.bind(this, 'comment');

        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.loadMap = this.loadMap.bind(this);

        this.apiKey = 'AIzaSyBRTVcYz0_cUoX8TlBpF40i5q3Scr-HObo'
        this.state = {
            shown: true,
            timestamp:null,

            groupList: this.props.userInfo.allowedGroups,
            group:null,
            
            email:this.props.email,
            knownUserNames:[],

            siteName:null,
            region:null,
            
            emailConflict:0,
            sitenameConflict: false,
            mode: this.props.Username?"edit":"create",
            showRolePopup:false,
            address: "Kala Pattar Ascent Trail, Khumjung 56000, Nepal",
            position: null,
            boundary:null,
            defaultPosition: null,
            // {
            //     lat: 33.413733507261774,
            //     lon: -110.97229217401843
            // },
            mMap:null,
        };
    }
    
    componentDidMount() {
        

        try {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(data=>{
                    this.setState({defaultPosition:{
                        lat:data.coords.latitude,
                        lon:data.coords.longitude
                    }})
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
            }    
        } catch (error) {
        }

        setTimeout(() => {
            console.log("Map elem: ",this.state.mMap);
            this.loadMap();
        }, 500);
    }
    /*
    * @brief run once on startup
    */
    loadMap() {
        console.log("Open site: ",this.props);

        let centerLat = this.state.defaultPosition.lat;
        let centerLon = this.state.defaultPosition.lon;

        
        //DEFINE the map tile sources
        //================================
        //Get data from HERE, the extent and minZoom constrain the region that is pulled from HERE
        var hereLayer = new Tile({
            source: new XYZSource({
                url: 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/png8?apiKey=YaibLoQqb4TyE5urE7yIl2JS4dnR8P0zbWMFrgFWGs8'
            }),
            extent :  transformExtent([119.72217825630148,-22.604643313893195, 120.08187458098014,-22.34583588618293], COORDTYPE_GPS, COORDTYPE_MERCATOR),
            minZoom: 13.5,
            // extent: '[13325019.32213908, -2580963.0505704763, 13371550.148508567, -2555194.983377812]',
            visible:true,            
        });
        //Get data from Google, the default source for all the tiles
        var googleLayer = new Tile({
            source: new XYZSource({
                url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', //satellite hybrid (? why no api key?)
            }),
            visible:true,
        });

         //--------------------------------------------------------
        //Create layers to show geofenced regions:
        var overlaySource = new VectorSource({});
        var overlayLayer = new Vector({
            source: overlaySource,
            style: new Style({
                stroke: new Stroke({
                    color: 'white',
                    lineDash: [8],
                    width: 4,
                })
            }), 
            visible:true,         
        });
       
         var mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(6),
            projection: 'EPSG:4326',
            // projection: 'EPSG:3857',
        });

        //Define the map:
        const map = new Map({
            //  Display the map in the div with the id of map
            target: 'siteMap',
            layers: [
                googleLayer,
                hereLayer,
                overlayLayer
            ],
            // Add in the following map controls
            controls: defaultControls().extend([mousePositionControl]),
            // Render the tile layers in a map view with a Mercator projection
            view: new View({
                center: centerLon? fromLonLat([ centerLon,centerLat]):null,
                zoom: 13
            })
        })



        let interactions =[];
        let drawPoly = new Draw({
            source: overlaySource,
            type: 'Polygon',            
        });
        drawPoly.setActive(true);
        drawPoly.on('drawstart', function(evt){
        });
        drawPoly.on('drawend', evt => {
            drawPoly.setActive(false);
            let regionDesc = getRegionDesc(evt.feature,'polygon');
            overlaySource.clear();
            overlaySource.addFeature(drawPolygon(JSON.parse(regionDesc.coordinates)));
            this.setState({
                position:{lat:regionDesc.center.lat, lon:regionDesc.center.lon},
                boundary: regionDesc.coordinates
            })

        });
        interactions['drawpoly'] = drawPoly;
        map.addInteraction(drawPoly);
        

        let modifyRegions = new Modify({source: overlaySource});
        //Handle the event that is fired when the modification stops:
        modifyRegions.on('modifyend',evt=>{
            //Extract the feature from the event
            let feature = null;
            try{
                if(evt.feature){feature = evt.feature;  }
                else{
                    let features =  evt.features.getArray();
                    feature = features[0];
                }
            }catch(e){}
            //get the new region definition from the modified feature
            try {
                if(feature){
                    let regionDesc = getRegionDesc(feature,'polygon');
                    console.log("Extracted Modified: ",regionDesc);
                    this.setState({
                        position:{lat:regionDesc.center.lat, lon:regionDesc.center.lon},
                        boundary: regionDesc.coordinates
                    })    
                }else{
                    console.log("No feature?: ",evt,evt.features.getArray());
                }
            } catch (error) {}
            
        })
        map.addInteraction(modifyRegions);


        // console.log("Map: ",map);
        this.setState({mMap:map, mOverlaySource: overlaySource, mInteractions:interactions});
    }

    handleClear(){
        this.state.mInteractions['drawpoly'].setActive(true);
        this.state.mOverlaySource.clear();
        this.setState({position: {lat: 0,lon: 0}, boundary:null});
        // this.setState({mMap:map, moverlaySource: overlaySource});
    }
    

    handleLocationChange ({ position, address, places }) {
        // Set new location
        this.setState({ position, address });
      }
    
   
    /*
    * @brief handle the username field update request
    */
    handleSiteNameUpdate(_data){
        if(this.state.mode==='edit'){return;}
        let updatedSiteName = _data.target.value;
        //Test against the known users:
        let bConflict = false;
        //Check the new name against the currenlty used names:
        if(this.props.siteNames.includes(updatedSiteName.toLowerCase())){
             bConflict = true;
        }
        //update the tracked state:
        this.setState({siteName:updatedSiteName, sitenameConflict:bConflict});
    }//end handleUsernameUpdate
    /*
    // * @brief handle the email field update request
    // */
    // handleRegionUpdate(_data){
    //     //update the tracked state:
    //     this.setState({region:_data.target.value});
    // }//end handleEmailUpdate
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        let returnData = {};        
        //collect the data from the card
        //package into object
        returnData.name = this.state.siteName;
        returnData.region = this.state.region;

        returnData.location = this.state.position;
        returnData.boundary = this.state.boundary;
        returnData.id = generateUniqueID();

        // console.log("Submit new site: ",returnData,this.state);
        // return;

        //return to the list
        if(this.props.handleSubmit){this.props.handleSubmit(returnData,'newsite');}

        //Release the map
        try {
            if(this.state.mMap){
                this.state.mMap.setTarget(null);
                this.setState({mMap:null});
            }
        } catch (error) {
            // console.log("Failed to release: ",this.state.mMap,error);
        }

    }//end handleSubmit
    /*
    * @brief handle closing the card with submit
    */
    handleCancel(_data){
        //return to the list
        if(this.props.handleClose){this.props.handleClose();}
        //Release the map
        try {
            if(this.state.mMap){
                this.state.mMap.setTarget(null);
                this.setState({mMap:null});
            }
        } catch (error) {
            // console.log("Failed to release: ",this.state.mMap,error);
        }
    }//end handleSubmit
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }
        
        //Test the fields for reserved values:
        let siteNameClassName = "entry-field-wide";
        let siteNameConflictMessage = null;
        if(this.state.sitenameConflict){
            siteNameClassName+="-conflict"
            siteNameConflictMessage = "Site Name not unique";
        }
        
        let siteLocationClassName = "entry-field-wide";
        
        // console.log("Submit: ",this.state.emailConflict,this.state.usernameConflict);
        // console.log("Show Help: ",this.state.showRolePopup)
        //Set the style and the size of the map:
        const mapStyle = {
            backgroundColor: '#cccccc',
        }

        let positionString = null;
        try {
            positionString = this.state.position.lat +","+this.state.position.lon;
        } catch (error) {
        }

        return (
            <div className="site-utils" style={style}>
                <ReactModal isOpen={this.state.shown} className="modal-dialog site-utils-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="site-utils-content" style={style} >
                        {/* <div className='c'>Card Data</div> */}

                        <div className = 'um-title'>                                                  
                            {this.state.mode === 'create'?"Create New Site":"Site Details"}
                        </div>

                        <div className="um-spec">
                            <div className="title-wide">Site Name</div>
                            <input type="text" id="usernameInput" className={siteNameClassName}
                                value={this.state.siteName}
                                // onChange={(e) => {this.setState({username:e.target.value})}}/>
                                onChange = {this.handleSiteNameUpdate}
                                disabled={this.state.mode==='edit'?true:false}
                            />
                            {siteNameConflictMessage && <div className="title-wide-error">{siteNameConflictMessage}</div>}
                        </div>

                        <div className="um-spec">
                            <div className="title-wide">Location</div>
                            <input type="text" id="usernameInput" className={siteLocationClassName}
                                value={positionString}
                                // onChange={(e) => {this.setState({username:e.target.value})}}/>
                                // onChange = {this.handleUsernameUpdate}
                                disabled={this.state.mode==='edit'?true:false}
                                placeholder='0,0'
                            />
                        </div>
                        
                        <div className="um-spec">
                            <div className="title-wide">Region</div>
                            <Combobox className="entry-field-wide"
                                hideEmptyPopup={true}
                                data={this.props.regionNames}
                                value={this.state.region}
                                // onChange = {this.handleRegionUpdate}
                                onChange={(value) => {this.setState({region: value})}}
                            />  
                        </div>

                        

                        
                        <button className="btn btn-warning" 
                            onClick={this.handleClear}
                        >Clear</button>
                        <div id='siteMap' style={mapStyle} className = "site-olMap" ></div>                 


                       

                        <div className="um-footer">
                            <div className="adding-buttons">
                                <button className="btn btn-danger" 
                                     onClick={this.handleCancel}
                                >Cancel</button>
                                 
                                {/* {(this.state.emailConflict===0 && !this.state.usernameConflict && !roleConflictMessage) && */}
                                {(!this.state.sitenameConflict) &&
                                    <button className="btn btn-primary" 
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                } 
                            </div>
                        </div>
                              
                    </div>
                </ReactModal>

                { this.state.showRolePopup &&
                    <UserRoleHelpPopup 
                        handleClose={()=>{this.setState({showRolePopup:false})}}   
                        groupconfig = {this.props.groupconfig}
                    />
                }
            </div>
        );
    }
}//end AddSite

/*
* @brief Modal popup to show the help img
*/
export class UserRoleHelpPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }
     /*
    * @brief Render the content of the card:
    */
     render() {
        return (
            <div className="usermanagement-popup" >
                
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog usermanagement-popup-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{
                                this.props.handleClose()
                            }  }
                >
                    <div className="help-popup" >
                        <img className="roleimg" src={rolesBis} alt="roles" />
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end of UserRoleHelpPopup 
