import React, { Component, PureComponent } from 'react';

import { InfoWidget, FlatGoogleChart, FlatRadialChart, TimelineGraph, RadialImageChart, TopDriverWidget, PolarGraph } from './BaseComponents.js';

import { EDGE3_PRIMARY_COLORS, EDGE3_SECONDARY_COLORS,EDGE3_TERTIARY_COLORS, filterToTime, displayInfraction,getColorByType } from './Util.js';

import { setupPerf } from './Perf.js';

import * as moment from 'moment';

/*
* @brief Display the chart that shows the proportions of each infraction type
*/
export class InfractionChart extends PureComponent {
    constructor(props) {
        super(props);
        setupPerf(this, 'InfractionChart', () => {return this.props.summary && this.props.summary.timeUploaded !== undefined;});
    }
   
    render() {
        // console.log("Colors:" ,EDGE3_TERTIARY_COLORS.concat(EDGE3_SECONDARY_COLORS.concat(EDGE3_PRIMARY_COLORS)));
        const summary = this.props.summary;
        //  console.log("Summary: ",summary);
        var infractionProportions = [['Infraction Type', 'Count']];

        let totalInfractions = summary.totalInfractions || 0;
       
        if(summary.infractions){
            //Loop over the infractiosn and push them onto a list of name,count
            Object.keys(summary.infractions).forEach(function(key) {
                if(key === 'Irrelevant'){return;}
                if(key === 'totalinfraction'){return;}
                if(key === 'Egress'){return;}

                infractionProportions.push([key, summary.infractions[key]])
            });
            
            totalInfractions = summary.infractions.totalinfraction || 0;
            // console.log("Found total infractions; ",totalInfractions)
        }

        if(totalInfractions){
            totalInfractions -= summary.infractions['Irrelevant']
        }
        
        // const totalInfractions = summary.totalInfractions || 0;
        // console.log("Total", totalInfractions)
        if (totalInfractions === 0) {
            // console.log("ADD NONE?")
            infractionProportions.push(['None', 1]);
        }
        const titleTextStyle = {
            color: "#757575",
            fontSize: 16,
            bold: true,
        };

        let titleText= 'Overall Infraction Proportions';
        //Change the name of the graph title for pintovalley
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley' || this.props.groupconfig.group.toLowerCase() === 'demo_group'){
            titleText = "Infraction Proportions"
        }

        //  console.log("Data: ",summary,totalInfractions,infractionProportions)
        // console.log("Pie data: ",infractionProportions[1])
        //Signal the loading is complete if an infraction is returned in the summary
        //Required to support the withLoadingAnimation wrapper
        if(totalInfractions>1){
            if(this.props.loadingComplete){ this.props.loadingComplete(true) }
        }
        


        //Format the slices in the pie to show the fixed colors:
        let slices = {};
        let offsetIndices = [1,3,5,7,8,11,13,15,17];
        for (let [index, value] of infractionProportions.entries()) {
            if(index === 0){continue;}
            let indexToWrite = index -1;
            //  console.log("Values",index,value,getColorByType(infractionProportions[index][0]));
            if(offsetIndices.includes(indexToWrite)){
                
                slices[indexToWrite] = {
                    color: getColorByType(infractionProportions[index][0]),
                    offset: 0.2,
                }
            }else{
                slices[indexToWrite] = {color: getColorByType(infractionProportions[index][0])}
            }
        }
        
        //Enable hiding the chart as it collects empty data in CSS
        //The google chart loads on a delay if not preloaded while the data is being fetched
        //Rendering Chart... will be displayed if load not performed in background
        let graphidName = 'infraction-proportions'
        //Check if hideRender has been defined, this is required to support the withLoadingAnimation wrapper
        //If set to true, then the component needs to perform the task to fetch/analyze data, but not render the result
        if(this.props.hideRender){
            graphidName = 'infraction-proportions-hide'
        }

        return (

                <FlatGoogleChart 
                    chartType="PieChart"
                    data={infractionProportions}
                    titleText = {this.props.hideRender?"":titleText}
                    options={{  
                                legend: {textStyle: {fontSize: 10}, alignment:'center'},
                                slices: slices,
                                titleTextStyle: titleTextStyle,
                                pieStartAngle: 285,
                                }}
                    graph_id={graphidName}
                    width="125%"
                    onClick={this.props.onClick}
                />
            
            
        );
    }
}

/*
* @brief Highlights reduction chart component
*/
export class HighlightsReductionChart extends Component {
    constructor(props) {
        super(props);
        setupPerf(this, 'HighlightsReductionChart', () => {return this.props.summary &&
                                                                  this.props.summary.timeUploaded !== undefined &&
                                                                  this.props.summary.timeTotalInfractions !== undefined;
                                                          });
    }
    shouldComponentUpdate(nextProps) {
        const summary = this.props.summary;
        const nextSummary = nextProps.summary;
        // If either summary was undefined, only update if that changed between them
        if (summary === undefined || nextSummary === undefined) {
            return summary === nextSummary;
        }
        // If the state of undefinedness between both hasn't changed, there's no need to update
        if ((summary.timeTotalInfractions === undefined || summary.timeUploaded === undefined) ===
            (nextSummary.timeTotalInfractions === undefined || nextSummary.timeUploaded === undefined)) {
            return false;
        }
        // Otherwise only change if the actual data changed
        return !(summary.timeUploaded === nextSummary.timeUploaded &&
                 summary.timeTotalInfractions === nextSummary.timeTotalInfractions);
    }
    render() {
        const summary = this.props.summary;
        const percent = summary.timeUploaded ? 100 - summary.timeTotalInfractions / summary.timeUploaded * 100 : 100;
        return (
            <FlatRadialChart title="Reduction in Reviewable Video" percent={percent} color="#5d9cec" size={180} />
        );
    }
}

/*
* @brief Processed video time chart, shows the proportion of video that has been processed
*/
export class ProcessedVideosChart extends PureComponent {
    constructor(props) {
        super(props);
        setupPerf(this, 'ProcessedVideosChart', () => {return this.props.summary && this.props.summary.timeProcessed !== undefined;});
    }
    render() {
        const summary = this.props.summary;
        // Determine what percent of the uploaded videos we've processed, by time
        const processedTime = summary.timeProcessed || 0;
        const uploadedTime = summary.timeUploaded || 0;
        const unprocessedTime = uploadedTime - processedTime;
        var unknownVehicle = processedTime;
        const rows = [];
        rows.push(['Unprocessed', unprocessedTime]);
        const byVehicle = summary.vehicleTimeProcessed;
        for (var k in byVehicle) {
            if (byVehicle.hasOwnProperty(k)) {
                rows.push([k, byVehicle[k]]);
                unknownVehicle -= byVehicle[k];
            }
        }
        if (unknownVehicle > 0) {
            rows.push(['Processed', unknownVehicle]);
        }
        const titleTextStyle = {
            color: "#757575",
            fontSize: 16,
            bold: true
        };
        if (uploadedTime === 0) {
            rows.push(['None', 1]);
        }
        rows.map((row) => {
            row[1] = row[1] / 3600;
        });
        return (
            <FlatGoogleChart
                chartType="PieChart"
                data={[['Category', 'Hours']].concat(rows)}
                options={{legend: 'none',
                            title: 'Processed Video Time',
                            colors: EDGE3_SECONDARY_COLORS,
                            titleTextStyle: titleTextStyle
                            }}
                graph_id="processed-videos-proportion"
                width="100%"
            />
        );
    }
}

/*
* @brief A component for the infractions over time graph
*/

export class InfractionGraphClass extends PureComponent {
    constructor(props) {
        super(props);
        this.dataClick = this.dataClick.bind(this);
        setupPerf(this, 'InfractionGraph', () => {return this.props.infractions && this.props.infractions.length > 0;});
        this.state = {
            minDay: null,
            maxDay: null,
            timeFilter: 'all',
            timeSinceRefresh: new Date(),
        }
    }

    dataClick(index) {
        // console.log("Data click:", index, this.mapToDay[index], this.mapToDay);
        if(this.props.onDayClick){   this.props.onDayClick(this.mapToDay[index],this.props.serviceType); }
    }
    render() {
        // console.log("Render the timeline: ",this.props);        
        const start = filterToTime(this.props.filter.time);

        let endDate = null;
        if(this.props.filter.time.endDate){
            endDate=moment(this.props.filter.time.endDate,'YYYY-MM-DD')
            
        }

        if(this.props.infractions.length ===0){
            // console.log("Empty data")
            //Check if the loadingComplete callback is defined, if found, report that the data is pending 
            //Required to support the withLoadingAnimation wrapper
            if(this.props.loadingComplete){this.props.loadingComplete(false);}
            //Check if hideRender has been defined, this is required to support the withLoadingAnimation wrapper
            //If set to true, then the component needs to perform the task to fetch/analyze data, but not render the result
            if(this.props.hideRender){    return false;}
        }
        let  infractions = (this.props.infractions || []).filter((inf) => {
            return start.isSameOrBefore(inf.dayOnly, 'day');
        });
        //Check if the loadingComplete callback is defined, if found, report that the data has returned
        //Required to support the withLoadingAnimation wrapper
        if(this.props.loadingComplete){this.props.loadingComplete(true);}

        const local_infractionsType = {}
        if(this.props.infractionsByType){
            // console.log("ByType: ",this.props.infractionsByType)
            for (const [type_, value_] of Object.entries(this.props.infractionsByType)) {
                // console.log("Type:" ,type_,value_);
                let value = value_ || [];//assume empty set if not found
                local_infractionsType[type_] = local_infractionsType[type_] || [];
                local_infractionsType[type_] = value.filter((inf) => {
                     return start.isSameOrBefore(inf.dayOnly, 'day');
                });
                if(endDate){
                    local_infractionsType[type_] = value.filter((inf) => {return endDate.isAfter(inf.dayOnly, 'day');});
                }
            }
            // console.log("By Type:" ,local_infractionsType);
        }
        
        //  console.log("Infractions: ",this.props.infractionsByType)
        //  console.log("Infractions: ",local_infractionsType)

        let minDay = start;    
        let maxDay =  moment().startOf('day');
        if(endDate){
            maxDay = endDate;
        }
        
        //Scan the data to establish the min day if we want all
        if(this.props.filter.time === 'all' ){
            minDay = moment().utc().add(1, 'day');    
        }
        const byDay = {};
        //Loop over all the infraction data from the API return
        infractions.map(inf => {
            // console.log("Date: ",inf.dayOnly.toString())
            const infMoment = moment(inf.dayOnly).utc();
            if (inf.countSum) {
                minDay = moment.min(minDay, infMoment);
            }
            const dateValue = infMoment.format('YYYY-MM-DD');
            byDay[dateValue] = inf.countSum;
        });

        // console.log("Day Range: ",minDay,maxDay);
        

        //Track the min day, so that the timeline doesn't reset when changing filters
        if(this.props.filter.time === 'all' ){
            if(!this.state.minDay){ //handle the initial case
                this.setState({minDay:minDay});
            }else{
                if( minDay.isBefore(this.state.minDay)){
                    this.setState({minDay:minDay});
                }else{
                    minDay = this.state.minDay;
                }
                
            }
            
        }
        //Leave the max day as the current day
        // let maxDay =  moment().startOf('day');

        const infractionsByDay = [];
        const infractionsByType = [];
        const xLabels = [];
        const today = moment().startOf('day');
        this.mapToDay = [];
        //Enable the cumulative infractions:
        let runningTotal = 0;
        let bCumulative = false;
        //Set cumulative view for the following groups:

        // console.log("ByDay: ",byDay)

        // console.log("Min day: ",start,minDay,this.props.infractionsByType);

        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){
            // bCumulative = true;
        }
        
        let trackByType = []
        const currentDay = minDay.clone();
        // console.log("Days:", minDay, maxDay);
        for ( currentDay; currentDay.isBefore(maxDay, 'day'); currentDay.add(1, 'day')) {
            const formatted = currentDay.format('YYYY-MM-DD');
            // console.log("Current day: ",formatted)
            this.mapToDay.push(formatted);
            runningTotal += parseInt(byDay[formatted],10) || 0;
            
            if(bCumulative){
                infractionsByDay.push(runningTotal);                
            }else{
                infractionsByDay.push(byDay[formatted] || 0);
                //Go over all values:
                for (const [type_, value] of Object.entries(local_infractionsType)) {
                    if(type_.toLowerCase()==='irrelevant'){continue;}
                    if(!this.props.infractionsToDisplay.includes(type_)){ 
                        // console.log("Skipping type: ",type_,this.props.infractionsToDisplay);
                        continue;
                    }
                    infractionsByType[type_] = infractionsByType[type_]||[]
                    
                    trackByType[type_] = trackByType[type_]||[]
                    try{
                        let found = local_infractionsType[type_].find(elem_ => elem_.dayOnly === formatted);
                        // if(type_==='Other - Unsafe' && formatted === '2021-10-12'){
                        // if(formatted === '2021-10-12'){
                        //     console.log("Matched found ",found,formatted)
                        // }
                        if(found){
                            infractionsByType[type_].push(found.count);    
                            trackByType[type_].push(found);
                        }else{
                            infractionsByType[type_].push('-');    
                            trackByType[type_].push('-');
                        }
                    }catch(error){
                        // console.log("Error in loop:" ,error);
                    }
                    
                }
            }
            
            xLabels.push(currentDay.format('MMMM Do, YYYY'));
        }
        // console.log("XLabels: ",xLabels,this.props.infractionsByType);
        let titleText = "Offline Infractions Over Time";
        if(this.props.serviceType.includes('TRIFECTA')){
            titleText = "Live Infractions Over Time";
        }
        let dataText = "Infractions"
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley' || this.props.groupconfig.group.toLowerCase() === 'demo_group'){
            titleText = "Infractions Over Time";
            dataText = "Infractions"
        }
        //  console.log("Data:" ,infractionsByDay,infractionsByType,xLabels)
        // console.log("INfractions: ",infractionsByType,local_infractionsType,trackByType,xLabels)
        return (

            <TimelineGraph title={titleText} dataTitle={dataText}
                           data={infractionsByDay} byType={Object.keys(local_infractionsType).length >0 ?infractionsByType:null} xLabels={xLabels}
                           onDataClick={this.dataClick} serviceType={this.props.serviceType}
            />
        )
    }
}

/*
* @brief A component for the infractions over time graph
*/
export class InfractionPolarGraph extends PureComponent {
    constructor(props) {
        super(props);
        this.dataClick = this.dataClick.bind(this);
        setupPerf(this, 'InfractionPoalGraph', () => {return this.props.infractions && this.props.infractions.length > 0;});
        this.state = {
            minDay: null,
            maxDay: null,
            timeFilter: 'all',
            timeSinceRefresh: new Date(),
        }
    }

    dataClick(index) {
        // console.log("Data click:", index, this.mapToDay[index], this.mapToDay);
        // if(this.props.onDayClick){   this.props.onDayClick(this.mapToDay[index],this.props.serviceType); }
    }
    render() {

        if(!this.props.hourly.byHour){        
            //Check if the loadingComplete callback is defined, if found, report that the data is pending
            //Required to support the withLoadingAnimation wrapper
            if(this.props.loadingComplete){this.props.loadingComplete(false);}
            return false;
        }
        //Check if the loadingComplete callback is defined, if found, report that the data has returned
        //Required to support the withLoadingAnimation wrapper
        if(this.props.loadingComplete){this.props.loadingComplete(true);}



        const xLabels = [];
        const infractionsByHour = [];
        //  const infractionsByType = [];
      
            
        let quantSize = this.props.hourly.quantSize;
        let quantStep = 60/quantSize; //60/10 -

        //Create an entry for every quantized point
        let hourSet = {};        
        let minutesVal =0;
        for(let i=0; i<24; i++){
            for(let j=0; j<quantStep; j++){
                minutesVal = i*60 + j*quantSize;
                hourSet[minutesVal] = { count:0 }
            }
        }
        
        // console.log("Filter:" ,this.props.filter);
        //Switch to clockwise orientation
        let dataToPlot = this.props.hourly.byHour;
        if(this.props.filter && this.props.filter.infractions && this.props.filter.infractions!=='all' ){
            
            // console.log("Render data -type:" ,this.props.filter.infraction, this.props.hourly)
            // console.log("Data: ",this.props.hourly.byHourType[this.props.filter.infraction])            
            dataToPlot = this.props.hourly.byHourType[this.props.filter.infractions];
            if(!dataToPlot){dataToPlot= {}}
        }
        
        
        let maxCount = 0;
        for (const [type_, value] of Object.entries(dataToPlot)) {
            // let hourText = String(i).padStart(2, '0')+':00','
            //Convert time to minutes:
            let splitParts = type_.split(":");
            let typeVal = 0;
            // if(parseInt(splitParts[0],10) === 0){                
            //     typeVal = 24*60 + parseInt(splitParts[1],10);
            //     // typeVal = parseInt(splitParts[0],10)*60 ;
            // }else{
                typeVal = parseInt(splitParts[0],10)*60 + parseInt(splitParts[1],10);
                // console.log("Time: ",typeVal,splitParts);
            // }
            try {
                hourSet[typeVal] = hourSet[typeVal] || { count:0 }
                if(value && value.count){
                    hourSet[typeVal].count=value.count;
                    maxCount = Math.max(maxCount,value.count)
                }
                // console.log("Type and value: ",type_,typeVal,value,splitParts);    
            } catch (error) {
                console.log("Failed: ",error,typeVal, value);
            }
        }
        // console.log("Keys: ",Object.keys(hourSet))
        // console.log("HoursSet: ",hourSet);
        let iCount = 0;
        // let countMax = 0;
        for (const key of Object.keys(hourSet)) {
            infractionsByHour.push(hourSet[key].count)
            iCount++;
        }
        // infractionsByHour.reverse(); //reverse the direction for clockwise

        //Create the labels, only set the label on the hour
        for(let i=0; i<24; i++){
            for(let j=0; j<quantStep; j++){
                if(j === 0){ //set the label on the hour
                    xLabels.push(   String(i).padStart(2, '0')+':00');
                }else{ //and space holder, need to match labels to data
                    xLabels.push('');
                }
            }
        }        
        // xLabels.reverse();

        if(xLabels.length ===0){
            return (<div></div>)
        }else{
            // console.log("Counts:" ,infractionsByHour);
        }
        // console.log("Infractions by hour: ",hourSet,infractionsByHour)

        
        
        let maxVal = maxCount;
        if(maxCount > 100){   maxVal = Math.round(maxCount / 10) * 10;}
        if(maxCount > 150){   maxVal = Math.round(maxCount / 50) * 50;}        
        if(maxCount <= 100){   maxVal = Math.round(maxCount / 5) * 5;}
        if(maxCount < 10){  
            maxVal =Math.ceil(maxCount / 5) * 5; //force to a multiple of 5 to make sure we get no decimals            
        }
        // let maxVal = 300;
        
        // 

        //  console.log("Data to render: ",infractionsByHour,maxVal,maxCount);
        let titleText = "INFRACTIONS BY TIME OF DAY";
        let dataText = "Infractions"
        return (
            
            <PolarGraph title={titleText} dataTitle={dataText} legend={this.props.filter.infractions}
                                    data={infractionsByHour}  xLabels={xLabels}
                                    onDataClick={this.dataClick} maxValue={maxVal}
                                    filterCount={this.props.filterCount}
                        />
            
            
        )
    }
}
