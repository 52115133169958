import React, { Component } from 'react';

import { Auth, API } from 'aws-amplify';

import { ApiCaller } from './ApiCaller.js';

import { VIDEO_STATUS, delayPromise, displayInfraction, displayStatus,filenameAlphaStripper } from './Util.js';

import { setupPerf } from './Perf.js';

import { HrReviewFilters } from './ReviewFilters.js';

import * as moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ProgressBar from 'react-bootstrap/ProgressBar'
import cellEditFactory from 'react-bootstrap-table2-editor';
import './VideoLister.css';


// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 7; // in seconds

/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class VideoLister extends Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);
    this.updateAuditor = this.updateAuditor.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.refreshApiCall = this.refreshApiCall.bind(this);
    this.onLoadingState = this.onLoadingState.bind(this);
    this.formatSecondsTime = this.formatSecondsTime.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.onFilterSelected = this.onFilterSelected.bind(this);

    this.state = {
      videos: [],
      currentUsername: null,
      reviewers: null,
      retryCount: 0,
      startDate:null,
      endDate:null,//moment().add(-30, 'days'),
      filters: {},
    };
    // setupPerf(this, 'VideoLister', () => {return this.state.videos && this.state.videos.length > 0;});
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }
  refreshApiCall() {
    // this.props.refreshNeed = false;
    const promiseReturn = this.getApiCall();
    promiseReturn.then(data => this.updateData(data))
    promiseReturn.catch(function(error) {
      // console.log("Refresh VideoLister API error: ",error);
    });
  }
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
    // console.log("Calling API as: ",this.props.groupconfig)
    const realPromise = Auth.currentSession().then(
      (auth) => {
        let apiName = "AuthLambda";
        let path = "/listVideos";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            filter: this.props.filter,
            getReviewerList: this.props.groupconfig.group.includes("reviewgroup") || this.props.groupconfig.group.includes("testinggroup"),
            startDate: this.state.startDate,
            endDate: this.state.endDate,          
            timezone:  moment().format('Z'),  
          }
        };

        try {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
        } catch (error) {
        }

        return API.post(apiName, path, myInit);
      }
    )
    .catch((error) => {
      console.error("VL api call; ",error); 
    }); 
      
    if (DEBUG_DELAY_API_RESPONSE) {
      return delayPromise(realPromise, DEBUG_DELAY * 1000);
    } else {
      return realPromise;
    }
  }

  formatSecondsTime(_seconds){
    var dur = moment.duration({'seconds':_seconds});
    var hours = Math.floor(dur.asHours());
    var mins  = Math.floor(dur.asMinutes()) - hours * 60;
    var sec   = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;
    // console.log("Seconds: ",_seconds,hours);
    var hoursTXT = ("0" + hours).slice(-2);
    if(hours > 99){hoursTXT =("0" + hours).slice(-3);}
    var minsTXT = ("0" + mins).slice(-2);
    var secTXT = ("0" + sec).slice(-2);

    var result = hoursTXT + ":" + minsTXT + ":" + secTXT;
    return result;
  }
 
  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(data) {
      // console.log("VL: ",data,this.props);
    console.log("VL: ",data.results.length);
    if(!data.results){return;}
    
    // console.log("Inf names: ",this.props.groupconfig.infractionSet);
    let maxTimeStamp = null; 
    //Filter: iterate over the elements, creates a new [] full of the true returns (note necessarily the same size)
    const withSummedInfractions = data.results.filter(row=>{ //Apply a filter first to the returned list
      // console.log("Row:" ,row)
      row.status = parseInt(row.status,10); //Convert the variable to an integer (safe on strings and numbers)
      
      if(row.received.includes('const_')){          
        let dateFormatted = moment(row.received.replace('const_',''),"YYYY-MM-DD HH-mm-ss").format("YYYY-MM-DD HH:mm");
        row.received = dateFormatted;
      }else{
        row.received = moment(row.received).format("YYYY-MM-DD HH:mm");
      }
      if(row.status===VIDEO_STATUS.INPROGRESS){
        //Find the most recent row set to InProgress:
        if(!maxTimeStamp){maxTimeStamp= moment(row.received);} 
        else{
          if(moment(row.received).isAfter(maxTimeStamp)){maxTimeStamp=moment(row.received);}
        }  
      }

      //Format with the SiteID
      if(this.props.possibleFilters){
        try {
          let siteDetail = (this.props.possibleFilters.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === row.siteid.toLowerCase()})
          //  console.log("Site detail: ",siteDetail,siteDetail[0].alias);
          // console.log("Site detail: ",siteDetail);
          if(siteDetail && siteDetail[0] && siteDetail[0].alias){
            row.siteid = siteDetail[0].alias;    
          }  

          //Rename the file using the alias:          
          let compressedAlias = siteDetail[0].alias.replace(/\s+/g, '');//remove white space
          //Break into the parts
          let nameparts = row.filename.split('_');
          // console.log("name parts: ",nameparts,compressedAlias);
          row.filename = nameparts[0]+'_'+compressedAlias+'_'+nameparts[2] 
          // console.log("New name: ",row.filename);
        } catch (error) {
        }
      }
      
      if(data.allowedids){ //If we have a whitelist of IDS then the rows must match this
        if(data.allowedids.includes(row.uniqueKey)){
               return row;       
        }
      }else{return row; }
    })

    //Map: review the each element, then return into a new [], returns can be valid data or null/undefined [row,null,row] (same size)
    .map(row => {
    // const withSummedInfractions = data.results.map(row => {
      row.InfractionCount = 0;
      //Testing key: leave for future tests:  // row.uniqueKey="0e9a860a-41c5-11e8-8068-0a03aeb838ee"; //all video redirect to test page
      const keys = Object.keys(row)
      //Look at each key in the row: (key,value pair)
      for (const key of keys) {        
        if(key.includes("Count") && key!=='InfractionCount'){           
          //Sum the infraction count based on the allowed infractions:
          if(this.props.groupconfig.disabledInfractionSet && !this.props.groupconfig.disabledInfractionSet.includes(key.replace("Count",""))){
            row.InfractionCount += row[key] || 0;  
          }
        }
      }
      
      //Change the lenght from a seconds to hh:mm:ss time format
      if(row.length){row.length = this.formatSecondsTime(row.length);  }

      //Set the DriverID to "..." if a valid id is not present
      if(row.driverId){
        let driverIDset = row.driverId.split(',');
        if(driverIDset.length>=3){  
          row.driverId = driverIDset[0]+","+driverIDset[1]+",..."; 
        }else if(row.driverId.length<=1){  
          row.driverId = "..."; 
        }
      }else{
        row.driverId = row.driverId||"...";
      }
      row.reactKey = row.uniqueKey + '-' + row.filename;

      //Change the status for simulated videos 
      if (row.simulated) { row.status = VIDEO_STATUS.PENDING;  }
      
      // console.log("Row.status: ",row.status, row.status === VIDEO_STATUS.AVAILABLE, typeof(row.status), typeof(VIDEO_STATUS.AVAILABLE));
      //Handle the new asset tag, this should only be displayed if we are in the testing uploader:
      if(this.props.groupconfig.group && this.props.groupconfig.group ==="testinggroup"){        
        if(row.status === VIDEO_STATUS.AVAILABLE && !row.roi){
            row.status = VIDEO_STATUS.NEWASSET;
        }
      }
      //Format the status as a progress bar
      if(row.status === VIDEO_STATUS.AVAILABLE && row.tagsreviewed && row.tagsreviewed > 0){
        row.reviewprogress = row.tagsreviewed/(row.InfractionCount||1000);
        row.status = Math.round(row.reviewprogress * 100)+100;
      }

      if(row.status===VIDEO_STATUS.INPROGRESS){        
        //Mark all rows with older timestamps as "Waiting:"
        if(moment(row.received).isBefore(maxTimeStamp)){
          row.status = VIDEO_STATUS.WAITINGFORUPLOAD;
        }
      }
      
      return row; //return the modified row to the map call
    });
    const zeroedPendingInfractions = withSummedInfractions.filter(row=>{ //Apply a filter first to the returned list
      // if(row.InfractionCount>0 && row.status!== VIDEO_STATUS.PENDING){ //If we have a whitelist of IDS then the rows must match this
      if(this.props.groupconfig.mode && this.props.groupconfig.mode.includes("drive")){ //only filter in the drive portal
        if(row.InfractionCount > 0){
          return row;
        }else{
          if( row.status === VIDEO_STATUS.PENDING){
            return row;
          }
        }
      }else{ //don't filter the clouddetect results
        return row;
      }
    }).map(row => {
      //Check the status of the video, if it is pending or unusable, do not display a clip count:
      if (row.status === VIDEO_STATUS.PENDING || row.status === VIDEO_STATUS.UNUSABLE|| row.status === VIDEO_STATUS.PROCESSING) {
        row.InfractionCount = null;
      }
      return row;
    });

    let reviewers = null;
    try{
      reviewers = data.reviewUsers.map( row_ =>{
        return row_.username;
      })
    }catch(e){

    }
    //  data.reviewUsers || null;
    // console.log("VL: ",zeroedPendingInfractions)
    this.setState({videos: zeroedPendingInfractions, reviewers: reviewers});

  }

   /*
    * @brief Apply the filter changes to cards:
    */
   applyFilters(){
    // console.log("Apply filter selected");
    //Re-execute the api request:
    this.setState({filtersChanged: false});
    setTimeout(() => {this.setState({retryCount: this.state.retryCount+1});}, 500);
  }
  /*
  * @brief A callback for when a specific filter, by name, is set to a particular value
  */
  onFilterSelected(name, value) {
      // console.log("OnFilterSelected: "+name+": ",value);
      // Handle setting filters for date changes
      if(name==='startDate'){this.setState({startDate:value.value})}
      if(name==='endDate'){this.setState({endDate:value.value})}
      //Update the state
      this.setState({
          filtersChanged: true,
      });
  }

  /* @brief Update the assigned reviewer in the displayed list
  */
  updateAuditor(data) {    
    try{
      const updatedVideos = this.state.videos.map(row => {
        //Check the status of the video, if it is pending or unusable, do not display a clip count:
        if (row.uniqueKey === data.uniqueKey) {        
          row.reviewerName = data.reviewerName;
        }
        return row;
      });
      this.setState({videos:updatedVideos});
    }catch(e){}
  }
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
    this.setState({videos:[]});
    try{
      //Try to force remove all child objects of the video-lister:
      var es = document.getElementsByClassName("video-lister");
      [...es].forEach(e=>{
        var child = e.lastElementChild;  
        while (child) { 
            e.removeChild(child); 
            child = e.lastElementChild; 
        } 
      });
      
  }catch(e){
    console.log("Error on VL release: ",e);
  }
  }
  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {

    // this displays a date nicely instead of it being in ISO8601 format
    const dateFormatter = (date, row, rowIndex, extraData) => {
        let dateObj = moment(date);
        return dateObj.format("YYYY-MM-DD HH:mm"); // can configure displayed date format freely here
    };
    // this just displays a dash instead of empty cells when there's no data
    const nullableFormatter = (thing, row, rowIndex, extraData) => {
      if (thing === undefined || thing === null || thing === '') {
        return <span>&mdash;</span>;
      }
      return thing;
    }
    // Update the names to display in the status column using a list, if no translation is provided, the input is returned
    const statusFormatter = (status,row, rowIndex) => {
      // console.log("Status: ",status,rowIndex);
      let displayVal ='';
      if(status >=99){
        let progress = status - 100;
        progress = Math.min(progress,100);
        progress = Math.max(progress,0);
        displayVal =<div><ProgressBar className="testpb" striped variant="success" now={progress} key={"progbar-"+rowIndex} label={`${progress}%`}  /></div>        
      }
      else{
        displayVal = displayStatus(status, 'Pending');
        if(displayVal.includes("Unusable")){displayVal = "Unusable By AI"}
        if(displayVal.includes("Inprogress")){displayVal = "In Progress"}
        if(displayVal.includes("Newasset")){displayVal = "New Asset"}
        // if(displayVal.includes("Waitingforupload")){displayVal = "Waiting For Upload"}
        //  if(displayVal.includes("Waitingforupload")){displayVal = "Uploading..."}
        if(displayVal.includes("Waitingforupload")){
          displayVal = <div className="status-waiting" >
            <div>Waiting</div> 
            <div>For Upload</div>
            </div> 
        }
        
         

        
      }
      return displayVal
    };
     // Update the names to display in the filename column using a list, if no translation is provided, the input is returned
     const srcfileFormatter = (filename) => {
      if(this.props.groupconfig.group && this.props.groupconfig.group ==="reviewgroup"){
        return filenameAlphaStripper(filename);
      } else{
        return filename;
      }
      
    };
    // Compare the lenght of the videos, sort function is a simple LT GT comparison
    const lengthSorter = (a, b, order) => {
      // Sort statuses by their displayed value
      let res = 0;
      if (a < b) {  res = 1; } else if (a > b) {    res = -1;   }
      return order === 'asc' ? res : -1 * res;
    };
    const statusSorter = (a, b, order) => {
      // Sort statuses by their displayed value
      let displayA = a>=100?a:displayStatus(a);
      let displayB = b>=100?b:displayStatus(b);
       
      //Group the partially reviewed together with the available
      if(a >=99){displayA = displayStatus(2); }
      if(b >=99){ displayB = displayStatus(2); }


      let res = 0;
      if (displayA < displayB) {
        res = 1;
      } else if (displayA > displayB) {
        res = -1;
      }
      return order === 'asc' ? res : -1 * res;
    };

    
    /* Describe the columns of the table
    * 'dataField' is the name of the key in 'data' list that it will read from
    * 'text' is what it will display on the webpage for the column header
    * 'formatter' is a custom formatting function it will use for that column to transform from
    *             the data in the list to what it displays
    * 'sort' (set in the loop below cols) sets if you should be able to sort by that column
    * 'hidden' is if the column should be hidden from view, this is useful so we can get a
    *          react 'key' from a column that we don't actually want to show to the user
    */
    const videoData = this.state.videos;
    let cols = [
      {dataField: 'status', text: 'Status',editable:false, formatter: statusFormatter, sortFunc: statusSorter},
      {dataField: 'filename', text: 'Filename',editable:false, formatter: srcfileFormatter},
      {dataField: 'received', text: 'Time',editable:false, formatter: dateFormatter},
      {dataField: 'length', text: 'Length',editable:false, formatter: nullableFormatter,sortFunc: lengthSorter},
      {dataField: 'InfractionCount', text: 'Clips',editable:false, formatter: nullableFormatter},
    ];
    /*
      Add infraction columns to the table, leave most hidden
      Only unhide the column that we're filtering/sorting by
    */
    this.props.groupconfig.infractionSet.map(inf => {
      let hidden = true;
      if (this.props.filter) {
        hidden = inf !== this.props.filter.infractionType;
      }
      //cols.push({dataField: inf+'Count', text: displayInfraction(inf), formatter: nullableFormatter, hidden: hidden});
      cols.push({dataField: inf+'Count', text: displayInfraction(this.props.groupconfig.displayNameSet),editable:false, formatter: nullableFormatter, hidden: hidden});
    });


    /*@brief Send the updated username to the SQL table via the AuthLambda API
    */
    const updateAssignmentInSQL = (_id,_username) => {
      // console.log("Update assignment?")
      Auth.currentSession().then(
        (auth) => {
          let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              apiName: "updateAssignment",
              id: _id,                    
              username: _username,
            }
          };
          return API.post("AuthLambda", "/apiRouter", myInit);
        }
      ); //end promise definition     
    }

    /*@brief handle the click on the assign button
    */
    const reassignClick = (e,cell,row) => {
      try{
        e.stopPropagation(); //console.log("Click on button",e,cell,row);
        let promptString = "Assign video reviewer to?"
        let auditorSet = "";
        try{
          //Create a suggested list based on the available users:
          auditorSet+="("
          this.state.reviewers.map(name_ => {auditorSet+=name_+", "});
          auditorSet = auditorSet.slice(0, -2);
          auditorSet += ")";
        }catch(e){
          auditorSet = auditorSet.slice(0, -1);
        }
        var promptReturn = window.prompt(promptString+auditorSet,"sabbas");
        if (promptReturn) { //if anything was entered
          // Ask the user to confirm that the new user is correct
          if(window.confirm("Assign to "+promptReturn)){
            
            if(this.state.reviewers.includes(promptReturn)){
              updateAssignmentInSQL(row.uniqueKey,promptReturn);
              row.reviewerName = promptReturn;
              this.updateAuditor(row);
            }else{
              if(window.confirm(promptReturn+" not found in "+auditorSet+". Proceed with assignment?")){
                updateAssignmentInSQL(row.uniqueKey,promptReturn);
                row.reviewerName = promptReturn;
                this.updateAuditor(row);
              }
            }
            
          }
        }//end prompt return
      }catch(e){
        // console.log("Click handle error: ",e);
      }
    }
    
    let hideAssign = true;
    if(this.state.currentUsername && (this.state.currentUsername ==='review_user' || this.state.currentUsername ==='testing_uploader') ){hideAssign = false;}
    const assignFormatter = (cell,row) => {
      let displayVal =<div>
        <button type="button" className="btn btn-outline-success btn-sm ml-2 ts-buttom" size="sm" onClick={(e)=>reassignClick(e,cell,row)}>
                 Assign
             </button>
        </div>        
      return displayVal
    };

    /*
      Add in the rest of the columns after the infraction counts
    */
    let hiddenCol = false;
    let hideSite = false;
    if(this.props.groupconfig.group && this.props.groupconfig.group==="reviewgroup"){
      hiddenCol = true;
    }
    let hideReviewer = true;
    if(this.state.currentUsername && (this.state.currentUsername ==='review_user' || this.state.currentUsername ==='testing_uploader') ){hideReviewer = false;}

    if(this.props.groupconfig.group && this.props.groupconfig.group==="testinggroup"){ hideSite = false;}



     /*@brief handle the click on the commit button
    */
   const commitClick = (e,cell,row) => {
    // console.log("Commit click?")
    try{
      e.stopPropagation(); //console.log("Click on button",e,cell,row);
      // let promptString = "Commit change to SQL table?"
      var promptReturn = window.prompt("Change ("+row.driverId +") to :");
      if (promptReturn) { //if anything was entered
      
        // Ask the user to confirm that the new user is correct
        if(window.confirm("Commit changes to the SQL table?")){
          console.log("Update: ",row,this.state.videos);
          const videos = this.state.videos;
          let newVids = videos.map(row_ => {
            //Check the status of the video, if it is pending or unusable, do not display a clip count:
            if (row_.uniqueKey === row.uniqueKey) {
              console.log("Update the driverID");
              row_.driverId = promptReturn;
            }
            return row_;
          });
          this.setState({videos: newVids});

          Auth.currentSession().then(
            (auth) => {
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  apiName: "updateDriverID",                    
                  data: row,    
                  driverid:  promptReturn,               
                }
              };
              return API.post("AuthLambda", "/apiRouter", myInit);
            }
          ); //end promise definition
        }
      }
    }catch(e){
      // console.log("Click handle error: ",e);
    }
  }
  
    const driveridFormatter = (cell,row) => {
      // console.log("IDform:" ,cell,row);
      let displayVal = <div>{row.driverId}</div>
      if(this.props.groupconfig.group==="devgroup" || this.props.groupconfig.group==="drive_test_group" || this.props.groupconfig.group==="testinggroup"){
        displayVal =<div>
                <button type="button" className="btn btn-outline-success btn-sm ml-2 ts-buttom" size="sm" onClick={(e)=>commitClick(e,cell,row)}>
                    {row.driverId}
                </button>
          </div>   
      }
      //      
      return displayVal
    };

    cols = cols.concat([
      {dataField: 'siteid', text: 'Site ID', editable:false, formatter: nullableFormatter, hidden:hiddenCol},
      {dataField: 'driverId', text: 'Driver ID',editable:false, formatter: driveridFormatter, hidden:hiddenCol},      
      {dataField: 'vehicleId', text: 'Asset ID',editable:false, formatter: nullableFormatter, hidden:hiddenCol},      
      {dataField: 'reviewerName', text: 'Auditor',editable:false, formatter: nullableFormatter, hidden:hideReviewer},      
      {dataField: 'uniqueKey', text: 'Unique Key',editable:false, hidden: true},
      {dataField: 'reactKey', text: 'React Key',editable:false, hidden: true},
      {dataField: 'simulated', text: 'Simulated',editable:false, hidden: true},
      {dataField: 'assigned', text: 'AssignTo',editable:false, formatter: assignFormatter,hidden: hideAssign},
    ]);

    

    cols.map(col => {
      col.classes = 'vid-' + col.dataField;
      return col;
    });

    for (var i = 0; i < cols.length; ++i) { // set all the columns as sortable
      cols[i].sort = true;
      cols[i].headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => {
        if (isLastSorting) {
          return "sorted-by sorted-" + sortOrder;
        }
        return "";
      };
    }
    const rowEvents = {
      
       onClick: (e, row, rowIndex) => {
        //  console.log("Row clicked: ",e,row,rowIndex);
         this.props.videoClicked(row);
       }
    };
    //  {/* <ProgressBar className="testpb" striped variant="success" now={35} key={1} label={`30%`}  /> */}
    const rowClasses = (row, rowIndex) => {
      return 'videolist-row row-status-' + displayStatus(row.status).toLowerCase();
    };

    /*
      Set style for the rows in the table     
    */
    const rowStyle = (row, rowIndex) => {
      // let rowcolor = '#DEFCB4'
      let rowcolor = '#00afed05'
      if(rowIndex%2 ===0){
        // rowcolor = '#F1F7E9'
        rowcolor = '#00afed20'
      }
      return{
        backgroundColor: rowcolor,
      }
      //return { ... };
    };

    return (
      <div className="video-lister">
        {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} retryCount = {this.state.retryCount}/>:null}
        <div className="analytics-toprow">
          <HrReviewFilters className = "analytics-filters" filters={this.state.filters} onFilterSelected={this.onFilterSelected} onApply={this.applyFilters} hasChanged={this.state.filtersChanged} startDate={this.state.startDate} endDate={this.state.endDate}/>
          <div className="table-filters" > {this.props.filterDisplays}   </div>
        </div>
        
        <BootstrapTable keyField='reactKey' // a react specific thing that sets the 'key' for each row in the table
                                             // react uses keys to keep track of identity when things change
                        data={videoData} // <-- IMPORTANT: this is the actual data being displayed
                        columns={cols}
                        defaultSorted={[
                                        {dataField: 'received', order: 'desc'}, // how things should be sorted by
                                        {dataField: 'status', order: 'desc'},
                                        {dataField: 'filename', order: 'asc'}
                                      ]} // default when first displayed
                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                                       // keep track of what data belongs to what row when scanning across
                        rowStyle={ rowStyle}
                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                                       // mouse is over it. This signals to the user that the row is clickable
                        classes={"video-table"} // sets a CSS class so we can style this table specifically
                        rowEvents={rowEvents} // set what events we want to happen on rows, right now just the click
                        rowClasses={rowClasses}
                        cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                        bootstrap4 = {true}
        />
      </div>
    );
  }
}

export { VideoLister };