
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';
import { Auth, API } from 'aws-amplify';

import './GeofenceManagement.css';

import BootstrapTable from 'react-bootstrap-table-next';
import { SpeedPolicySettings } from './SpeedPolicySettings';

import {Combobox } from 'react-widgets'
import 'react-widgets/styles.css';


// this just displays a dash instead of empty cells when there's no data
const actionTypeFormatter = (thing, row, rowIndex, extraData) => {
    if (thing === true ) {
        return <span>&#10003;</span>;        
    }else{
        return <span>&mdash;</span>;        
    }
}

/*
* @brief This is an modal dialog to be opened when addding or editing a user
*/
export class GeofenceManagement extends PureComponent {
    constructor(props) {
        super(props);

        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.handleAssignClick = this.handleAssignClick.bind(this);

        this.state = {
            shown: true,
            regionList: [],
            baseRegionList: [],
            columns:[],
            assignGroup:null,
            selected:[],
            groupList:[],
            groupSettings: null,
        };
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        console.log("Open management: ",this.props);
       let apiPromise = this.getApiCall();
       apiPromise.then(data => {
         this.updateData(data);
       });

       //configure the table columns to show:
        let cols = [
            {dataField: 'region', text: 'Region name',editable:false, sort:true,headerStyle: () => {return { width: "15%",whiteSpace:"unset",textAlign:"left"};}},
            {dataField: 'groupname', text: 'Group',editable:false,sort:true,
            // events: {
            //     onClick: (e, column, columnIndex, row, rowIndex) => { 
            //         e.stopPropagation();
            //         let data = {
            //             group: row.groupname,
            //         }
            //         this.setState({groupSettings: data})
            //     },
            //   }
            },
            {dataField: 'bEnter', text: 'Enter',editable:false,sort:true,formatter: actionTypeFormatter },
            {dataField: 'bExit', text: 'Exit',editable:false,sort:true, formatter: actionTypeFormatter},
            {dataField: 'bSpeeding', text: 'Speeding',editable:false,sort:true, formatter: actionTypeFormatter},
            {dataField: 'bDwell', text: 'Dwell',editable:false,sort:true, formatter: actionTypeFormatter},
            {dataField: 'bAssetCount', text: 'AssetCount',editable:false,sort:true, formatter: actionTypeFormatter},
            // {dataField: 'email', text: 'Contact',editable:false,},

        ];
        //set the classnames for each column:
        cols.map(col => {
            col.classes = 'gf-' + col.dataField;
            return col;
        });
        this.setState({columns: cols})
    }

     /*
    * @brief The definition of the API call that we need to do to display this list
    */
     getApiCall() {
    //    console.log("Get regions: ",this.props);
       //define the list of allowed sites to return:

       const regionPromise = Auth.currentSession().then(
        (auth) => {
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    apiName: "handleGeoFence",
                    mode: "fetch",
                    site: this.props.sitename,
                }
            };
            return API.post("AuthLambda", "/apiRouter", myInit);
         })
         .catch((error) => {
            console.error("Failed on call to get regions; ",error); 
          }); 
         return regionPromise;
   }
 
  
   /*
   * @brief Takes care of updating the list with new data when we receive it
   */
   updateData(data) {
        // console.log("Region Data: ",data);

        let groupSet = new Set();
 
        //iterate over the list of sites
        let regionList = (data.regions || [])
        .filter(region_=>{
            //only return elements that are matched to the selected ids from the map:
            if(this.props.ids){
                return this.props.ids.includes(region_.id)?true:false;
            }else{
                return true;
            }
            
        })     
        .map(region_=>{
            //Set defaults for the display states:
            region_.bEnter = false;
            region_.bExit = false;
            region_.bSpeeding = false;
            region_.bAssetCount = false;
            region_.bDwell = false;
            //iterate over the tracked actions
            for(const event_ of region_.events_monitored){
                //break out each action as boolean enabled or disabled
                switch(event_.type){
                    case 'Speed': region_.bSpeeding = true; break;
                    default: break;
                }
            }

            //Add this group name to the list of groups?
            if(region_.groupname){
                groupSet.add(region_.groupname);
            }

            region_.reactKey = region_.id; //unique key for the table
            return region_;
        })

        // //add the new regions:
        // this.addRegionsToMap(data.regions);
        this.setState({regionList: regionList,baseRegionList: regionList, groupList:[...groupSet]});
   }
 
    /*
    * @brief Called on update to the input search field, filter the listed user names:
    */
    handleSearch(_data){
        console.log("Handle Search: ",_data,this.state.baseRegionList);
        let searchValue = _data.toLowerCase();
        // console.log("Test: ",this.state.baseRegionList[0].groupname,this.state.baseRegionList[0].groupname.toLowerCase().includes(searchValue)    )

        switch(_data){
            case 'All':{
                this.setState({regionList: this.state.baseRegionList});
            }break;
            case 'Unassigned':{
                //Filter the list of regions, only return those that are not assigned
                let filteredRegions = (this.state.baseRegionList || []).filter(region_=>{
                    try {
                        return region_.groupname?false:true; 
                    } catch (error) {
                        return false;
                    }
                    
                });
                //Update the list that is visible
                this.setState({regionList: filteredRegions});
            }break;
            default:{
                //Filter the list of regions by the received value:
                let filteredRegions = (this.state.baseRegionList || []).filter(region_=>{
                    try {
                        return region_.groupname.toLowerCase().includes(searchValue); 
                    } catch (error) {
                        return false;
                    }
                    
                });
                //Update the list that is visible
                this.setState({regionList: filteredRegions});
            }break;
        }
        

    }

    /*
    * @brief Callback when the modal dialog closes
    */
    handleClose(_data){
        // console.log("Handle close: ",_data);
        if(_data && _data === 'submit'){
            let apiPromise = this.getApiCall();
            apiPromise.then(data => {
                this.updateData(data);
            });
        }
        this.setState({assignGroup:null});
    }

    /*
    * @brief Handle when the assign to group is clicked, get the necessary data to forward
    */
    handleAssignClick(){
        console.log("Selected; ",this.state.selected);

        
        let selectedRegions =[];
        (this.state.regionList ||[]).forEach(region_ => {
            //filter the current regions based on the selected list:
            if(this.state.selected.includes(region_.id)){
                //Add the data that is needed for the assign dialog
                selectedRegions.push({
                    id: region_.id,
                    name: region_.name,
                    groupname: region_.groupname,
                })
            }
            
        });
        this.setState({assignGroup:selectedRegions})

    }
    
    
    /*
    * @brief Render the management inteface:
    */
    render() {
        const regionList = this.state.regionList;
        const tableColumns = this.state.columns;

        //Define the table interaction callbacks:
        const rowEvents = {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                
                 console.log("Row clicked: ",e,row,rowIndex, column, columnIndex);
            }
        };
        const rowClasses = (row, rowIndex) => {
            // return 'user-lister-row row-status-' + row.status;
            return 'geofence-lister';
        };
    
        /*
            Set style for the rows in the table     
        */
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){
             rowcolor = '#00afed20'
            }
            return{
             backgroundColor: rowcolor,
            }
        };

        /*
            Handle what happens when we select the checkbox on a row
        */
        const selectRow = {
            
            mode: 'checkbox', // single row selection
            //Hide selection buttons if write permision is not defined:
            hideSelectColumn: (this.props.groupconfig.permissions && this.props.groupconfig.permissions.livemap 
                                && this.props.groupconfig.permissions.livemap.map && this.props.groupconfig.permissions.livemap.map!=='write')?true:false,
            hideSelectAll: (this.props.groupconfig.permissions && this.props.groupconfig.permissions.livemap 
                && this.props.groupconfig.permissions.livemap.map && this.props.groupconfig.permissions.livemap.map!=='write')?true:false,
            //handle the callback when a single checkbox is selected            
            onSelect: (row, isSelect) =>{
                // console.log("Checkbox: ",row,isSelect,this.state.selected)
                if (isSelect) { //was the checkbox selected
                    this.setState(() => ({ selected: [...this.state.selected, row.id]})); //add the id to the list
                } else {
                    this.setState(() => ({  selected: this.state.selected.filter(x => x !== row.id) })); //filter out the deselected id from the list
                }
            },
            //Handle the callback when the select all is chosen
            onSelectAll: (isSelect, rows) => {
                const ids = rows.map(r => r.id); //get a list of all the region ids
                if (isSelect) { //did we select alll
                  this.setState(() => ({selected: ids })); //set the ids to all selected
                } else {
                  this.setState(() => ({selected: []})); //set the selected ids to empty
                }
              }
        }; //end handle on row selections
        
        let bAllowEdits = true;
        return (
            <div className="geofencemanagement">
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog geofencemanagement-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{ 
                                this.props.handleClose()
                            }  }
                >
                    <div className="geofencemanagement-content"  >
                        <div className="geoTitle">Configured Geofence regions and groups for {this.props.sitename}</div>
                        <div className="topRow">
                            {this.state.selected.length > 0 && 
                                <button className = {"AssignGroup"} onClick={this.handleAssignClick} >
                                    Assign To Group
                                </button>                     
                            }
                            {/* <div className='search-title'>Group Search: </div>
                            <Combobox className="groupSearch"
                                data={["All","Unassigned",...this.state.groupList]}
                                placeholder="Group Name"
                                onChange={(value) => {this.handleSearch(value)}}                                    
                                hideEmptyPopup
                                defaultValue={"All"}
                            /> */}
                        </div>
                        
                        {tableColumns.length>0 && 
                            <BootstrapTable 
                                        keyField='reactKey' // a react specific thing that sets the 'key' for each row in the table
                                                            // react uses keys to keep track of identity when things change
                                        data={regionList} // <-- IMPORTANT: this is the actual data being displayed
                                        columns={tableColumns}
                                        defaultSorted={[
                                                        {dataField: 'region', order: 'asc'}, // how things should be sorted by
                                                        {dataField: 'groupname', order: 'asc'}
                                                        ]} // default when first displayed
                                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                        // keep track of what data belongs to what row when scanning across
                                        rowStyle={ rowStyle}
                                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                                        //                  // mouse is over it. This signals to the user that the row is clickable
                                        classes={"geofence-lister"} // sets a CSS class so we can style this table specifically
                                        rowEvents={bAllowEdits?rowEvents:null} // set what events we want to happen on rows, right now just the click
                                        rowClasses={rowClasses}
                                        bootstrap4 = {true}
                                        detailView={true}
                                        detailViewIcon={false}
                                        detailViewByClick={true}
                                        selectRow={ selectRow }
                            />
                        }



                    </div>
                </ReactModal>
                { (this.state.assignGroup) &&
                    <AssignGroupPopup 
                        handleClose={(data)=>{this.handleClose(data)}}   
                        groupconfig = {this.props.groupconfig}
                        regions = {this.state.assignGroup}
                        groupList = {this.state.groupList}
                    />
                }
                { (this.state.groupSettings) &&
                    <SpeedPolicySettings 
                        handleClose={(data)=>{this.setState({groupSettings:null})}}   
                        {...this.state.groupSettings}
                    />
                }
            </div>
        );
    }
}//end GeofenceManagement

/*
* @brief Modal popup to set the group name
*/
export class AssignGroupPopup extends PureComponent {
    constructor(props) {
        super(props);
        
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            shown: true,
            group:null,
            groupList:  this.props.groupList,
            prevAssigned: null,
        }
    }

    
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        
        //get the list of ids from the regions
        let idsSelected = (this.props.regions || []).map(region_=>{
            return region_.id;
        })
        let idSet = null;
        if(idsSelected.length>0){
            idSet = idsSelected.join(',');
        }
        if(!idSet){
            console.log("failed to get IDset");
            return;
        }
        // console.log("Data to submit: ",this.state,idSet);
        //Execute the update query to the API:
        const submitPromise = Auth.currentSession().then(
            (auth) => {
              let myInit = {
                body: {
                  token: auth.idToken.jwtToken,
                  apiName: "handleGeoFence",
                  mode: "setGroupName",
                  id:idSet,
                  groupname: this.state.group,
                }
              };
              return API.post("AuthLambda", "/apiRouter", myInit);
        });

        submitPromise.then(data => {
            this.props.handleClose('submit')
        });
        submitPromise.catch(error => {
            this.props.handleClose()
        });

    }//end handleSubmit

    componentDidMount(){
        //Check if the regions are already assigned to a group
        console.log("Filter: ",this.props.regions)
        let prevAssigned =  (this.props.regions || []).filter(region_=>{
            console.log("region in filter:" ,region_);
            return region_.groupname?true:false;
        })
        console.log("Set prev: ",prevAssigned);
        this.setState({prevAssigned:prevAssigned});
    }

     /*
    * @brief Render the content of the card:
    */
     render() {
        // console.log("Render assign: ",this.props, this.state.prevAssigned);

        let style = {
            height: "15vh"
        }

        let warningMessage = null;
        if(this.state.prevAssigned){
            style.height = (15+(this.state.prevAssigned.length*2)) + "vh" //resize the modal box to fit a longer message
            warningMessage = <div className='warningmessage'>
                {this.state.prevAssigned.map((region_) => {
                    return(  <div key={region_.id+"warn"}>
                                {region_.name} is currently assigned to {region_.groupname}
                                </div> 
                        )
                })} 
            </div>
        }

        return (
            <div className="assigngroup" >
                
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog assigngroup-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{ 
                                this.props.handleClose()
                            }  }
                >
                    <div className="assigngroup-content" style={style} >
                        <div className = "geoTitle2">Assign regions to a Group</div>
                        <Combobox className="entry-field-wide"
                                data={this.state.groupList}
                                placeholder="GroupName"
                                onChange={(value) => {
                                    let nameToSet = value;
                                    
                                    if(nameToSet.length > 20){
                                        nameToSet = nameToSet.substring(0,20);
                                    }
                                    this.setState({group: nameToSet})
                                }}                                   
                                value = {this.state.group} 
                                hideEmptyPopup
                                dropUp                                
                        />
                        {warningMessage}
                        <div className="ag-footer">
                            <div className="adding-buttons">

                                <button className="btn btn-danger" 
                                        onClick={ ()=>{this.props.handleClose()}}
                                >Cancel</button>

                                {(this.state.group && this.state.group.length > 0) &&
                                    <button className="btn btn-primary" 
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                }
                            </div>
                        </div>
                        
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end of AssignGroupPopup 
