import React, { Component,PureComponent } from 'react';

import * as moment from 'moment';
import './RiskWorst.css';


import {severityFormatter, percentageToColor, pictureFormatter, radialFormatter, noteFormatter} from './RiskUtils.js'
import { WorstTile } from './WorstTile';
import { Spinner } from '../ApiCaller';

/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class RiskWorst extends Component {
  constructor(props) {
    super(props);
    
    this.onLoadingState = this.onLoadingState.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = {
        mDriverData: {},
        refreshCount: 0,
        retryCount: 0,
        
        activeView: 'callin',
        tabViews: [{key:'callin', name:'All'},{key:'status', name:'Camera + IR Status Call-ins'}],
        refreshTimeoutID:null,
        startDate: moment().subtract(30, 'days'),
        endDate:null,
      
    };
  }
  onLoadingState(state) {
    this.setState({loadingState: state});
  }

  componentDidMount(){
    // console.log("On Mount props:" ,this.props);
  }
  
   /*
  * @brief Clicked on a row to select the worst drive row tile
  */
   onClick(_data){
    // console.log("Clicked on: ",_data);
    if(this.props.onClick){this.props.onClick(_data)}
  }

  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {
 
   
    // const rowEvents = {
    //   // console.log("Row Events: ",e);
    //   onClick: (e, row, rowIndex) => {
    //     console.log("Clicked on row:" ,row, rowIndex);
    //     if(this.props.onClick){this.props.onClick(row)}
    //   }
    // };
    // const rowClasses = (row, rowIndex) => {
    //   return 'risklist-row';
    // };

    // /*
    //   Set style for the rows in the table     
    // */
    // const rowStyle = (row, rowIndex) => {
    //   let rowcolor = '#00afed05' 
    //   return{
    //     backgroundColor: rowcolor,
    //   }
    // };

    let scale = 1;
    if (this.state.winWidth && this.state.divWidth) {
        scale = this.state.winWidth / this.state.divWidth;
    }

    let bDisplayTable = true;
    let tableData = [];
    try {
        //Need to make a deep copy so that the table can see that the data has changed
        tableData = Object.values(this.props.driverData).map( row_ =>{
          return Object.assign({}, row_);
        })
        if(tableData.length === 0 && this.props.apiReturned){
          bDisplayTable = false;
        }
    } catch (error) {
    }
    
    if(!tableData || tableData.length===0){
      //  console.log("Data is empty? ",this.state);
      // return(null);  
    }
    //  console.log("Table data: ",tableData);

     tableData= (tableData||[]).sort( (a,b) => {
      // console.log("Compare: ",a,b);
      try {
          
          if(a.score < b.score){return 1;}
          return -1;
      } catch (error) {
          console.log("Error on compare: ",a,b);
          return 0;
      }
  }) 
    
    
    let worstTiles = ( tableData||[]).map( (worst_,idx) =>{

      //Find the current loading progress:
      let allowToLoad = false;
      try {
        let loadedIdx = this.props.loadState.findIndex(elem_=>{ return elem_===false});
        if(loadedIdx<0 || idx < loadedIdx+1){
          allowToLoad=true;
        }
      } catch (error) {
        allowToLoad = true;
      }

      // worstTiles.push ( 
      return <WorstTile {...worst_} loadAllowed ={allowToLoad} onClick = {this.onClick} idx ={idx} key ={"risktiles-"+idx}/>
      // return <WorstTile {...worst_} loadAllowed ={false} onClick = {this.onClick} idx ={idx} key ={"risktiles-"+idx}/>
    });//end drivertile populate

    const formatRender = (_worstTiles) => {

      if (this.props.noDataMessage){
        return(   <div className="riskworst"> {this.props.noDataMessage} </div>
              );
      }
      else{
        return(   <div className="riskworst">
                    {_worstTiles.length === 0 ? <div/> :
                      <div className='risktableheader'>
                        <div></div>
                        <div>Picture</div>
                        <div>DriverID</div>
                        <div>Count</div>
                        <div>RiskProfile</div>
                        <div>Interventions</div>          
                      </div>
                    }
                    {_worstTiles.length === 0 ? <Spinner /> :
                      <div className='risktable'>
                        {_worstTiles}
                      </div>
                    }
                </div>
              );

      }

  



    }

    return ( formatRender(worstTiles));

      
        
        
        
        
      
      //   {this.props.noDataMessage? this.props.noDataMessage:
      //     <
      //       {worstTiles.length === 0 ? <Spinner /> :
      //         <div>   
      //           <div className='risktableheader'>
      //             <div></div>
      //             <div>Picture</div>
      //             <div>DriverID</div>
      //             <div>Count</div>
      //             <div>RiskProfile</div>
      //             <div>Interventions</div>          
      //           </div>
      //           <div className='risktable'>
      //             {worstTiles}
      //           </div>
      //         </div>
      //       }    
          
      //   }
      // </div>
      
    // );
  }
}


export { RiskWorst };