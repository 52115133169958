import React, { Component } from 'react';

import './navbar.css';
import './userprofile.css'
import { FlatMenuBar} from './NavBar-menu.js'
import { FlatLogoBar } from './NavBar-logos.js'

/*
* Menu link definitions
*
* AUTHED is for when a user is logged in, UNAUTHED is for when they are not
*/
const AUTHED_MENU =
[ {text: 'Dashboard', }, //0
    {text: 'Analytics', }, //1
    {text: 'Reporting', }, //2
    {text: 'Live Map',}, //3
    {text: 'Driver Rankings',}, //4
    // {text: 'Admin',}, //5
    {text: 'Connectivity',}, //6
    {text: 'Upload', }, //7 
    {text: 'Call-ins', }, //7 
    {text: 'Risk Profile', }, //8 
    {text: 'Staff Performance', }, //9
];
const DASHBOARD = 0;
const ANALYTICS = 1;
const REPORTING = 2;
const MAP = 3;
const DRIVERRANKING = 4;
const ASSETSTATUS = 5;
const UPLOAD = 6;
const CALLINS = 7;
const RISKPROFILE = 8;
const STAFFPERFORMANCE = 9;


/* fill in unspecified hrefs, makes it easier to specify links quickly */
for (var i = 0; i < AUTHED_MENU.length; ++i) {
    AUTHED_MENU[i].href = AUTHED_MENU[i].href || AUTHED_MENU[i].text.toLowerCase();
}

const UNAUTHED_MENU =
    [ AUTHED_MENU[0]
    ];

/*
* @brief The navbar that appears at the top of each page
*
* Contains the logo bar and the menu bar internally.
*/
const NavBar = ({...props}) => {

    const [authedMenu, setMenu] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState(null);
    const [groupConfig, setGroupConfig] = React.useState(null);

    React.useEffect(() => { loadNavBar()},[userInfo,groupConfig]);
    React.useEffect(() => { 
        if(authedMenu.length === 0){return;} 
        // console.log("AuthMenu updated: ", authedMenu)
        loadDefaultView(authedMenu)}
    ,[authedMenu]);

    // Signal upstream that we should sign out
    function signOut() {
        if(props.eventNotify){props.eventNotify({type: "signout"});}
    }
    // Signal upstream that a link was clicked
    function linkClicked(data) {
        // console.log("Send click: ",{type: "link", data: data})
        if(props.eventNotify){props.eventNotify({type: "link", data: data});}
    }
    // Generate the menu links based on the user data:
    function generateMenu() {
        // console.log("Entered classic")
        let authedMenu = [];
        let userRole = "";
        
        if(props.userInfo.role){
            userRole = props.userInfo.role;
        }

        //handle user info selection?
        if(props.userInfo.allowedTabs && props.userInfo.allowedTabs.length>0 
            && (!userRole.includes("Admin") && !userRole.includes("SiteManager")) ){
            // console.log("Allowed tabs; ",this.props.userInfo.allowedTabs);
            (props.userInfo.allowedTabs || []).forEach( tab_ =>{
                authedMenu.push({text: tab_, href: tab_.toLowerCase() });
            })
            

        }else{ // use default group selections:
            let bAdminTabAllowed = false;

            if(userRole.includes("Admin") ){
                if(props.userInfo.allowedTabs && props.userInfo.allowedTabs.includes("Admin")){bAdminTabAllowed = true;}
            }
            // console.log("Admin allowed: ",bAdminTabAllowed)


            if(props.groupconfig.group==='reviewgroup'){
                authedMenu.push(AUTHED_MENU[ANALYTICS]);
            }
            else if(props.groupconfig.group==='reviewgroup_beirut'){
                // authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push({text: 'Live', href: "live" });   
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push({text: 'Monitor', href: "monitor" });   
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]);                     
            }
            else if(props.groupconfig.group==='bis' || props.groupconfig.group==='bis_drive'){
                authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push(AUTHED_MENU[ANALYTICS]);
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[DRIVERRANKING]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]); 
                if(bAdminTabAllowed){authedMenu.push({text: 'Admin', href: "admin" });   }
                authedMenu.push(AUTHED_MENU[UPLOAD]);
            }
            else if(props.groupconfig.group==='pintovalley'){
                authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push({text: 'Monitor', href: "monitor" });   
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[DRIVERRANKING]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]); 
                if(bAdminTabAllowed){authedMenu.push({text: 'Admin', href: "admin" });   }
            }
            else if(props.groupconfig.group==='demo_group'){
                authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push({text: 'Monitor', href: "monitor" });   
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[DRIVERRANKING]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]); 
                if(bAdminTabAllowed){authedMenu.push({text: 'Admin', href: "admin" });   }
            }
            else if(props.groupconfig.group==='devgroup'){
                authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push(AUTHED_MENU[ANALYTICS]);
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[DRIVERRANKING]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]); 
                if(bAdminTabAllowed){authedMenu.push({text: 'Admin', href: "admin" });   }
            }
            else{
                authedMenu.push(AUTHED_MENU[DASHBOARD]);
                authedMenu.push(AUTHED_MENU[ANALYTICS]);
                authedMenu.push(AUTHED_MENU[REPORTING]);
                authedMenu.push(AUTHED_MENU[MAP]); 
                authedMenu.push(AUTHED_MENU[DRIVERRANKING]); 
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]);       
                if(bAdminTabAllowed){authedMenu.push({text: 'Admin', href: "admin" });   }
                authedMenu.push(AUTHED_MENU[UPLOAD]);       
            }

            if(props.groupconfig.group === 'drive_test_group' ){
                authedMenu.push(AUTHED_MENU[ASSETSTATUS]); 
            }
    
            if(props.username === 'review_user' || props.username === 'testing_uploader' ){
                authedMenu.push({text: 'AdminReview', href: "adminreview" });   
            }
            if(props.groupconfig.group === 'devgroup'){
                authedMenu.push({text: 'Live', href: "live" });   
            }

            // if(userRole.includes("Admin") ){
            //     (props.userInfo.allowedTabs || []).forEach( tab_ =>{
            //         authedMenu.push({text: tab_, href: tab_.toLowerCase() });
            //     })
            // }
        }
        //Create the pairs to generated the menu
        const map = new Map();
        authedMenu.forEach(v => map.set(v.text, v)) // 'text' should be unique, we don't want multiple tabs with same name
        authedMenu =  [...map.values()];
        return authedMenu;
    }

    // Generate the menu links based on the user data and loaded permissions:
    function generateMenu_permissions(){
        // console.log("Entered with permissions")
        let authedMenu = [];
        //The permissions use key:value with the key denoting the tab name
        //Check each in order to maintain the desired list order
        if(groupConfig.permissions.dashboard){authedMenu.push(AUTHED_MENU[DASHBOARD]);}
        if(groupConfig.permissions.live){authedMenu.push({text: 'Live', href: "live" });   }
        if(groupConfig.permissions.analytics){authedMenu.push(AUTHED_MENU[ANALYTICS]);}
        if(groupConfig.permissions.reporting){authedMenu.push(AUTHED_MENU[REPORTING]);}
        if(groupConfig.permissions.monitor){authedMenu.push({text: 'Monitor', href: "monitor" });}
        if(groupConfig.permissions.livemap){authedMenu.push(AUTHED_MENU[MAP]);}
        if(groupConfig.permissions.callins){authedMenu.push(AUTHED_MENU[CALLINS]);}
        if(groupConfig.permissions.riskprofile){authedMenu.push(AUTHED_MENU[RISKPROFILE]);}
        if(groupConfig.permissions.driverranking){authedMenu.push(AUTHED_MENU[DRIVERRANKING]);}
        if(groupConfig.permissions.assetstatus){authedMenu.push(AUTHED_MENU[ASSETSTATUS]);}
        if(groupConfig.permissions.admin){authedMenu.push({text: 'Admin', href: "admin" });}
        if(groupConfig.permissions.upload){authedMenu.push(AUTHED_MENU[UPLOAD]);}
        if(groupConfig.permissions.staffperformance){authedMenu.push(AUTHED_MENU[STAFFPERFORMANCE]);}

        // console.log("Authed Menu: ",authedMenu);
        return authedMenu;
    }
   


    //Listen for changes to the UserInfo property:
    React.useEffect(() => { 
        // console.log("UserInfo: ",props.userInfo);
        if(!props.userInfo){return;}
        if(props.userInfo.bLoaded){ setUserInfo(props.userInfo);}
    }, [props.userInfo]);
    //Listen for changes to the groupConfig property:
    React.useEffect(() => { 
        
        if(!props.groupconfig){ return;}
        // console.log("GroupConfig: ",props.groupconfig);
        if(props.groupconfig.bLoaded){ setGroupConfig(props.groupconfig);}
        if(!props.groupconfig.group){ setGroupConfig(props.groupconfig);}
    }, [props.groupconfig]);

   
     //Execute once on load
     function loadNavBar() {
        if(!userInfo || !groupConfig){
            // console.log("Not enough data" )
            return;
        }
        // console.log(`Got user info? `,props.userInfo,userInfo);
        // console.log("Enter did mount",_data,props.username,userInfo,groupConfig)
        let authedMenu = [];        
        try {
            if(props.username && userInfo && groupConfig.bLoaded){
                if(groupConfig.permissions ){
                    authedMenu = generateMenu_permissions();
                }else{
                    authedMenu = generateMenu();
                }
            }    
        } catch (error) {
            authedMenu = generateMenu();
        }
        
        // console.log("Set menu: ",JSON.parse(JSON.stringify(authedMenu)));
        setMenu(authedMenu);
    }//end loadNavBar


    function loadDefaultView(_data){
        // console.log("Load default: ")
        //is the DASHBOARD in the allowed set?
        let defaultElement = (_data||[]).find(elem_ => elem_.href === 'dashboard');
        if(!defaultElement){
            defaultElement = _data[0];
        }
        // console.log("Send click: ",defaultElement,window.history)
        try {
            if(window.history.state.mainView === 'default'){
                linkClicked(defaultElement);  
            }    
        } catch (error) {
            linkClicked(defaultElement);  
        }
    }
    
    function render() {
        // console.log("Nav: ",authedMenu,props)
        return (
        <div id="pcoded" className="pcoded">
            <div className="pcoded-container">
                <FlatLogoBar groupconfig={props.groupconfig} userInfo={props.userInfo} username={props.username} onSignOut={signOut} newMessage={props.newMessage} />
                <FlatMenuBar authed={props.username} authed_items={authedMenu} unauthed_items={UNAUTHED_MENU}
                                onLinkClicked={linkClicked} 
                                defaultFilter={props.defaultFilter}
                                possibleFilters={props.possibleFilters}
                                displayFilters={props.displayFilters}
                />
                <div className="pcoded-main-container">
                    <div className="pcoded-wrapper">
                        <div className="pcoded-content">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }//end render

    return render();
}

// Only exporting the FlatNavBar, the other pieces are considered internal details
export { NavBar };
