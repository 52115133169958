
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';

import './NotecardDriverID.css';

import blankAvatar from '../assets/avatar-blank-large.png';

import { ApiCaller,Spinner } from '../ApiCaller.js';

import { Auth, API } from 'aws-amplify';

import { StatefulButton } from '../VideoReview/vid-util';

// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

/*
* @brief This is an modal dialog to be opened when addding or editing a user
*/
export class NotecardDriverID extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            shown: false,
            timestamp:null,
            selected: null,            
        };

        this.videoRef =  React.createRef(null);
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        // console.log("Open notecard driverid: ",this.props);
        window.setTimeout(() => {
            this.setState({shown: true});
        }, EXPANDED_CARD_SHOW_LATENCY);
     
    }
    /*
    * @brief Run on exit, clean up the video player 
    */
    componentWillUnmount(){
        //Release the video file:
        try {
            if(this.videoRef && this.videoRef.current){
                this.videoRef.current.src = null;
                this.videoRef.current.load();
            }    
        } catch (error) {}
    }

  
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        // let returnData = {};        
        // console.log("Handle submit: ",JSON.stringify(this.state.selected));

        //Dispatch the update to the SQL table:
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDriverInfo";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        driverid: this.state.selected,
                        infractionid: this.props.infractionid,
                        mode: 'updateDriverID',              
                    }
                };
                return API.post(apiName, path, myInit);
        })

        if(this.props.onClose){this.props.onClose({driverid: this.state.selected});}

    }//end handleSubmit
    /*
    * @brief handle closing the card with submit
    */
    handleCancel(_data){
        // console.log("Cancel clicked")
         if(this.props.onClose){
            // console.log("Call onClose")
            this.props.onClose();
        }
    }//end handleSubmit

    
    /*
    * @brief Handle clicking on the driver photo
    */
    handleClick(_data){
        // console.log("clicked ",_data)
        this.setState({selected:_data})
    }//end handleSubmit

    /*
    * @brief Render the content of the card:
    */
    render() {
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        let drivers = [];

        // console.log("Props: ",this.props);
        //Iterate over the top 3 candidates and add them to the list of displayed assets:
        if(this.props.top3 && this.props.top3.candidates){
            for(const driver_ of this.props.top3.candidates){
                // console.log("Driver_: ",driver_,)
                let confidenceDisplay = driver_.confidence;
                confidenceDisplay = Math.floor(confidenceDisplay/10);
                if(driver_.id === 'Pending'){continue;}
                drivers.push(<DriverDetail className='driverdetail'
                                driverid = {driver_.id}
                                confidence = {confidenceDisplay}
                                selected = {this.state.selected}
                                onClick = {this.handleClick}
                                asset = {this.props.asset}
                                key={'nc-driverid-'+driver_.id}
                            />)
            }
        }
        //Pad so that only drivers are on the top row
        if(drivers.length > 0 && drivers.length<3){
            let runLength = 3-drivers.length;
            for (let i = 0; i < runLength; i++) { 
                drivers.push(<div className='driverblank'></div>)
            }
        }
        
        //Push on the empty guesses:
        drivers.push(<DriverDetail className='driverdetail'
                        driverid = 'Pending'
                        selected = {this.state.selected}
                        onClick = {this.handleClick}
                        key={'nc-driverid-'+'Pending'}
                    />)
        drivers.push(<DriverDetail className='driverdetail'
                        driverid = 'Unavailable'
                        selected = {this.state.selected}
                        onClick = {this.handleClick}
                        key={'nc-driverid-'+'Unavailable'}
                    />)

        return (
            <div className="notecarddriverid-card" style={style} >
                {/*Create a modal window to pop up on top of the page*/}
                <ReactModal isOpen={this.state.shown} className="modal-dialog notecarddriverid-card-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="notecarddriverid-modal" style={style} >
                        {/*Set the title of the card*/}
                        <div className = 'title'> DriverID Select </div>

                        {/*Add the an array of driver option photos and the video to the top of the card*/}
                        <div className='topDisplay'>
                            <div className='driverDisplay'>

                                {drivers.map((driver_) =>{
                                    return(driver_)
                                })}
                                
                            </div>
                            {/*Load the video*/}
                            <video className='notecard-video' 
                                ref = {this.videoRef}
                                id='notecard-video'
                                muted="muted"
                                preload="auto"
                                controls
                            >
                                {this.props.video && <source src= {this.props.video} type="video/mp4"></source>}
                            </video>
                        </div>
                        
                        {/*Add the Cancel and Submit buttons to the footer */}
                        <div className="footer">
                                <StatefulButton className="btn btn-danger" 
                                     onClick={this.handleCancel}
                                >Cancel</StatefulButton>

                                <div className='notecardRef'> Notecard reference: <span>{this.props.infractionid}</span> </div>
                                {/*Pass the selection state to the submit button to set the disabled attribute  */}
                                <StatefulButton className="btn btn-primary" disabled={this.state.selected?false:true}
                                    onClick={this.handleSubmit}
                                >Submit</StatefulButton>
                        </div>
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end NotecardDriverID

class DriverDetail extends PureComponent {
    
    constructor(props) {
        super(props);
        this.fetchDriverPhotos = this.fetchDriverPhotos.bind(this);
        this.updateData = this.updateData.bind(this);
        this.handleClick = this.handleClick.bind(this);


        this.state = {
            shown: false,
            timestamp:null,
            drivername: null,
            driverPhoto: null,
            bApiReturned: this.props.driverid.length !==5,
        };
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        // console.log("load driver detail: ",this.props);
        this.fetchDriverPhotos();
    }

    /*
    * @brief Fetch the data from the database given the driverid
    */
    fetchDriverPhotos(){
        //Make an API call to download some photos:
        //Call in parallel to allow for quicker update to the render?
        let idx =0;
        //Dispatch the update to the SQL table:
        const fetchPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleDriverInfo";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        assetid: this.props.asset,
                        driverid: this.props.driverid,
                        index: idx++,
                        mode: 'fetchbyasset',              
                    }
                };
                return API.post(apiName, path, myInit);
        })
        return fetchPromise;

    }
    /*
    * @brief handle database return information
    */
    updateData(_data) {
        // console.log("Data return: ",_data);

        try {
            let photoData = _data.driverinfo.photo;
            if(!photoData.includes('data:image')){            //Is it tagged?
                console.log("Attach tag")
                photoData = 'data:image/jpeg;base64,'+_data.driverinfo.photo;
            }
    
            let driverName = _data.driverinfo.name;
            this.setState({driverName: driverName, driverPhoto: photoData,bApiReturned:true})    
        } catch (error) {
            console.log("Failed to load driver details: ",error);
        }
        
    }
    /*
    * @brief Handle clicking on the driver photo
    */
    handleClick() {
        // console.log("Clicked: ",this.props);
        this.props.onClick(this.props.driverid)
    }
    

    /*
    * @brief Draw the photo and driverid
    */
    render() {
        let isSelected = this.props.driverid===this.props.selected?'true':'false';

        // 
        //     drivers
        // :
        //     <Spinner />
        // }
        let backImgStyle = {};
        // backImgStyle.backgroundImage = `url(../assets/avatar-blank-large.png)`;
        backImgStyle.backgroundImage = `url(${blankAvatar})`;
        if(this.state.driverPhoto){
            backImgStyle.backgroundImage = `url('${this.state.driverPhoto}')`;
        }


        return (
            <div className='driver-select' selected-driver={isSelected} onClick={this.handleClick} key={'driver-details-'+this.props.driverid}>
                {this.props.driverid.length ===5 &&
                    <ApiCaller apiCall={this.fetchDriverPhotos} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />
                }
                {this.state.bApiReturned?
                    <div className="driver-details">
                        {this.props.confidence? <div className='cell-label'>Conf: {this.props.confidence}/10</div> : <div/>}
                        <div className="driverphoto" blank={this.state.driverPhoto?"false":"true"} style = {backImgStyle}/>
                        <div className='cell-label'>{this.props.driverid}</div>
                    </div>
                
                :
                    <Spinner />
                }
                
            </div>
             
        )
    }
}