import getBrowserFingerprint from 'get-browser-fingerprint';
import {API} from 'aws-amplify';
import axios from 'axios';


export function authenticateBrowser(_data) {
     var promise = new Promise( (resolve, reject) => {

        let username = null;
        try {
            const login = _data.signInUserSession.idToken.payload;
            username = login['cognito:username']    
        } catch (error) {
            console.log("Failed to get username: ",error);
        }

        //Get the IP address of the browser:
        // axios({ //don't specify the response type -> returns the JSON object
        //     url: 'https://geolocation-db.com/json/',
        //     method: 'GET',
        //     // responseType: 'blob', // important
        // })
        // .then(res => {
        //     // console.log("IP axios",res.data);
        //     let ipreturn = {
        //         address :null,
        //         lat :null,
        //         lon :null
        //     }
             
        //     try {
        //         ipreturn.address = res.data.IPv4;
        //         ipreturn.lat = res.data.latitude;
        //         ipreturn.lon = res.data.longitude;
        //     } catch (error) {
                
        //     }

        //     const t0 = new Date();
        //     const fingerprint = getBrowserFingerprint({ enableWebgl: true, debug: false});
        //     const t1 = new Date();
        //     // console.log("Fingerprint: ", fingerprint, t1-t0);


        //     //Query the API to authenticate against restrictions:
        //     let apiName = "TrifectaAPI";
        //     let path = "/handleLogin";
        //     let myInit = {
        //         body: {
        //             fingerprint: fingerprint,
        //             username: username,
        //             ipaddress: ipreturn.address,
        //         }
        //     };

        //     let apiPromise = API.post(apiName, path, myInit);
        //     apiPromise.then((data) => {
        //         // console.log("Broswer Auth return: ",data);
        //     });
        //     apiPromise.catch((error) => {
        //         console.error("Failed on broswer auth; ",error); 
        //     }); 
        //     resolve("Promise resolved successfully");
        // })
        // .catch(error => {
            // console.error("Promise rejected",error);

            const t0 = new Date();
            const fingerprint = getBrowserFingerprint({ enableWebgl: true, debug: false});
            const t1 = new Date();
            // Query the API to authenticate against restrictions:
            let apiName = "TrifectaAPI";
            let path = "/handleLogin";
            let myInit = {
                body: {
                    fingerprint: fingerprint,
                    username: username,
                    // ipaddress: ipreturn.address,
                }
            };
            console.log("Send handleLogin on error");

            let apiPromise = API.post(apiName, path, myInit);
            apiPromise.then((data) => {
                console.log("Broswer Auth return: ",data);
            });
            apiPromise.catch((error) => {
                console.error("Failed on broswer auth; ",error); 
            }); 
            resolve("Promise resolved successfully");
        // })
      
    });
    
    return promise;
    
}



