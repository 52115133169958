import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Spinner } from './ApiCaller';


// this displays a date nicely instead of it being in ISO8601 format
export const dateFormatter = (date, row, rowIndex, extraData) => {
  if(!date){return '---'}
  // let dateObj = moment(date);
  let dateObj = moment.parseZone(date);
  
  return dateObj.format("YYYY-MM-DD HH:mm:ss"); // can configure displayed date format freely here
};

/*
* @brief Format what is displayed in the Notes section
*/
export const noteFormatter = (data, row, rowIndex, extraData) => {
  try {
    let notes = data.split('|');
    if(data.length<=1){return '---';}
    
    // return dateObj.format("YYYY-MM-DD HH:mm:ss"); // can configure displayed date format freely here
    let returnString = notes.join(',')
    if (returnString.charAt(returnString.length - 1) === ',') {
      returnString = returnString.substr(0, returnString.length - 1);
    }
    let statusNotes = notes.filter(elem_=>{return elem_.includes(':')});

    //If there are notes for the assets, don't display the N/A from the call
    if(statusNotes.length>0){
      returnString = returnString.replace("N/A,","");
    }

    //Set the default notes display
    let noteDiv = <div className="notes-short">{returnString}</div>

    // Create the default notes section that is displayed when hovering over the notes section
    // This is what is shown when the call subject is offline or system warning
    let overlayContent = <span></span>;
    if(statusNotes.length>0){
      overlayContent = <div className='notes-long'>
                  <table>
                  <thead><tr>
                      <th>Asset</th>
                      <th>Status</th>
                    </tr></thead>
                  <tbody>
                    {                      
                      statusNotes.map( (item,idx) => {

                      let tmpKey = row.key+'-'+idx
                      let items = item.split(':')
                      return (
                        <tr key={tmpKey}>
                          <td>{ items[0] }</td>
                          <td>{ items[1] }</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
    }

    //If the notes contain a call failure then display the popup showing all attempts:
    //Change the hover display 
    let bIncludeCallOverlay = false;
    if(notes.includes("No Answer")) {bIncludeCallOverlay = true;}
    if(notes.includes("Call Issue")) {bIncludeCallOverlay = true;}
    if(notes.includes("Hung Up")) {bIncludeCallOverlay = true;}

    //should the overlay popup be modified due to the note types
    if(bIncludeCallOverlay){
      overlayContent = renderOverlayContent(row); //Add the call attempts overlay table
      noteDiv = renderCallCount(row,returnString);  //Add the count of the call attempts
    }
    
    //Create the actual rendered element in the notes section
    //Using the overlayTrigger component allows for a new action to take place when hovered over
    return <OverlayTrigger
            placement="bottom-start"
            // placement="top-start"
            delay={{ show: 250, hide: 400 }}
            // overlay={renderTooltip}
            overlay={overlayContent}
          >
            {noteDiv}
          </OverlayTrigger>

    // return returnString;
  } catch (error) {
    return '---';
  }
};//end noteFormatter
/**
 * Helper function to format rendering the list of calls made for the overlay
 * @param {*} row : the row data from the current table row
 */
const renderOverlayContent = (row) => {
  let renderedOverlay = <span></span>;
  try {
    //If there was only 1 call made, then don't display the count
    if(row.set && row.set.length<=1){return renderedOverlay;}

    //Update the hover display based on the type of note that was detected
      //Define a small table to render in the popup/overlay
      //Iterate over each of the items(calls) in the group and add it to the table 
      renderedOverlay = <div className='notes-long'>
                  <table>
                    <thead><tr>
                        <th>Escalation</th>
                        <th>Call Number</th>
                        <th>Call Time</th>
                    </tr></thead>
                  <tbody>
                    {row.set.map( (item,idx) => {
                      
                      let tmpKey = row.key+'-'+idx
                      return (
                        <tr key={tmpKey}>
                          {item.alertflag===1?<td style={{color:"red"}}>{ item.escalation }</td>:<td>{ item.escalation }</td>}
                          {item.alertflag===1?<td style={{color:"red"}}>{ item.callnumber }</td>:<td>{ item.callnumber }</td>}
                          {item.alertflag===1?<td style={{color:"red"}}>{ item.calltime }</td>:<td>{ item.calltime }</td>}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>;
  }
  catch(error){
  }
  return renderedOverlay;
}; // end renderOverlayContent

/** Helper function to create a count div when the attempted call count is greater than 1 */
const renderCallCount = (_row, _returnString)=>{
  let noteDiv = <div className="notes-short">{_returnString}</div>;
  try {
        //Create the HTML div element
        //only add the callcount when the set lenght is > 1
        noteDiv = <div className="notes-short">
                    {_returnString}              
                    {(_row.set && _row.set.length>1) > 0? <span className="callcount">{_row.set.length}</span>:<span ></span>  }
                  </div>  
  } catch (error) {
    // console.log("Error on div?: ",error);
  }
  return noteDiv;
}//end renderCallCount

export const notecardFormatter = (data, row, rowIndex, extraData) => {
  // if(!date){return '---'}
  // let dateObj = moment(date);
  try {
    if(row.cardData){
      return row.cardData;
    }
    return '---';
  } catch (error) {
    return '---';
  }
  
};

export const siteFormatter = (data, row, rowIndex, extraData) => {
  // if(!date){return '---'}
  // let dateObj = moment(date);
  try {
    // console.log("Props:" ,extraData);
    if(extraData.Sites){
      let siteDetail = (extraData.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === data.toLowerCase()})
      // console.log("Site detail: ",siteDetail,siteDetail[0].alias);
      if(siteDetail){
        return siteDetail[0].alias;    
      }
    }else{
      return '---';
      // switch(data){
      //   case 'pintovalleymine':{return 'Pinto Valley Mine'}
      //   case 'royhill':{return 'Roy Hill'}
      //   case 'huntervalleyoperations':{return 'HVO'}
      // }
    }
    
  } catch (error) {
    //  console.log("Error: ",error);
    return '---';
  }
  
};



export class CallFetchingDialog extends PureComponent {
  constructor(props) {
      super(props);
      this.state = {
          shown: true,            
      }
  }
   /*
  * @brief Render the dialog:
  */
   render() {
       let progressVal = 0;
       try {
          progressVal = Math.round((this.props.fetched/this.props.toFetch)*100);    
          progressVal = Math.min(progressVal,100);
          progressVal = Math.max(progressVal,0);
       } catch (error) {
       }

       let progressStyle = {
          width: "0%", 
          height: "15px"
      }
      progressStyle.width = progressVal+"%";

      let titleText = 'Call-in Log';
      let pluralText = 'Days'
      if(this.props.stringType.includes("event")){
          titleText = 'Day';
          pluralText = 'Days'
      }

      let fetchingString = "Fetching "+this.props.toFetch+ " "+pluralText;
      //Apply the details if available
      // if(this.props.fetchDetails){
      //     // console.log("Add details: ",this.props.fetchDetails);
      //     fetchingString ="Fetching"
      //     let bAddComma = false;
      //     if(this.props.fetchDetails.events){
      //         fetchingString +=" "+this.props.fetchDetails.events+ " "+pluralText;
      //         bAddComma = true;
      //     }
      //     if(this.props.fetchDetails.alerts){
      //         if(bAddComma){fetchingString+=","}
      //         fetchingString +=" "+this.props.fetchDetails.alerts+ " Alerts";
      //     }
          
      // }

      //Define the layout for the fetching stage
      let fetchingRender = <div>
                              {/*Show the number of journeys that are being retrieved versus total to retreive */}
                              <div>
                                  {"Fetching "+this.props.fetched+" out of "+this.props.toFetch}                                
                              </div>
                                  {/*Render a progress bar, set the progress as the width of the bar*/}
                                  <div className="progress">
                                      <div className="progress-bar progress-bar-success progress-bar-striped" 
                                          role="progressbar" aria-valuenow={progressVal} aria-valuemin="0" aria-valuemax="100" 
                                          style={progressStyle}>
                                          <span className="sr-only">40% Complete (success)</span> 
                                      </div>
                                  </div>
                          </div>
      //Don't show a progress bar unless specified by the instance
      if(!this.props.progressBar){
          fetchingRender =    <div>
                                   {fetchingString}                                
                              </div>
      }

      let messageDiv = <div></div>;
      //Switch the dialog based on witch state we are displaying
      switch(this.props.dialogState){
          default:
          case 0:break; //Closed, or fetching          
          case 1: //finding
              messageDiv = <div>
                              <div>Finding {pluralText}...</div>
                              <Spinner />
                              {this.props.onCancel && <button className="btn btn-danger" 
                                  onClick={this.props.onCancel}                                                    
                              >Cancel</button>}
                          </div>
          break;
          case 2: //empty params
              messageDiv = <div>
                              <div>No {pluralText} available, please modify the filters </div>
                              <button className="btn btn-primary" 
                                  onClick={this.props.onApply}                                                    
                              >Close</button>
                          </div>
          break;
          case 3: //bad date range
              messageDiv = <div>
                              <div>Fetching of Call-in logs is limited to a period of 31 days . Please reduce your date range to less than 31 days </div>
                              <button className="btn btn-primary" 
                                  onClick={this.props.onApply}                                                    
                              >Close</button>
                          </div>
          break;
      }
      // console.log("Query promise: ",this.props.queryPromise)
      // console.log("Fetching state: ",this.props.dialogState)

      return (
          <ReactModal isOpen={(this.props.fetched < this.props.toFetch)||this.props.dialogState>0} className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title">{titleText +" Fetch"}</h5>
                      </div>
                      <div className="modal-body">
                          <div className={"api-caller-message api-caller-state-" + this.state.apiState}>
                              {this.props.dialogState>0?
                                  <div>
                                       {messageDiv}
                                  </div>
                              :
                                  <div>
                                      {fetchingRender}
                                      {this.props.onCancel && <button className="btn btn-danger" 
                                          onClick={this.props.onCancel}                                                    
                                      >Cancel</button>}
                                  </div>
                                  
                              }
                              
                          </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                      </div>
                  </div>
                  </ReactModal>

      )
   }//end render
}