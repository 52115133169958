import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';

import { ApiCaller } from '../ApiCaller.js';
import { displayStatus} from '../Util.js';
import { AddSite } from './Site-Utils.js';
import { SiteOverviewCard } from './SiteOverviewCard.js';

import { useImperial,gpsStringToCoords } from '../UtilOpenLayers.js';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { GrPowerReset } from "react-icons/gr";

import '../VideoLister.css';
import './AdminUserManagement.css';

import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";

//Define the formatters:
const gpsFormatter = (_gps) => {
    
    try {
        if(_gps){
            let loc = gpsStringToCoords(_gps);
            let returnVal = parseFloat(loc.lat).toFixed(4) + ", "+parseFloat(loc.lon).toFixed(4)
            return returnVal;
        }
    } catch (error) {
    }
    return _gps;
}//end gpsFormatter

const speedFormatter = (_speed,_row) => {
    // console.log("speed: ",_speed,_row)
    try {
        let bShowComma = false;
        let returnVal = _speed;
        if(_speed){
            bShowComma = true;
         
            
            if(_row.bImperial){
                returnVal += "  mph"
            }else{
                returnVal += "  km/h"
            }
            
            //Add the speed
            if(_row.speedtime){
                returnVal += " , "+_row.speedtime
                returnVal += "  secs"
            }
            
        }else{
            returnVal = "";
        }
        //Add the Speed policy indicator
        if(_row.speedpolicies && _row.speedpolicies.length>0){
            if(bShowComma){returnVal += " , "}
            returnVal += "Speed Policy Set"
        }
        return returnVal;
        
    } catch (error) {
    }
    return _speed;
}//end speedFormatter

const statusFormatter = (_status, row, rowIndex, extraData) => {
    if(_status === 'DISABLED'){
        return <span className = 'disabledAccount'>{_status}</span>;    
    }
    return _status;
}//end statusFormatter



export class AdminSiteManagement extends Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);

        this.handleNewSite = this.handleNewSite.bind(this);
        this.handleEditSite = this.handleEditSite.bind(this);

        this.handleCardReturn = this.handleCardReturn.bind(this);
        this.removeSite = this.removeSite.bind(this);
        this.handleRemoveSite = this.handleRemoveSite.bind(this);
        this.resetUser = this.resetUser.bind(this);
        this.handleResetUser = this.handleResetUser.bind(this);

        this.handleShowDisabledToggle = this.handleShowDisabledToggle.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        
        
        this.state = {
            siteList: [],
            baseSiteList: [],
            userList:[],
            columns:[],
            addSiteCard:null,
            retryCount: 0,
            bShowDisabled: false, //by default - don't show the disabled users
            mSiteRegionNames: [],
        };
      // setupPerf(this, 'VideoLister', () => {return this.state.videos && this.state.videos.length > 0;});
    }
    onLoadingState(state) {
      this.setState({loadingState: state});
    }
    
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        //  console.log("Get Site data : ",this.props);
        //define the list of allowed sites to return:
        let siteList = [];
        if(this.props.possibleFilters && this.props.possibleFilters.Sites 
            && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
            //Extract the name of the sites from the site,gps pair
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
        } 
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleSites";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    mode: 'fetch',                
                }
            };
           return API.post(apiName, path, myInit);
        }
       )
       .catch((error) => {
         console.error("UserManagent api call; ",error); 
       }); 
        
         return realPromise;
    }
  
   
    /*
    * @brief Takes care of updating the list with new data when we receive it
    */
    updateData(data) {
        //  console.log("UpdateSite Data: ",data);
        if(!data.sites){return;}

        let filteredSites = (data.sites||[]).filter(site_ => {
            if(this.state.bShowDisabled===false){
                return site_.status === 'disabled'? false:true;
            }else{return true;}
        
        })
        // console.log("Filtered Site:", filteredSites);

        let regionSet = new Set();

        let updatedSites = (filteredSites||[]).map(site_ => {
            //  console.log("Site:" ,site_)
            site_.bImperial = false;
            if(site_.gps && site_.speedlimit){
                let coords = gpsStringToCoords(site_.gps)
                
                if(useImperial(coords)){
                    site_.speedlimit = Math.round(site_.speedlimit* 0.621371);
                    site_.bImperial = true;
                }

            }
            if(site_.gps && site_.gps ==="0,0"){
                site_.gps = null;
            }
            //Display site region:
            //Add the regions to a set (sets only allow unique values)
            if(site_.region){
                regionSet.add(site_.region)
            }

            site_.reactKey = site_.site + '-' + site_.clientid;

            return site_;        
        });
        // console.log("Site list:" ,updatedSites);
        //User list is display, base user list is used to reset
        this.setState({siteList: updatedSites,baseSiteList: updatedSites, mSiteRegionNames:[...regionSet]});
    }
  
    
    /* @brief Run once when the class is leaving
    */
    componentWillUnmount(){
    }
    /*
    * @brief The runs once when the component first mounts
    */
    componentDidMount(){

        // console.log("Open site: ",this.props);

        let siteList = [];
        if(this.props.possibleFilters && this.props.possibleFilters.Sites 
            && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
            //Extract the name of the sites from the site,gps pair
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
        } 

        let apiName = "UserManagement";
        let path = "/listAllCurrentUsers";
        let myInit = {
            body: {
                GroupName: this.props.groupconfig.group,                
                Groups: (this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length>0)?this.props.userInfo.allowedGroups.join(','):null,
                Sites: siteList.length>0?siteList.join(','):null,
            }
        };
        API.post(apiName, path, myInit).then(data=>{
            // console.log("User data: ",data);
            
            let userList = (data.users || []).map(user_ => {
                // console.log("Users: ",user_.Username);
                return user_.Username;
            });
            // console.log("Userlist: ",userList);
            this.setState({userList:userList});
        });
    

        /* Describe the columns of the table
        * 'dataField' is the name of the key in 'data' list that it will read from
        * 'text' is what it will display on the webpage for the column header
        * 'formatter' is a custom formatting function it will use for that column to transform from
        *             the data in the list to what it displays
        * 'sort' set to true to allow the column to be sorted
        * 'hidden' is if the column should be hidden from view, this is useful so we can get a
        *          react 'key' from a column that we don't actually want to show to the user
        */

        //Check on the permissions, assume write permission for legacy support:
        let bPermissionHide = false;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.users ==='read'){bPermissionHide = true;}

        let cols = [
           
            {dataField: 'alias', text: 'Site',editable:false, sort:true,headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'region', text: 'Region',editable:false,sort:true, formatter: statusFormatter,headerStyle: () => {return { width: "18%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'gps', text: 'GPS Location',editable:false,sort:true, formatter: gpsFormatter,headerStyle: () => {return { width: "18%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'speedlimit', text: 'Speed Limit',editable:false,sort:true, formatter: speedFormatter,headerStyle: () => {return { width: "18%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'remove', text: 'Enable / Disable',editable:false, hidden:bPermissionHide, formatter:this.removeSite, headerStyle: () => {return { width: "6%",whiteSpace:"unset",textAlign:"center"};}},

          ];
          if(this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length > 0){
             cols.splice(1,0,
                {dataField: 'clientid', text: 'Group',editable:false,sort:true, formatter:this.nullableFormatter,headerStyle: () => {return { width: "10%",whiteSpace:"unset",textAlign:"center"};}},
             ) 
          }
          //set the classnames for each column:
          cols.map(col => {
            col.classes = 'um-' + col.dataField;
            return col;
          });
        this.setState({columns: cols})
    }

    /*
    * @brief Define what is rendered in the remove user column
    */
    removeSite(cell,row){
        
        if(row.status && row.status.toLowerCase() === 'disabled'){
            return  <div>                        
                        <button type="button" className="enableSite" onClick={(e)=>this.handleRemoveSite(e,row,{type:'enabled'})}>
                            enable
                        </button>
                    </div>  
        }else{
            return  <div>
                        <button type="button" className="removeSite" onClick={(e)=>this.handleRemoveSite(e,row,{type:'disabled'})}>
                            disable
                        </button>
                    </div>  
        }
    };
    /*@brief handle the click on the remove user button
    */
    handleRemoveSite(e,row,_options){
        // return;
        try{
            e.stopPropagation(); 
             console.log("Click on remove? ",e,row,_options);              
            
            if(!row.site){return;}

            let displayString ="Please confirm that you wish to ";
            if(_options.type==='disabled'){
                displayString +="disable";
            } else{
                displayString +="enable";
            }
            displayString +=" the "+row.site+" site"

            if(!window.confirm(displayString)){
                return;
            }
            
            //Dispatch the update to the SQL table:
            Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleSites";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'updateStatus',
                        id: row.id,
                        status: _options.type,
                    }
                };
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("UserManagent remove site Fail; ",error); 
            })
            .then((_data)=>{//wait for the promiste to complete
                console.log("Remove site returned success:" ,_data);
                this.setState({retryCount:this.state.retryCount+1});
            });         
        }catch(e){
            // console.log("Click handle error: ",e);
        }
    }//end handleRemoveSite

    /*
    * @brief Define what is rendered in the reset password column
    */
    resetUser(cell,row){
        let displayVal =<div>
            <GrPowerReset className="resetUser" onClick={(e)=>this.handleResetUser(e,cell,row)}/>
        </div>        
        return displayVal
    };
    /*@brief handle the click on the reset password button
    */
    handleResetUser(e,cell,row){
        return;
        // try{
        //     e.stopPropagation(); //console.log("Click on button",e,cell,row);          
        //     // console.log("Click on remove? ",e,cell,row);              
        //     if(!row.Username){
        //         console.log("Failed to find username, abort",row);
        //         return;
        //     }
        //     //  //Dispatch the update to the SQL table:
        //     Auth.currentSession().then(
        //     (auth) => {
        //         let apiName = "UserManagement";
        //         let path = "/adminResetUserPassword";
        //         let myInit = {
        //             body: {
        //                 token: auth.idToken.jwtToken,
        //                 UserName: row.Username,
        //             }
        //         };
        //         return API.post(apiName, path, myInit);
        //     })
        //     .catch((error) => {
        //         console.error("UserManagent RemoveUser Fail; ",error); 
        //     })
        //     .then((_data)=>{//wait for the promiste to complete
        //         console.log("Reset user returned success:" ,_data);
        //         this.setState({retryCount:this.state.retryCount+1});
        //     });         

        // }catch(e){
        //     // console.log("Click handle error: ",e);
        // }
    }//end handleResetUser

    /*
    * @brief Handle the return from the card, did we create or edit?
    */
    handleCardReturn(_data,_mode){
        // console.log("Handle return: ",_mode);
        //Check if the site is in the US:
        _data.units = 'metric';
        if(_data.location){
            let coords = _data.location;
            // convert string coordinates into a gps object
            if(typeof value === 'string'){  coords = gpsStringToCoords(_data.location);  }
            if(useImperial(coords)){
                _data.units = 'imperial';
            }
        }else{
            console.log("not location: ",_data);
        }
        switch(_mode){
            case "newsite":
                this.handleNewSite(_data);
            break;
            case "editsite":
                this.handleEditSite(_data);
            break;
        }
    }
    /*
    * @brief Handle parsing and calling API to add new user
    */
    handleNewSite(_data){
         
        // console.log("New Site added:" ,_data,this.props.groupconfig.group);
        this.setState({addSiteCard:null});
        
        //Dispatch the update to the SQL table:
        const realPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleSites";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'newsite', 
                        data: _data,
                        clientid: this.props.groupconfig.group  
                    }
                };
                return API.post(apiName, path, myInit);
            }
            )
            .catch((error) => {
                console.error("Site management api call; ",error); 
            }); 

        realPromise.then((_return) => {
            // console.log("Returned from API: ",_return)
            this.setState({retryCount:this.state.retryCount+1});
        });
        
        return;
    }
    /*
    * @brief Handle parsing and calling API to edit user attributes
    */
    handleEditSite(_data){
        // console.log("Edit Site:" ,_data);
        // return;
        //Dispatch the update to the SQL table:
        const realPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleSites";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'editsite', 
                        data: _data,
                        clientid: this.props.groupconfig.group  
                    }
                };

                // console.log("Send handleSite editsite",myInit.body)
                return API.post(apiName, path, myInit);
            }
            )
            .catch((error) => {
                console.error("Site managment api call; ",error); 
            }); 

        realPromise.then((_return) => {
            // console.log("Returned from API: ",_return)
            this.setState({siteConfigCard:null,retryCount:this.state.retryCount+1});
        });
    }

    /*
    * @brief Called on update to the show disabled users checkbox
    */
    handleShowDisabledToggle(){
        this.setState({bShowDisabled: !this.state.bShowDisabled,retryCount:this.state.retryCount+1})
    }
    
     /*
    * @brief Called on update to the input search field, filter the listed assets:
    */
     handleSearch(_data){
        let updatedSearchValue = _data.target.value;
        updatedSearchValue = updatedSearchValue.toLowerCase();
        // console.log("Name to filter: ",this.state.baseSiteList);
        //Filter the list of sites by the received value:
        let filteredSites = (this.state.baseSiteList || []).filter(site_=>{
            try {
                if(site_.site.toLowerCase().includes(updatedSearchValue) || site_.alias.toLowerCase().includes(updatedSearchValue)){return true;}
                else{return false;}
                // return site_.site.toLowerCase().includes(updatedSearchValue)    
            } catch (error) {
                return false;
            }
            
        });
        this.setState({siteList: filteredSites});
    }
    
    /*
    * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
    */
    render() {

        const siteData = this.state.siteList;
        const tableColumns = this.state.columns;
        // console.log("Site list to display: ",this.state.siteList)
        
        //Define the table interaction callbacks:
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                // console.log("Row clicked: ",e,row,rowIndex);

                //Get a list of current site names:
                let siteNames = (this.state.siteList || []).map( elem_ =>{return elem_.site.toLowerCase()})
                // console.log("Site names: ",siteNames);
                
        //    this.props.videoClicked(row);
                const card = {
                    sitename: row.site,
                    region: row.region,
                    location: row.gps,
                    boundary:row.boundary,
                    config:[],
                    knownSites: siteNames,
                    alias: row.alias,
                };
                card.config.push({
                    speedlimit:row.speedlimit,
                    speedtime:row.speedtime,
                    site: row.site,
                    clientid: row.clientid,
                    speedpolicies: row.speedpolicies,
                })
                this.setState({siteConfigCard:card});
            }
        };
        //  {/* <ProgressBar className="testpb" striped variant="success" now={35} key={1} label={`30%`}  /> */}
        const rowClasses = (row, rowIndex) => {
            return 'user-lister-row row-status-' + displayStatus(row.status).toLowerCase();
        };
    
        /*
            Set style for the rows in the table     
        */
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){
             rowcolor = '#00afed20'
            }
            return{
             backgroundColor: rowcolor,
            }
        };

        
        let scale = 1;
        if (this.state.winWidth && this.state.divWidth) {
            scale = this.state.winWidth / this.state.divWidth;
        }

        //Pagination options:
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{
              text: '10', value: 10
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        //Check on the permissions, assume write permission for legacy support:
        let bAllowEdits = true;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.users ==='read'){bAllowEdits = false;}
    //    console.log("UserData:" ,userData,tableColumns);
        return (
            <div className="user-lister">
                {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} retryCount = {this.state.retryCount}/>:null}
                <div className='topRow'>
                    
                    {bAllowEdits && 
                        <button className = {"addUser"} onClick={()=>{
                            let sites = (this.state.siteList || []).map( elem_ =>{return elem_.site.toLowerCase()})
                            this.setState({addSiteCard:{siteNames:sites}})}
                        } >
                        Add Site
                        </button>
                    }
                    <div className='totalusers'>{'Total Sites: '+siteData.length}</div>
                    
                    <div className="show-disabled-users">
                        <input className= 'disabledCheck' type="checkbox"  value="Show Disabled" checked={this.state.bShowDisabled===true}  onChange={this.handleShowDisabledToggle} />
                        <label className= 'disabledText'>
                            {this.state.bShowDisabled?"Hide Disabled Sites":"Show Disabled Sites"} 
                        </label>
                    </div>

                    <div className='search-title'>Site Search: </div>
                    <input type="text" id="usernameSearch" className='usernameSearch'
                                    onChange = {this.handleSearch}
                    />
                    

                </div>

                {tableColumns.length>0 && 
                    <BootstrapTable 
                                keyField='reactKey' // a react specific thing that sets the 'key' for each row in the table
                                                    // react uses keys to keep track of identity when things change
                                data={siteData} // <-- IMPORTANT: this is the actual data being displayed
                                columns={tableColumns}
                                defaultSorted={[
                                                {dataField: 'Username', order: 'asc'}, // how things should be sorted by
                                                {dataField: 'email', order: 'asc'}
                                                ]} // default when first displayed
                                striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                // keep track of what data belongs to what row when scanning across
                                rowStyle={ rowStyle}
                                hover={true}   // sets a hover effect, so the background color of a row changes when the
                                //                  // mouse is over it. This signals to the user that the row is clickable
                                classes={"user-lister"} // sets a CSS class so we can style this table specifically
                                rowEvents={bAllowEdits?rowEvents:null} // set what events we want to happen on rows, right now just the click
                                rowClasses={rowClasses}
                                // cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                                bootstrap4 = {true}
                                // expandRow={ expandRow }
                                detailView={true}
                                detailViewIcon={false}
                                detailViewByClick={true}
                                pagination={ paginationFactory(paginationOptions) }
                    />
                }
                { this.state.addSiteCard &&
                    <AddSite 
                        handleClose={()=>{this.setState({addSiteCard:null})}}                
                        handleSubmit={this.handleCardReturn}
                        scale={scale}   
                        groupconfig = {this.props.groupconfig}
                        userInfo = {this.props.userInfo}
                        possibleFilters = {this.props.possibleFilters}
                        regionNames ={this.state.mSiteRegionNames}
                        {...this.state.addSiteCard}
                    />
                }
                 { this.state.siteConfigCard &&
                    <SiteOverviewCard handleClose={(data,bSubmit)=>{
                                            // console.log("Closed site config", data,bSubmit);
                                            this.setState({siteConfigCard:null})
                                        }}
                                    {...this.state.siteConfigCard}
                                    assets = {this.props.possibleFilters.Assets}
                                    users = {this.state.userList}
                                    scale={scale}   
                                    groupconfig = {this.props.groupconfig}
                                    handleSubmit={this.handleCardReturn}
                                    regionNames ={this.state.mSiteRegionNames}
                    />
                }

            </div>
        );
    }//end of render
  }
  
  