import React, { PureComponent } from 'react';
import { setupPerf, perfDidClick } from '../Perf.js';
import { ApiCaller,Spinner } from '../ApiCaller.js';
import { Auth, API } from 'aws-amplify';
import { generateUniqueID, EDGE3_REGION_COLORS } from '../Util.js';
import 'react-datepicker/dist/react-datepicker.css';
import * as moment from 'moment';
import './MapDisplayView.css';
import { Filters } from '../BaseComponents.js';
import { ExpandedCard } from '../ExpandedNotecard/NoteCard-Expanded.js';
import { GeoFenceCard } from '../GeoFenceCard.js';
import { SiteConfigCard } from './SiteConfigCard.js';
import { GeofenceManagement,AssignGroupPopup } from '../GeofenceManagement.js'
import { SpeedPolicySettings } from '../SpeedPolicySettings.js';


import { MapJourney } from './mapJourney.js';
import { MapSiteBoundary } from './mapSiteBoundary.js';
import {MapControls, MapFiltersView, JourneyFiltersView} from "./map-filters.js"
import { DateFilter } from '../Filters/DateFilter.js';
import { MapFetchingDialog,getColor,formatSpeed,removeMarker } from './map-utils.js';
import { getBoundingBoxOverlap } from './journey-utils.js';
import {COORDTYPE_GPS,COORDTYPE_MERCATOR} from './map-utils.js'
import { formatAssetName,filterAssetSet } from '../Util-asset.js';

import { getRegionDesc,gpsCoordsToString,setFeatureGeom } from '../UtilOpenLayers.js';

import Dexie from 'dexie'

import { JourneyNotecard } from './JourneyNotecard.js';
import {FilterSelectionView} from './FilterSelectionView.js'



import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";
import { getGreatCircleBearing,isPointInPolygon,getDistance,getCenter } from 'geolib';
import { getBounds } from 'geolib'; 


import Transform from "ol-ext/interaction/Transform";
import Select from "ol/interaction/Select";
import { toLonLat } from 'ol/proj';


import PopupFeature from 'ol-ext/overlay/PopupFeature'
import Popup from 'ol-ext/overlay/Popup'
import Zoom from 'ol-ext/featureanimation/Zoom'
import Blink from 'ol-ext/featureanimation/Blink'
import Fade from 'ol-ext/featureanimation/Fade'
import {inAndOut} from 'ol/easing'
import Toggle from 'ol-ext/control/Toggle'
import LegendControl from 'ol-ext/control/Legend'
import LegendLegend from 'ol-ext/legend/Legend'

// import ol from 'ol-ext/dist/ol-ext'
// import Legend from 'ol-ext/control/Legend'
import LayerSwitcherImage from 'ol-ext/control/LayerSwitcherImage'

import {Draw,Modify,Snap,Translate,DoubleClickZoom}  from 'ol/interaction';
import {getArea} from 'ol/sphere';
import Collection from 'ol/Collection';
  
import {Control, defaults as defaultControls, Navigation} from 'ol/control';


// add OpenLayer support:
// var ol = require('ol');
// require('ol/css/ol.css');

import {Map,View } from 'ol'
import { Layer, Tile, Vector,VectorImage,Group } from "ol/layer";
import { OSM as OSMSource, Vector as VectorSource, XYZ as XYZSource, BingMaps, OSM} from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon,Text } from 'ol/style';
import { fromLonLat, get,transformExtent,transform } from 'ol/proj';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
// import Point from 'ol/geom/Point';
import {Point,Circle,Polygon} from 'ol/geom';
//interactive imports:
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';

import {toContext} from 'ol/render';

// // import Interaction from 'ol-ext';
import {altKeyOnly,singleClick, click, pointerMove,shiftKeyOnly,doubleClick} from 'ol/events/condition';
import { getArea as getExtentArea, getIntersectionArea } from 'ol/extent.js';
import { setInfractionStyle,setAssetStyle } from './map-styles.js';
import { tilegroup_Satellite, tiles_OSM, tiles_TOPO } from './TileProviders.js';
import {createInfractionLayers,createAlertLayers,createVectorLayer, createVectorImageLayer} from './LayerBuilder.js'
import { RoadBuilder } from './roads/road-builder.js';
import { roadStyler,signStyler } from "./roads/road-render.js";
// import Interaction from 'ol-ext/interaction';

import {MapAsset,AssetFeature, animateAssets } from './assets/MapAsset.js';


// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 10; // in seconds

const marker_img = 'http://openlayers.org/en/latest/examples/data/dot.svg'
// const speeding_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/icon_alert_destructive.svg'
const speeding_img = 'https://e3d-is2y4ihl25.s3-us-west-2.amazonaws.com/public/exclaim_important.svg'
{/* <div>Icons made by <a href="https://www.flaticon.com/authors/pixel-buddha" title="Pixel Buddha">Pixel Buddha</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
/*
* @brief Main HR Review page of the app
*/
export class MapDisplayView extends PureComponent {
    static defaultProps = {
        center: {
          lat: 33.429323,
          lng: -111.949211
        },
        zoom: 15,
        numDeltas: 3,
      };
    constructor(props) {
        super(props);
        this.apiKey = 'AIzaSyBRTVcYz0_cUoX8TlBpF40i5q3Scr-HObo'
        this.updateData = this.updateData.bind(this);
            this.processAssetUpdates = this.processAssetUpdates.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.getEventsApiCall = this.getEventsApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.windowResized = this.windowResized.bind(this);

        this.refreshCall = this.refreshCall.bind(this);
        this.toggleFeatureAnimation = this.toggleFeatureAnimation.bind(this);
        this.addMarker = this.addMarker.bind(this);
        this.handleWebsocket = this.handleWebsocket.bind(this);
        // this.linkMapRef = this.linkMapRef.bind(this);

        this.onFilterSelected = this.onFilterSelected.bind(this); //When the filter bar changes
        this.onInfractionsFilterSelected = this.onInfractionsFilterSelected.bind(this); //When the filter bar changes
        this.onJourneyFilterSelected = this.onJourneyFilterSelected.bind(this); //When the filter bar changes
        this.onMapJourneyResult = this.onMapJourneyResult.bind(this); //When the filter bar changes        


        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.updateAssetFeatures = this.updateAssetFeatures.bind(this);
        this.updateAssetsVisibility = this.updateAssetsVisibility.bind(this);
        this.updateInfractionVisibility = this.updateInfractionVisibility.bind(this);
        this.updateRegionVisibilty = this.updateRegionVisibilty.bind(this);

        this.repositionMapToJourney = this.repositionMapToJourney.bind(this);

        this.toggleLayerByName = this.toggleLayerByName.bind(this);
        this.getLayerByName = this.getLayerByName.bind(this);
        // this.processFeatureVisibility = this.processFeatureVisibility.bind(this);
        
        this.getTimeScale = this.getTimeScale.bind(this);
        this.addRegionsToMap = this.addRegionsToMap.bind(this);
        this.refreshGeofenceGroupList = this.refreshGeofenceGroupList.bind(this);
        this.handleRegionComplete = this.handleRegionComplete.bind(this);
        this.handleRegionUpdate = this.handleRegionUpdate.bind(this);

        this.toggleGeoFencing = this.toggleGeoFencing.bind(this);
        this.handleRegionClose = this.handleRegionClose.bind(this);
        this.updateRegionList = this.updateRegionList.bind(this);
        this.updateGeofenceGroup = this.updateGeofenceGroup.bind(this);
        this.fetchGeofenceList = this.fetchGeofenceList.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        this.addSpeedToMap = this.addSpeedToMap.bind(this);
        this.addAlertsToMap = this.addAlertsToMap.bind(this);
        this.getFeatureArea = this.getFeatureArea.bind(this);
        this.getSmallestArea = this.getSmallestArea.bind(this);
        this.getSmallestGeofence = this.getSmallestGeofence.bind(this);

        this.cardChange = this.cardChange.bind(this);
        this.cardTag = this.cardTag.bind(this);

        this.mapJourneyRef = React.createRef(); //create a reference for the mapJourney instance
        this.addLayer = this.addLayer.bind(this); //When the filter bar changes   
        this.clearSet = this.clearSet.bind(this);    

        this.releaseJourney = this.releaseJourney.bind(this);
        
        this.autoFollowAsset = this.autoFollowAsset.bind(this);
        this.autoFollowAssetLatLon = this.autoFollowAssetLatLon.bind(this);
        this.addLegend = this.addLegend.bind(this);
        // this.fetchSiteDetails = this.fetchSiteDetails.bind(this);

        this.state = {
            markers:[],
            eventMarkers:[],
            currentZoom:14,
            mMap:null,
            // winSize: null,
            winSize:{width:window.innerWidth, height:window.innerHeight},
            intervalID:null,
            intervalTime:moment(),
            offlineCheckTime: moment(),
            lastUpdateTime: moment(),
            retryCount:0,
            animationsTable: [], //array to hold the active animations
            styleCache : {},
            styleCacheOffline : {},
            styleCacheInRegion: {},
            // infractionsSource :null,
            
            filters:{
                vehicle: new Set(this.props.possibleFilters.AssetsTrifecta),
                infractions: new Set(this.props.possibleFilters.infractiontags),
                // infractions: ()=>{
                //    return  new Set(this.props.possibleFilters.infractiontags)
                // },
                drivers: new Set(this.props.possibleFilters.DriverID),
                journeys: [],
            },
            geofenceFilters:{                
                vehicle: new Set(this.props.possibleFilters.AssetsTrifecta),
                sites: new Set(this.props.possibleFilters.gpsSites),
                regions: [],
                groups: [],
            },
            
            activeFilters:{},
            activeJourneyFilters:{},
            followAsset: null,
            activeAsset: null,
            bShowInfractions: true,
            mPopup: null,
            selectedFeature: false,
            expandedCard: null,
            geoFenceCard: null,
            siteConfigCard: null,
            siteConfig: null, //array containing set site level speed
            // selectedInfraction: 'Severe Drowsiness',
            selectedInfraction: 'None',
            selectedInfractionFilters: {
                infraction:'All',
                timeScale: this.props.groupconfig.group.includes('bis')? 'week':'shift',
            },
            overlaySource:[],
            overlayLayers:[],
            mInteractions:[],
            mTimeScale: this.props.groupconfig.group.includes('bis')? 'week':'shift',
            bUpdateInfractions: true,
            mSelectedDriver: null,
            mGeoFenceDrawEnabled:false,
            siteList:[],
            mCurrentSite:null,
            mCurrentSiteDetails: null,
            mSelectedRegion:null,
            // startDate: this.props.groupconfig.group.includes('bis')? moment().subtract(7,'days'):null,
            startDate: moment().subtract(14,'days'),
            // startDate: null,
            endDate: null,

            mAlertLayers: ['GF_Speed','GF_Dwell','GF_Enter','GF_Exit','GF_AssetCount','Speeding'],
            manageFences: null,
            mutliSelectFeatures: [],
            groupSettingsCard: null,
            mapJourney: null,
            filterView: 'Site',
            journeyData: null,
            selectedJourney: null,
            journeyFetchList:{},
            fetchingDialogState: 0,
            journeyQueryPromise: null,
            mEventsToFetch: 0,
            mEventFetchStats:{},
            mEventsFetched: 0,
            mFetchingEventState: 0,
           
        };
        setupPerf(this, 'MapDisplayView', () => false);

        this.db = new Dexie('DriverPhoto');
        this.db.on("blocked", function() {
        console.log ("Database upgrading was blocked by another window. Please close down any other tabs or windows that has this page open");
        });
        this.db.on("ready", function() {
        // console.log ("ready Please close down any other tabs or windows that has this page open");
        });
        this.db.on("versionchange", function() {
        console.log ("Version change Please close down any other tabs or windows that has this page open");
        });
    }
  
  
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    UNSAFE_componentWillMount(){
        window.addEventListener("optimizedResize", this.windowResized);
        this.windowResized();
    }

    getLayerByName(_name,_type='source'){
        switch(_type){
            default:
            case 'source':{return this.state.overlaySource[_name];}
            case 'layer':{return this.state.overlayLayers[_name];}
        }
    }

    /*
    * Given an input name, hide the other map layers and show the one specified
    */
    toggleLayerByName(_name){
        // console.log("Toggle layer by name: ",_name);
        const overlayLayers = this.state.overlayLayers;
        Object.entries(overlayLayers).forEach(([name_, layer_]) => {
            try{
                //Don't handle special layers:
                if(name_==='asset'){return;}
                if(name_==='geofence'){return;}
                if(name_==='SpeedLimit'){return;}
                if(name_==='boundary'){return;}
                if(name_==='journey'){ return;}
                if(name_==='roads'){ return;}
                if(name_==='signs'){ return;}
                // if(name_==='journey'){ layer_.setVisible(true); return;}


                if((_name && name_===_name) ||
                    //Add a special case for the geofencing alerts and speeding
                    //These should be shown when "All" is selected
                    (_name === 'All' && this.state.mAlertLayers.includes(name_))
                    // (_name === 'All' && name_==='Alerts') ||
                    // (_name === 'All' && name_==='Speeding') 
                ){
                    layer_.setVisible(true);
                }else{
                    layer_.setVisible(false);
                }
            }catch(e){
            }
        });
        
    }

    //button click
    toggleGeoFencing(_override){
        //  console.log("Called toggle on geofence draw: ",_override)
        try{
            const geoFenceDrawEnabled = this.state.mGeoFenceDrawEnabled;
            const mapRef = this.state.mMap;
            // console.log("Interactions: ",this.state.mMap.getInteractions())
            // console.log("Set draw state: ",geoFenceDrawEnabled,mapRef)
            // if(geoFenceDrawEnabled){ //alread enabled, so turn it off

            this.state.mInteractions['multiselect'].setActive(false);
            this.state.mInteractions['snap'].setActive(false);
            this.state.mInteractions['modify'].setActive(false);
            this.state.mInteractions['translate'].setActive(false);


            if(_override === false){
                this.state.mInteractions['select'].setActive(true);                
                this.state.mInteractions['drawCircle'].setActive(false);
                this.state.mInteractions['drawPoly'].setActive(false);
            }else{ //not enabled, turn it on
                
                
                switch(_override){
                    case 'create':
                        this.state.mInteractions['select'].setActive(false);
                        this.state.mInteractions['drawPoly'].setActive(false);
                        this.state.mInteractions['drawCircle'].setActive(true);
                        break;
                    case 'polygon':
                        this.state.mInteractions['select'].setActive(false);
                        this.state.mInteractions['drawCircle'].setActive(false);
                        this.state.mInteractions['drawPoly'].setActive(true);
                        break;
                    case 'edit':
                        console.log("Edit selected");
                        this.state.mInteractions['drawCircle'].setActive(false);
                        this.state.mInteractions['drawPoly'].setActive(false);
                        this.state.mInteractions['snap'].setActive(true);
                        this.state.mInteractions['modify'].setActive(true);
                        this.state.mInteractions['translate'].setActive(true);
                        this.state.mInteractions['select'].setActive(true);
                        break;                    
                    case 'addtogroup':
                        console.log("Toggle interations for addtogroup");
                        this.state.mInteractions['select'].setActive(false);
                        this.state.mInteractions['multiselect'].setActive(true);
                        break;
                    default:{
                        this.state.mInteractions['select'].setActive(true);
                    }break;

                }
                // mapRef.addInteraction(this.state.drawCircle);
            }
            this.setState({mGeoFenceDrawEnabled:!geoFenceDrawEnabled}); //toggle the state
        }catch(e){
            console.log("Failed to update drawing: ",e);
        }
        

    }

    //Capture key presses:
    handleKeyPress(_event){
        // console.log(`Key: ${_event.key} with keycode ${_event.keyCode} has been pressed`,_event.type);
        try{          
            switch(_event.key){
                case 'Escape':
                    const filtersCopy = Object.assign({}, this.state.activeFilters);
                    if(filtersCopy['geofencing'] && (filtersCopy['geofencing']==='create'
                                                    ||filtersCopy['geofencing']==='polygon')
                                                    ||filtersCopy['geofencing']==='edit'){
                        // console.log('create is active')
                        filtersCopy['geofencing']='show'
                    }
                    // console.log("Set active filters to: ",filtersCopy);
                    this.setState({activeFilters:filtersCopy});
                break;
                case 'Meta': //use the command key on Mac
                case 'Control':{
                    if(this.state.mInteractions['translate'].getActive()){
                        switch(_event.type){
                            case 'keydown':{
                                console.log("Activate selectHover");
                                this.state.mInteractions['selectHover'].setActive(true);
                            }break;
                            case 'keyup':{
                                try {
                                    this.state.mSelectHover.getFeatures().clear();
                                    this.state.mSelect.getFeatures().clear();                                     
                                } catch (error) {
                                    console.log("Failed to clear:",error);
                                }
                                this.state.mInteractions['selectHover'].setActive(false); 
                            }break;
                        }
                    }
                    
                }break;
                case 'Shift':{
                    if(this.state.mInteractions['multiselect'].getActive()){
                        switch(_event.type){
                            case 'keyup':{
                                // console.log("Shift Released: ",this.state.mutliSelectFeatures);
                                let idsSelected = (this.state.mutliSelectFeatures || []).map(feature_=>{
                                    return {
                                            id: feature_.get('id'),
                                            name: feature_.get('name'),
                                            groupname: feature_.get('groupname'),
                                    }
                                })

                                //get the list of groups:
                                let groupList = this.state.geofenceFilters.groups.map(group_=>{return group_.name})
                                if(idsSelected && idsSelected.length > 0){
                                    const card = {
                                        regions: idsSelected,
                                        sitename: this.state.mCurrentSite,
                                        groupList: groupList,
                                    };
                                    //pass the list along to the popup
                                     this.setState({geofenceAssignGroup:card});
                                }
                                
                            }break;
                        }
                    }
                }break;
            }
        }catch(error){
    
          // console.log("Error on key: ",error);
        }
         
      }

    onMapJourneyResult(_data){
        // console.log("Returned: ",_data);

        if(_data && _data.loaded){
            // console.log("Call loaded")
            this.setState({lastUpdateTime: moment()});
            return;
        }

        //Update the fetching dialog box:
        let journeyFetchList = Object.assign({}, this.state.journeyFetchList)
        if(_data && _data.journeyFetched){
            try{
                journeyFetchList[_data.journeyFetched.srclocation].fetched = true;
            }catch(e){
                console.log("Failed to find in fetch list: ",journeyFetchList,_data)
            }
            this.setState({journeyFetchList:journeyFetchList});
            return;
        }      

        const filtersCopy = Object.assign({}, this.state.filters);        

        //New API call has been issued: Clear the previous results:
        if(_data && _data.clear){
            // console.log("Clear called");
            filtersCopy.journeys = []
            this.releaseJourney(true);
            this.setState({filters:filtersCopy,journeyFetchList:{},fetchingDialogState:1 });
            return;
        }
        if(_data && _data.reset){
            // console.log("Clear called");
            filtersCopy.journeys = []
            this.setState({filters:filtersCopy,journeyFetchList:{},fetchingDialogState:0 });
            return;
        }

        if(_data && _data.length === 0){ //no results returned by the query?
            // console.log("Empty data found")
            this.setState({fetchingDialogState: 2});
            return;
        }
        if(_data && _data.daterange){ //no results returned by the query?
            //  console.log("Date range found")
            this.setState({fetchingDialogState: 3});
            return;
        }

        if(_data && _data.infractions && _data.infractions==='loaded'){ //no results returned by the query?
            try {
                let selectedInfraction = this.state.activeJourneyFilters.infraction || 'None';
                this.updateInfractionVisibility(selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)    
            } catch (error) {}
            return;
        }

        //Filters have changed:
        if(_data){
            // console.log("Processing data")
            for(const journey_ of _data){
                filtersCopy.journeys.push(journey_.srclocation);
                if(!journeyFetchList[journey_.srclocation]){journeyFetchList[journey_.srclocation] = {fetched: false}};
            }
            this.setState({filters:filtersCopy,journeyFetchList:journeyFetchList,fetchingDialogState:0});
            return;
        }

        
        
        
    }

    /**
     * Find the current bounding box of the selected journey and reposition the map to view the journey if necessary
     * @param {ol.Feature} _journey 
     */
    repositionMapToJourney(_journey){
        //Get the GPS points from the journey
        let routes = _journey.get('routeData');
        try {
            let combinedGPS = [];
            //Get all the GPS points from the Journey, include each linestring
            for( const route_ of routes){
                combinedGPS.push(...route_.gps);
            }
            //Convert array notation in geolib notation
            let geoLibGPS = (combinedGPS||[]).map(coord_=> {
                return { latitude: parseFloat(coord_[1]), longitude: parseFloat(coord_[0]) }
            });
            //Get the bounds of the GPS coordinates using geolib
            let journeyBounds = getBounds(geoLibGPS);
            //Convert the geolib return in to a x1,y1,x2,y2 notation for a rectangle
            let journeyBoundingBox = [journeyBounds.minLng,journeyBounds.minLat,journeyBounds.maxLng,journeyBounds.maxLat];

            //Get the current extent of the map's viewport:
            var mapExtent = this.state.mMap.getView().calculateExtent(this.state.mMap.getSize());
            //Convert the extent into GPS coordinates
            let mapBoundingBox = transformExtent(mapExtent, COORDTYPE_MERCATOR, COORDTYPE_GPS);        

            //Compare the areas of the bounding boxes:
            let overlapReturn = getBoundingBoxOverlap(journeyBoundingBox, mapBoundingBox)
            // console.log("Overlap return: ",overlapReturn,overlapReturn.percentMax,overlapReturn.journey.overlap);

            //Update the map view to find the Journey (Zoom+translate or translate):            
            //---------------------------------------------------------------------
            //Check the percent coverage of the journey against the map, if coverage is too low or high then 
            //initiate a fit() on the view to zoom and translate to the journey's boudning box
            if(overlapReturn.percentMax < 0.25 || overlapReturn.percentMax > 1.25){
                //Convert the journey bounding box into an extent (project the GPS coordinates to the map)
                let journeyExtent = transformExtent(journeyBoundingBox, COORDTYPE_GPS,COORDTYPE_MERCATOR );        
                //Pass the journey's extent to the current view
                this.state.mMap.getView().fit(journeyExtent, 
                    {size: this.state.mMap.getSize()
                    , duration: 1000  //Set the duration of the animation to 1 second
                    , padding:[100,100,100,100] //add a padding to the fit so that it stays slightly zoomed out from the Journey
                });
            }else{
                //The zoom level of the map is sufficient to view the Journey, instead check if the map needs to be 
                //translated to find the journey. If less than 50% of the Journey is visible on the map then shift the map to view the Journey                
                if(overlapReturn.journey.overlap < 50){                
                    // console.log("Translate on: ",_journey.get("journeyname"));
                    this.state.mMap.getView().animate({center: transform([overlapReturn.journey.center.latitude,overlapReturn.journey.center.longitude], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                }
            }
        } catch (error) {
            console.log("Fail on extract boundary: ",error);
        }
    }//end repositionMapToJourney


    /*
    * @brief A callback for when a specific filter, by name, is set to a particular value
    */
    onJourneyFilterSelected(name, value) {
        // console.log("Journey selected: ",name,value.value)
        this.closePopup();

        const filtersCopy = Object.assign({}, this.state.activeJourneyFilters);
        filtersCopy[name] = value.value;
        if (!value.value) {
            delete filtersCopy[name];
        }
        // console.log("Set active filters to: ",filtersCopy);
        this.setState({activeJourneyFilters:filtersCopy});
        try{
            switch(name){
                case 'vehicle':
                break;
                case 'infractions':

                    if(value.value === 'Clear'){
                        //delete the infractions and clips
                        this.clearSet("infractions");                        
                        this.clearSet("alerts");
                    }

                    if(value.value === 'None'){
                        //Clear the current infractions:
                        // this.clearSet("infractions");                        
                    }
                    console.log("Set vis: ",value.value,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
                    this.updateInfractionVisibility(value.value,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
                    this.setState({selectedInfraction: value.value})
                break;
                case 'drivers':
                break;
                case 'journeys':
                    // this.state.mapJourney.setMemberVar({selectedJourney: value.value});
                    // this.setState({selectedJourney: value.value});
                    switch(value.value){
                        case 'Clear':{
                            console.log("Call clear");
                            if(this.mapJourneyRef.current){
                                this.mapJourneyRef.current.clearJourneys();
                                this.setState({fetchingDialogState:0, journeysToFetch:0, journeysFetched:0})
                            }
                            return;
                        }break;
                        case 'All':{
                            this.releaseJourney(true);
                            return;
                        }break;
                        default:{
                            this.releaseJourney(false); //disable the speed route view when changing the journeys
                        }
                    }



                    //Get the journey feature:
                    let routeSet = null;
                    let featureToTest = null;
                    try {
                        routeSet = this.state.overlaySource["journey"].getFeatures();    
                        let filteredSet = (routeSet || []).filter(journey_=>{
                            try {
                                return journey_.get('journeyname').includes(value.value)    
                            } catch (error) { return false; }
                        });
                        // console.log("Filtered set: ",filteredSet);
                        featureToTest = filteredSet[0];
                        // console.log("Selected journey: ",this.state.selectedJourney, featureToTest)
                    } catch (error) {
                        console.log("Failed to get features:" ,error);
                    }
                    this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,featureToTest);
                    //Reposition the map to show the Journey:
                    this.repositionMapToJourney(featureToTest);

                break;
              
            }//end switch
            
        }catch(e){
            console.log("Error with filters: ",e);            
        }
    }//end onFilterSelected
    /*
    * @brief A callback for when a specific filter, by name, is set to a particular value
    */
    onFilterSelected(name, value) {
        //  console.log("selected: ",name,value.value)
        this.closePopup();

        // console.log("Roads features: ",this.state.overlaySource["roads"],this.state.overlaySource["roads"].getFeatures())

        const filtersCopy = Object.assign({}, this.state.activeFilters);
        filtersCopy[name] = value.value;
        if (!value.value) {
            delete filtersCopy[name];
        }
        // console.log("Set active filters to: ",filtersCopy);
        this.setState({activeFilters:filtersCopy});
        // Allow this to be used as the start of a performance measurement
        // perfDidClick('filter-selected');
        
        try{
            switch(name){
              
                case 'geofencing':
                    switch(value.value){
                        case 'edit':
                        case 'polygon':
                        case 'create':
                            this.toggleGeoFencing(value.value);
                            this.state.overlayLayers['geofence'].setVisible(true);
                            break;
                        case 'show':
                            this.toggleGeoFencing(false);
                            this.state.overlayLayers['geofence'].setVisible(true);
                            break;
                        case 'hide':
                            this.toggleGeoFencing(false);
                            this.state.overlayLayers['geofence'].setVisible(false);
                            break;
                    }//end switch
                break;
                case 'group':

                    this.toggleGeoFencing(value.value);
                    this.updateRegionVisibilty(filtersCopy);
                    switch(value.value){                        
                        case 'addtogroup':
                            break;
                        case 'showgroups':
                            break;
                        case 'hidegroups':
                            break;
                        case 'listgroups':{
                            const card = {
                                sitename: this.state.mCurrentSite,
                            };
                            this.setState({manageFences:card});                            
                        }break;
                    }//end switch
                break;
                case 'sites':{
                    let valueSet = value.value.split(',');
                    let siteToSet = valueSet[0];
                    let toSet = {mCurrentSite: siteToSet, followAsset:null} //default state values to set

                    //Filter the list of assets:
                    let filteredAssets = filterAssetSet(this.props.possibleFilters.AssetsTrifecta,{startDate:this.state.startDate, endDate:this.state.endDate},siteToSet, this.props.groupconfig)
                    // console.log("FIltered Assets: ", filteredAssets,this.props.possibleFilters.AssetsTrifecta)
                    if(filteredAssets){
                        
                        toSet.filters = Object.assign(this.state.filters,{vehicle:filteredAssets})
                        toSet.geofenceFilters = Object.assign(this.state.geofenceFilters,{vehicle:filteredAssets})
                    };

                    //Fetch roads for the current site
                    try {
                        // console.log("Call build roads:" ,this.state.overlayLayers["roads"].getVisible())
                        RoadBuilder.loadRoads(siteToSet,this.props, {roads: this.state.overlaySource["roads"], signs: this.state.overlaySource["signs"]});   
                        // this.state.overlaySource 
                    } catch (error2) {
                        console.log("error loading roads:" ,error2);
                    }
                    

                    try {
                        let currentSiteConfig = this.state.siteConfig.filter(item => item.site === valueSet[0]);
                        // console.log("Current Site Config: ",currentSiteConfig,this.state.siteConfig,valueSet[0])
                        if(currentSiteConfig.length>0){
                            toSet.mCurrentSiteDetails = currentSiteConfig[0]
                        }    
                    } catch (error) {
                        console.log("Error on set: ",error,this.state.siteConfig, valueSet[0]);
                    }
                    // console.log("Filtered Assets: ",filteredAssets,toSet);
                    //Set the name of the current site:                    
                    this.setState(toSet,
                        ()=>{ //execute after the state is updated
                            
                            let mapView = this.state.mMap.getView();
                            //get the gps coordinates 2,3:
                            if(valueSet[2] && valueSet[1]){
                                mapView.animate({center: transform([valueSet[2], valueSet[1]], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                                // console.log("Site selected: ",transform([valueSet[2], valueSet[1]], COORDTYPE_GPS, COORDTYPE_MERCATOR));
                            }else{
                                console.log("Empty GPS: ",valueSet,valueSet[2],value.value);
                            }
                            //Update the visibilities of the assets:
                            this.updateAssetFeatures(); 
                            this.updateAssetsVisibility()
                            //Clear the Journeys and infractions on a site change:
                            if(this.mapJourneyRef.current){  
                                this.clearSet("journeys");
                                this.onMapJourneyResult({reset:true});
                                this.clearSet("infractions");
                                this.clearSet("alerts");
                            }

                            this.fetchGeofenceList(valueSet[0]);
                        }
                    ) //update the state
                }break;
                case 'regions':{
                    //need to recenter onto the region:
                    let geoFenceRegions = this.state.overlaySource['geofence'].getFeatures();
                    let valueSet = value.value.split(',');
                    (geoFenceRegions || []).forEach( (elem_) =>{
                        //Find the region selected by the filter:
                        if(elem_.get('id')===valueSet[1]){
                            
                            //get the GPS coordinates from the region:
                            let gps={
                                lat: elem_.get('Lat'),
                                lon: elem_.get('Lon'),
                            }
                            // console.log("Move to : ",gps);
                            elem_.setStyle(new Style({
                                fill: new Fill({color: 'rgba(141, 198, 63, 0.20)'}),
                                stroke: new Stroke({
                                color: 'white'
                                })
                            }))
                            // console.log("Found region: ",value.value, gps);
                            let mapView = this.state.mMap.getView();
                            if(gps.lat && gps.lon){
                                mapView.animate({center: transform([gps.lon, gps.lat], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                            }else{
                                console.log('Empty GPS');
                            }
                            this.setState({mSelectedRegion:elem_});
                        }else{
                            elem_.setStyle();
                        }

                    });
                }break;
                
                case 'settings':{
                    const card = {
                        sitename: this.state.mCurrentSite,
                        config: this.state.siteConfig.filter(item => item.site === this.state.mCurrentSite),
                    };
                    this.setState({siteConfigCard:card});
                }break;
                case 'managefences':{
                    const card = {
                        sitename: this.state.mCurrentSite,
                    };
                    this.setState({manageFences:card});
                }break;
                case 'vehicle':
                    switch(value.value){
                        case 'All':{this.setState({followAsset: 'All'},()=>{this.updateAssetsVisibility()});}break;
                        case 'None':{this.setState({followAsset: null},()=>{this.updateAssetsVisibility()});}break;
                        default:{this.setState({followAsset: value.value},()=>{this.updateAssetsVisibility()});}break;
                    }                    
                    // this.setState({mCurrentSite: valueSet[0]},()=>{this.updateAssetsVisibility()})
                break;
                case 'date':{ //handle changes to either start or end
                    // console.log("Date change:" ,value.value);
                    let start_date = value.value.start;
                    let end_date = value.value.end;
                    let toSet = {startDate: start_date, endDate: end_date} //update the stored start/end dates
                    //filter the assets based on the defined time window:
                    let filteredAssets = filterAssetSet(this.props.possibleFilters.AssetsTrifecta,{startDate:start_date, endDate:end_date},this.state.mCurrentSite, this.props.groupconfig);
                    if(filteredAssets){
                        // console.log("Set the filters:" ,filteredAssets);
                        toSet.filters = Object.assign(this.state.filters,{vehicle:filteredAssets})
                        toSet.geofenceFilters = Object.assign(this.state.geofenceFilters,{vehicle:filteredAssets})
                        toSet.bUpdateInfractions = true;
                    }
                    this.setState(toSet,()=>{this.updateAssetFeatures()});

                }break;
                  
            }//end switch
        }catch(e){
            console.log("Error with filters: ",e);            
        }
    }//end onFilterSelected

    /*
    * @brief A callback for when a specific filter, by name, is set to a particular value from the infractions tab
    */
    onInfractionsFilterSelected(name, value) {
        // console.log("Infractions selected: ",name,value.value)
        this.closePopup();

        const filtersCopy = Object.assign({}, this.state.activeFilters);
        filtersCopy[name] = value.value;
        if (!value.value) {
            delete filtersCopy[name];
        }
        // console.log("Set active filters to: ",filtersCopy);
        this.setState({activeFilters:filtersCopy});
        // Allow this to be used as the start of a performance measurement
        // perfDidClick('filter-selected');
        
        try{
            switch(name){
                case 'apply':{
                    // console.log("Call updateInfractionVisibility ")
                    this.setState({ activeAsset: this.state.selectedInfractionFilters.asset,
                                    selectedInfraction: this.state.selectedInfractionFilters.infraction,
                                    mSelectedDriver: this.state.selectedInfractionFilters.driver,
                                    mTimeScale:this.state.selectedInfractionFilters.timeScale,
                    })

                    if(this.state.bUpdateInfractions){
                        // console.log("Time changed:" ,this.state.selectedInfractionFilters.timeScale, this.state.mTimeScale)

                        //Adjust the time filter and trigger another API request:
                        //-------------------------------------------------------
                        //Clear the map layers to receive the new data
                        // this.clearSet("journey");
                        
                        if(this.mapJourneyRef.current){
                            // console.log("Triggered journey clear?")
                            this.mapJourneyRef.current.clearJourneys();
                            this.setState({fetchingDialogState:0, journeysToFetch:0, journeysFetched:0})
                        }else{
                            this.clearSet('infractions');
                            this.clearSet('alerts');
                        }
                       
                        //Trigger the infraction fetch
                        this.getEventsApiCall();  

                    }else{
                        //The api return will call the update on the visibility
                        this.updateInfractionVisibility(this.state.selectedInfractionFilters.infraction,this.state.selectedInfractionFilters.asset,this.state.selectedInfractionFilters.driver,this.state.selectedJourney); 
                    }
                }break;
                case 'vehicle':
                    if(value.value === 'All'){
                        this.setState({selectedInfractionFilters: Object.assign(this.state.selectedInfractionFilters,{asset: null}),bUpdateInfractions:true});
                    }else{
                        this.setState({selectedInfractionFilters: Object.assign(this.state.selectedInfractionFilters,{asset: value.value}),bUpdateInfractions:true});
                    }
                break;
                case 'infractions':

                    if(value.value === 'Clear'){
                        //delete the infractions and clips
                        this.clearSet("infractions");                        
                        this.clearSet("alerts");
                    }

                    if(value.value === 'None'){
                        //Clear the current infractions:
                        // this.clearSet("infractions");                        
                    }

                    this.setState({selectedInfractionFilters: Object.assign(this.state.selectedInfractionFilters,{infraction: value.value}),bUpdateInfractions:true},
                    ()=>{
                        this.updateInfractionVisibility(this.state.selectedInfractionFilters.infraction,this.state.selectedInfractionFilters.asset,this.state.selectedInfractionFilters.driver,this.state.selectedJourney); 
                    });
                    
                break;
                
                case 'drivers':
                    this.setState({selectedInfractionFilters: Object.assign(this.state.selectedInfractionFilters,{driver: value.value}),bUpdateInfractions:true},()=>{
                        this.updateInfractionVisibility(this.state.selectedInfractionFilters.infraction,this.state.selectedInfractionFilters.asset,this.state.selectedInfractionFilters.driver,this.state.selectedJourney); 
                    });
                break;
                      
            }//end switch
        }catch(e){
            console.log("Error with filters: ",e);            
        }
    }//end onInfractionsFilterSelected

    fetchSiteDetails(_site){

        const filterPromise = Auth.currentSession().then(
          (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/getSiteDetails";
            let myInit = {
              body: {
                token: auth.idToken.jwtToken,
                site:_site,
                clientid: this.props.groupconfig.group,
              }
            };
            return API.post(apiName, path, myInit);
          })
          .then(_data=>{
            console.log("Details returned:" ,_data);

            const siteconfigs = this.state.siteConfig;
            //Find the site in the set and update the speed
            (siteconfigs||[]).forEach( site_=>{
                // console.log("Testing: ",site_);
                    if(site_.site === _data.site){
                        site_.boundary = JSON.parse(_data.boundary); //update the site_ element
                    }

                }
            )
            this.setState({siteConfig:siteconfigs});
          });
    }

    fetchGeofenceList(_details){
         // handleGeoFence
         const regionPromise = Auth.currentSession().then(
            (auth) => {
                //Set up the API request:
                let apiName = "TrifectaMapAPI";
                let path = "/handleGeoFence";
                let myInit={};
                let bodyParams= {
                    token: auth.idToken.jwtToken,
                    mode: "fetch",
                    site: _details,
                }
                myInit.body = Object.assign(bodyParams,{});

                return API.post(apiName, path, myInit);
        });
        regionPromise.then(data => {
            // console.log("Regions returned: ",data);
            //Clear the old regions
            let geofenceFilters = Object.assign({}, this.state.geofenceFilters);
            geofenceFilters.regions = [];
            this.setState({geofenceFilters:geofenceFilters});
            this.state.overlaySource['geofence'].clear();

            //add the new regions:
            this.addRegionsToMap(data.regions);
        })
        regionPromise.catch(function(error) {
            console.log(error);
        });

    }

    /*
    * Update the visibility of the infraction markers. Given the chosen filters choose which markers should be made visible
    */
    updateInfractionVisibility(_infraction,_asset,_driverid,_journey){
        // console.log("Update Infraction Visiblity:",_infraction,_asset, _driverid, _journey, this.state.selectedInfractionFilters);
        // console.log("Update Infraction Visiblity:",_infraction,this.state, this.state.overlaySource[_infraction],_journey)
        //Iterate the markers and set their styles:
        if(_infraction==='None' || _infraction==='Clear'){
            this.toggleLayerByName(_infraction);
            return;
        }

        //Get the set of infractions
        let infractionSet = [];
        try {
             infractionSet = this.state.overlaySource[_infraction].getFeatures();    
            //  console.log("Infraction layers: ",infractionSet);
        } catch (error) {
            console.log("Failed to get features:" ,error,_infraction);
        }
        
        
        //When processing the "All" set we also need to include the markers from the 
        // speeding and alerts sets.
        if(_infraction==='All'){ 
            // let speedingSet =this.state.overlaySource['Speeding'].getFeatures();
            // infractionSet  = infractionSet.concat(speedingSet); 
            //Get the feautures out of each of the alert layers:
            for(const layer_ of this.state.mAlertLayers){
                try {
                    let alertsSet =this.state.overlaySource[layer_].getFeatures();
                    infractionSet  = infractionSet.concat(alertsSet);    
                } catch (error) {
                    // console.log("Alert layers no return?");                    
                }
            }
            
        }
        //  console.log("Update visibility: ",infractionSet.length,_journey);


        // console.log("Compare with: ",_asset,_driverid,_infraction);
        (infractionSet || []).forEach( (elem_) =>{
            // if(_journey){console.log("Inf: ",elem_,_journey.get('journeyid'));}
            //  console.log("Inf: ",elem_);
            let bVisible = false;
            if(_asset === 'All'||!_asset) {
                if(_driverid && _driverid!=='All'){
                    if(elem_.get('driverid')===_driverid){
                        // console.log("Matched the driverid: ",_driverid,elem_.get('driverid'))
                        bVisible = true;
                    }
                }else{
                    bVisible = true;
                } 

            }else{ //asset is selected
                if(elem_.get('name') === _asset){
                    if(_driverid && _driverid!=='All'){
                        if(elem_.get('driverid')===_driverid){
                            // console.log("Matched the driverid: ",_driverid,elem_.get('driverid'))
                            bVisible = true;
                        }
                    }else{
                        bVisible = true;
                    } 
                }
            }
            //Check against the journey name:
            try {
                if(_journey){
                    if(elem_.get('journeyname')!==_journey.get('journeyname')){
                        bVisible = false;
                    }
                }    
            } catch (error) {}
            

            // console.log("Set visible: ",bVisible);
            //Set the feature's style to adjust visibility
            if(bVisible){
                elem_.setStyle(); //reset to the default style
            }else{
                elem_.setStyle(new Style({})); //set to an empty style
            }
        })
        
        //Update the assetmarkers:
        this.updateAssetsVisibility(this.state.activeAsset);     
        //Updat
        this.toggleLayerByName(_infraction);
    }

       /*
    * Stop listening for Card drags because this Component will be disappearing
    * (view changed most likely)
    */
    componentWillUnmount() {
        // console.log("unmount map called: ",this.state.intervalID);
        clearInterval(this.state.intervalID);
        this.state.intervalID=0;
        window.removeEventListener("optimizedResize", this.windowResized);
        window.removeEventListener('keydown',this.handleKeyPress)
        window.removeEventListener('keyup',this.handleKeyPress)

        
        try {
            if(this.state.mMap){
                this.state.mMap.setTarget(null);
                this.setState({mMap:null});
            }
        } catch (error) {
            // console.log("Failed to release: ",this.state.mMap,error);
        }
    }


    addLayer(_name, _vector, _source){
        // console.log("Add: ",_name);
        const layers = this.state.overlayLayers;
        const sources = this.state.overlaySource;
        layers[_name] = _vector;
        sources[_name] = _source;
        this.setState({overlayLayers:layers,overlaySource:sources});
    }

    

    /*
    * Execute once when the page loads
    */
    componentDidMount() {      
        // console.log("Loaded:" ,this.props, this.state);

        this.db.version(1).stores(
            { photos: "driverid" }
          )
          this.db.open().then(function (db) {
          }).catch (function (err) {
             // Error occurred
             console.log("Map - Failed to open db: ",err);
          });    


        // console.log("Loaded dev version", this.props);
        window.addEventListener('keydown',this.handleKeyPress);
        window.addEventListener('keyup',this.handleKeyPress)
        this.setState({
            divWidth: this.m_childDiv && this.m_childDiv.clientWidth,
            winWidth: document.documentElement.clientWidth - 40
        });

        
        let filteredAssets = filterAssetSet(this.props.possibleFilters.AssetsTrifecta,{startDate:this.state.startDate, endDate:this.state.endDate},this.state.mCurrentSite, this.props.groupconfig)
        if(filteredAssets){
            let toSet = {}
            toSet.filters = Object.assign(this.state.filters,{vehicle:filteredAssets})
            toSet.geofenceFilters = Object.assign(this.state.geofenceFilters,{vehicle:filteredAssets})
            this.setState(toSet,()=>{this.updateAssetFeatures()});
            // console.log("Filtered assets:" ,filteredAssets);
        };

        var mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(6),
            projection: COORDTYPE_GPS,
            // projection: COORDTYPE_MERCATOR,
        });

        let zoomLevel = this.state.currentZoom;
        // let centerLat = 33.413733507261774;
        // let centerLon =  -110.97229217401843;
        let centerLat = null;
        let centerLon =  null;
        if(this.props.groupconfig.group==='devgroup' || this.props.groupconfig.group==='bis_drive'){
            centerLat = -32.757946;
            centerLon =  116.351955;
            zoomLevel = 13;
        }
        //Get the default GPS coordinates from the list of allowed sites
        try {
            // console.log("Sites: ",this.props.possibleFilters.Sites)
            if(this.props.possibleFilters.Sites && this.props.possibleFilters.Sites.length > 0){
                //  console.log("Site data: ",this.props.possibleFilters.Sites[0].gps);                
                if(this.props.possibleFilters.Sites[0].gps){
                    let coordinates = this.props.possibleFilters.Sites[0].gps.split(',');
                    centerLat = parseFloat(coordinates[0]);
                    centerLon = parseFloat(coordinates[1]);
                    // centerLon = 119.97477;
                    // console.log("Set center GPS: ",centerLat, centerLon)
                }
            }    
        } catch (error) {
            console.log("Error getting default site location: ",error);
        }

        

       
        

        //Create a set of layers to hold the asset markers and infraction markers
        let mapTileLayers = {};

        var boundarySource = new VectorSource({});
        var boundaryVector = new VectorImage({
            source: boundarySource,
            visible:true,    
            displayInLayerSwitcher: false,
        });

        this.addLayer("boundary",boundaryVector,boundarySource);

        //--------------------------------------------------------
        //Create layers to show geofenced regions:
        var fenceSource = new VectorSource({});
        var fenceLayer = new Vector({
            source: fenceSource,           
            style: new Style({
                // fill: new Fill({color: 'rgba(200, 0, 0, 0.75)'}),
                fill: new Fill({color: 'rgba(0, 174, 239, 0.20)'}),
                stroke: new Stroke({
                color: 'white'
                })
            }), 
            visible:true,         
            displayInLayerSwitcher: false,
        });
        this.addLayer("geofence",fenceLayer,fenceSource);
        // overlaySource['geofence'] = fenceSource;
        // overlayLayers['geofence'] = fenceLayer;
    

    //-----------------
    //CREATE THE MAP:
    //-----------------
        // Create an Openlayer Map instance with two tile layers
        const map = new Map({
            //  Display the map in the div with the id of map
            target: 'map',
            layers: [
                tiles_OSM,
                tiles_TOPO,
                tilegroup_Satellite,
                boundaryVector,
                fenceLayer,
                // journeyVector,
            ],
            // Render the tile layers in a map view with a Mercator projection
            view: new View({
                center: fromLonLat([centerLon,centerLat]),
                zoom: zoomLevel
            })
        })
        map.addControl(new LayerSwitcherImage()); //enable switching between map layers
        this.setState({mMap:map});


       
        //Add layers to the map:
        //------------------------------------------------------------------------------------------------
        
        //add layer to show the roads
        createVectorImageLayer('roads',map,this.addLayer,{style:roadStyler});
        createVectorImageLayer('signs',map,this.addLayer,{style:signStyler});
        // createVectorImageLayer('signs',map,this.addLayer,{style:signStyler, enableClustering: true});
        //Add the layer to show the journeys
        createVectorImageLayer('journey',map,this.addLayer); //style set later by the MapJourney component
        // createVectorImageLayer('journey',map,this.addLayer,{enableClustering: true}); //style set later by the MapJourney component
        
       
        //Create infraction layers, one per infraction type:
        createInfractionLayers(this.props.possibleFilters.infractiontags,this.state,map,this.addLayer);
        createAlertLayers(this.state.mAlertLayers,this.state,map,this.addLayer);  
            
        //Enable a layer to draw the asset markers:
        createVectorLayer('asset',map,this.addLayer,{style: (feature,resolution)=>setAssetStyle(feature,resolution,'#0000e0',this.state.styleCache)})
        
        

        //------------------------------------------------------------------------------------------------------

        //Define the map interactions:
        //------------------------------------------------------------------------------------------------------
        let interactionArray = [];
        //add support for popup:
        var select = new Select({
            hitTolerance: 5,
            multi: true,
            // condition: condition.singleClick
        });
        interactionArray["select"] = select;
        map.addInteraction(select);


        var selectDC = new Select({
            hitTolerance: 5,
            multi: true,
            condition: doubleClick
        });
        map.addInteraction(selectDC);

        const dblClickInteraction = map.getInteractions().getArray().find(interaction => {return interaction instanceof DoubleClickZoom; });
        // console.log("DblClick: ",dblClickInteraction);
        map.removeInteraction(dblClickInteraction);

       

        //Disable the double click navigation
        // var Navigation = new Navigation({
        //     defaultDblClick: function(event) { return; }
        // });
        // map.addControl(Navigation);

        // this.routeSelect = new Select({
        //     filter: function(feature) { return feature.getGeometry().getType() == 'Point' }
        // })

        //Define a hover interaction 
        var selectHover = new Select({
            hitTolerance: 5,
            multi: false,
            layers: [fenceLayer],
            condition: pointerMove,
        });
        interactionArray["selectHover"] = selectHover;
        interactionArray["selectHover"].setActive(false);
        map.addInteraction(selectHover);


        //add multi region selection interaction:
        var multiselect = new Select({
            hitTolerance: 5,
            multi: true,
            condition: singleClick,
            toggleCondition: shiftKeyOnly
        });
        interactionArray["multiselect"] = multiselect;
        interactionArray["multiselect"].setActive(false); //set to disabled by default
        map.addInteraction(multiselect);

        //Define the action when the multiselect returns:
        multiselect.on("select", event => {
            let features =  multiselect.getFeatures().getArray();
            
            //Iterate over the selected features            
            (features || []).forEach(feature_=>{
                //Change the color of the feature we grabbed
                feature_.setStyle(new Style({
                    fill: new Fill({color: 'rgba(141, 198, 63, 0.20)'}),
                    stroke: new Stroke({
                    color: 'white'
                    })
                }))
            })
            this.setState({mutliSelectFeatures:features})
        });

        // var start_drawing = false;
        let drawCircle = new Draw({
            source: fenceSource,
            type: 'Circle',            
        });
        drawCircle.setActive(false);
        drawCircle.on('drawstart', function(evt){
            console.log("start: ",evt.feature.getGeometry().getCenter(),map,evt,evt.feature,);
        });
        drawCircle.on('drawend', evt => {
            // console.log("event end: ",evt,evt.feature,transform(evt.feature.getGeometry().getCenter(), COORDTYPE_MERCATOR, COORDTYPE_GPS));
            this.handleRegionComplete(evt.feature,'circle',this.state.overlaySource['geofence']);
            this.openPopup(evt.feature); //automatically open the notecard to configure
        });
        interactionArray["drawCircle"] = drawCircle;
        map.addInteraction(drawCircle);

        let modifyRegions = new Modify({source: fenceSource});
        
        //Handle the event that is fired when the modification stops:
        modifyRegions.on('modifyend',evt=>{
            //Find the features that are regions at this pixel location
            let features =  map.getFeaturesAtPixel(evt.mapBrowserEvent.pixel_);
            for(const feature_ of features){
                if(feature_.get('isGeoFence')){ //if it is the geofence, and not a point in the polyon:
                    this.handleRegionUpdate(feature_,feature_.getGeometry().getType());
                }
                
            }
        })
        map.addInteraction(modifyRegions);
        interactionArray["modify"] = modifyRegions;
        //Define the translate edit for the features, use the hover interaction to select which features are interacted with
        let translateRegions = new Translate({
            source: fenceSource,
            features: selectHover.getFeatures(),
        });
        //Handle the end of the translation
        translateRegions.on('translateend',evt=>{
            // console.log("end of translate?",evt)
            let features =  map.getFeaturesAtPixel(evt.mapBrowserEvent.pixel_);
            for(const feature_ of features){
                if(feature_.get('isGeoFence')){ //if it is the geofence, and not a point in the polyon:
                    this.handleRegionUpdate(feature_,feature_.getGeometry().getType());
                }
                
            }
        })        
        interactionArray["translate"] = translateRegions;
        interactionArray["translate"].setActive(false);
        // interactionArray["modify"] = translateRegions;
        map.addInteraction(translateRegions);
        //Add a snap to the geofence layer so its easier to grab the edges of the regions:
        let snap = new Snap({source: fenceSource});
        map.addInteraction(snap);
        interactionArray["snap"] = snap;

        

        let drawPoly = new Draw({
            source: fenceSource,
            type: 'Polygon',            
        });
        drawPoly.setActive(false);
        drawPoly.on('drawstart', function(evt){
            // console.log("Start: ",evt);
        });
        drawPoly.on('drawend', evt => {
            this.handleRegionComplete(evt.feature,'polygon',this.state.overlaySource['geofence']);
            this.openPopup(evt.feature);//automatically open the notecard to configure
        });
        

        interactionArray["drawPoly"] = drawPoly;
        map.addInteraction(drawPoly);

        //------------------------------------------------------------------------------------------------------
        
        var animationsTable = [];
        var popup = new Popup (
        {	
            popupClass: "default", //"tooltips", "warning" "black" "default", "tips", "shadow",
            closeBox: false,
            // onshow: function(){ console.log("You opened the box"); },
            onclose: function(){ 
                // console.log("You closed the box"); 
            },
            positioning: 'auto',
            autoPan: true,
            autoPanAnimation: { duration: 250 }
        });

        

        map.addOverlay(popup);
        this.setState({mPopup: popup,mSelect: select,mSelectHover: selectHover, mInteractions:interactionArray},
            //Wait for the setup state to be stored, then start the animation, and forward the stored states to the function
            ()=>{
            //Add the animation on the asset layer?
            try {
                this.state.overlayLayers['asset'].on('postrender',(event_)=>{
                    animateAssets(event_,this.state.mMap,this.state.overlaySource)}
                )
                console.log("Animation layer linked on asset layer");
            } catch (error) {
                console.log("Failed to add animation: ",error);
            }
        });


        
        // var anim = new Fade({
        // });
        // // Continune animation if not stop by user
        // anim.on('animationend', function(e)
        // {	if (!e.user) animationsTable[e.feature.get('name')] = markersLayer.animateFeature (e.feature, anim);
        // });
        

        let target = [];
        target.value = [];
        selectDC.on("select", event => {
            // console.log("Double click: ",event)
            target.value = selectDC.getFeatures().getArray();
            if (target.value.length){
                // console.log("DC on: ",target.value,target.value[0].get('type'));
                this.openPopup(target.value[0],selectDC,true);
            }else{ //click on nothing:
                this.closePopup();
                selectDC.getFeatures().clear();
            }
        })
        select.on("select", event => {
            //  console.log("this state: ",this.state,event);
            target.value = select.getFeatures().getArray();
            // console.log("Select: ",event,target.value);

            let groupNames = [];
            try {
                groupNames= (this.state.geofenceFilters.groups || []).map(elem=> elem.name.toLowerCase());
            } catch (error) {}
            // console.log("Region names?: ",groupNames);
            //Are we configuring a group:
            if(this.state.activeFilters && this.state.activeFilters.group && this.state.activeFilters.group==='configuregroups'){
                //get the region:
                let region = this.getSmallestGeofence(target.value);
                try {
                    let data = {
                        group: region.get('groupname'),
                        groupList: groupNames,
                    }
                    this.setState({groupSettingsCard: data})    
                } catch (error) {}
                select.getFeatures().clear();
                return;
            }
            
            //Reset the region color:
            if(this.state.mSelectedRegion){
                // console.log("Reset style: ",this.state.mSelectedRegion);
                this.state.mSelectedRegion.setStyle();
                this.setState({mSelectedRegion:null})
            }

            // console.log("val: ",target.value,this);
            if (target.value.length){
                // var feature = target.value[0];
                var feature = this.getSmallestArea(target.value);        
                // console.log("Smallest area:" ,feature);        
                //Manipulate the collection of features to only highlight the one we selected:
                let tmpCollection = select.getFeatures();
                tmpCollection.clear();
                tmpCollection.push(feature);
                // console.log("Returned: ",tmpCollection);
                //Pass the selected feature to be opened in the popup:
                this.openPopup(feature,select);

            }else{ //click on nothing:
                // console.log("Background click? ",this.state.selectedJourney)
                this.closePopup();
                select.getFeatures().clear();
                //Have to reclear the infractinos after a release, not sure why?
                this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
            }
            
        // target.dispatchEvent(new CustomEvent("input"));
        })

          // Pulse on click 
        map.on('singleclick', event => {
            // var point = map.getCoordinateFromPixel(event.pixel)
            // var lonLat = toLonLat(point); 
            // this.setState({lastClickPx: event.pixel})
            
            if(this.state.mSelectedRegion){
                // console.log("Reset region: ",this.state.mSelectedRegion);
                this.state.mSelectedRegion.setStyle();
                this.setState({mSelectedRegion:null})
            }
            if(this.state.selectedJourney){
                // console.log("Release journey from map single click ",event)
                this.releaseJourney(false);       
                this.closePopup();       
            }
            // console.log("Clicked on map: ", overlaySource['asset'].getClosestFeatureToCoordinate(evt.originalEvent));
        });
        // console.log('FA: ',faCoffee);

        // Add selection tool (a toggle control with a select interaction)
		var toggleInfractionsControl = new Toggle({
                // html: '<i class="fa fa-hand-pointer-o"></i>',
                 html: `<img src='https://e3d-qa-xcel4nr1.s3-us-west-2.amazonaws.com/static/media/hamburger-menu-icon.87316fc2.png' style="width: auto; height:50%;"/>`,
                // html: <FontAwesomeIcon icon="check-square" />
                className: "toggle-infractions",
                title: "Select",
                interaction: new Select(),
                active:true,
                onToggle: function(active)
                {	
                    // console.log("Toggle selected",infractionsLayer);
                    if(this.getLayerByName('All','layer').getVisible()){
                        this.getLayerByName('All','layer').setVisible(false);
                        // this.setState({bShowInfractions:false})
                    }else{
                        this.getLayerByName('All','layer').setVisible(true);
                        // this.setState({bShowInfractions:true})
                    }
                }
            });
        // map.addControl(toggleInfractionsControl);
        // map.addControl(mousePositionControl);

        this.setState({
            intervalID: setInterval(this.refreshCall,2000),
        }); 


        //Set the default location to the browser location:
         if(!centerLat || !centerLon){
            try {
                //Get the current location from the browswer
                if (navigator.geolocation) {
                    // console.log("In geolocation")
                    navigator.geolocation.getCurrentPosition(data=>{
                        //extract the gps coordinates
                        centerLat=data.coords.latitude;
                        centerLon=data.coords.longitude;
                        //get the map view:
                        if(map){
                            let mapView = map.getView();
                            // console.log("Animate to Default(lon,lat):" ,centerLon,centerLat);
                            //set the location
                            if(centerLat && centerLon){
                                mapView.animate({center: transform([centerLon,centerLat], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                            }
                        }
                        
                    });
                } else {
                    centerLat = 32.2314; //fall back to Tucson location on fail
                    centerLon =  -111.3298;
                }    
            } catch (error) {
                centerLat = 32.2314; //fall back to Tucson location on fail
                centerLon =  -111.3298;
            }
        }
        
        // this.handleWebsocket();
    }
    //Compute the area of a geofenced area
    getFeatureArea(_feature){
        let areaMeters =0;
        if(_feature.get('type')!=='geofence'){
            return areaMeters;
        }
        try {
            // console.log("Feature Type: ",_feature.getGeometry().getType());
            switch(_feature.getGeometry().getType()){
                case 'Circle':{
                    areaMeters = _feature.getGeometry().getRadius();
                    areaMeters *= _feature.getGeometry().getRadius();
                    areaMeters *= Math.PI;
                    // console.log("circle area: ",areaMeters);
                }break;
                default:{
                    areaMeters = getArea(_feature.getGeometry());
                    // console.log("Poly area: ",areaMeters);
                }break;     
    
            }//end type switch    
        } catch (error) {
            console.log("Error computing area: ",error);
        }
        finally{
            return areaMeters;
        }
        
        
    }//end getFeatureArea
    getSmallestGeofence(_featureArray){
        //return the smallest region
        let smallest = null
        for(const feature_ of _featureArray){
            //compute the area:
            if(feature_.get('type')==='geofence'){
                let areaMeters = this.getFeatureArea(feature_);
                
                if(!smallest){ //if not defined then add current as the new smalled
                    smallest = {
                        area : areaMeters,
                        feature : feature_,
                    }
                }else{
                    //check if the current is smaller than the known
                    if(areaMeters < smallest.area){
                        smallest.area = areaMeters;
                        smallest.feature = feature_;
                    }
                }
            }//end check for fence region
        }
        if(smallest){ //return smallest if it exists
            return smallest.feature;
        }else{ //error catch, just return the first
            return _featureArray[0];
        }
    }

    //Find the smallest geofenced area in a set by area.
    getSmallestArea(_featureArray){
        if(_featureArray.length ===1){
            return _featureArray[0];
        }
        //Is one of the features an asset, return that first
        for(const feature_ of _featureArray){
            // console.log("Feature to test: ",feature_);
            if(feature_.get('type')==='asset'){
                return feature_;
            }
        }
        //Is one of the features an infraction, return that over a region
        // console.log("Single click: ",this.state.lastClickPx,this.state.mMap.getCoordinateFromPixel(this.state.lastClickPx),transform(this.state.mMap.getCoordinateFromPixel(this.state.lastClickPx), COORDTYPE_MERCATOR, COORDTYPE_GPS),this.state.mMap.getFeaturesAtPixel(this.state.lastClickPx));
        for(const feature_ of _featureArray){
            if(feature_.get('type')==='infraction'){
                return feature_;
            }
        }
        //Is one of the features an alert, return that over a region
        
        for(const feature_ of _featureArray){
            if(feature_.get('type')==='alert'){
                return feature_;
            }
        }
       return this.getSmallestGeofence(_featureArray);
    }
    
    //Terminate the update of the region, get the new coordinates and send to cloud
    handleRegionUpdate(_feature,_type){
        // console.log("Feature: ",_feature);
        // console.log("Descibe: ",_feature.getGeometry());
        let regionDesc = getRegionDesc(_feature,_type);
        //Set new attributes:
        Auth.currentSession().then(
            (auth) => {
                
                let siteName = this.state.mCurrentSite;
                // console.log("Update SQL with site: ",siteName,this.state)

                //Set up the API request:
                let apiName = "TrifectaMapAPI";
                let path = "/handleGeoFence";
                let myInit={};
                let bodyParams= {
                    token: auth.idToken.jwtToken,
                    mode: "updateShape",
                    type: regionDesc.type,
                    id: _feature.get('id'),
                    coordinates: regionDesc.coordinates,
                    site: siteName,
                    name: _feature.get('name'),
                }
                myInit.body = Object.assign(bodyParams,{});

                return API.post(apiName, path, myInit);
        });
    }
    //Terminate the drawing of the circle, add to the list of features
    handleRegionComplete(_feature,_type,_layer){
        console.log("Region complete?", this);
        _feature.set('type','geofence');
        let areaMeters = this.getFeatureArea(_feature);
        if(areaMeters < 15){
            setTimeout((_feature)=>{  
                console.log("Region too small...delete")
                try {
                    var features = _layer.getFeatures();
                    // console.log("Feature count: ",features,features.length)
                    var lastFeature = features[features.length - 1];
                    _layer.removeFeature(lastFeature);
                } catch (error) {
                    console.log("Remove error",error,_layer,);
                }
            },1000);
            // try {
            //     _layer.removeFeature(_feature);        
            // } catch (error) {
            //     console.log("Remove error",error,_layer,_feature);
            // }
            
            return;
        }
        
        
        // console.log("Descibe: ",_feature.getGeometry());
        let regionDesc = getRegionDesc(_feature,_type);
        setFeatureGeom(regionDesc,_feature);

        //Set new attributes:
        _feature.set('isGeoFence',true)
        
        _feature.set("id",generateUniqueID());
        _feature.set("events_monitored",[]);
        // let defaultName = "Region"+this.state.overlaySource['geofence'].getFeatures().length;
        // _feature.set("name",defaultName);
        _feature.set("name","Region");
        _feature.set("Lat",regionDesc.center.lat)
        _feature.set("Lon",regionDesc.center.lon)


        this.updateRegionList(_feature);

        // return;
        // handleGeoFence
        Auth.currentSession().then(
            (auth) => {
                
                let siteName = this.state.mCurrentSite;
                //Set up the API request:
                let apiName = "TrifectaMapAPI";
                let path = "/handleGeoFence";
                let myInit={};
                let bodyParams= {
                    token: auth.idToken.jwtToken,
                    mode: "update",
                    type: regionDesc.type,
                    id: _feature.get('id'),
                    coordinates: regionDesc.coordinates,
                    site: siteName,
                    name: _feature.get('name'),
                }
                myInit.body = Object.assign(bodyParams,{});

                return API.post(apiName, path, myInit);
                
        });
    }

    addLegend(){
        // console.log("Configured site: ",this.state.siteConfig);
        let speedLimit = 100;
        try {
            let config =  this.state.siteConfig.filter(item => item.site === this.state.mCurrentSite);
            // console.log("Configured site: ",config);
            if(config[0].speedlimit){speedLimit = config[0].speedlimit;}
            
        } catch (error) {
            
        }

        // console.log("Features: ",this.state.overlaySource['journey'].getFeatures())
        let legend = new LegendLegend({ //Add the legend:
            style: function(f) {
                var dh = f.get('dh');
                // console.log("Got color:",dh);
                return [ new Style({
                    fill: new Fill({
                    color: getColor(dh)
                    })
                })];
            },
            textStyle: new Text({
                    font: 'bold 16px sans-serif',
            }),
            title: "  ", //needs to have a title or the text does not align with the elements
            margin: 0,
            size: [40, 15]
        });
        
        this.state.mMap.addControl(new LegendControl({ legend: legend, collapsible: false }));

        //Style the legend
        // console.log("Legend:" ,speedLimit)
        legend.addItem({ title: '  > '+formatSpeed(this.state.mCurrentSiteDetails,speedLimit,{show:true, precision:0}), properties: { dh: 255 }, typeGeom: 'Polygon'});
        
        
        let iSteps = 15;        
        let floorValue = Math.round(speedLimit/iSteps);
        let stepValue = 230/iSteps-1; 
        // console.log("Floor val: ",floorValue,stepValue)
        for (let i = 0; i < iSteps; i++) {
            // console.log("Scale: ",i,230-(230/iSteps*i))
            switch(i){
                case 0:
                    // console.log("0: ", 230-(stepValue*i));
                    legend.addItem({ title:'     '+formatSpeed(this.state.mCurrentSiteDetails,speedLimit,{show:true, precision:0}), properties: { dh: 230-(stepValue*i) }, typeGeom: 'Polygon'});
                    break;
                case 5:
                case 10:
                    let speedLabel = Math.floor((speedLimit - i*(speedLimit/iSteps))/floorValue)*floorValue;
                    legend.addItem({ title:'     '+formatSpeed(this.state.mCurrentSiteDetails,speedLabel,{show:true, precision:0}), properties: { dh: 230-(stepValue*i) }, typeGeom: 'Polygon'});        
                    break;                
                case (iSteps-1):
                    // console.log("0: ",iSteps-1,i, 230-(stepValue*i));
                    legend.addItem({ title:'      '+formatSpeed(this.state.mCurrentSiteDetails,0,{show:true, precision:0}), properties: { dh: 0 }, typeGeom: 'Polygon'});
                    break;
                default:
                    // console.log("Step: ",i, 230-(stepValue*i));
                    legend.addItem({ title:'', properties: { dh: 230-(stepValue*i) }, typeGeom: 'Polygon'});    
                    break;
            }
        } 
        legend.addItem();
    }
    /*  Handle what occurs when the popup is opened, this applies to both the asset markers and the 
        infraction markers
    */
    openPopup(feature,_select,_isDoubleClick){
        // console.log("Open popup: ",feature, _select,_isDoubleClick);
        var content = "";
        if(feature.get('isGeoFence')){
            // console.log("Clicked on geofence: ");
            //Call close on the popup, if the popup is not displayed it will return right away.
            this.closePopup(feature);

            //Get a list of geofence names:
            let regionNames = [];
            try {
                regionNames= (this.state.geofenceFilters.regions || []).map(elem=> elem.name.toLowerCase());
            } catch (error) {}
            // console.log("Open popup: ",this.state.activeFilters, this.state.geofenceFilters,regionNames)

            const card = {
                id: feature.get('id'),
                events_monitored: feature.get('events_monitored'),
                name: feature.get('name'),
                permissions: (this.props.groupconfig.permissions && this.props.groupconfig.permissions.livemap)? this.props.groupconfig.permissions.livemap.geofencing: null,
                regionList: regionNames,
            };
            this.setState({geoFenceCard:card});
            return;
        }
        // console.log("Feature selected: ",feature.get('type'))
        switch(feature.get('type')){
            case 'infraction':{
                // console.log("popup: ",feature.get('infractionid'), this);
                // console.log("Calling get notecard on ",feature.get('infractionid'));
                const realPromise = Auth.currentSession().then(
                (auth) => {
                    let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        apiName: "getCardFromInfractionID",
                        mode: "fetch",
                        infractionid: feature.get('infractionid'),
                    }
                    };
                    return API.post("AuthLambda", "/apiRouter", myInit);
                });
                //When the data is returned arrange it into a basic card and open the card:
                realPromise.then(data => {
                    //  console.log("Notecard fetch returned: ",data,data.data);
                    (data.data || []).forEach( (elem_) =>{
                        // console.log("Notecard fetch returned: ",elem_);
                        let infractionTagArray = elem_.infractiontags || [];
                        if(elem_.infractiontags ){
                            infractionTagArray = elem_.infractiontags.split(",");
                        }
                        // console.log("Card data: ",elem_);
                        const card = {
                            tag: elem_.tag,
                            infractionType: elem_.infractiontype,
                            infractionID: feature.get('infractionid'),
                            status: 'FleetReview',
                            severity: elem_.severity,
                            cardID: elem_.videocardid,
                            id: elem_.parentid,
                            flags: elem_.flags,
                            vehicleID: formatAssetName(this.state.filters.vehicle,feature.get("name")),
                            siteID: elem_.siteid,
                            notes: "",    
                            name: elem_.driverid,
                            infractionTags : infractionTagArray,
                            timeReceived: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                            timeOfDay: elem_.timeofday? moment.parseZone(elem_.timeofday):null,
                            metadata: elem_.metadata
                          };
                        this.setState({expandedCard:card});
                    });
                });
            }break;
            case 'alert':{
                // console.log("Open alert: ",feature.get("alertid"),feature, feature.get('Speed'));
                content += '<div>Alert Type: '+feature.get("classification")+'</div>';
                if(feature.get("name")){    content += '<div>AssetID: '+formatAssetName(this.state.filters.vehicle,feature.get("name"))+'</div>';  }
                if(feature.get("driverid")){    content += '<div>DriverID: '+feature.get("driverid")+'</div>';  }
                // console.log("Feature: ",feature);
                if(feature.get("Speed")){       content += '<div>Speed: '+formatSpeed(this.state.mCurrentSiteDetails,feature.get("Speed"),{show:true, precision:1})+'</div>';}
                if(feature.get("SpeedLimit")){       content += '<div>SpeedLimit: '+feature.get("SpeedLimit")+'</div>';}
                //Display the details based on the type of geofencing alert
                switch(feature.get("classification")){
                    case 'Dwell':{
                        if(feature.get("max")){       content += '<div>Max Time: '+feature.get("max")+' minutes</div>';}
                        if(feature.get("min")){       content += '<div>Min Time: '+feature.get("min")+' minutes</div>';}
                    }break;
                    case 'AssetCount':{
                        if(feature.get("max")){       content += '<div>Max Count: '+feature.get("max")+'</div>';}
                        if(feature.get("min")){       content += '<div>Min Count: '+feature.get("min")+'</div>';}
                    }break;
                }//end switch
                //Add the date:
                if(feature.get("timeofday")){       content += '<div>Date: '+feature.get("timeofday")+'</div>';}

                this.state.mPopup.show(feature.getGeometry().getFirstCoordinate(), content); 
                // this.setState({selectedFeature: feature});
            }break;
            
            case 'asset':{
                content += '<div>AssetID: '+formatAssetName(this.state.filters.vehicle,feature.get("name"))+'</div>';
                if(feature.get("Speed")){
                    // console.log("Found speed")
                    content += '<div>Speed: '+formatSpeed(this.state.mCurrentSiteDetails,feature.get("Speed"),{show:true, precision:1})+'</div>';
                }
                this.state.mPopup.show(feature.getGeometry().getFirstCoordinate(), content); 
                //Reset the asset icon: pass the name of the asset so it preserves the color of the icon:
                this.updateAssetsVisibility();
                this.setState({selectedFeature: feature});
            }break;
            default:
            case 'boundary':{
                if(_select){_select.getFeatures().clear();}
                return;
            }break;
            case 'route':
            case 'route_arrows':
            case 'route_speed_arrows':                
            case 'route_speed':{
                
                this.setState({selectedJourney: feature},
                    // ()=>{console.log("Set state on journey: ",this.state.selectedJourney)}
                );
              
               //Add the speed gradient key:
               this.addLegend();

               //Enable the hover callback:
               if(this.state.mInteractions['journeyHover']){this.state.mInteractions['journeyHover'].setActive(true);}
                
                // console.log("Update the infractions? ",feature);
                this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,feature)
                _select.getFeatures().clear(); //release the select
                //Interaction was a double click -> collect the details about the selected Journey
                //and pass them to the Journey Notecard to dispay
                if(_isDoubleClick){
                    //Iterate over the journeys to extract the one 
                    try {
                        let journeySet = this.state.overlaySource['journey'].getFeatures();   
                        let routes = [];
                        let speeds = [];
                        let infractions = [];
                        //Iterate over all the loaded journeys
                        for(const journey_ of journeySet){
                            if(journey_.get('journeyname')!=feature.get('journeyname')){continue;} //only process the journey with the matching name                            
                            //Get the OpenLayer geometry to allow drawing the Journey on a map
                            routes.push(journey_.getGeometry());
                            //Get all recorded speed values from the continous GPS routes
                            let route_ = journey_.get('routeData');
                            speeds.push(...(route_.map(val=>{return val.speedArray}))); //Add all the speed arrays to the list to pass 
                            //Pass a list of the notecards linked to the Journey
                            infractions.push(journey_.get('infractionSet'));
                        }
                        console.log("Selected Journey: ",feature.get('journeyid'),feature.get('journeyname'));
                        const dataToPass = {
                            id: feature.get('journeyid'),
                            assetid: formatAssetName(this.state.filters.vehicle,feature.get('assetid')),
                            siteid: feature.get('siteid'),
                            name: feature.get('journeyname'),
                            infractionSet: infractions,
                            speedArray: speeds,
                            time: {
                                start: feature.get('timeStart'),
                                end:   feature.get('timeEnd'),
                                duration: feature.get('timeDuration'),
                            },
                            startDate: feature.get('startDate'),
                            route: routes,
                            drivers: feature.get('driverids')                     
                        };
                        this.setState({journeyData:dataToPass}); //Store the data in a state variable to trigger the render state


                    } catch (error) {
                        console.log("Failed to open card: ",error);
                    }
                    

                   
                }else{
                    // console.log("Release select; ",_select,this.state.mInteractions['select'])
                    //Change the color of the feature that is highlighted
                    feature.set('type','route_speed_arrows');
                    feature.set('singleview',true);
                    // this.state.mapJourney.setMemberVar({selectedJourney: feature.get('journeyname')});
                    return;
                }

                
            }
            

        }//end type switch
        
    }
    closePopup(feature){
        if(!this.state.mPopup){return;}
        if(!this.state.mPopup.getVisible()){return; }

        const selectedFeature = this.state.selectedFeature;
        const animationsTable = this.state.animationsTable;
        if(selectedFeature){
            
            //Disable the animation
            // console.log("Animationtable: ",animationsTable)
            if (animationsTable[selectedFeature.get('name')] && animationsTable[selectedFeature.get('name')].isPlaying()){	
                animationsTable[selectedFeature.get('name')].stop({user: true});
            }else{
                // console.log("No animation")
            }
            //Reset the styles of the assets:
            this.updateAssetsVisibility();
            selectedFeature.set('img',null);
        }
     
        //Close the popup:
        this.state.mPopup.hide(); 
        this.setState({selectedFeature: null});
        this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
    }

    handleWebsocket(){
        //handle new websocket events:
        // console.log("Props: ",this.props)
        if(this.props.websocket){
            const {eventMarkers} = this.state;
            this.props.websocket.onmessage = evt => {
            // console.log("On Message: ",evt);
            // on receiving a message, add it to the list of messages
            const receivedMessage = JSON.parse(evt.data)
            
            // console.log("Message received: ",receivedMessage,evt );

            //Did we get a new event?
            if(receivedMessage.event.includes("infractions-notify")){
                
                var foundEvent = eventMarkers.filter(item => item.infractionid === receivedMessage.message.infractionid);
                if(foundEvent && foundEvent.length > 0){
                    // console.log("Already handled event? ",foundEvent[0],eventMarkers)
                }
                else{
                    // console.log("New event received",receivedMessage);
                    
                    try {
                        if(this.props.groupconfig.group.toLowerCase() === receivedMessage.message.clientid.toLowerCase()){
                            let tempMark = {
                                assetid: receivedMessage.message.assetid,
                                lon: parseFloat(receivedMessage.message.lat,10).toFixed(6) || -110.97229217401843,
                                lat: parseFloat(receivedMessage.message.lon,10).toFixed(6) || 33.401733507261774,
                                id: receivedMessage.message.infractionid,
                                infraction: receivedMessage.message.infraction,
                                infractionid: receivedMessage.message.infractionid,
                                infractiontags: receivedMessage.message.infractiontags,
                            }
                            this.addMarker(tempMark,'event',this.state.overlaySource);    
                        }
                        else{
                            // console.log("Not our message: ")
                        }
                    } catch (error) {
                        console.log("failed to add new marker: ",error);
                    }
                    
                }
                
            }
        }
        }
    }
    /*
    * Add a marker icon (infraction, asset) to the map 
    */
    addMarker(_details, _markerSet,_vectorSource){
            //add to set:
        let markers =null;
        switch(_markerSet){
            case 'event':{
                markers = this.state.eventMarkers;
            }break;
            default:
            case 'marker':{
                markers = this.state.markers;                
            }break;
        }

        if(!_details.lat || !_details.lon){return;}

        var marker = {
            assetid: _details.vehicle || _details.assetid,
            lat: parseFloat(_details.lat,10).toFixed(6),
            lon: parseFloat(_details.lon,10).toFixed(6),
            time: _details.time,
            state: _details.state,
            driverid: _details.driverid,
            infraction: _details.infraction,
            infractiontags: _details.infractiontags,    
            site: _details.site,        
        };
        if(_details.infractionid){marker.infractionid = _details.infractionid}
        

        //Add the feature:
        var featureToAdd = null;
        if(marker.infractionid){
            featureToAdd = new Feature({
                // geometry: new Point(fromLonLat([ -110.97229217401843,33.401733507261774])),
                geometry: new Point(fromLonLat([ marker.lon, marker.lat])),
                name: marker.assetid,
                state: marker.state,
                driverid: marker.driverid,
                img: null,
                time: marker.time,
            });
        }else{
            featureToAdd = new AssetFeature({
                geometry: new Point(fromLonLat([ marker.lon, marker.lat])),
                name: marker.assetid,
                state: marker.state,
                driverid: marker.driverid,
                img: null,
                time: marker.time,
            });
        }
         



        let tempID = marker.id || markers.length;

        if(marker.infraction){
            featureToAdd.set('infraction',marker.infraction)
        }
        if(marker.infractionid){
            featureToAdd.set('infractionid',marker.infractionid)
            featureToAdd.set('type','infraction');
        }else{
            featureToAdd.set('type','asset');
        }

        featureToAdd.set('site',marker.site);

        //Add the journeyid if available
        if(_details.journey){ featureToAdd.set('journeyid',_details.journey);  }
        if(_details.journeyname){ featureToAdd.set('journeyname',_details.journeyname);  }
        

        marker.id = tempID;
        // console.log("Add marker:" ,marker,tempID,_details);
        featureToAdd.setId(tempID)
        featureToAdd.set('Lat',marker.lat);
        featureToAdd.set('Lon',marker.lon);
        featureToAdd.set('prevLat',marker.lat);
        featureToAdd.set('prevLon',marker.lon);
        featureToAdd.set('state','active');


        if(_details.type && _details.type==='simulated'){
            // console.log("Add the simulated tag")
            featureToAdd.set('asset_type','simulated');
        }

        
        //Check for CAS events:
        if(marker.infractiontags && marker.infractiontags.includes('CAS')){ featureToAdd.set('classification','CAS'); }
        if(marker.infractiontags && marker.infractiontags.includes('CAS-AEB')){ featureToAdd.set('classification','CAS-AEB');}

        // console.log("Add feature:" ,featureToAdd, marker)

        //add the marker to the vector layer:
        if(marker.infractionid){
            //  console.log("Setting infraction: ",marker)
            // _vectorSource['all'].addFeature(featureToAdd);
            _vectorSource['All'].addFeature(featureToAdd);

            //Add to each layer:
            (this.props.possibleFilters.infractiontags || []).forEach( type_ =>{
                // console.log("Add tag to type: ",type_);
                try {
                    if(marker.infractiontags && marker.infractiontags.includes(type_)){    
                        _vectorSource[type_].addFeature(featureToAdd);
                    }    
                } catch (error) {
                    console.log("failed to add inf marker for: ",type_,marker)
                }
                
                
            }); //end iterater over tags

        }else{
            try {
                // console.log("FeatureToadd: ",featureToAdd);
                _vectorSource['asset'].addFeature(featureToAdd);    
            } catch (error) {
                console.log("Failed to load",error);
            }
            
        }
        //  console.log("Feature to add: ",featureToAdd,featureToAdd.getId())

        marker.featureid = featureToAdd.getId();
        markers.push(marker);
        switch(_markerSet){
            case 'event':{
                this.setState({eventMarkers:markers});
            }break;
            default:
            case 'marker':{
                this.setState({markers:markers});
            }break;
        }
    }

    releaseJourney(_clearJourney){
        if(this.state.selectedJourney){

            this.state.selectedJourney.set('type','route_arrows');
            this.state.selectedJourney.set('singleview',false);
            //Remove the legend for the speed key                
            try {
                let legendControl = this.state.mMap.getControls().getArray().find(control_ => {return control_ instanceof LegendControl });    
                this.state.mMap.removeControl(legendControl);

                if(this.state.mInteractions['journeyHover']){this.state.mInteractions['journeyHover'].setActive(false);}

            } catch (error) {                
            }

            
            if(_clearJourney){
                this.setState({selectedJourney:null});
                this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver)
            }else{
                this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
            }
        }else{
        }
    }
    /*
    * Remove data:
    */
    clearSet(_type){
        switch(_type){
            case 'journey':
            case 'journeys':{
                // console.log("Journeys:" ,this.state.overlaySource["journey"])
                this.state.overlaySource["journey"].clear();    
            }
            case 'infractions':{
                //Get all of the infractions
                let infractionSet = null;
                try {
                    infractionSet = this.state.overlaySource["All"].getFeatures();    
                } catch (error) {
                    console.log("Failed to get features:" ,error);
                }
                //Iterate through the infractions, and remove each based on the unique id
                (infractionSet || []).forEach( (elem_) =>{
                    removeMarker(elem_.get("infractionid"),this.state.overlaySource);
                });

            }break;
            case 'alerts':{
                let infractionSet = [];
                for(const layer_ of this.state.mAlertLayers){
                    try {
                        
                        let alertsSet =this.state.overlaySource[layer_].getFeatures();
                        infractionSet  = infractionSet.concat(alertsSet);    
                        
                    } catch (error) {
                        //  console.log("Alert layers no return?",layer_,error);                    
                    }
                }
                //Iterate through the infractions, and remove each based on the unique id
                (infractionSet || []).forEach( (elem_) =>{
                    removeMarker(elem_.get("infractionid"),this.state.overlaySource);
                });
            }
        }//end switch
    }
   

    

    toggleFeatureAnimation(_feature, _bOn){
        const {animationsTable,markersLayer,anim} = this.state;

        if(_bOn){
            // Create animation if doesn't exist
            if (!animationsTable[_feature.get('name')]){
                animationsTable[_feature.get('name')] = markersLayer.animateFeature (_feature, anim);
            }
            else if(!animationsTable[_feature.get('name')].isPlaying()){
                animationsTable[_feature.get('name')].start();
            }
        }
    }
    /*
    * @brief A handler for the event when the browser window is resized
    * Used to update the current window width, for scaling the page contents to fit
    */
    windowResized() {  
        // console.log("Doc: ", document.documentElement,document.documentElement.clientHeight )
        // console.log("Doc: ", window.innerHeight,window.outerHeight )
        this.setState({
            winSize : {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            winWidth: document.documentElement.clientWidth - 40,
        });
    }
 
    /*
    * @brief Process the Geofence regions visibility and appearance
    */
    updateRegionVisibilty(_activeFilters){
        // console.log("Active Filters:" ,_activeFilters);
        switch(_activeFilters.group){
            case 'hidegroups':{
                //Reset the colors scheme of all regions:
                let regions = this.state.overlaySource['geofence'].getFeatures();
                (regions || []).forEach( (feature_) =>{
                    feature_.setStyle();
                });

            }break;
            case 'configuregroups':
            case 'showgroups':{
                let colorset = EDGE3_REGION_COLORS;
                
                //get all the regions
                let regions = this.state.overlaySource['geofence'].getFeatures();
                console.log("regions: ",regions);
                //group by the groupname
                let regionGroups = {};
                (regions || []).forEach( (feature_) =>{
                    let groupname = feature_.get('groupname');
                    if(!groupname){groupname="unassigned"}
                    //Create an empty array if it doesn't exist
                    if(!regionGroups[groupname]){regionGroups[groupname] = []}
                    //Add to the array:
                    regionGroups[groupname].push(feature_);

                })
                let index = 0;
                //Iterate over the set of groups:
                Object.entries(regionGroups).forEach(([groupName_, elementSet_]) => {
                    // console.log("Loop: ",index++,groupName_,elementSet_,colorset);
                    if(groupName_==='unassigned'){
                        (elementSet_ || []).forEach( (feature_) =>{
                            //Update the features display style
                            feature_.setStyle(new Style({})); //set to invisible
                        });
                    }else{
                        (elementSet_ || []).forEach( (feature_) =>{
                            let colorIndex = index % colorset.length;
                            //Update the features display style
                            feature_.setStyle(new Style({
                                fill: new Fill({color: colorset[colorIndex]}), //set the color from based on the index
                                stroke: new Stroke({
                                color: 'white'
                                })
                            }));
                        });
                    }
                     
                    index++;
                });
            }break;
            case 'listgroups':            
            case 'addtogroup':{
                //reset the visiblity
                let regions = this.state.overlaySource['geofence'].getFeatures();
                (regions || []).forEach( (feature_) =>{
                    feature_.setStyle(); //reset to visible
                })
            }break;
            default:{ //a singular group has been selected
                //get all the regions
                let regions = this.state.overlaySource['geofence'].getFeatures();
                // console.log("regions: ",regions);
                //group by the groupname
                let regionGroups = {};
                (regions || []).forEach( (feature_) =>{
                    let groupname = feature_.get('groupname');
                    if(!groupname || groupname !==_activeFilters.group ){
                        feature_.setStyle(new Style({})); //set to invisible
                    }else{
                        feature_.setStyle(); //reset to visible
                    }

                })
            }
        }


    }
    
    /**
     * Filters have changed, so we need to update the data stored in the asset
     * @param {*} _filters 
     */
    updateAssetFeatures(_filters){
        //Get the list of current stored features on the map
        let vehicleFeatures = this.state.overlaySource['asset'].getFeatures(); 
        (vehicleFeatures || []).forEach( (feature_) =>{
            //Get the current site from the filters:
            // console.log("Update from filter: ",feature_,this.state.filters.vehicle);

            //If the returned set is empty then reset the changes to the feature to the default value from the input set
            if(this.state.filters.vehicle.length ===0){
                (this.props.possibleFilters.AssetsTrifecta || []).forEach( (filter_) =>{
                    // console.log("Compare:" ,feature_.get('name'),filter_.asset);
                    if(feature_.get('name') === filter_.asset){
                        //update the feature's site based on which site is selected:
                        feature_.set('site',filter_.current_site || filter_.site);
                    }
                });//end filter iteration
            }
            else{
                (this.state.filters.vehicle || []).forEach( (filter_) =>{
                    // console.log("Compare:" ,feature_.get('name'),filter_.asset);
                    if(feature_.get('name') === filter_.asset){
                        //update the feature's site based on which site is selected:
                        feature_.set('site',filter_.site);
                    }
                });//end filter iteration
            }
        });//end feature iteration
    } //end update map featrues


    /*
    * @brief Handle changing the asset markers from visible to invisible and blue to gray, called by both the refreshAPI and onFilterSelected
    */
    updateAssetsVisibility(_activeAsset){
        
        // let vehicleFeatures = this.state.vectorSource.getFeatures();
        let vehicleFeatures = this.state.overlaySource['asset'].getFeatures(); 
        // console.log("Update visibility: ",vehicleFeatures);

        let activeAsset = _activeAsset || this.state.activeAsset;
        if(this.state.filterView==='Site'){
            activeAsset = activeAsset || this.state.followAsset ;
        }
        if(activeAsset && activeAsset==='All'){activeAsset = null;} //set to null if the ALL is selected
        
        // console.log("Update assets: ",this.state.mCurrentSite, _activeAsset, activeAsset,this.state.filterView,this.state.followAsset);

        (vehicleFeatures || []).forEach( (feature_) =>{
            let timeSinceUpdate = moment.duration(moment().diff(feature_.get('time')) ).asSeconds();
            // console.log("Vehicle: ",feature_,feature_.get('time'), timeSinceUpdate,this.state.mCurrentSite)
            let bVisible = true;

            //Filter by the siteid:
            try {
                // if(this.props.groupconfig.group !== 'demo_group'){ //don't apply this to the demo user
                if(this.state.mCurrentSite && this.state.mCurrentSite.toLowerCase() !== 'none'){
                    if(feature_.get('site').toLowerCase() === this.state.mCurrentSite.toLowerCase()){                            
                        bVisible = true;
                    }
                    else{
                        bVisible = false;
                    }
                }//end check if site is selected
                // }
            } catch (error) { }

            
            //Filter by active asset:
            if(activeAsset){
                if(feature_.get('name') !== activeAsset){bVisible = false;}
            }

            //Update the render states to set the asset colors:
            //If the asset has refreshed in X seconds then change the icon to the offline color:            
            if(timeSinceUpdate > (60*2)){ feature_.set('state','inactive'); }        
            //Check if the asset is in a Geofence region
            if(feature_.get('InsideGeoFence')){ feature_.set('state','inregion');}
            
            //The feature state is read by the render function and the color is changed accordingly
            if(bVisible){
                feature_.setStyle();
            }else{
                feature_.setStyle(new Style({})); //set to invisible
            }
        });//end for each
    } //end updateAssetsVisibilty
    /*
    * @brief Time refresh of the API call
    */
    refreshCall() {
        // console.log("Refresh call entered")
        var elapsedDuration = moment.duration(moment().diff(this.state.intervalTime));
        let elapsedSecs = elapsedDuration.asSeconds();
        // console.log("Time since set: ",elapsedSecs);
        if(elapsedSecs>0.5){
            //  console.log("More than 2 second elapsed: ", this.state.retryCount);
            this.setState({intervalTime: moment(), retryCount: this.state.retryCount+1});
        }
        //Check if the assets are offline
        try {
            var timeSinceOffline = moment.duration(moment().diff(this.state.offlineCheckTime)).asSeconds();
            if(timeSinceOffline > 5){ //only run this every 5 seconds.
                this.updateAssetsVisibility(this.state.activeAsset);
                this.setState({offlineCheckTime: moment()})
            }
        } catch (error) {
            
        }
    }

    getTimeScale(){
        const timeScale = this.state.mTimeScale;
        let shiftTime = 12;        
        try {
            switch(timeScale){
                case "shift":
                    var currentTime = moment();//moment('02:00:00', 'hh:mm:ss')
                    // var currentTime = moment('14:00:00', 'hh:mm:ss')
                    let amShiftTime = moment('06:00:00', 'hh:mm:ss');
                    let pmShiftTime = moment('18:00:00', 'hh:mm:ss');
                    
                    if(currentTime.isBetween(amShiftTime,pmShiftTime)){
                        shiftTime = moment.duration(currentTime.diff(amShiftTime)).asHours();
                        // console.log("in am shift", shiftTime);
                    }else{
                        shiftTime = moment.duration(currentTime.diff(pmShiftTime)).asHours();
                        let midnightTime = moment('00:00:00', 'hh:mm:ss');
                        if(currentTime.isAfter(midnightTime)){
                            shiftTime = moment.duration(currentTime.diff(midnightTime)).add(4,'hours').asHours();
                            // console.log("in pm shift morn ",shiftTime)
                        }else{
                            // console.log("in pm shift night ",shiftTime)
                        }
                        
                    }
                break;
                // case "day":
                //     //calculate hours since midnight
                //     shiftTime = moment.duration(moment.tz("Australia/Perth").diff(moment.tz("Australia/Perth").startOf('day'))).asHours();
                // break;
                case "week":
                    shiftTime = 7*24; 
                break;
                case "month":
                    shiftTime = 31*24; 
                break;
                case "all":
                    shiftTime = -1; 
                break;
            }
            
        } catch (error) {
            console.log("failed to compute shiftime: ",error);
        }
       

        return shiftTime;
    }
    /*
    * @brief The definition of the API call that we need to do to retrieve the GPS data
    */
    getEventsApiCall() {

        //Clear the current infractions:
        this.clearSet("infractions");
        this.clearSet("alerts");

        // console.log("Calling eventlist",this.props.possibleFilters.AssetsTrifecta,this.state.mCurrentSite.toLowerCase());
        const shiftTime = null;//this.getTimeScale();
        this.setState({bUpdateInfractions:false, mFetchingEventState:1})

        //Limit based on site
        let assetToSearch = (this.state.filters.vehicle||[]).map(elem_ => {return elem_.asset});
        // console.log("Call get: ",assetToSearch);
        if(this.state.selectedInfractionFilters.asset){
            assetToSearch = [this.state.selectedInfractionFilters.asset];
        }

        let infractionFilter = this.state.selectedInfractionFilters.infraction;
        if(infractionFilter === 'All'){
            infractionFilter = null;
        }
        let driverFilter = this.state.selectedInfractionFilters.driver;
        if(driverFilter === 'All'){
            driverFilter = null;
        }

        // console.log("Send asset list: ",assetToSearch,this.state.mCurrentSite);
        let startDate = moment(this.state.startDate);
        let endDate = moment(this.state.endDate);

        // console.log("Start date: ",startDate, this.state);
            
        const countPromise = Auth.currentSession().then(
            (auth) => {
                // console.log("Body: ",myInit);
                // return API.post(apiName, path, myInit);
                 //Set up the API request:
                 let apiName = "TrifectaMapAPI";
                 let path = "/getGPS_eventList";
                 let myInit={};
                 let bodyParams= {
                        token: auth.idToken.jwtToken,
                        shiftTime: shiftTime,
                        assetList: assetToSearch.join(','),
                        timezone: moment().format('Z'),
                        // endDate: this.state.endDate? this.state.endDate.add(1,'days').endOf('day').format("YYYY-MM-DD"):null ,
                        startDate: this.state.startDate? startDate.startOf('day').format("YYYY-MM-DD"):null, //align the date to the start and end of the day
                        endDate: this.state.endDate? endDate.add(1,'days').endOf('day').format("YYYY-MM-DD"):null ,
                        site: this.state.mCurrentSite,
                        countOnly: true,
                        infraction: infractionFilter,
                        driver: driverFilter,
                 }
                 myInit.body = Object.assign(bodyParams,{});
 
                 return API.post(apiName, path, myInit);
                
        });

        //Handle response:
        countPromise.then(data => {
            // console.log("Event return: ",data);
            //Get the length:
            
            let toFetch = 0;
            let eventFetchStats = {};
            if(data.count){
                toFetch += data.count.alerts
                toFetch += data.count.events
                eventFetchStats.alerts = data.count.alerts;
                eventFetchStats.events = data.count.events;
            }
            if(toFetch>0){
                this.setState({mEventsToFetch:toFetch,mFetchingEventState:0,mEventFetchStats:eventFetchStats})
            }else{
                this.setState({mEventsToFetch:0,mFetchingEventState:2,mEventFetchStats:eventFetchStats})
            }

            //Start the data fetch:
            const eventPromise = Auth.currentSession().then(
                (auth) => {
                    let apiName = "TrifectaMapAPI";
                    let path = "/getGPS_eventList";
                    let myInit={};
                    let bodyParams= {
                            token: auth.idToken.jwtToken,
                            shiftTime: shiftTime,
                            assetList: assetToSearch.join(','),
                            timezone: moment().format('Z'),
                            startDate: this.state.startDate? startDate.startOf('day').format("YYYY-MM-DD"):null, //align the date to the start and end of the day
                            endDate: this.state.endDate? endDate.endOf('day').format("YYYY-MM-DD"):null,
                            site: this.state.mCurrentSite,
                            infraction: infractionFilter,
                            driver: driverFilter,
                    }
                    myInit.body = Object.assign(bodyParams,{});
    
                    return API.post(apiName, path, myInit);
            });
            eventPromise.then(data => {
                //  console.log("Event return: ",data);

                //Pass the data to render on the map
                this.updateData(data)
            })

            // this.updateData(data)
        })// rest of script
        

    }
    /*
    * @brief The definition of the API call that we need to do to retrieve the GPS data
    */
    getApiCall() {
        // console.log("Get update API? ",this.props.possibleFilters,this.state.filters,this.props.possibleFilters.Sites)
        
        //Get the list of assets to update the GPS location
        let assetlist = null;
        try {
            //Pull the current list from the state filter
            if(this.state.filters && this.state.filters.vehicle){
                assetlist = this.state.filters.vehicle.map(elem_ => {return elem_.asset}) //just return the asset, not the alias
            }
            // console.log("Get update API? ",assetlist);    
        } catch (error) {}
        
        // Create the API call + promise wrapper that will get the set of GPS points
        const dataPromise = Auth.currentSession().then((auth) => 
            {
                let apiName = "TrifectaMapAPI";
                 let path = "/getGPSLocationRT";
                 let myInit={};
                 let bodyParams= {
                        token: auth.idToken.jwtToken,
                        assetlist: assetlist, //pass the asset list to the API query
                 }
                 myInit.body = Object.assign(bodyParams,{});
 
                 return API.post(apiName, path, myInit);
        });
        //If we are calling to update GPS locations only
        // if(this.state.retryCount > 0 && !this.state.bUpdateInfractions){
        if(this.state.retryCount > 0 ){
            return dataPromise;
        }else{ //Otherwise, also ping the list of infractions:
            if(this.state.retryCount === 0){ //only on startup
                const sitePromise = Auth.currentSession().then(
                    (auth) => {
                        let myInit = {
                            body: {
                                token: auth.idToken.jwtToken,
                                apiName: "handleSiteConfig",
                                mode: "fetch",
                                clientid: this.props.groupconfig.group,
                            }
                        };
                        return API.post("AuthLambda", "/apiRouter", myInit);
                });
                // const combinedPromise = Promise.all([dataPromise, eventPromise,sitePromise]);
                const combinedPromise = Promise.all([dataPromise, sitePromise]);
                // const combinedPromise = Promise.all([dataPromise, eventPromise]);
                return combinedPromise;
            }
        }
    }
    /*
    * @brief Recenter the map on the selected asset
    */
    autoFollowAsset(_elem,_gpsLocation){
        try {
            if(_elem && _elem.vehicle){
                // if(this.state.followAsset===_elem.vehicle && this.props.groupconfig.group!=='pintovalley'){
                if(this.state.followAsset===_elem.vehicle){
                    // console.log("Follow:" ,this.state.followAsset)
                    // console.log("Follow elem?",_elem,_marker,parseFloat(_marker.lat),parseFloat(_marker.lon))
                    this.autoFollowAssetLatLon(_gpsLocation.lat,_gpsLocation.lon);
                }
            }else{
                //If "all" is selected:
                // if(this.state.followAsset === 'All' && this.props.groupconfig.group!=='pintovalley'){
                if(this.state.followAsset === 'All'){
                    //  console.log("Follow all reached?",_elem)
                    //Get all the assets and average their lat,lon position
                    let centerLat = 0;
                    let centerLon = 0;
                    centerLon = 0;
                    let assetCount = 0;
                    //All assets
                    let vehicleFeatures = this.state.overlaySource['asset'].getFeatures();
                     //List of assets at the current site:
                    // console.log("Get teh vehicles: ",[...this.state.geofenceFilters.vehicle]);
                     
                    let siteAssets= [];
                    try {
                        siteAssets= [...this.state.geofenceFilters.vehicle].filter(value_ => {
                            // console.log("current site: ",this.state.mCurrentSite)
                            if(this.state.mCurrentSite && this.state.mCurrentSite !== 'None'){
                                return value_.site.toLowerCase() === this.state.mCurrentSite.toLowerCase()
                                
                            }else{
                                return true;
                            }                        
                        });
                        siteAssets = siteAssets.map(asset_=>{return asset_.asset});
                        // console.log("Site assets: ",siteAssets);    
                    } catch (error) {
                        // console.log("Error: ",error);
                    }
                    

                    (vehicleFeatures || []).forEach( (feature_) =>{
                        let assetName = feature_.get('name');
                        // console.log("Asset name",assetName);
                        if(siteAssets.includes(assetName)){
                            
                            let assetLon = parseFloat(feature_.get('Lon'));
                            let assetLat = parseFloat(feature_.get('Lat'));
                            // console.log("Found asset in list:" ,assetName, assetLon, assetLat)
                            //Compute sum
                            if(assetLon!==0 || assetLat!==0){
                                centerLat+=assetLat;
                                centerLon+=assetLon;
                                assetCount++;
                            }
                        }
                    });
                    
                    //compute average:
                    if(assetCount > 0){
                        centerLat /= assetCount;
                        centerLon /= assetCount;
                        // console.log("Avg GPS: ",centerLat,centerLon);
                        // this.autoFollowAssetLatLon(centerLat,centerLon); //don't reposition the screen when following all assets
                    }else{
                        return;
                    }
                }
            }
        } catch (error) {
            
        }
    }
    /**
     * Reposition the map based on the lat,lon of the selected asset
     * @param {*} _lat 
     * @param {*} _lon 
     */
    autoFollowAssetLatLon(_lat,_lon){
        // console.log("Entry auto follow lat lon")
            if(_lat ==null){ return;}
            if(_lon ==null){ return;}
            if(_lat===0 && _lon === 0 ){ return;}
      
             
                    
            // console.log("Updating location: ",_lat,_lon);
            var mapExtent = this.state.mMap.getView().calculateExtent(this.state.mMap.getSize());
            let extent = transformExtent(mapExtent, COORDTYPE_MERCATOR, COORDTYPE_GPS);                    
            // console.log("Extent: ",extent, marker.lat, marker.lon);
            let bIsOnScreen = isPointInPolygon({ latitude:  _lat, longitude:  _lon }, [
                { latitude: extent[1], longitude: extent[0] },
                { latitude: extent[1], longitude: extent[2] },
                { latitude: extent[3], longitude: extent[2] },
                { latitude: extent[3], longitude: extent[0] },
            ]);
            if(bIsOnScreen){
                let gpsViewPortCenter = getCenter([
                    { latitude: extent[1], longitude: extent[0] },
                    { latitude: extent[3], longitude: extent[2] }
                ])
                let distanceX = getDistance( 
                    { latitude: extent[1], longitude: extent[0] },
                    { latitude: extent[1], longitude: extent[2] },
                );
                let distanceY = getDistance( 
                    { latitude: extent[1], longitude: extent[2] },
                    { latitude: extent[3], longitude: extent[2] },
                );
                let distanceFromCenter = getDistance( 
                    gpsViewPortCenter,
                    { latitude:  _lat, longitude:  _lon },
                );
                // console.log("On Screen: ",bIsOnScreen,gpsViewPortCenter);
                // console.log("Distance: ",distanceX,distanceY,distanceFromCenter);
                let percentX = distanceFromCenter/(distanceX/2);
                let percentY = distanceFromCenter/(distanceY/2);
                // console.log("Distance: ",percentX,percentY,distanceFromCenter);
                if(percentX > 0.75 || percentY > 0.75){
                    let mapView = this.state.mMap.getView();
                    if(_lat && _lon){
                        mapView.animate({center: transform([_lon, _lat], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                    }
                    
                }
            }else{
                let mapView = this.state.mMap.getView();
                if(_lat && _lon){
                    mapView.animate({center: transform([_lon, _lat], COORDTYPE_GPS, COORDTYPE_MERCATOR)});
                }
            }
    }//end of autoFollowAssetLatLon
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        // console.log("Testing: ",this.state);
        // console.log("API data: ",data,data.length );
        const tmpFilters = this.state.filters || {};
        let vehicles = data.vehicles;
        let infractions = data.events||[];
        let speedLimit = null;
        let alerts = data.alerts||[];
        let siteConfig = null;
        if(data.length){
            vehicles = data[0].vehicles;
            // infractions =  data[1] && data[1].events;
            // alerts =  data[1] && data[1].alerts;
            speedLimit = data[1] && data[1].speedlimit;
            siteConfig = data[1] && data[1].siteconfig;
            // console.log("Data: ",data,vehicles,speedLimit,siteConfig);
        }
        const { markers, eventMarkers} = this.state;

        //Update the siteConfig:
        if(siteConfig){
            // console.log("SiteConfig: ",siteConfig);
            this.setState({siteConfig:siteConfig});
        }

        //Handle the updating of the assets on the map:
        this.processAssetUpdates(vehicles)

        //Iterate over all infractions received on startup and add to the map:
        let bNewInfractionsAdded = false;
        let infractionCount = 0;
        (infractions || []).forEach( (elem_) =>{
            // console.log("API data: ",data, );
            elem_.id = elem_.infractionid;
            // console.log("Elem to add: ",elem_);
            this.addMarker(elem_,'marker',this.state.overlaySource);            
            infractionCount++;
            bNewInfractionsAdded = true;
            if(infractionCount % 50 ===0){
                // console.log("Set fetched: ",infractionCount);
                this.setState({mEventsFetched:infractionCount})    
            }
            
        });
        // console.log("Test set: ",infractions.length, alerts.length)
        if(infractions.length<1 && alerts.length>0){
            bNewInfractionsAdded = true;
        }
        //Add the alerts:
        this.addAlertsToMap(alerts);
        if(bNewInfractionsAdded){
            
            // console.log("Set the fetched state: ",this.state.mEventsToFetch);
            this.setState({mEventsFetched:this.state.mEventsToFetch});
            this.updateInfractionVisibility(this.state.selectedInfraction,this.state.activeAsset,this.state.mSelectedDriver,this.state.selectedJourney)
        }
        // this.addSpeedToMap(speedLimit);
        // console.log("Set filters: ",tmpFilters);
        this.setState({markers: markers,eventMarkers:eventMarkers});
    }

    /**
     * Helper method to break out the asset procesing from the UpdateData() method
     * @param {*} _assets: asset data returned from the API query
     */
    processAssetUpdates(_assets){
        // console.log("Call from updateData");
        if(_assets){
            if(this.state.followAsset === 'All'){
                // console.log("Call autofollow");
                this.autoFollowAsset();
            }
        }
        //Get the list of current assets:
        let assetFeatures = this.state.overlaySource['asset'].getFeatures(); 
        //Iterate over each vehicle:
        let newAssetAdded=false;
        (_assets || []).forEach( (asset_) =>{
            //   console.log("Vehicle: ",asset_)
            //add to list set, or update
            // var foundVehicle = markers.filter(item => item.assetid === elem_.vehicle);
            let foundAsset = assetFeatures.find(feature_=> feature_.get('name')===asset_.vehicle)
            if(foundAsset){
                //6 decimal is approximately 11.12cm accuracy
                let gpsLocation = {lat: parseFloat(asset_.lat,10).toFixed(6),lon: parseFloat(asset_.lon,10).toFixed(6), }
                //Send the GPS location and the remaining API return data to the asset:
                foundAsset.apiUpdate(gpsLocation,asset_);
                //Evaluate if the asset is visible on the currently selected site:
                foundAsset.set('onSite',false); //initialize state to false
                try {
                    if(this.state.filters){ //Check if the filter has been set
                        //Iterate over the filters, if the assetid is listed in the vehicle list then it is visible on site with 
                        //the current date filter
                        let matchedFilterAsset = (this.state.filters.vehicle||[]).find(filter_=> {
                            // console.log("Filter compare: ",filter_.asset, foundAsset.get('name'),foundAsset.get('name')===filter_.asset)
                            return foundAsset.get('name')===filter_.asset})
                        // console.log("result: ",matchedFilterAsset);
                        if(matchedFilterAsset){ //Asset was found:
                            foundAsset.set('onSite',true); //set onSite state to true
                        }
                    }//end filters check
                } catch (error) {}
                
                //Enable auto-follow, :
                if(this.state.followAsset && this.state.followAsset !== 'All'){     
                    // console.log("Update no all: ",this.state.followAsset,elem_);               
                    this.autoFollowAsset(asset_,gpsLocation);
                }

                //Tag Popup update the tag position if the asset updates
                try{
                    if(this.state.mPopup.getVisible()){     
                        // console.log("Comparing: ",this.state.selectedFeature, featureToUpdate);                       
                        if(this.state.selectedFeature && this.state.selectedFeature.get("name")===foundAsset.get("name")){

                            let content = '<div>AssetID: '+formatAssetName(this.state.filters.vehicle,foundAsset.get("name"))+'</div>';
                            if(foundAsset.get("Speed")){
                                content += '<div>Speed: '+formatSpeed(this.state.mCurrentSiteDetails,foundAsset.get("Speed"),{show:true, precision:1})+'</div>';
                            }
                            // console.log("Set content: ",content);
                            this.state.mPopup.show(foundAsset.getGeometry().getFirstCoordinate(), content); 
                        }
                    }
                }catch(e){
                    console.log("Set pos fail:" ,e);
                }

            }else{ //New asset to render
                newAssetAdded = true;
                // console.log("Add marker: ",elem_)
                this.addMarker(asset_,'marker',this.state.overlaySource);
            }
        });
        if(newAssetAdded){
            // console.log("New vehicle: update visiblity");
            this.updateAssetsVisibility();
        } //if we added new asset re-evaluate the active coloring
    }//end processAssetUpdates

    /*
    * Add the geofencing alerts and speeding alerts to the map layers
    */
    addAlertsToMap(_alerts){
        (_alerts || []).forEach((elem_,idx)=>{
            try {
                // if(elem_.assetid === 'EDGE3-3'){console.log("alert: ",elem_)}
                //Add the feature:
                var featureToAdd = new Feature({
                    geometry: new Point(fromLonLat([ elem_.lon, elem_.lat])),                    
                });
                featureToAdd.setId(generateUniqueID())
                featureToAdd.set('Lat',elem_.lat);
                featureToAdd.set('Lon',elem_.lon);
                featureToAdd.set('type','alert'); //set the type as alert for the popup rendering
                featureToAdd.set('classification',elem_.type);
                featureToAdd.set('name',elem_.assetid);
                featureToAdd.set('alertid',elem_.id);
                if(elem_.driverid && elem_.driverid.length===5){featureToAdd.set('driverid',elem_.driverid);}

                //Add the date:
                if(elem_.timeofday){
                    featureToAdd.set('timeofday',moment.parseZone(elem_.timeofday).format("YYYY-MMM-DD HH:mm:ss"));
                }

                //If we have speeding metadata then extract it
                if(elem_.metadata && elem_.metadata.speed && elem_.metadata.speedlimit ){
                    let speedlimit = elem_.metadata.speedlimit;
                    let speed = elem_.metadata.speed;
                    //Add the converted speed values back to the feature to display
                    // featureToAdd.set('Speed',formatSpeed(this.state.mCurrentSiteDetails,speed,{show:true, precision:2}));
                     featureToAdd.set('Speed',speed);
                    featureToAdd.set('SpeedLimit',formatSpeed(this.state.mCurrentSiteDetails,speedlimit,{show:true, precision:0}));
                }
                //Extract the geofencing action type specific data and add to the stored feature:                
                if(elem_.metadata){
                    switch(elem_.type){
                        case 'Dwell':{
                            if(elem_.metadata.max ){featureToAdd.set('max',Number(elem_.metadata.max).toFixed(2));}
                            if(elem_.metadata.min ){featureToAdd.set('min',Number(elem_.metadata.min).toFixed(2));}
                        }break;
                        case 'AssetCount':{
                            if(elem_.metadata.max ){featureToAdd.set('max',Number(elem_.metadata.max).toFixed(0));}
                            if(elem_.metadata.min ){featureToAdd.set('min',Number(elem_.metadata.min).toFixed(0));}
                        }break;
                    }//end switch
                }

                if(elem_.type.includes("Speeding")){
                    elem_.type = "Speeding";
                }
                let typeName = "GF_"+elem_.type;
                
                switch(elem_.type){                    
                    case 'Speeding':{
                        typeName = elem_.type;
                    }break;                    
                    default:{
                        typeName = "GF_"+elem_.type;
                        
                    }break;
                }

                // console.log("Adding to layer: ",typeName,elem_.type);
                this.state.overlaySource[typeName].addFeature(featureToAdd);    
                
            }catch(e){
                // console.log("Failed to add feature: ",e,elem_);
            }
        });
        // console.log("Added?: ",this.state.overlaySource['SpeedLimit'].getFeatures());
    }

    /*
    * Debug method to render where speed values have been collected on the cloud cache
    */
    addSpeedToMap(_speed){
        (_speed || []).forEach((elem_,idx)=>{
            try {
                // console.log("speed: ",elem_)
                 //Add the feature:
                var featureToAdd = new Feature({
                    geometry: new Point(fromLonLat([ elem_.lng, elem_.lat])),
                    name: elem_.provider,
                    time: elem_.timeupdated,
                });
                featureToAdd.setId(generateUniqueID())
                featureToAdd.set('Lat',elem_.lat);
                featureToAdd.set('Lon',elem_.lng);
                //Add to the map layer
                this.state.overlaySource['SpeedLimit'].addFeature(featureToAdd);
            }catch(e){
                console.log("Failed to add feature: ",e);
            }
        });
        // console.log("Added?: ",this.state.overlaySource['SpeedLimit'].getFeatures());
    }

    refreshGeofenceGroupList(_geoFences){

        let geofenceFilters = Object.assign({}, this.state.geofenceFilters);
        // const geofenceFilters = this.state.geofenceFilters;
        geofenceFilters.groups = [];
        //Update the list of groups for the regions
        let groupSet = new Set();
        (_geoFences || []).forEach((elem_,idx)=>{
            //Extract the group name from the list
            if(elem_.groupname){groupSet.add(elem_.groupname); }
        });
            
        [...groupSet].forEach(group_=>{
            geofenceFilters.groups.push({
                name: group_,                   
            });
        })

        // console.log("Set new groups: ",geofenceFilters.groups,groupSet)
        this.setState({geofenceFilters:geofenceFilters });
    }

    //Add new regions to the map
    addRegionsToMap(_geoFences){
        let geofenceFilters = Object.assign({}, this.state.geofenceFilters);
        // const geofenceFilters = this.state.geofenceFilters;
        // geofenceFilters.groups = [];
        
        let bUpdateRequired = false;
        (_geoFences || []).forEach((elem_,idx)=>{
            try {

                // console.log("fence: ",elem_)
                let coordinates = JSON.parse(elem_.coordinates)
                // console.log("fence: ",elem_, coordinates);
                //  console.log("Actions: ",elem_.events_monitored,JSON.parse(elem_.events_monitored[0]));
                let monitoredEvents = [];
                if(elem_.events_monitored){
                    (elem_.events_monitored || []).forEach( event_=>{
                        // console.log("?:",event_)
                        monitoredEvents.push(event_);
                    })
                }
                //draw the feature on the map:
                let newFeature = null;
                switch(elem_.type){
                    case "circle":
                    {
                        newFeature = setFeatureGeom({coordinates:elem_.coordinates,type:'circle'});
                    }
                    break;
                    case "polygon":
                    {
                        // console.log("Poly features:" ,elem_.coordinates)
                        newFeature = setFeatureGeom({coordinates:elem_.coordinates,type:'polygon'});
                    }
                    break;
                    
                }
                
                //Set common features
                newFeature.set('type','geofence');                
                newFeature.set("isGeoFence","true");
                newFeature.set("id",elem_.id);
                newFeature.set("events_monitored",monitoredEvents);
                let regionName = elem_.region || "Region"+idx
                newFeature.set("name",regionName);
                newFeature.set("groupname",elem_.groupname);
                // console.log("Name to set: ",regionName);
                
                //Add the region to the map:
                
                this.state.overlaySource['geofence'].addFeature(newFeature);
                // console.log("Add to layer: ",newFeature)
                //Add to the list of regions:
                // geofenceFilters.regions.push(newFeature.get('name'));
                geofenceFilters.regions.push({
                    name: newFeature.get('name'),
                    id: newFeature.get('id'),
                });

                bUpdateRequired = true;
            } catch (error) {
                console.log("Failed to add fence: ",error);
            }

        });
        //Update the list:
        if(bUpdateRequired){

            this.setState({geofenceFilters:geofenceFilters },
                () => {   this.refreshGeofenceGroupList(_geoFences); }
            );
            
        }
        
    }

    updateGeofenceGroup(_data){

        //Did the name change?
        try {
            if(_data && _data.priorName !== _data.newName){
                let geofenceFilters_ = Object.assign({}, this.state.geofenceFilters);
                const index = (geofenceFilters_.groups||[]).findIndex((el) => el.name === _data.priorName)
                
                geofenceFilters_.groups[index] = {name:_data.newName}


                //Update the names in the features:
                let regionSet = this.state.overlaySource['geofence'].getFeatures();
                (regionSet || []).forEach( (feature_) =>{
                    let currentGroupName = feature_.get('groupname');
                    if(currentGroupName === _data.priorName){
                        feature_.set('groupname',_data.newName);
                    }
                });//end iterate

                // console.log("Updated group name:", geofenceFilters_);
                this.setState({geofenceFilters:geofenceFilters_});
            }    
        } catch (error) {}

    }
    
    updateRegionList(_feature){
        // console.log("Exclude list: ",_excludeList);
        //add the region to the filter list:
        let geofenceFilters = Object.assign({}, this.state.geofenceFilters);
        geofenceFilters.regions=[];
        //get the list of regions on the map:
        
        let regionSet = this.state.overlaySource['geofence'].getFeatures();
        //iterate over the list of regions
        (regionSet || []).forEach( (feature_) =>{
            geofenceFilters.regions.push({
                name: feature_.get('name'),
                id: feature_.get('id'),
            });
        });//end iterate

        //check if the specified feature was found:
        if(_feature){
            let foundElem = regionSet.find(elem_ => elem_.get('id')===_feature.get('id'));
            if(foundElem){
                // console.log("Found, new feature: ",foundElem);
            }else{
                geofenceFilters.regions.push({
                    name: _feature.get('name'),
                    id: _feature.get('id'),
                });
            }
        }
        // console.log("Update? ",geofenceFilters.regions)
        this.setState({geofenceFilters:geofenceFilters});
    }
    //Called when the region is closed, syncs data to the cloud
    handleRegionClose(_data,_delete,_updateField){
        // console.log("On close: ",_data,_delete,_updateField);

        if(_updateField && _updateField === 'name'){
            //set locally
            let regionSet = this.state.overlaySource['geofence'].getFeatures();
            (regionSet || []).forEach( (feature_) =>{
                if(feature_.get('id') === _data.id){
                    feature_.set('name',_data.regionName);
                }
            });
            //and on the cloud
            Auth.currentSession().then(
                (auth) => {
                    //Set up the API request:
                    let apiName = "TrifectaMapAPI";
                    let path = "/handleGeoFence";
                    let myInit={};
                    let bodyParams= {
                            token: auth.idToken.jwtToken,
                            mode: "updateRegionName",
                            id: _data.id,
                            name: _data.regionName,
                    }
                    myInit.body = Object.assign(bodyParams,{});

                    return API.post(apiName, path, myInit);
                });
            return;
        }
        //Otherwise allow the close to run:
        this.setState({geoFenceCard:null})
        this.state.mSelect.getFeatures().clear();
        //Compare against the stored feature:
        
        

        let regionSet = this.state.overlaySource['geofence'].getFeatures();
        (regionSet || []).forEach( (feature_) =>{
            if(feature_.get('id') === _data.id){
                // console.log("found the feature ",feature_.get('name'))
                //Update the region data:
                feature_.set("events_monitored",_data.actionList);
                //Check if the region name was updated
                if(feature_.get('name')!==_data.regionName){
                    // console.log("Update name ",_data.regionName);
                    feature_.set('name',_data.regionName);
                    //Set in API:
                    Auth.currentSession().then(
                        (auth) => {
                            //Set up the API request:
                            let apiName = "TrifectaMapAPI";
                            let path = "/handleGeoFence";
                            let myInit={};
                            let bodyParams= {
                                    token: auth.idToken.jwtToken,
                                    mode: "updateRegionName",
                                    id: _data.id,
                                    name: _data.regionName,
                            }
                            myInit.body = Object.assign(bodyParams,{});

                            return API.post(apiName, path, myInit);
                    });
                }//end name update
                //Should this region be removed?
                if(_delete){
                    //Remove from the features:
                    this.state.overlaySource['geofence'].removeFeature(feature_);
                }
               
            }
        });
        //add the region to the filter list:
        this.updateRegionList();
    }

    cardChange(_inCard,_noShow) {
        //  console.log("Card change: ",_inCard,_noShow);
        this.props.cardChange(_inCard);
        try{
            if(_inCard.delete === true){
                removeMarker(_inCard.infractionID,this.state.overlaySource);
            }
        }
        catch(error){

        }
      }
      
      
      /* @brief handle the callback when a tag is changed in the notecard.
      * This refreshes the displayed cards in the list
      */
      cardTag(_inCard,_noShow) {
      }



    //Call the display for the page
    render() {       
        // console.log("Call render",this.state.filters) 
        //Set some default title vales, these can be changed if the BIS group is loaded
        let titleCaption = null;
        if(this.props.groupconfig.group==="bis" || this.props.groupconfig.group === 'pintovalley'){
            titleCaption = <div className="no-map-message" >Coming Soon</div>
        }

        //Set the style and the size of the map:
        const style = {
            backgroundColor: '#cccccc',
        }


        //   console.log("Pass activefilters: ",this.state.selectedInfractionFilters);
        let controls = <div className='filter-controls' >
                            <FilterSelectionView   groupconfig={this.props.groupconfig} 
                                onFilterViewSelected={(_view)=>{ 
                                    // console.log("On filter view:" ,_view);
                                    //cascade this state request so that it forces a re-render quicker?                                    
                                    this.setState({filterView: _view.view,followAsset:null}
                                    ,()=>{this.setState({lastUpdateTime: moment()}) }
                                )}} 
                                currentSite={this.state.mCurrentSite}
                                assets = {this.state.filters.vehicle}
                            />
                            {/* Choose the date range to apply to the filter */}
                            <DateFilter startDate={this.state.startDate} endDate={this.state.endDate} onChange={this.onFilterSelected} /> 
                            {/* Show the filter content based on the selected tab */}
                            { this.state.filterView==='Site' && <MapControls className='map-controls' filters={this.state.geofenceFilters} 
                                                                                        activeFilters={this.state.activeFilters} onFilterSelected={this.onFilterSelected} groupconfig={this.props.groupconfig} currentSite={this.state.mCurrentSite}/>}
                            
                            { this.state.filterView==='Infraction' && <MapFiltersView   className='map-filters2' filters={this.state.filters} onFilterSelected={this.onInfractionsFilterSelected} groupconfig={this.props.groupconfig}
                                                                                        activeFilters = {this.state.selectedInfractionFilters} allowApply = {this.state.bUpdateInfractions}
                                                                                        startDate={this.state.startDate} endDate={this.state.endDate} currentSite={this.state.mCurrentSite}
                                                                                        onApply={()=>{ 
                                                                                            this.onInfractionsFilterSelected('apply',{value:0});
                                                                                        }}
                                                                                        />}
                            { this.state.filterView==='Journey' && <JourneyFiltersView  className='map-filters2' filters={this.state.filters} onFilterSelected={this.onJourneyFilterSelected} 
                                                                                        activeFilters = {this.state.activeJourneyFilters} groupconfig={this.props.groupconfig} 
                                                                                        selectedJourney={this.state.selectedJourney} currentSite={this.state.mCurrentSite} 
                                                                                        startDate={this.state.startDate} endDate={this.state.endDate} 
                                                                                        onApply={()=>{ 
                                                                                            // console.log("ref?: ",this.mapJourneyRef,this.mapJourneyRef.current);
                                                                                            if(this.mapJourneyRef.current){
                                                                                                this.setState({journeyQueryPromise: this.mapJourneyRef.current.query()});
                                                                                            //    console.log("Query: ",this.mapJourneyRef.current.query());
                                                                                                // this.mapJourneyRef.current.query();
                                                                                            }
                                                                                        }} 
                                                                                    />}

                            
                        </div>

        let scale = 1;
        if (this.state.winWidth && this.state.divWidth) {
            scale = this.state.winWidth / this.state.divWidth;
        }

        //Compute the percent complete of the fetched journeys
        let journeysToFetch = 0;
        let journeysFetched = 0;
        let progressVal = 0;
        if(this.state.journeyFetchList){
            journeysToFetch = Object.keys(this.state.journeyFetchList).length;
            //Get the list of journeys that are fetched
            const filtered = Object.keys(this.state.journeyFetchList).filter(key => {return this.state.journeyFetchList[key].fetched})
            .reduce((obj, key) => { return { ...obj, [key]: this.state.journeyFetchList[key]}; }, {});
            
            journeysFetched = Object.keys(filtered).length;
            progressVal = Math.round((journeysFetched/journeysToFetch)*100);
        }

        
        progressVal = Math.min(progressVal,100);
        progressVal = Math.max(progressVal,0);
        
        let progressStyle = {
            width: "0%", 
            height: "15px"
        }
        progressStyle.width = progressVal+"%";
        // console.log("Fetched count: ",progressStyle);
        // width: this.state.winSize.width,
        // height: this.state.winSize.height,
        let mapStyle = {
            marginTop: '-10vh',
            height: '74vh',
            // width: '100vw'
            width: '97vw' //leave margin for centering and the scroll bar?
        }
        if(this.state.winSize.height<700){
            mapStyle.marginTop = '-14vh';
            mapStyle.height = '66vh'
        }

        
        //Filter the set to produce a list of only asset names for the Geofencecard
        let siteAssets = [];
        if(this.state.geoFenceCard){
            try {
                if(this.props.groupconfig.group.toLowerCase().includes("demo") || this.props.groupconfig.group.toLowerCase().includes("devgroup")){
                    siteAssets =([...this.state.filters.vehicle]||[]).map( elem_ => elem_.asset);
                }else{
                    siteAssets =[...this.state.filters.vehicle].filter( elem_ => elem_.site.toLowerCase()===this.state.mCurrentSite.toLowerCase());
                    siteAssets =(siteAssets||[]).map( elem_ => elem_.asset);
                }
                
            } catch (error) {}
            //  console.log("Assets: ",siteAssets,this.state.filters.vehicle,this.state.mCurrentSite);
        }
        

        return (
            
            // Important! Always set the container height explicitly
            <div className='mapView' style={mapStyle} >
                <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} retryCount={this.state.retryCount}/>
                {/* Link the MapJourney class to handle the retrieval of the journey data */}
                {this.state.mMap && <MapJourney 
                    overlayLayers = {this.state.overlayLayers}
                    overlaySource = {this.state.overlaySource}
                    interactions = {this.state.mInteractions}
                    currentSite = {this.state.mCurrentSite}
                    siteConfig = { this.state.siteConfig?this.state.siteConfig.filter(item => item.site === this.state.mCurrentSite): null}
                    selectedJourney = {this.state.selectedJourney}
                    activeFilters = {this.state.activeJourneyFilters}
                    filters = {this.state.filters}
                    startDate = {this.state.startDate}
                    endDate = {this.state.endDate}
                    map = {this.state.mMap}
                    ref={this.mapJourneyRef} 
                    mapCallback = {this.onMapJourneyResult}
                    addMarker = {this.addMarker}
                    clearSet = {this.clearSet}
                    canceledQuery = {()=>{
                        // console.log("Cancel completed")
                        this.setState({fetchingDialogState:0, journeysToFetch:0, journeysFetched:0})
  
                    }}
                    popUp = {this.state.mPopup}


                />}
                {this.state.mMap && <MapSiteBoundary
                    overlayLayers = {this.state.overlayLayers}
                    overlaySource = {this.state.overlaySource}
                    sites={this.state.siteConfig}
                    map = {this.state.mMap}
                    groupconfig = {this.props.groupconfig}
                />}
                {/* Display the filter controls at the top of the map */}
                {controls}

                {/* Add a div to the render section, OpenLayers will look for this DIV element and attach the rendered canvas */}
                <div id='map' style={style} className = "olMap" ></div>
                <div id="popup"></div>
                {/* Open an infraction notecard */}
                { this.state.expandedCard &&
                    <ExpandedCard handleClose={()=>{
                                            this.setState({expandedCard:null})
                                            this.state.mSelect.getFeatures().clear();
                                            this.closePopup();
                                            }}
                                    {...this.state.expandedCard}
                                    cardChange={this.cardChange}
                                    tagChange={this.cardTag}
                                    eventNotify={this.props.eventNotify}
                                    // newDetails= {this.onCardCreate}
                                    scale={scale}   
                                    noLink={this.props.groupconfig.group.toLowerCase().includes("bis")?false:true}                     
                                    // noEdit={disableEdits}
                                    filter={this.props.filter}
                                    groupconfig = {this.props.groupconfig}
                                    siteDetails = {(this.props.possibleFilters.Sites||[]).filter(filt_ => {return this.state.expandedCard && filt_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}                                    
                    />
                }
                {/* configure a geofence region, define the alerts */}
                { this.state.geoFenceCard &&
                    <GeoFenceCard handleClose={this.handleRegionClose}
                                    {...this.state.geoFenceCard}
                                    siteconfig = {this.state.siteConfig.filter(item => item.site === this.state.mCurrentSite)}
                                    assets = {siteAssets}
                                    scale={scale}   
                                    groupconfig = {this.props.groupconfig}
                    />
                }
                {/* Open the siteconfig card, set site details such as speed limit */}
                { this.state.siteConfigCard &&
                    <SiteConfigCard handleClose={(data)=>{
                                            //  console.log("Closed site config", data,this.state.siteConfig);
                                            this.setState({siteConfigCard:null})
                                            //update the local copy of the site configuration:
                                            const siteconfigs = this.state.siteConfig;
                                            //Find the site in the set and update the speed
                                            (siteconfigs||[]).forEach( site_=>{
                                                // console.log("Testing: ",site_);
                                                    if(site_.site === data.config.site){

                                                        site_.speedlimit = data.speedConfig.speedlimit; //update the site_ element
                                                        site_.speedtime = data.speedConfig.speedtime; 
                                                        // console.log("update site: ",site_);
                                                        if(this.mapJourneyRef.current){
                                                            this.mapJourneyRef.current.updateSiteSpeed(site_);
                                                        }
                                                    }

                                                }
                                            )
                                            //Save it to the class state
                                            this.setState({siteConfig:siteconfigs});
                                        }}//end handle data
                                    {...this.state.siteConfigCard}
                                    assets = {this.state.filters.vehicle}
                                    scale={scale}   
                                    groupconfig = {this.props.groupconfig}
                    />
                }
               {/* Open the geofence management card, this shows an overview of the configured geofences */}
                {this.state.manageFences&&
                    <GeofenceManagement 
                        handleClose={(data)=>{
                            // console.log("Closed geofence manage", data);
                            this.setState({manageFences:null})
                            this.fetchGeofenceList(this.state.mCurrentSite);
                        }}
                        {...this.state.manageFences}
                        groupconfig = {this.props.groupconfig}
                    />
                }
                {/* Open the assign to group card */}
                {this.state.geofenceAssignGroup&&
                    <AssignGroupPopup 
                        {...this.state.geofenceAssignGroup}
                        handleClose={()=>{
                            this.setState({geofenceAssignGroup: null})
                            this.fetchGeofenceList(this.state.mCurrentSite);
                        }}   
                        groupconfig = {this.props.groupconfig}
                    />
                }
                {/* Open the geofence settings card */}
                {this.state.groupSettingsCard&&
                    <SpeedPolicySettings 
                        {...this.state.groupSettingsCard}
                        handleClose={(_data)=>{
                            this.setState({groupSettingsCard: null})
                            this.updateGeofenceGroup(_data);
                            //Update the dropdown list with the new names:
                            // console.log("Geofences to process: ",_data,this.state.geofenceFilters)
                            
                            
                            
                            // this.refreshGeofenceGroupList(_geoFences)                            
                        }}   
                        groupconfig = {this.props.groupconfig}
                        type={'geofence'}
                    />
                }
                {/* Open the Journey Notecard */}
                { this.state.journeyData &&
                    <JourneyNotecard handleClose={()=>{this.setState({journeyData:null}) }}
                                    {...this.state.journeyData}
                                    db = {this.db}
                                    groupconfig = {this.props.groupconfig}
                                    siteDetails = {(this.props.possibleFilters.Sites||[]).filter(filt_ => {return this.state.journeyData && filt_.site.toLowerCase()===this.state.journeyData.siteid.toLowerCase()})}
                    />
                }
                
                {/* Declare a modal dialog box to open when fetching data */}
                <MapFetchingDialog toFetch = {journeysToFetch} fetched= {journeysFetched} dialogState = {this.state.fetchingDialogState}
                    queryPromise = {this.state.journeyQueryPromise}
                    stringType = {'journeys'} progressBar= {true}
                    onApply={()=>{ 
                        this.setState({fetchingDialogState:0})
                    }}
                    onCancel={()=>{ 
                        // console.log("Cancel clicked")
                        if(this.mapJourneyRef.current){
                            this.setState({fetchingDialogState:0, journeysToFetch:0, journeysFetched:0})
                            this.mapJourneyRef.current.cancelQuery();
                            
                        }
                        

                    }}
                />
                <MapFetchingDialog toFetch = {this.state.mEventsToFetch} fetched= {this.state.mEventsFetched} dialogState = {this.state.mFetchingEventState}
                    fetchDetails = {this.state.mEventFetchStats}
                    stringType = {'events'} progressBar= {false}
                    onApply={()=>{ 
                        setTimeout(this.setState({mEventsToFetch:0,mEventsFetched:0,mFetchingEventState:0,lastUpdateTime: moment()}),500);
                        // console.log("Apply state to 0")
                        this.setState({mFetchingEventState:0})
                    }}
                />

            </div>
          );
    }

    
}

