import React, { PureComponent } from 'react';
import { setupPerf, perfDidClick } from './Perf.js';
import { ApiCaller } from './ApiCaller.js';
import { Auth, API } from 'aws-amplify';
import { displayInfraction, delayPromise, IN_FLIGHT_WORKFLOWS, toTitleCase, severityNumber,SEVERITY,OTHERHWTAGS } from './Util.js';
import { CardList, registerCardDragListener, unregisterCardDragListener } from './VideoCard.js';
import { ExpandedCard } from './ExpandedNotecard/NoteCard-Expanded.js';
import axios from 'axios';
import { formatAssetName,filterAssetSet } from './Util-asset.js';

import {HrReviewFilters, filterKeys} from './ReviewFilters.js';

import * as moment from 'moment';

import './ReportingView.css';
import './ReportingView-filters.css';
import './VideoCard.css';
import './VideoCard-reviewcard.css';

// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 10; // in seconds

/* The values from each card we want to be able to use as a filter,
    and optionally a function to retreive the respective value from a card

    (if key is null, the name will be used as a key in the card object)

    - The 'title' optionally defines how that filter will be labelled in the website
    - The 'sortKey' optionally defines a function that will be called on values before
      sorting them in the dropdown menu.
*/


const dateFormat = 'YYYY-MM-DD';

/*
* @brief A helper function to check if a card is allowed through a chosen set of filters
*
* @param card The card data to check
* @param filters The filters, a list of filters in the following format:
*      [name, value]: - 'name' is a name from the filterKeys map above, or the
                        special names 'startDate' or 'endDate'
                      - 'value' is the value that is expected, for the common case it
                        is a value that should match what will be returned by
                        filterKeys[name]['key'](card) or card[name] if that key is not set
                      - For the startDate and endDate filter names, the value should be a
                        moment.js date. Only the year, month and day-of-month will be used
*/
const matchesFilters = (card, filters) => {
    // console.log("MatchFilters:" ,card,filters);
    try {
        Object.entries(filters).forEach(([filterName, filterValue]) => {
            if (filterName === 'startDate') {
                if (card.timeReceived.format(dateFormat) < filterValue.format(dateFormat)) {
                    throw new Error('unmatched');
                }
            } else if (filterName === 'endDate') {
                if (card.timeReceived.format(dateFormat) > filterValue.format(dateFormat)) {
                    throw new Error('unmatched');
                }
            } else if (filterName === 'infractionType') {
                const f = filterKeys[filterName]['key'];
                const v = f ? f(card) : card[filterName];
                if (v !== filterValue) {
                    if(!card.infractionTags.includes(filterValue)){ //check the tags before returning
                        throw new Error('unmatched');
                    }                    
                }
            }
            //Make sure the displayedInfractions is not handled as a filter value:
            else if (filterName === 'displayedInfractions') {
                //Do nothing
            }
            else {
                const f = filterKeys[filterName]['key'];
                const v = f ? f(card) : card[filterName];
                if (v !== filterValue) {
                    throw new Error('unmatched');
                }
                
            }
        });
    /* An exception is used to trigger a non-match because the forEach is calling a local
       anonymous function, so returning a value there is not possible.
    */
    } catch (e) {
        if (e && e.message === 'unmatched') {
            return false;
        }
        // If it is an exception we don't expect, rethrow it for error handling
        throw e;
    }
    return true;
}


/*
* @brief Main HR Review page of the app
*/
export class ReportingView extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.handleCardDragged = this.handleCardDragged.bind(this);
        this.onFilterSelected = this.onFilterSelected.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.expandCard = this.expandCard.bind(this);
        this.unexpandCard = this.unexpandCard.bind(this);

        //Callbacks from the expanded card:
        this.cardChange = this.cardChange.bind(this);
        this.cardComment = this.cardComment.bind(this);
        this.changeDriverID = this.changeDriverID.bind(this);
        this.cardTag = this.cardTag.bind(this);

        this.updateFilters = this.updateFilters.bind(this);
        this.windowResized = this.windowResized.bind(this);
        this.processCardData = this.processCardData.bind(this);
        this.onCheck = this.onCheck.bind(this);

        this.state = {
            allCards: [],
            sites: [],
            vehicles:[],
            drivers:[],
            cardsByWorkflow: {},
            filters: {},
            activeFilters: {},
            filtersChanged: false,
            moves: {},
            expandedCard: null,
            // startDate: moment('2024-03-18','YYYY-MM-DD'),
            startDate: this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-3, 'days'),
            // endDate:moment('2024-03-18','YYYY-MM-DD'),
            endDate: null,
            retryCount: 0,
            fetchCount: 0,
            waitingForDownload: false,
            timerDebug: null,
            allowedFlows: [],
            FILTERKEYS:[],
            filterUpdateTime:new Date(),
        };
        setupPerf(this, 'HrReview', () => false);
    }
    /* @brief Expand a given card for user viewing and editing
    */
    expandCard(cardData) {
        perfDidClick('expand-card-click');
        console.log("Expand click: ",cardData);
        //Get the status of the card: this is the current column: ie. FleetReview
        let workflow = cardData.status;
        //Check the workflow against the permissions:
        try {
            //Check If permissions have been defined
            if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.reporting){
                let tmpFlows = Object.keys(this.props.groupconfig.permissions.reporting);
                //Check the allowed workflows and the permission levels
                if(tmpFlows.includes(workflow)){                    
                    //if there permission is set to read, then disable the edits
                    if(this.props.groupconfig.permissions.reporting[workflow]==='read'){
                        cardData.noEdit=true; //set the noEdit flag if the workflow is set to read only
                    }
                }//end check if workflow is allowed
            } //end chec if permissions are enabled
        } catch (error) {}
        //Set the details to open the card:
        this.setState({expandedCard: cardData});
    }
    /* @brief Close the expanded card
    */
    unexpandCard(inCard) {
        perfDidClick('unexpand-card-click');
        if(inCard && inCard.delete){
                this.setState(state => {
                    const rawCards = state.allCards.slice();
                    const allCards = rawCards.filter(card_ => card_.cardID!==inCard.cardID);

                    const cardsByWorkflow = Object.assign({}, state.cardsByWorkflow);
                    Object.entries(cardsByWorkflow).forEach(([k, v]) => {                                                
                        let tmpCards = v.slice();
                        cardsByWorkflow[k] =  tmpCards.filter(card_ => card_.cardID!==inCard.cardID);
                        // cardsByWorkflow[k] = v.slice();
                    });
                    // const arrays = Object.values(cardsByWorkflow);
                    // arrays.push(allCards);
                    return {allCards: allCards, cardsByWorkflow: cardsByWorkflow};
                },()=>{ //handle after the display update
                    // console.log("Set state returned")
                    if(this.state.activeFilters){
                            Object.entries(this.state.activeFilters).forEach(([filterName, filterValue]) => {
                                if(!filterName.includes('Date')){ this.onFilterSelected(filterName, {value: filterValue});  }                                
                            });
                    }
                    this.setState({expandedCard: null});
                });
        }
      
        else{ //not handling a delete
            this.setState({expandedCard: null});
            if(this.state.activeFilters){
                Object.entries(this.state.activeFilters).forEach(([filterName, filterValue]) => {
                    if(!filterName.includes('Date')){ this.onFilterSelected(filterName, {value: filterValue});}
                });
            }
        }
       
    }
    /* @brief Handler for when a card receives a comment
    *
    * This happens when the user enters a comment on the ExpandedCard for instance
    */
    cardComment(inCard) {

        // console.log("Card change: ",inCard);
        this.props.cardChange && this.props.cardChange(inCard);
       
        this.setState(state => {
            const allCards = state.allCards.slice();

            const cardsByWorkflow = Object.assign({}, state.cardsByWorkflow);
            Object.entries(cardsByWorkflow).forEach(([k, v]) => {
                cardsByWorkflow[k] = v.slice();
            });
            const arrays = Object.values(cardsByWorkflow);
            arrays.push(allCards);
            arrays.forEach(array => {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].cardID === inCard.cardID) {
                        const copy = Object.assign({}, array[i]);
                        if(inCard.severity){copy.severity = inCard.severity;}
                        if(inCard.comment){copy.notes = inCard.comment;}
                        if(inCard.notes){copy.notes = inCard.notes;}
                        array[i] = copy;
                        break;
                    }
                }
            });
            if (state.expandedCard) {
                const expandedCard = Object.assign({}, state.expandedCard);
                if(inCard.severity){expandedCard.severity = inCard.severity;}
                return {allCards: allCards, cardsByWorkflow: cardsByWorkflow, expandedCard:expandedCard};
            }else{
                return {allCards: allCards, cardsByWorkflow: cardsByWorkflow};
            }
        });
        
    }
    changeDriverID(_inCard) {
        // console.log("Change driver id: ",_inCard)
        this.setState(state => {
            const allCards = state.allCards.slice();
            const cardsByWorkflow = Object.assign({}, state.cardsByWorkflow);
            Object.entries(cardsByWorkflow).forEach(([k, v]) => {
                cardsByWorkflow[k] = v.slice();
            });
            const arrays = Object.values(cardsByWorkflow);
            arrays.push(allCards);
            // arrays.push(allCards);
            arrays.forEach(array => {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].infractionID === _inCard.infractionID) {
                        const copy = Object.assign({}, array[i]);
                        if(_inCard.driverID){copy.driverID = _inCard.driverID;}
                        if(_inCard.name){copy.name = _inCard.name;}
                        array[i] = copy;
                        // console.log("Found card: ",array[i])
                        // console.log("Writing copy: ",copy, array[i])
                        // array[i] = Object.assign(array[i]|| {}, _inCard);
                        break;
                    }
                }
            });
            // console.log("Cards ",allCards,cardsByWorkflow);
            return {allCards: allCards, cardsByWorkflow: cardsByWorkflow};
        });
        
    }

    cardTag(inCard) {

        this.setState(state => {
            const allCards = state.allCards.slice();

            const cardsByWorkflow = Object.assign({}, state.cardsByWorkflow);
            Object.entries(cardsByWorkflow).forEach(([k, v]) => {
                cardsByWorkflow[k] = v.slice();
            });
            const arrays = Object.values(cardsByWorkflow);
            arrays.push(allCards);
            arrays.forEach(array => {
                for (let i = 0; i < array.length; ++i) {
                    if (array[i].cardID === inCard.cardID) {
                        const copy = Object.assign({}, array[i]);
                        copy.infractionTags = inCard.infractionTags;
                        array[i] = copy;
                        break;
                    }
                }
            });
            this.updateFilters(allCards); //allows a newly added state to by one of the filters
            return {allCards: allCards, cardsByWorkflow: cardsByWorkflow};
        });

        
    }

    /**
     * Card data has changed, update the local copy of the new data
     * @param {*} _cardData : Changed data from the ExpandedCard
     */
    cardChange(_cardData){
        // console.log("Card data changed: ",_cardData,this.state);
         this.setState(state => {
            try {
                //Update the allCard stack
                let foundCard = (state.allCards||[]).find(card_=>{return card_.infractionID === _cardData.infractionID});            
                foundCard = Object.assign(foundCard,_cardData);
                //Update the byWorkFlow stack
                let foundCard2 = (state.cardsByWorkflow[_cardData.status]||[]).find(card_=>{return card_.infractionID === _cardData.infractionID});            
                foundCard2 = Object.assign(foundCard2,_cardData);
            } catch (error) {}
            return {allCards: state.allCards, cardsByWorkflow: state.cardsByWorkflow};
        });
    }//end cardChange
    
    /*
    * @brief Apply the filter changes to cards:
    */
   applyFilters(){
        // console.log("Apply filter selected");
        // this.setState({timerDebug:new Date()});

        //Re-execute the api request:
        // this.setState({retryCount: this.state.retryCount+1,filtersChanged: false,allCards:[],cardsByWorkflow:[]});
        this.setState({fetchCount:0,filtersChanged: false,allCards:[],cardsByWorkflow:[]});
        setTimeout(() => {this.setState({retryCount: this.state.retryCount+1});}, 500);
        

   }
    /*
    * @brief A callback for when a specific filter, by name, is set to a particular value
    */
    onFilterSelected(name, value) {
        //  console.log("OnFilterSelected: "+name+": ",value);
        // Allow this to be used as the start of a performance measurement
        perfDidClick('filter-selected');
        // Can't modify the state directory, so we have to copy it first and then modify the copy
        const filtersCopy = Object.assign({}, this.state.activeFilters);
        // console.log("Active filters: ",this.state.activeFilters);
        filtersCopy[name] = value.value;

        let toSet = value.value;

        let returnObj ={};

        switch(name){
            case 'startDate':{
                let bModified = false;
                // console.log("IS null:" ,toSet, toSet===null);
                if(toSet === null){
                    let mTime = this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-3, 'days');
                    toSet = mTime.toISOString();
                    console.log("Found value: ",value.value,toSet);
                }            
                //Add to the items to return to state
                returnObj ={startDate:moment(toSet)}

                if(bModified){
                    returnObj.filterUpdateTime = new Date();
                }
                // this.setState(returnObj);
            }break;
            case 'endDate':
                //Add to the items to return to state
                returnObj ={endDate:value.value}
                // this.setState({endDate:value.value});
            break;
            default:
                if (toSet === 'All') {
                    delete filtersCopy[name];
                } else if (toSet === 'unset') {
                    filtersCopy[name] = null;
                }
            break;
        }
       
        // console.log("Update filters:" ,filtersCopy)

        //Update the state
        this.setState({
            activeFilters: filtersCopy,
            filtersChanged: true,
            ...returnObj
        });
    }

    /*
    * @brief Handle what occurs when a checkbox filter is selected
    */
    onCheck(_data) {
        
        // console.log("OnCheck selected: ", _data);

        // Determine what cards should be shown based on the new value
        const filtersCopy = Object.assign({}, this.state.activeFilters);
        const cardsByWorkflow = {};
        this.state.allCards.forEach(card => {
            if (matchesFilters(card, filtersCopy) ) {
                //If the filter is selected then skip any card without metadata
                if(_data.state === true && !card.metadata){  return;  }
                
                const status = this.state.moves[card.infractionID] || card.status;
                const cardCopy = Object.assign({}, card);
                cardCopy.status = status;
                cardsByWorkflow[status] = cardsByWorkflow[status] || [];
                cardsByWorkflow[status].push(cardCopy);
            }
        });
        // // And then actually set the state
         this.setState({
        // /     activeFilters: filtersCopy,
            cardsByWorkflow: cardsByWorkflow,
        });
        // });
    }
    /*
    * @brief A callback for when a card is dragged between two CardLists
    * 
    * @param item The card data that has been moved
    * @param result An object describing the drag target, specifying the new workflow
    * 
    * Note: the new workflow may be the same as the old workflow if the user drags to
    *       the same CardList
    */
    handleCardDragged({ item, result }) {
        // Only do anything if the Card was dragged to a different CardList
        //   console.log("Card dragged: ",item,result, this.state.allowedFlows);
        //Check if the destination is in the allowed drop list:
        if(!this.state.allowedFlows.includes(result.workflow)){
            // console.log("Drop blocked");
            return;
        }
        //If drop is allowed, then update the card lists in the class:
        if (result.workflow !== item.status) {
            this.setState(state => {
                const cardCopy = Object.assign({}, state.cardsByWorkflow);
                const itemCopy = Object.assign({}, item);
                const movesCopy = Object.assign({}, state.moves);
                movesCopy[item.infractionID] = result.workflow;
                itemCopy.status = result.workflow;
                cardCopy[item.status] = cardCopy[item.status].filter(card => card.infractionID !== item.infractionID);
                cardCopy[result.workflow] = (cardCopy[result.workflow] || []).slice();
                cardCopy[result.workflow].push(itemCopy);
                this.props.cardChange({cardID: itemCopy.cardID, status: itemCopy.status, infractionID: itemCopy.infractionID});
                return {cardsByWorkflow: cardCopy, moves: movesCopy};
            });
        }
    }
    /*
    * Start listening for Card drags
    */
    componentDidMount() {
        // let viewPortWidth = window.innerWidth + "px";
        // let viewPortHeight = window.innerHeight + "px";        
        // console.log("Check Viewport", viewPortWidth,viewPortHeight); //debug printout of the window size

        // console.log("Check config: ",this.props);
        // These CardLists are then used as the children of the wrapper <div> for this Component
        let allowedFlows = [];
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.reporting){
            let tmpFlows = Object.keys(this.props.groupconfig.permissions.reporting);
            allowedFlows = IN_FLIGHT_WORKFLOWS.filter(flow_=>{
                if(tmpFlows.includes(flow_)){return true;}
                return false;
            })
        }else{
            allowedFlows = IN_FLIGHT_WORKFLOWS;    
        }

        registerCardDragListener('ReportingVew', this.handleCardDragged);
        this.setState({
            divWidth: this.m_childDiv && this.m_childDiv.clientWidth,
            winWidth: document.documentElement.clientWidth - 40
        });
        window.addEventListener("optimizedResize", this.windowResized);

        //Set the state of the active filter:
        const filtersCopy = Object.assign({}, this.state.activeFilters);
        if(this.props.groupconfig.driverid){
            filtersCopy.driverID = this.props.groupconfig.driverid;
            
        }
        // console.log("Check config: ",this.props.groupconfig);
        if(this.props.groupconfig.displayedInfractions){
            filtersCopy.displayedInfractions = this.props.groupconfig.displayedInfractions
        }

        

        //Displayed filters:
        const filterKeys = {
            'siteID': {
                'title': 'Site',
                order: 5
            },
            'severity': {
                'display': toTitleCase,
                'title': 'Severity',
                'sortKey': severityNumber,
                order: 6
            },
            'vehicleID': {
                'title': 'Asset',
                order: 1
            },
            'infractionType': {
                title: 'Clip',
                order: 2
            },
            'hwtags': {
                title: 'Hardware',
                order: 3,
            },
            'driverID': {
                'title': 'Driver',
                order: 4
            },
        };

        if(this.props.groupconfig.group.includes('pintovalley')){
            //Set default clip filter:
            filtersCopy.infractionType = 'Severe Drowsiness';
            filterKeys.infractionType.defaultVal = 'Severe Drowsiness'
        }
        // console.log("Set filterscopy: ",filtersCopy);
        this.setState({activeFilters:filtersCopy, allowedFlows:allowedFlows, FILTERKEYS:filterKeys});



        
    }
    /*
    * @brief A handler for the event when the browser window is resized
    * 
    * Used to update the current window width, for scaling the page contents to fit
    */
    windowResized() {
        this.setState({winWidth: document.documentElement.clientWidth - 40});
    }
    /*
    * Stop listening for Card drags because this Component will be disappearing
    * 
    * (view changed most likely)
    */
    componentWillUnmount() {
        unregisterCardDragListener('HrReview');
        window.removeEventListener("optimizedResize", this.windowResized);
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        // Create the API call + promise wrapper that will get the video highlights
        // this.setState({timerDebug:new Date()});

        //  console.log("Get cards: ",this.props.defaultFilter, this.props.filter,(Object.keys(this.props.groupconfig.permissions.reporting) || IN_FLIGHT_WORKFLOWS).join(","));
        // console.log("Get Cards: ",this.state.activeFilters);
        let allowedFlows = IN_FLIGHT_WORKFLOWS;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.reporting){
            allowedFlows = Object.keys(this.props.groupconfig.permissions.reporting);
        }
        try {
            allowedFlows = allowedFlows.join(',');
        } catch (error) {}
        // console.log("AllowedFlows:" ,allowedFlows);
        
        // console.log("State query: ",this.state.filters,this.state.filters.hwtags?[...this.state.filters.hwtags]:null)

        //Test the dates:
        let filtersModified = false;
        let filtersToPass = JSON.parse(JSON.stringify(this.state.activeFilters));
        //Don't allow the end date to be defined without the start date
        if(!filtersToPass.startDate && filtersToPass.endDate){      
            // console.log("No Start date: ");
            let mTemp = this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-3, 'days');
            filtersToPass.startDate = mTemp.toISOString();
            filtersModified = true;
        }
        let mStartDate = moment(filtersToPass.startDate);
        let mEndDate = null;
        //Sanity check the dates:
        if(filtersToPass.startDate && filtersToPass.endDate){
            mEndDate = moment(filtersToPass.endDate);
    
            if(!mStartDate.isBefore(mEndDate)){
                // console.log("Dates are wrong: ",filtersToPass);
                //swap the dates
                let tmpVal = filtersToPass.endDate;
                filtersToPass.endDate = filtersToPass.startDate;
                filtersToPass.startDate = tmpVal;
                filtersModified = true;
        
                //Update the start and end dates in the moments
                mStartDate = moment(filtersToPass.startDate);
                mEndDate = moment(filtersToPass.startDate);
            }
        }
        //Reset the filters states:
        if(filtersModified){
            this.setState({ activeFilters: JSON.parse(JSON.stringify(filtersToPass)),
                    selectedFilters: JSON.parse(JSON.stringify(filtersToPass)),
                    startDate:moment(filtersToPass.startDate),
                    endDate:filtersToPass.endDate?moment(filtersToPass.endDate):null,
            });
        }
            

        
        const dataPromise = Auth.currentSession().then((auth) => 
            {
                // console.log("GetCards:" ,filtersToPass);
                let apiName = "AuthLambda";
                let path = "/getCardsForReview";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: this.props.defaultFilter,
                        startDate: this.state.startDate? this.state.startDate.format('YYYY-MM-DD'):null, //align the date to the start and end of the day
                        endDate: this.state.endDate? this.state.endDate.format('YYYY-MM-DD'):null ,
                        // filters: this.state.activeFilters,
                        filters: filtersToPass,
                        countOnly: this.state.fetchCount===0,
                        timezone: moment().format('Z'), //send the timezone so the datepicker can match the information
                        allowedFlows: allowedFlows,
                        possibleInf:this.state.filters.infractionType?[...this.state.filters.infractionType]:null,             
                        possibleHWT:this.state.filters.hwtags?[...this.state.filters.hwtags]:null,             
                    }
                };
            return API.post(apiName, path, myInit);
        });
        if (DEBUG_DELAY_API_RESPONSE) {
            return delayPromise(dataPromise, DEBUG_DELAY * 1000);
        } else {
            return dataPromise;
        }
    }

    /*
    * @brief Populate the filter lists for the filter dropdown menus
    */
    updateFilters(_cards){
        
        // console.log("Cards loaded with filter: ",this.props.defaultFilter);
        const filters = {};
        let bAudioAlertFound = false;
        _cards.forEach(card => {
            // console.log("Card details:" ,card);
            //Check if we have metadata for the audio alert:
            if(card.metadata){
                bAudioAlertFound = true;
            }

            let excludeList = ['infractionType','siteID','vehicleID','driverID','severity'];
            Object.entries(filterKeys).forEach(([name, { key }]) => {
                const v = key ? key(card) : card[name];                
                filters[name] = filters[name] || new Set();

                // if(name!=='infractionType' && name!=='siteID' && name!=='vehicleID' && name!=='driverID'){
                if(!excludeList.includes){
                    // console.log("Filter adding: ",name);
                    filters[name].add(v);
                }
                
                
            });
        });

        try {
            if(bAudioAlertFound){
                filters['audioalert'] = new Set();
                filters['audioalert'].add('drowsy');
            }    
        } catch (error) {
        }
        
        //Fill this list based on the returned types, and not the available types in the cards
        try {
            // console.log("Update filters: ",this.props.groupconfig)
            filters['infractionType'] = filters['infractionType'] || new Set();            
            if(this.props.groupconfig.displayedInfractions && this.props.groupconfig.displayedInfractions.length>0){
                (this.props.groupconfig.displayedInfractions || []).forEach( type_ =>{
                    filters['infractionType'].add(type_);    
                });
            }
            else{
                for (let i in this.props.groupconfig.infractionTags) {
                    let elem_ = this.props.groupconfig.infractionTags[i];
                    if(elem_.type!==" "){
                        //  console.log("Infraction tag to add: ",elem_.type);
                        if(elem_.type ==='Irrelevant'){//add it in the list beofre irrelevant                
                            filters['infractionType'].add("System Tampering");    //Add the System Tampering:
                        }
                        filters['infractionType'].add(elem_.type);    
                    }
                }                
            }
            //Add the alert types to drop down list:
            
            try {
                for (const alert_ of (this.props.groupconfig.alertset||[])) {
                    if(alert_.type===" "){continue;}
                    filters['infractionType'].add(alert_.type);    
                }//end for loop
            } catch (error) {}
            
        } catch (error) {
        }

        //Fill in the hwtags:
        try {
            filters['hwtags'] = filters['hwtags'] || new Set();
            filters['hwtags'].add("None");    
            for (const tag_ of OTHERHWTAGS) {
                // console.log("Tag:" ,tag_,OTHERHWTAGS);                
                if(tag_.type!==" "){
                    //  console.log("Infraction tag to add: ",elem_.type);                    
                    filters['hwtags'].add(tag_.type);    
                }
            }
            
        } catch (error) {
        }


        //Fill this list based on the returned types, and not the available types in the cards
        try {
            // console.log("Returned sites: ",this.state);
            filters['siteID'] = filters['siteID'] || new Set();
            for (let i in this.state.sites) {
                let elem_ = this.state.sites[i];
                if(elem_!==" "){ 
                    filters['siteID'].add(elem_);  
                }

            }
            // console.log("SiteID: ",filters['siteID'],this.state.sites)
        } catch (error) {
        }
        //Fill this list based on the returned types, and not the available types in the cards
        try {
            filters['vehicleID'] = filters['vehicleID'] || new Set();
            

            for (let i in this.state.vehicles) {
                let elem_ = this.state.vehicles[i];
                if(elem_!==" "){  filters['vehicleID'].add(elem_);    }
            }
            //  console.log("Update filters:" ,filters, this.state.vehicles)

        } catch (error) {
        }
        //Fill this list based on the returned types, and not the available types in the cards
        try {
            filters['driverID'] = filters['driverID'] || new Set();
            for (let i in this.state.drivers) {
                let elem_ = this.state.drivers[i];
                // if(elem_==='0EBE6'){console.log("Driver to add: ",elem_)}

                if(elem_!==" "){  filters['driverID'].add(elem_);    }
            }

            // console.log("Add drivers filters", filters['driverID']);
        } catch (error) {
        }
        //Default severity:
        try {
            filters['severity'] = filters['severity'] || new Set();
            Object.values(SEVERITY).forEach(value_ => {
                filters['severity'].add(value_);
            });
        } catch (error) {        }
        // console.log("Set filters:" ,filters);
        this.setState({filters: filters});
    }

    
    /*
    * @brief Process card data received from the API return and/or the file return
    * Cards are added to the current set that is found on the page.
    */
    processCardData(_cardData, _bFromFile) {

        const cardsByWorkflow = Object.assign({}); //need to clear this since we are reprocessing allcards to repopulate the cardsByWorkflow below
        const allCards = [...this.state.allCards];

        //  console.log("Start length: ",allCards.length)

        if(_cardData){
            //  console.log("Process Card Data: ",_cardData.length);
            _cardData.forEach(card => {
                // console.log("Add card: ?",card);
                //The timereceived should already have the timezone applied, don't let it apply another time zone:
                card.timeReceived = moment(card.timeReceived,'YYYY-MM-DDTHH:mm:ss.SSSS'); //this loads without timezone parsing
                //Assign the asset alias if one is avialable
                card.vehicleID = formatAssetName(this.props.possibleFilters.AssetList,card.vehicleID,card.vehicleID);
                
                // card.timeReceived = moment(card.timeReceived);
                // console.log("Time of day: ",card.timeOfDay, moment(card.timeOfDay).format("HH:mm:ss"), moment.parseZone(card.timeOfDay).format("HH:mm:ss"))
                card.timeOfDay = moment.parseZone(card.timeOfDay);
                
    
                if(card.infractionTags){ card.infractionTags = card.infractionTags.split(",");}
                else{card.infractionTags = [];}
    
                card.infractionType =  displayInfraction(this.props.groupconfig.displayNameSet,card.infractionType);
                // set default name if one isn't known (DriverID first, if that is set)
                card.name = (card.name || card.driverID || "DriverID: Unavailable").replace('_', ' ');
                // console.log("Find: ",allCards.find(element => element.infractionID == card.infractionID))
                //  if(allCards.find(element => element.infractionID == card.infractionID)){
                //      console.log("Already found card? ",card.infractionid);
                //  }else{
                    allCards.push(card);
                //  }
                if(card.infractionID ==='5bb2051e-1ae9-4668-b749-7e625a11527f'){
                    console.log("Load card: ",card);
                }
            });

           
    
            
            //Reapply existing filters on the new data:
            allCards.forEach(card => {
                    const status = this.state.moves[card.infractionID] || card.status;
                    const cardCopy = Object.assign({}, card);
                    cardCopy.status = status;
                    cardsByWorkflow[status] = cardsByWorkflow[status] || [];
                    cardsByWorkflow[status].push(cardCopy);
            });

            //Update the states
            if(_bFromFile){
                //Did we just process the 2nd download set (from the file)
                //if so then release the waitingForDownload lock
                this.setState({cardsByWorkflow: cardsByWorkflow, allCards: allCards,waitingForDownload:false},this.updateFilters(allCards));
            }else{
                this.setState({cardsByWorkflow: cardsByWorkflow, allCards: allCards},this.updateFilters(allCards));
            }
            // console.log("Processed: ",new Date - this.state.timerDebug);
        }
    }

    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        

        // console.log("Loaded props:", this.props);
        //  console.log("Returned: ",data,this.props);
        try{    //Handle just getting the card count back, not the card data:
            if(data.cards.count){
                // console.log("Count return: ",new Date() - this.state.timerDebug);
                this.setState({fetchCount:data.cards.count,});
                setTimeout(() => {this.setState({retryCount: this.state.retryCount+1});}, 500);
                return;
            }
        }catch(e){
            console.log("Error setting the fetchCount: ",e);
        }

        
        // console.log("Returned: ",new Date() - this.state.timerDebug);
        
        //Get the sitelist from the return values:
        if(this.props.liveonly){
            //  console.log("Live Site Props: ",this.props.possibleFilters.Sites);
            let siteList = [];
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  
                if(!row_.status){siteList.push(row_);}
                else{
                    if(row_.status!=='disabled'){siteList.push(row_);}
                }
            })
            this.setState({sites:siteList, vehicles:this.props.possibleFilters.Assets});
        
        }else{
            try{
                
                //  console.log("both Site Props: ",this.props.possibleFilters.Sites,data.sites);
                
                
                if(data.sites){
                    let siteReturn = data.sites;
                
                    //filter out the 'PVM' site for demo user:
                    if(this.props.groupconfig.group === 'demo_group'){
                        //  siteReturn = data.sites.filter(elem_ => {return !elem_.includes('PintoValleyMine')})
                        siteReturn = data.sites.filter(elem_ => {return elem_.clientid.toLowerCase() === this.props.groupconfig.group})
                    }


                    let siteList = [];                    
                    siteReturn.forEach(row_=>{ 
                        if(siteList.findIndex(elem_ =>{return elem_.site === row_.site})>=0){ 
                            // console.log("Found duplicate site: ",row_.site);
                            return;}
                        if(!row_.status){siteList.push(row_);}
                        else{
                            if(row_.status!=='disabled'){siteList.push(row_);}
                        }
                    })
                    this.setState({sites:siteList});
                }
            }catch(e){
            }

            try{
                //Display the list of assets given the filter values from the App
                if(this.props.possibleFilters){
                    // console.log("Filters:" ,this.props);
                    this.setState({vehicles:this.props.possibleFilters.AssetList});
                }
            }catch(e){        
            }
        }




        //Populate the driverid list
        try{
            //Check first if this is a single operator signon
            if(this.props.groupconfig.driverid){
                // console.log("Driverlist: ",this.props.groupconfig)
                //Do nothing
            }
            else if(data.drivers){
                // console.log("Add drivers", data.drivers);
                let driverList = [];
                data.drivers.forEach(row_=>{ driverList.push(row_.driverid); })
                //add the "Pending" to the driver list:
                // driverList.push("Pending");
                // console.log("Drivers:" ,driverList)
                this.setState({drivers:driverList});
            }

        }catch(e){
        }

        //Handle data received from the API return:
        if(data.cards){
            this.processCardData(data.cards,false);
        }
        //Handle data received from the file:
        if(data.file){
            //Need to notify the class that we are still waiting for a file to download and process (waitingForDownload)
            this.setState({waitingForDownload:true})
            axios.get(data.file)
            .then(res => {
                this.processCardData(res.data,true);
            })
        }
        
      
    }
    
    /*
    * @brief Called to render the updated view, called everytime a state value is changed.
    */
    render() {
        const cardsByWorkflow = this.state.cardsByWorkflow || {};

        let bFromHrReview = false;
        if(this.props.groupconfig && this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'){
            bFromHrReview=true;
        }
        // We create a CardList from each possible workflow status here, and populate it with the appropriate
        // Cards for that list, if any
        //

        
       
            
        
            
        let cardCount = 0;
        const lists = IN_FLIGHT_WORKFLOWS.map(flow => {
            if(cardsByWorkflow[flow]){
                cardCount += cardsByWorkflow[flow].length
            }            
            let style = {};      
            if(!this.state.allowedFlows.includes(flow)){
                style.opacity=0.5;      
            }
            return <CardList key={flow} fromHrReview={bFromHrReview} workflow={flow} cards={cardsByWorkflow[flow]} onCardClick={this.expandCard} sortByReverse={true}  groupconfig = {this.props.groupconfig} 
                    style={style}/>
        });

        let scale = 1;

        if (this.state.winWidth && this.state.divWidth) {
            scale = this.state.winWidth / this.state.divWidth;
        }
        const smallerScale = Math.min(1, scale);

        const style = {
            'transformOrigin': 'top left',
        };
        if (smallerScale !== 1) {
            style.transform = 'scale(' + smallerScale + ')';
        }

        
        // console.log("Sites:"  sites={(this.state.sites||[])})
        // console.log("Sites:" ,this.props.possibleFilters.Sites,(this.props.possibleFilters.Sites||[]).filter(elem_ => {return this.state.expandedCard && elem_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})     )
        // console.log("Filters:" ,this);
        // <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />
        //If waitingForDownload is true, then don't display the apply button, it will refresh as soon as the lock is removed.
        return (
            <div className="hr-review" ref={(e) => {this.m_overallDiv = e;}} style={style}>
                <div className = "search-results">
                    <div>Total Notecards: </div>
                    {cardCount}
                </div> 

                <HrReviewFilters    className = "review-filters" filters={this.state.filters} onFilterSelected={this.onFilterSelected} onApply={this.applyFilters} 
                    hasChanged={!this.state.waitingForDownload && this.state.filtersChanged} startDate={this.state.startDate} endDate={this.state.endDate} handleCheck={this.onCheck}
                    filterKeys={this.state.FILTERKEYS}  modifiedTime = {this.state.filterUpdateTime}
                />

                {this.props.groupconfig.bLoaded? 
                    <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} 
                                shortOverlongTime={this.state.fetchCount > 0 ? 250: 4000} shortOverlongMsg={this.state.fetchCount > 0 ? "Fetching "+ this.state.fetchCount+" cards": "Fetching "} 
                                retryCount = {this.state.retryCount}/>:null
                }
                
                <div className="card-lists" ref={(e) => {this.m_childDiv = e;}}>
                    {lists}
                    
                    
                </div>
                {this.state.expandedCard &&
                 <ExpandedCard handleClose={this.unexpandCard}
                               {...this.state.expandedCard}
                               cardChange={this.cardComment}
                               updateCard = {this.cardChange}                               
                               driverChange={this.changeDriverID} 
                               eventNotify={this.props.eventNotify}
                               tagChange={this.cardTag}
                               scale={scale}
                               filter={this.props.filter}
                               driverlist = {this.state.drivers}
                               groupconfig = {this.props.groupconfig}
                               fromHrReview={bFromHrReview}
                               siteDetails = {(this.props.possibleFilters.Sites||[]).filter(elem_ => {return this.state.expandedCard && elem_.site.toLowerCase()===this.state.expandedCard.siteID.toLowerCase()})}
                 />
                }
            </div>
        );
    }
}

// Following function is from: https://developer.mozilla.org/en-US/docs/Web/Events/resize
// License: https://developer.mozilla.org/en-US/docs/MDN/About (CC0)
/*
* The purpose of this function is to define a 'throttled' resize event that can be listened for.
*
* Depending on the browser, the default JavaScript window.onresize event can fire far more
* rapidly than it needs to, causing performance issues when the handler runs many times.
*
* This is a standard way to prevent that behavior and limit the number of times the handler
* can fire.
*/
(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    /* init - you can init any event */
    throttle("resize", "optimizedResize");
})();
// END MDN function
