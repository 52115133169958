import React, { PureComponent } from 'react';

import { Auth, API } from 'aws-amplify';

import { ALL_DRIVERS, SECONDS_IN_HOUR } from './Util.js';

// import { Filters, InfoWidget, FlatGoogleChart, FlatRadialChart, TimelineGraph, RadialImageChart, TopDriverWidget } from './BaseComponents.js';
import { Filters,DashboardFiltersView, InfoWidget, TopDriverWidget,HeartTest } from './BaseComponents.js';


import { InfractionPolarGraph, HighlightsReductionChart, InfractionChart,InfractionGraphClass } from './DashboardWidgets.js';
import { filterToTime } from './Util.js';
import { withLoadingAnimation } from './Wrappers/withLoadingAnimation.js';

import * as moment from 'moment';

import { DRIVER_FILTER_FIXED_OPTIONS, undisplayInfraction } from './Util.js';

import {UserPasswordVerify} from './FlatAuth.js'

//Wrap the widgets with the loading animation logic
const TopDriverWithLoading = withLoadingAnimation(TopDriverWidget); //
const PolarWithLoading = withLoadingAnimation(InfractionPolarGraph); //
const InfractionGraphWithLoading = withLoadingAnimation(InfractionGraphClass);
const InfractionChartWithLoading = withLoadingAnimation(InfractionChart);  //

const inHours = (seconds) => {
    return seconds / SECONDS_IN_HOUR;
}

const roundDec = (val) => {
    return Math.round(val * 100) / 100;
}

/*@brief Sum the counts of two objects, this allows for unique keys in each set
*/
function sumObjectsByKey(...objs) {
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k))
          a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  }
  

const TIME_FILTER_OPTIONS =
    [
        {text: "All", value: "all"},
        {text: "Last Week", value: "week"},
        {text: "Last Month", value: "month"},
        {text: "Last Quarter", value: "quarter"},
    ];




/*
* @brief Main dashboard page of the app
*/
class DashboardView extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.updateInfractionData = this.updateInfractionData.bind(this);
        this.updateInfractionData_offline = this.updateInfractionData_offline.bind(this);
        this.updateInfractionData_live = this.updateInfractionData_live.bind(this);
        this.timelineDayClicked = this.timelineDayClicked.bind(this);
        this.driverClicked = this.driverClicked.bind(this);
        this.infractionTypeClicked = this.infractionTypeClicked.bind(this);
        this.filterSelected = this.filterSelected.bind(this);
        this.downloadClick = this.downloadClick.bind(this);

        this.updateSummaryData = this.updateSummaryData.bind(this);
        this.updateInfractionSums = this.updateInfractionSums.bind(this);
        this.fetchQuantizedData = this.fetchQuantizedData.bind(this);
        this.applyFilters = this.applyFilters.bind(this);

        //Render methods:
        this.getTimelineGraphs = this.getTimelineGraphs.bind(this);
        this.getSecondRow = this.getSecondRow.bind(this);
        this.getThirdRow = this.getThirdRow.bind(this);

        this.state = {
            summary: null,
            infractions_offline: null,
            infractions_live: null,

            infractionsCards_offline: null,
            infractionsCards_live: null,

            infractionsByType_offline:{},
            infractionsByType_live:{},

            base_infractions_offline: null,
            base_infractions_live: null,
            base_infractionsCards_offline: null,
            base_infractionsCards_live: null,
            base_infractionsByType_offline:{},
            base_infractionsByType_live:{},
            resetCount: 0,
            filter: this.props.filter,
            filter_live: this.props.filter,
            filter_offline: this.props.filter,
            filter_polar: {
                quant:this.props.filter.quant,
                infractions: this.props.filter.infraction,
            },
            summary_offline:{},
            summary_live:{},
            bApiReturned:false,
            passwordPrompt: null,
            downloadClicked: false,
            mInfractionOptions : [],
            mInfractionList: [],
            mBinWidth: null,
            startDate: null,
            endDate: null,
            activeFiltersTop:{
                driver: this.props.defaultFilter.driver,
            },
            activeFilters:{
                quant: this.props.defaultFilter.quant,
                driver: this.props.defaultFilter.driver,
                infractions: 'Severe Drowsiness',
            },
            debugTime: new Date(),

            
        };

        

    
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        // if the filter changes, do the stuff we normally do on component mount,
        // ask for updates from the server for the graphs and widgets
        if (nextProps.filter && nextProps.filter !== this.state.filter) {
            //  console.log("Props recieved: ",nextProps);
            
            if(nextProps.filter.time !== this.state.filter.time){
                 console.log("Update props: ",nextProps,this.state.filter)

                if(nextProps.filter.graph === 'live'){ this.setState({filter_live:nextProps.filter})}
                if(nextProps.filter.graph === 'offline'){ this.setState({filter_offline:nextProps.filter})}
                if(nextProps.filter.graph === 'both'){ 
                    this.setState({ filter_live:nextProps.filter,
                                    filter_offline:nextProps.filter })
                    
                }
                //Update the pie chart with the new time filter:
                this.updateSummaryData(this.state.base_infractionsByType_live,nextProps.filter.time,'live'); //these need to use the base set to not be affected by the infraction filter
                this.updateSummaryData(this.state.base_infractionsByType_offline,nextProps.filter.time,'offline');
                //Increment the reset count to signal to the TopDriver widget that it needs to refresh
                this.setState({resetCount: this.state.resetCount+1})

                // this.fetchQuantizedData(nextProps.filter);
            }

            
            
            //Handle tracking based on the graph filter           
            switch(nextProps.filter.graph){
                case 'live':
                    if( nextProps.filter.driver!== this.state.filter_live.driver ){
                        this.componentDidMount("live",nextProps.filter);
                    }
                    if(  nextProps.filter.infraction!== this.state.filter_live.infraction){
                        this.updateInfractionSums( this.state.base_infractionsByType_live,this.state.base_infractionsCards_live, "live", nextProps.filter);
                    }
                    this.setState({filter_live:nextProps.filter,resetCount: this.state.resetCount+1})
                    break;
                case 'offline':
                    if( nextProps.filter.driver!== this.state.filter_offline.driver ){
                        this.componentDidMount("offline",nextProps.filter);
                    }
                    if(  nextProps.filter.infraction!== this.state.filter_offline.infraction){
                        this.updateInfractionSums( this.state.base_infractionsByType_offline,this.state.base_infractions_offline, "offline", nextProps.filter);
                    }
                    this.setState({filter_offline:nextProps.filter,resetCount: this.state.resetCount+1})
                    break;
                //Check both the live and offline to apply the current setting when switch to both
                case 'both':
                    if( nextProps.filter.driver !== this.state.filter_offline.driver){
                        this.componentDidMount("offline",nextProps.filter);
                        
                    }
                    if(  nextProps.filter.infraction!== this.state.filter_offline.infraction){
                        this.updateInfractionSums( this.state.base_infractionsByType_offline,this.state.base_infractions_offline, "offline", nextProps.filter);
                        
                    }
                    this.setState({filter_offline:nextProps.filter})

                    if( nextProps.filter.driver!== this.state.filter_live.driver ){
                        this.componentDidMount("live",nextProps.filter);
                    }
                    if(  nextProps.filter.infraction!== this.state.filter_live.infraction){
                        this.updateInfractionSums( this.state.base_infractionsByType_live,this.state.base_infractionsCards_live, "live", nextProps.filter);
                    }
                    this.setState({filter_live:nextProps.filter,resetCount: this.state.resetCount+1})
                    break;


            }
            this.setState({filter:nextProps.filter,downloadClicked:false})
        }
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        // console.log("Query 1 return: ",new Date() - this.state.debugTime)
        // console.log("Dashboard data: ",data);
        let dataBeforeThis = Object.assign({}, this.state.summary || {});
        let dataAfterThis = Object.assign(dataBeforeThis, data.result);
        if(data.infractions){
            dataAfterThis.infractions = data.infractions;
        }
        // dataAfterThis.infractions = data.infractions
        // this.setState({summary: dataAfterThis});
        // console.log("Query 1 Processed: ",new Date() - this.state.debugTime)
    }

    /*
    * @brief Route the udpate based on the graph type
    */
    updateInfractionData(data) {        
        // console.log("Query 2 return: ",new Date() - this.state.debugTime)
        // console.log("Dash Data: ",data,this.props.filter);
        if(data.result.graph === 'live'){
            this.updateInfractionData_live(data);
        }
        else{
            this.updateInfractionData_offline(data);
        }
        // console.log("Query 2 Processed: ",new Date() - this.state.debugTime)
    }

     //Handle the data to display on the pie cahrt
     updateSummaryData(_byType,_timefilter,_whichSource){
    
        // console.log("Call updateSummary with: ",_timefilter,_whichSource,_byType);

        let start = filterToTime(_timefilter);
        let endDate = null;
        if(!start){
            console.log("Try to use the date: ",_timefilter.startDate)            
            start =moment(_timefilter.startDate,'YYYY-MM-DD')
        }
        if(_timefilter.endDate){
            endDate=moment(_timefilter.endDate,'YYYY-MM-DD')
        }

        //Create and empty structure to pass to the pie chart
        let summaryData = {
            infractions:{
                totalinfraction:0,
            }
        };

        // console.log("Update date with date: ",start);
        //Iterate over the object by type
        // console.log("Start:" ,start);
        for (const [type_, days_] of Object.entries(_byType)) {
            summaryData.infractions[type_]= 0; //initialize the count to 0
            //Iterate over the days in the set
            // console.log("Type, days:" ,type_,days_)
            (days_ || []).map(day_ =>{
                //  console.log("Day to add:",day_.dayOnly)
                if(start.isSameOrBefore(day_.dayOnly, 'day')){
                    
                    if(endDate){
                        if(endDate.isAfter(day_.dayOnly, 'day')){
                            // console.log("Is after: ",day_.dayOnly)
                            //Sum the values per day:
                            summaryData.infractions[type_] += day_.count;
                            summaryData.infractions.totalinfraction += day_.count;
                        }
                    }else{
                    //Sum the values per day:
                    summaryData.infractions[type_] += day_.count;
                    summaryData.infractions.totalinfraction += day_.count;
                    }
                    
                }
            });//end map loop    
        }//end for loop
        

        switch(_whichSource){
            case 'live':
                this.setState({summary_live:summaryData})
                break;
            case 'offline':
                this.setState({summary_offline:summaryData});
                break;
        }
        
    
    }
    /*
    * @brief Filter the API return based on the selected infraction type
    */
    updateInfractionSums(_byType,_data,_whichSource,_filter){
        // console.log("To update: ",_whichSource, _filter,_data,_byType);
        if(this.props.groupconfig.group.toLowerCase() !== 'bis' && _whichSource==='offline'){return;}
         let localData = JSON.parse(JSON.stringify(_data));    
         
        //update the _data source, to set the counts:
        try {
            
            // var startTime = performance.now()
            let byDate ={};
            
            
            //iterate through the array and set all entries to 0
            for(const row_ of localData){
                
                const dayOnly = moment(row_.dayOnly).utc().format('YYYY-MM-DD');
                if(!byDate[dayOnly]){byDate[dayOnly] = 0}
                byDate[dayOnly] = row_.countSum;
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    row_.countSum = 0;
                    byDate[dayOnly] = 0;
                }
            }
            

            // var endTime = performance.now()
            // console.log(`Call to reset took ${endTime - startTime} milliseconds`)
            
            // console.log("ByDate: ",byDate);
            var startTime2 = performance.now()
            // console.log("ByType: ",_byType);
            for (const [type_, set_] of Object.entries(_byType)) {
            //     // console.log("Test: ",type_,set_);
                //filter the type based on the current filter:
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    // console.log("Test: ",type_);
                    if(_filter.infraction !== type_){continue;}
                    // console.log("Process: ",type_);
                    // console.log("Test: ",type_,set_);
                    for(const row_ of set_){
                        if(row_.count > 0){
                            // console.log("Row: ",row_);
                            //find the value index using the date
                            // let index = localData.findIndex(elem_=>{
                            //     const dayOnly = moment(elem_.dayOnly).utc().format('YYYY-MM-DD');
                            //     return dayOnly === row_.dayOnly?true:false }
                            // );
                            // if(index >=0){ //found in set, so update the count
                            //     localData[index].countSum+=row_.count;
                            // }
                            // else{ //add to the new set
                            //     localData.push({dayOnly: row_.dayOnly, countSum: row_.count});
                            // }
                            if(!byDate[row_.dayOnly]){byDate[row_.dayOnly] = 0}
                            byDate[row_.dayOnly] +=row_.count; 
                        }
                    }
                } //end if filtered
            }//end 

            //Place back into an array:
            let newData = [];
            try {
                for(const elem_ of Object.keys(byDate)){
                    // console.log("Elem: ",elem_);
                    newData.push({
                        dayOnly: elem_,
                        countSum : byDate[elem_]
                    })

                }    
            } catch (error) {
                console.log("Failed to parse: ",error);
            }
            
    
    
            // var endTime2 = performance.now()
            // console.log(`Call to filter took ${endTime2 - startTime2} milliseconds`)
            //  console.log("ByDate: ",_whichSource,byDate,newData);
            if(_whichSource === 'live'){
                // var startTime3 = performance.now();
                let filteredByType = JSON.parse(JSON.stringify(_byType));
                //Need to filter the ByType?
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    filteredByType = {}
                    filteredByType[_filter.infraction] = _byType[_filter.infraction];
                }
                // console.log("ByType: ",filteredByType)    
    
                this.setState({
                    infractionsCards_live: newData, 
                    infractionsByType_live: filteredByType,
                })

                // var endTime3 = performance.now()
                // console.log(`Call to filter took2 ${endTime3 - startTime3} milliseconds`)
            }
            else{
                let filteredByType = JSON.parse(JSON.stringify(_byType));
                //Need to filter the ByType?
                if( (_filter.infraction && (_filter.infraction !== 'all' && _filter.infraction !== 'no-infraction-selected'))){
                    filteredByType = {}
                    filteredByType[_filter.infraction] = _byType[_filter.infraction];
                }
                //  console.log("Offline: ",newData,filteredByType)    
    
                this.setState({
                    infractionsCards_offline: newData, 
                    infractionsByType_offline: filteredByType,
                })



                // this.setState({
                //     infractions_offline: newData, 
                // })            
            }
        } catch (error) {
            
        }
    }


    /*
    * @brief Called to update the infraction graph data we're displaying
    */
    updateInfractionData_live(data) {
        // console.log("Live Dash: ",data);
        //Parse the byType
        //Loop over all the infraction data from the API return
        let summaryData = {
            infractions:{
                totalinfraction:0,
            }
        };
        
        //Process the by type sorted data
        if(data.result.byType){
            this.updateSummaryData(data.result.byType,this.props.filter.time,'live')
        }
        else{
            this.setState({summary_offline:summaryData});
        }

        let dataSet = data.result.dataCards;
        if(!data.result.dataCards){
            console.log("No datacards")
            dataSet = data.result.data;

        }
       
        this.setState({ bApiReturned:true,
                        infractions_live: data.result.data,
                        infractionsCards_live:data.result.dataCards, 
                        infractionsByType_live:data.result.byType,
                        base_infractionsCards_live: dataSet,
                        base_infractionsByType_live:data.result.byType,
                        // infractions_liveHour: data.result.byHour,
        });
        //Update data to show on the timeline graph:
        this.updateInfractionSums( data.result.byType,dataSet, "live", this.props.filter);
    }


   
    /*
    * @brief Called to update the infraction graph data we're displaying
    */
    updateInfractionData_offline(data) {
        //   console.log("Offline dash: ",data,this.props);

        if(data.result.byType){
            
            // console.log("enter by type");
            const byDay = {};
            const byDayArray = [];
            //get list of displayed infractions:
            let infractionList = (this.state.mInfractionOptions||[]).map(value_=>{
                return value_.value;
            })
    

            //Loop over all the infraction data from the API return
            //sum the totals per day  
            // console.log("Infracitonlist: ",infractionList)              
            for (const [type_, value] of Object.entries(data.result.byType)) {
                //   console.log("Entry: ",type_,this.state.mInfractionOptions,infractionList,infractionList.includes(type_));
                //only sum those values that are in the accepted list
                if(!infractionList.includes(type_)){
                    //  console.log("Not found: ",type_);
                     continue;
                }
                //Don't count the irrelevant
                if(type_.toLowerCase()==='irrelevant'){continue;}
                 (value || []).map(entry =>{
                    //  console.log("Date: ",entry);                        
                    let dateValue = entry.dayOnly;
                    if(!byDay[dateValue]){ byDay[dateValue]=0 }
                    // if(dateValue === '2021-10-13'){
                    //      console.log("Adding for 10-13: ",type_, entry,byDay[dateValue])
                    // }
                    byDay[dateValue] += entry.count;
                 });
                //  inf.dayOnly.toString())
            }
            //convert to the array format
            for (const [type_, value] of Object.entries(byDay)) {
                // console.log("Entry: ",type_,value);
                byDayArray.push({
                        dayOnly:type_,
                        countSum: value
                    }
                )
            }

                     
            //Trigger the state update to refresh the display
            this.setState({ bApiReturned:true,
                            infractions_offline: byDayArray, 
                            base_infractions_offline: [...byDayArray], 
                            // infractions_offline: data.result.data, 
                            infractionsCards_offline:data.result.dataCards, 
                            // infractionsByType_offline:data.result.byType,
                            base_infractionsByType_offline:data.result.byType,
                        });
                        // console.log("ByDayArray: ",byDayArray)
            this.updateInfractionSums( data.result.byType,byDayArray, "offline", this.props.filter);
        }
    
        //Check if the infraction filter is selected
        if( (this.props.filter.infraction && (this.props.filter.infraction !== 'all' && this.props.filter.infraction !== 'no-infraction-selected'))){
            
        } //end if filtered
        else{
            // console.log("Offline no infraction selected? ",data)
            //Create and empty structure to pass to the pie chart
            let summaryData = {
                infractions:{
                    totalinfraction:0,
                }
            };
            //Only update the pie chart when the infraction filter is not selected?
            //Process the data set broken by type
            if(data.result.byType){
                this.updateSummaryData(data.result.byType,this.props.filter.time,'offline')    
            }else{
                this.setState({summary_offline:summaryData});
            }
        }
        
        
    }

    fetchQuantizedData(_filter) {
        this.setState({infractions_liveHour:[]});
        // console.log("Fetch the quant data: ",_filter);
        let binMinWidth =  _filter.quant || 5;

        this.setState({mBinWidth:binMinWidth})

        //Dispatch the update to the SQL table:
        const removePromise =Auth.currentSession().then(
            (auth) => {
                // console.log("Pass token: ",auth.idToken.jwtToken)
                let apiName = "TrifectaAPI";
                let path = "/getQuantizedInfractionsOverTime";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: _filter,
                        binsMin: binMinWidth,
                        graph: "live",
                        startDate: _filter.startDate? _filter.startDate:null,
                        endDate: _filter.endDate?_filter.endDate: null,
                    }
                };
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("getQuantizedInfractionsOverTime remove site Fail; ",error); 
            })
            .then((_data)=>{//wait for the promiste to complete
                //  console.log("Quant returned ",_data)
                if(_data && _data.result){
                    this.setState({infractions_liveHour: _data.result,filter_polar:_filter})
                }
            
        });         
    }
    
    /*
    * @brief Request the data to display, this function is called when the component comes into existence
    */
    componentDidMount(_graph,_filter) {
        // console.log("Doc: ", document.documentElement.clientWidth,document.documentElement.clientHeight )
        // console.log("Dash props:" ,this.props);
        //  console.log("Dashboard Props: ", this.state.activeFilters);
        //Get the list of infactions to display from the groupconfig infraction tags
        const INFRACTION_FILTER_FIXED_OPTIONS =
        [
            {text: "All", value: "no-infraction-selected"},
        ];
        
        const possibleInfractions = (this.props.groupconfig.infractionTags || []).map(tag_ => {
            return {text: tag_.type, value: tag_.type};
        });
        //Get the list of alerts to display from the groupconfig alerttypes
        const possibleAlerts = (this.props.groupconfig.alerttypes || []).map(tag_ => {
            return {text: tag_, value: tag_};
        });

        //Combine the alerts and infractions together
        const infractionOptions = INFRACTION_FILTER_FIXED_OPTIONS.concat(possibleInfractions).concat(possibleAlerts);
        let infractionList = (infractionOptions||[]).map(value_=>{
            return value_.value;
        })
        //  console.log("Infractions ",infractionOptions,infractionList);
        this.setState({mInfractionOptions:infractionOptions, mInfractionList: infractionList})

        //  console.log("Did mount called?", _graph,this.state.filter,this.props.filter);
        if(!_graph){
            _graph = 'both';
        }
        if(!_filter){
            _filter = this.props.filter;
        }
        // console.log(this.constructor.name + " mounting time:", Date.now());
        this.setState({resetCount: this.state.resetCount+1});
        // console.log("Did mount recalled? ",_graph,_filter)
        // console.log("Send asset list: ",this.props.possibleFilters ? this.props.possibleFilters.Assets.join(","): null)

        //Customize filter: (don't pass the selected infraction)
        let tmpFilter = Object.assign({},_filter);
        delete tmpFilter.infraction;
        // console.log("tmpFilter: ",tmpFilter,_filter);
        
        // console.log("Quant filter: ",_filter)
        let binMinWidth =  5;//_filter.quant;
        if(this.state.mBinWidth !== _filter.quant){
            //Only call the fetch on the quant data once?
            this.fetchQuantizedData(this.state.activeFilters);
        }

        // console.log("Time to first query: ",new Date() - this.state.debugTime)
        
        /*
        * Start the API call using our current login token
        */
       let timeZone = moment().format('Z');
    //    console.log("Send timezone: ",timeZone);
        // timeZone = '+11'; //debug testing at new location
        Auth.currentSession().then(
        (auth) => {
            let apiName = "AuthLambda";
            if(_graph === "both"){                
                let summaryPaths = [ "/getInfractionData"]; //pie chart
                let infractionPath = "/getInfractionsOverTime"; //timelines
                let myInitOffline = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: tmpFilter,
                        timezone: timeZone,
                        graph: "offline",
                        assetlist:  this.props.possibleFilters ? this.props.possibleFilters.Assets.join(","): null,
                        infractionList: infractionList,
                        binsMin: binMinWidth,
                    }
                };
                summaryPaths.map(summaryPath => {
                    API.post(apiName, summaryPath, myInitOffline).then(this.updateData);
                });
                API.post(apiName, infractionPath, myInitOffline).then(this.updateInfractionData);

                let myInitLive = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: tmpFilter,
                        timezone: timeZone,
                        graph: "live",
                        assetlist:  this.props.possibleFilters ? this.props.possibleFilters.Assets.join(","): null,
                        infractionList: infractionList,
                        binsMin: binMinWidth,
                    }
                };
                API.post(apiName, infractionPath, myInitLive).then(this.updateInfractionData);

            }else{
                // console.log("trigger getInfractionData");
                
                let infractionPath = "/getInfractionsOverTime"; //timelines
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: tmpFilter,
                        timezone: timeZone,
                        graph: _graph,
                        assetlist:  this.props.possibleFilters ? this.props.possibleFilters.Assets.join(","): null,
                        infractionList: infractionList,
                        binsMin: binMinWidth,
                    }
                };
               
                API.post(apiName, infractionPath, myInit).then(this.updateInfractionData);
            }
            
        });
    }
    timelineDayClicked(day, _serviceType, chart) {
        
        //Don't allow clicking on the graph for pintovalley
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){return;}
        if(this.props.groupconfig.group.toLowerCase() === 'demo_group'){return;}
        const date = moment(day);
        // console.log("Timeline click:", day, _serviceType, date.clone().format("YYYY-MM-DD"),date.clone().date());
        // console.log("Timeline click:", day, _serviceType, date.clone().utc().format(),date.clone().utc().date());
        this.props.eventNotify({
            type: 'link',
            data: {
                href: 'video-drilldown',
                extra: {
                    groupconfig: this.props.groupconfig,
                    filter: {
                        driverID: ALL_DRIVERS,
                        timePeriod: {
                            startDate: date.clone().format("YYYY-MM-DD"),
                            endDate: date.clone().add(1, 'day').format("YYYY-MM-DD"),
                        },                        
                        siteIDs: this.props.filter.siteIDs,
                        role: this.props.filter.role,
                        serviceType: _serviceType,
                    }
                }
            },
        });
    }
    driverClicked(driverID) {
        // console.log("Driver click:", driverID);
        //Don't allow clicking on the graph for pintovalley
        return;
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){return;}
        if(this.props.groupconfig.group.toLowerCase() === 'demo_group'){return;}
        this.props.eventNotify({
            type: 'link',
            data: {
                href: 'video-drilldown',
                extra: {
                    groupconfig: this.props.groupconfig,
                    filter: {
                        driverID: driverID,
                        timePeriod: this.props.filter.time,
                        siteIDs: this.props.filter.siteIDs,
                        role: this.props.filter.role
                    }
                }
            },
        });

    }
    infractionTypeClicked(infractionType) {
        // console.log("Infraction click:", infractionType);
        //Don't allow clicking for pintovalley users:
        // return;
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){return;}
        if(this.props.groupconfig.group.toLowerCase() === 'demo_group'){return;}
        this.props.eventNotify({
            type: 'link',
            data: {
                href: 'video-drilldown',
                extra: {
                    groupconfig: this.props.groupconfig,
                    filter: {
                        driverID: this.props.filter.driver,
                        timePeriod: this.props.filter.time,
                        infractionType: undisplayInfraction(infractionType),
                        siteIDs: this.props.filter.siteIDs,
                        role: this.props.filter.role,
                        graph: this.props.filter.graph,
                    }
                }
            },
        });
    }
    applyFilters(_filterSet,_whichFilter) {
        
        // console.log("active filters:" ,_filterSet,_whichFilter)
        const res = {};
        
        Object.entries(_filterSet).forEach(([name, value]) => {
            // console.log("Apply: ",name,value)
            try{
                switch(name){
                    case 'vehicle':
                        break;
                    case 'quant':
                        res['quant'] = value;                        
                        break;
                    case 'infractions':
                        // this.updateInfractionVisibility(value.value,this.state.activeAsset,this.state.mSelectedDriver)
                        // this.setState({selectedInfraction: value.value})
                        res['infraction'] = value;
                        // this.props.eventNotify({type: "filter", data: res});
                    break;
                    case 'driver':
                        res['driver'] = value;                        
                        break;
                    case 'startDate':
                         console.log("Apply startDate");
                        res['time'] = res['time'] || {}
                        res['time'].startDate = value.format("YYYY-MM-DD");
                        break;
                    case 'endDate':
                        res['time'] = res['time'] || {}
                        res['time'].endDate = value.format("YYYY-MM-DD");
                        break;
                    default:
                        break;
                }
            }catch(error){
    
            }
        })
        //Make sure we can reset the time stamp:
        if(!res['time']){
            console.log("Time not set");
            res['time'] ='all';
        }
        
        if(!_whichFilter){
            console.log("Apply the filters",res)
            this.props.eventNotify({type: "filter", data: res});
        }else{
            //Apply only to the TimeOfDay polar chart:
            console.log("Polar apply: ",_filterSet)
            this.fetchQuantizedData(_filterSet);
        }
        

    }
    filterSelected(kind, value, _whichFilter) {
        console.log("Filter returned: ",kind,value);

        switch(kind){
            case 'sites':
                if(value.value === 'All'){value.value = null;}
                break;
            case 'driver':
                    if(value.value === 'All'){value.value = null;}
                    break;
            default:
                break;
        }

        if(!_whichFilter){
            // const res = {};
            // res[kind] = value.value;
            // console.log("First case: ",res);
            // this.props.eventNotify({type: "filter", data: res});
            

            const filtersCopy = Object.assign({}, this.state.activeFiltersTop);
            filtersCopy[kind] = value.value;
            if (!value.value) {
                delete filtersCopy[kind];
            }
            // console.log("Set active filters to: ",filtersCopy);
            this.setState({activeFiltersTop:filtersCopy});


        }else{
            const filtersCopy = Object.assign({}, this.state.activeFilters);
            filtersCopy[kind] = value.value;
            if (!value.value) {
                delete filtersCopy[kind];
            }
            // console.log("Set active filters to: ",filtersCopy);
            this.setState({activeFilters:filtersCopy});
        }

        

        //Send back to the main application:
        
        
    }

    /*
    * Fetch, format, and download the dashboard data when the download button is pressed.
    */
    downloadClick(_isAuthenticated) {
        // let timeDebug = new Date();
        // console.log("Click on download: ",_isAuthenticated,this.props,this.state)

        //Create the filename for the CSV file
        let fileName = "Dashboard_";
        fileName+= moment().format('MMM-DD-YYYY')
        //Add time to the filename:
        if(this.props.filter.time !== 'all'){
            const start = filterToTime(this.props.filter.time);
            fileName+= "_"+start.format('MMM-DD-YYYY');
        }else{
            fileName+= "_all-time";
        }
        //Add the driverid selection to the filename:
        if(this.props.filter.driver!=='no-driver-selected'){
            fileName+= "_"+this.props.filter.driver;
        }
        //Add the infraction selection to the filename:
        if(this.props.filter.infraction!=='all' && this.props.filter.infraction!=='no-infraction-selected'){
            fileName+= "_"+this.props.filter.infraction;
        }
        //Set the filename type:
        fileName +=".csv";
        // console.log("FileName: ",fileName )

        
        //Was this called from the button press, the function is recalled after 
        //the password authentication with new data
        // console.log("Test it: ",_isAuthenticated)
        if(_isAuthenticated===undefined){
            //Execute a password validation:
            this.setState({passwordPrompt:true});
            return;
        }
        if(!_isAuthenticated){ //authentication failed, password prompt was canceled
            return;
        }
        // return;
        
        //Execute a query to the AWS Lambda:
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let myInit = {
            body: {
                token: auth.idToken.jwtToken,
                apiName: "getInfractionsOverTime_download",
                filter: this.props.filter,
                timezone: moment().format('Z'),
                assetlist:  this.props.possibleFilters ? this.props.possibleFilters.Assets.join(","): null,
            }
            };
            return API.post("AuthLambda", "/apiRouter", myInit);
        });

        //Wait the promise to return:
        realPromise.then(_return => {
            // console.log("Return from click: ",_return);
            if(_return.error){ //Did we receive an error message: failed to generate download link?
              console.log("returned error: ",_return);
            }
            else{ //data returned from lambda
                //place all data into a csv formatted string:
                let csvString = "data:text/csv;charset=utf-8,"; //create the header for the file
                
                //Iterate over the data and add it to the csv file string:
                if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){
                    //Add a header for the columns:
                    csvString += "Date,TimeOfDay,Type,DriverID" +"\r\n"; //add the end of line and append to the string
                    (_return.result.data ||[]).forEach(row_=>{
                        let rowData = moment.parseZone(row_.timestamp).format('YYYY-MM-DD')+","+moment.parseZone(row_.timestamp).format('HH:mm:ss')+","+row_.type+","+'="'+String(row_.driverid)+'"';
                        csvString += rowData +"\r\n"; //add the end of line and append to the string
                    })
                }else{
                    //Add a header for the columns:
                    csvString += "Date,TimeOfDay,Type,DriverID,Site,Speed" +"\r\n"; //add the end of line and append to the string
                    //Add the data into each row:
                    (_return.result.data ||[]).forEach(row_=>{
                        let rowData = moment.parseZone(row_.timestamp).format('YYYY-MM-DD');
                        rowData +=","+moment.parseZone(row_.timestamp).format('HH:mm:ss');
                        rowData +=","+row_.type;
                        let driverIDString = "Pending";
                        if(row_.driverid && row_.driverid.length === 5){
                            driverIDString = String(row_.driverid)
                        }
                        
                        rowData +=","+'="'+driverIDString+'"';
                        rowData +=','+row_.site;
                        // rowData +=','+row_.region;
                        rowData +=','+row_.metadata.speed;
                        csvString += rowData +"\r\n"; //add the end of line and append to the string
                    })
                }
                
                //Create the download function
                try {
                    // console.log("String to download: ",csvString);
                    const encodedURI = encodeURI(csvString);
                    const link = document.createElement('a'); //create a temporary link to use as the download
                    //Set the content and file name
                    link.href = encodedURI; //set the content of the file to download
                    link.download = fileName; //set the filename that will be shown to the user
                    document.body.appendChild(link); //add the temporary link to the document (webpage)
                    link.click(); //automatically click the link 
                    document.body.removeChild(link); //remove the temporary link used to download the file    
                    // console.log("Download",new Date() - timeDebug)   
                    // this.setState({downloadClicked:false});
                } catch (error) {
                    console.log("Failed to download: ",error);
                }
            }//end handle data return from lambda
          });//end handle promise return
    }//end downloadClick

    
    //Generate the calls to the Time Line graphs - use this to clean up the Render block
    //Define the timeline graphs, if we are looking at BIS, then show two graphs
    getTimelineGraphs(_displayFiltersTOP,_downloadButton){
        let timelineGraphs = null;
        if( this.props.groupconfig.group === 'bis'){
            timelineGraphs =    <div>
                                    <DashboardFiltersView  
                                        className='dashboard-filters' 
                                        filters={_displayFiltersTOP}
                                        activeFilters = {this.state.activeFiltersTop}
                                        onFilterSelected={this.filterSelected} 
                                        groupconfig={this.props.groupconfig} 
                                        startDate={this.state.startDate} 
                                        endDate={this.state.endDate} 
                                        // currentSite={this.state.mCurrentSite}
                                        onApply={()=>{this.applyFilters(this.state.activeFiltersTop)} }
                                    />

                                    <div className="dashboard-row">
                                            <InfractionGraphWithLoading    serviceType='CloudDETECT' infractions={(this.state.infractionsCards_offline||[])} 
                                                            infractionsByType = {(this.state.infractionsByType_offline||[])} 
                                                            filter={this.state.filter_offline} 
                                                            groupconfig={this.props.groupconfig}
                                                            infractionsToDisplay = {this.state.mInfractionList||[]}
                                                            onDayClick={this.timelineDayClicked}
                                            />
                                    </div>
                                    {(this.state.infractionsCards_live && this.state.infractionsCards_live.length >0)?
                                        <div className="dashboard-row">
                                            <InfractionGraphWithLoading serviceType='TRIFECTA' infractions={(this.state.infractionsCards_live||[])} infractionsByType = {(this.state.infractionsByType_live||[])} filter={this.state.filter_live} groupconfig={this.props.groupconfig}
                                                infractionsToDisplay = {this.state.mInfractionList||[]}
                                                onDayClick={this.timelineDayClicked}
                                            />
                                        </div>
                                    :null
                                    }
                                </div>
        }else{
            timelineGraphs =    <div>
                                    <DashboardFiltersView  
                                        className='dashboard-filters-download' 
                                        filters={_displayFiltersTOP}
                                        activeFilters = {this.state.activeFiltersTop}
                                        onFilterSelected={this.filterSelected} 
                                        groupconfig={this.props.groupconfig} 
                                        startDate={this.state.startDate} 
                                        endDate={this.state.endDate} 
                                        // currentSite={this.state.mCurrentSite}
                                        onApply={()=>{this.applyFilters(this.state.activeFiltersTop)} }
                                        button = {_downloadButton}                    
                                    />
                                    <div className="dashboard-row">

                                        <InfractionGraphWithLoading serviceType='TRIFECTA' infractions={(this.state.infractionsCards_live||[])} infractionsByType = {(this.state.infractionsByType_live||[])} filter={this.state.filter_live} groupconfig={this.props.groupconfig} 
                                            infractionsToDisplay = {this.state.mInfractionList||[]}
                                            onDayClick={this.timelineDayClicked}
                                        />
                                    </div>
                
                                </div>
        }
        return timelineGraphs;
    }//end of getTimelineGraphs
      
    //Generate the second row of components - returns the TopDriverWidget and the InfractionByTimeOfDay
    getSecondRow(_displayFilters){
        return(
            <div className="dashboard-row-limity">
                {this.props.groupconfig.driverid? 
                    null 
                    : 
                    <div className="col-xl-4">
                        <div className="card counter-card-1">
                            <div className="card-block-big top-driver-wrapper">
                                <TopDriverWithLoading filter={this.props.filter} isWorst limit={3}
                                    onDriverClick={this.driverClicked} groupconfig={this.props.groupconfig} resetCount = {this.state.resetCount}/>
                            </div>
                        </div>
                    </div>
                }
                
                <div>
                    <DashboardFiltersView  
                                className='dashboard-filters-2ndrow' 
                                filters={_displayFilters}
                                options={{infractions:'noall'}}
                                activeFilters = {this.state.activeFilters}
                                onFilterSelected={(type,value)=>{
                                    // console.log("data:" ,type,value)
                                    this.filterSelected(type,value,'second')}
                                } 
                                groupconfig={this.props.groupconfig} 
                                startDate={this.state.startDate} 
                                endDate={this.state.endDate}                     
                                onApply={()=>{this.applyFilters(this.state.activeFilters,'second')}}
                    />
                    
                    <PolarWithLoading serviceType='TRIFECTA' hourly={(this.state.infractions_liveHour||[])}  filter={this.state.filter_polar} groupconfig={this.props.groupconfig} 
                                    infractionsToDisplay = {this.state.mInfractionList||[]}
                                    onDayClick={this.timelineDayClicked}
                                    filterCount={Object.keys((_displayFilters||{})).length}
                    />
                            
                </div>
            </div>  
        )
    }//end getSecondRow

    //Generate the third row of components - returns the Overall infractions pie chart
    getThirdRow(_summary){
        return(
            <div className="dashboard-row-overlap">
                <div className="col-xl-4">
                    <div className="card counter-card-1">
                        <div className="card-block-big infraction-prop-wrapper">
                            <InfractionChartWithLoading summary={_summary} onClick={this.infractionTypeClicked} groupconfig={this.props.groupconfig} />
                        </div>
                    </div>
                </div>
                
            </div>
        )
        

    }//end getThirdRow

    //Call Render (need to clean this up so that we aren't doing computation in the render loop - it is making UI sluggish)
    render() {

        let summary = {
            infractions:{
                totalinfraction:0,
            }
        };
        //Change what is displayed based on the graph filter
        switch(this.state.filter.graph){
            case 'live':
                summary = this.state.summary_live;
                break;
            case 'offline':
                summary = this.state.summary_offline;
                break;
            case 'both':        
                    //Set the summary based on the available data
                    try {
                        let bOfflineAvailable =false;
                        let bLiveAvailable = false;
                        // console.log("Both: ",this.state.summary_offline, this.state.summary_live)
                        try {
                            if(this.state.summary_offline&& this.state.summary_offline.infractions && this.state.summary_offline.infractions.totalinfraction>0){bOfflineAvailable = true;}
                            if(this.state.summary_live && this.state.summary_live.infractions && this.state.summary_live.infractions.totalinfraction>0){bLiveAvailable = true;}    
                        } catch (error) {
                        }
                        
                        
                        if(bOfflineAvailable && !bLiveAvailable){ //only offline data available
                            summary = this.state.summary_offline;
                        }
                        else if(!bOfflineAvailable&& bLiveAvailable){ //only live data available
                            summary = this.state.summary_live;
                        }else if(bOfflineAvailable && bLiveAvailable){ //both  available
                            //Combine the values from both sets
                            summary.infractions = sumObjectsByKey(this.state.summary_live.infractions, this.state.summary_offline.infractions)
                        }    
                    } catch (error) {
                        
                    }
                    
                break;
        }


        const possibleDrivers = (this.props.possibleFilters.DriverID || []).map(driverID => {
            return {text: driverID, value: driverID};
        });
        let driverOptions = DRIVER_FILTER_FIXED_OPTIONS.concat(possibleDrivers);
        //Check if the single driverid is set (this is for operator level access)
        if(this.props.groupconfig.driverid){
            driverOptions =[];
            driverOptions.push({text: this.props.groupconfig.driverid, value: this.props.groupconfig.driverid});
        }

        // console.log("Filters: ",this.props.defaultFilter);
        const filterData = {
            'time': {
                'options': TIME_FILTER_OPTIONS,
                'default': this.props.defaultFilter.time,
                'title': 'Time:',
                order:4,
            },
            'driver': {
                'options': driverOptions,
                'default': this.props.defaultFilter.driver,
                'title': 'DriverID:',
                order:2,
            },
            'quant':{
                'options': [{text:'5 mins',value:5},{text:'10 mins',value:10},{text:'15 mins',value:15},{text:'30 mins',value:30},{text:'1 hour',value:60}],
                'default': this.props.defaultFilter.quant,
                'title': 'Quant:',
                order:1,
            }
            
        };

        // console.log("Filters:" ,this.props.possibleFilters)
        let displayFiltersTOP={
            infractions: new Set((this.state.mInfractionOptions||[]).map(elem_ => {return elem_.value})),
            driver: new Set((driverOptions||[]).map(elem_ => {return elem_.value})),
            // sites:new Set((this.props.possibleFilters.Sites||[]).map(elem_ => {return elem_.site})),
            // quant: new Set([5,10,15,30,60]),
        };
        let trifectaSites = [...new Set((this.props.possibleFilters.AssetsTrifecta||[]).map(elem_ => {return elem_.site}))]
        let displayFilters= null;
        try {
            //Get the site elements
            let sites = (this.props.possibleFilters.Sites||[]).filter(elem_ => {
                if(trifectaSites.includes(elem_.site.toLowerCase())){return true;}                
                return false;
            });
            //get the name of the site only
            // sites = (sites||[]).map(elem_ => { return elem_.site;}) 
            
            let infractions2 = (this.state.mInfractionOptions||[]).filter(elem_=>{return elem_.value!=='Speeding'});
            //Populate the filters:
            displayFilters = {
                infractions: new Set((infractions2).map(elem_ => {return elem_.value})),
                driver: new Set((driverOptions||[]).map(elem_ => {return elem_.value})),
                sites:new Set(sites),            
                quant: new Set([5,10,15,30,60]),
            };   
        } catch (error) {
            
        }
        if(displayFilters.sites&& displayFilters.sites.size<=1){
            delete displayFilters.sites;
            
        }

        
        //  console.log("Filter on dashboard: ",filterData,displayFilters,this.props);
        //Show the infraction data type as a filters dropdown on the dashboard
        if(this.props.groupconfig.group === 'pintovalley' || this.props.groupconfig.group === 'demo_group' ||  this.props.groupconfig.group === 'bis'){
            filterData.infraction = {
                'options': this.state.mInfractionOptions,
                'default': this.props.defaultFilter.infraction,
                'title': 'Infraction:',
                order:2,
            }
        }

        if(this.props.groupconfig.group === 'bis'){
            filterData.graph = {
                'options':  [
                    {text: "Both", value: "both"},
                    {text: "Offline", value: "offline"},
                    {text: "Live", value: "live"},
                ],
                'default': this.props.defaultFilter.graph,
                'title': 'Graph:',
                order:4,
            }
        }

        //Check if LIVE data is available, if not available, remove the graph from the filter bar:
        if(this.state.bApiReturned && (this.state.infractionsCards_live && this.state.infractionsCards_live.length ===0)){
            delete filterData.graph;
        }
        


        let dashboardReturn = null;
        //Define the download button, default to null
        let downloadButton = null;
        
        //Should the button be displayed?
        let bEnableDownload = false;
        if(this.props.groupconfig.group.toLowerCase() === 'pintovalley'){ bEnableDownload = true;}//Allow the  PVM user to always see the button
        //Enable the button when the speeding is displayed:
        if(this.props.filter.infraction === 'Speeding'){ bEnableDownload = true;}

        if(bEnableDownload){
            // console.log("Download button state: ",this.state.downloadClicked)
            downloadButton = <button  className={this.state.downloadClicked?"btn download-timeline-clicked":"btn download-timeline"}
                                onClick={()=>{
                                    if(!this.state.downloadClicked){ //disable the button if already clicked
                                        this.setState({downloadClicked:true})
                                        this.downloadClick()}
                                    }
                                } 
                                // whileTap ={{scale:0.75}}
                             > Download</button>
        }
    
        
        //Format the dashboard element to render - this will be the rendered element unless the user 
        //is not logged in
        dashboardReturn = 
        <div>
            {this.getTimelineGraphs(displayFiltersTOP,downloadButton)}
            {this.getSecondRow(displayFilters)}
            {this.getThirdRow(summary)}
        </div>

        //Return the formatted HTML:            
        return (
            <div className="main-dashboard">
                {dashboardReturn}

                { this.state.passwordPrompt &&
                    <UserPasswordVerify 
                        handleClose={(_data)=>{
                            if(_data === false){                               
                                this.setState({downloadClicked:false})
                            }
                            // console.log("Closed with: ",_data);
                            this.setState({passwordPrompt:null})
                            this.downloadClick(_data)
                        }}   
                        groupconfig = {this.props.groupconfig}
                        username = {this.props.username}
                    />
                }
            </div>
        );
    }
}


export { DashboardView };