import React, {useState, useEffect,Component,PureComponent } from 'react';
import ReactModal from 'react-modal';

import { Auth, API } from 'aws-amplify';

import { ApiCaller } from '../ApiCaller.js';

import { OTHERHWTAGS,DRIVER_FILTER_FIXED_OPTIONS} from '../Util.js';
// import { CallinFiltersView, filterKeys } from '../ReviewFilters.js';
import { CallinFiltersView } from '../Filters/CallinFilter.js';

import * as moment from 'moment';
import './RiskProfile.css';

import { RiskWorst } from './RiskWorst.js';
import { RiskStats } from './RiskStats.js';


import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { getTopNum } from './RiskUtils.js';

import Dexie from 'dexie'
import { CombinedCard } from '../CombinedCard/CombinedCard.js';



/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
/*
* @brief Create a simple view with the selection buttons, based on button selected change the called class.
*/
class RiskProfile extends Component {
  constructor(props) {
    super(props);
    this.updateData = this.updateData.bind(this);

    this.getApiCall = this.getApiCall.bind(this);
    
    this.fetchDriverNotes = this.fetchDriverNotes.bind(this);
    this.fetchRadialData = this.fetchRadialData.bind(this);

    this.fetchDriverPhotos = this.fetchDriverPhotos.bind(this);
    this.getDriverPhotos = this.getDriverPhotos.bind(this);
    this.handlePhotoReturn = this.handlePhotoReturn.bind(this);
    

    this.onFilterSelected = this.onFilterSelected.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    
    this.onClick = this.onClick.bind(this);
    

    this.state = {
        top10: {},
        top20: {},
        loadState:Array(20).fill(false),
        driverCard: null,
        refreshCount: 0,
        retryCount: 0,
        activeView: 'worst',
        tabViews: [{key:'worst', name:'Worst Performing Drivers'},{key:'stats', name:'Statistics'}],
        tabViewsAll: [{key:'worst', name:'Worst Performing Drivers'},{key:'stats', name:'Statistics'}],
        allDrivers: new Set((this.props.possibleFilters.DriverID||[])),
        refreshTimeoutID:null,
        startDate: moment().subtract(30, 'days'),
        endDate:null,
        // totalDriverCount: this.props.possibleFilters.DriverID.length,
        totalDriverCount: null,
        filterUpdateTime: new Date(),
        filters: {
          // sites: new Set(this.props.possibleFilters.gpsSites),
          driver: new Set((this.props.possibleFilters.DriverID||[])),
        },
        selectedFilters: {
          startDate: moment().subtract(30, 'days'),
          infraction :'Severe Drowsiness',
          
        },
        activeFilters: {
          startDate: moment().subtract(30, 'days'),
          infraction :'Severe Drowsiness',
        },
        filterKeys : {
          'driver': {            
            //'defaultValue': this.props.defaultFilter.driver,
            'title': 'DriverID:',
            order:2,
          },
          'site': {
            'title': 'Site',
            order: 4
          }
        },
        opened: new Date(),
        unfulfilledPromises: [],

    };

    this.db = new Dexie('DriverPhoto');
    this.db.on("blocked", function() {
      console.log ("Database upgrading was blocked by another window. Please close down any other tabs or windows that has this page open");
    });
    this.db.on("ready", function() {
      // console.log ("ready Please close down any other tabs or windows that has this page open");
    });
    this.db.on("versionchange", function() {
      console.log ("Version change Please close down any other tabs or windows that has this page open");
    });
    
  }
 

  componentDidMount(){
    // console.log("Riskprofile mounted: ",this.props) 

    this.db.version(1).stores(
      { photos: "driverid" }
    )
    
    this.db.open().then(function (db) {
   //   // Database opened successfully
     //  console.log("Created db: ",db);
   //   
    }).catch (function (err) {
   //     // Error occurred
        console.log("Failed to open db: ",err);
    });    
    // this.db.photos.clear();//Don't clear on open
    //Check on the age of the photos:
    let isLocalPromise = this.db.photos.toArray();
    isLocalPromise.then(_data=>{
      // console.log("Returned array? ",_data);
      // console.log("Photo request returned: ",_data,_inParams.row.driverid)
      if(!_data){return;}

      //sort by date added:      
      _data.sort( (a,b) =>{   return a.date>b.date?-1:1  }); //sort in desc order
      
      //Make sure the array is not growing above 100 entries
      let maxArraySize = 100;
      let scanDate = _data.slice(0,maxArraySize);
      let autoDelete = _data.slice(maxArraySize);
      // console.log("Data: ",autoDelete);
      //Remove anything above the max array size
      autoDelete.forEach( entry_ =>{
        this.db.photos.where('driverid').equals(entry_.driverid).delete().then(_result=>{})
      });//end delete above index

      //Remove anything that hasn't been viewed for more than 7 days
      scanDate.forEach( entry_ =>{
        try {
          let timeElapse = new Date() - entry_.date;
          timeElapse = timeElapse /(1000*60*60*24);
          // console.log("Time elapsed: ",entry_.driverid,timeElapse);
          if(timeElapse>=7){
            this.db.photos.where('driverid').equals(entry_.driverid).delete().then(_result=>{
              // console.log("Deleted entry: ",entry_.driverid)
            })
          }
        } catch (error) {
        }
      });//end loop
    }); //end then processing
    isLocalPromise.catch(error => {
      console.log("Failed to fetch with error: ",error);
    })

  }
  
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
    // console.log("Got the call me")
     //Trigger the API above on unfullfilledPromises:
     const promises = this.state.unfulfilledPromises;
     promises.forEach(promise_=>{
       if(API.cancel(promise_, "Filters updated, abort current queries")){ //did we cancel the promise, if yes, then remove it from the unfulfilled list
         this.setState(prevState => {          
           const promises = prevState.unfulfilledPromises; 
           const index = (promises||[]).indexOf(promise_);
           if (index > -1) { // only splice array when item is found
             promises.splice(index, 1);
           }
           return{unfulfilledPromises:promises}
         });  
       }
     });
  }
 
  /*
  * @brief The definition of the API call that we need to do to display this list
  */
  getApiCall() {
    //  console.log("Calling API with: ",this.props,this.state.activeFilters)
    this.setState({top10:{}, top20:{}, totalInfractions:0,totalUnavailable:0})


    let filtersToPass = JSON.parse(JSON.stringify(this.state.activeFilters));

    //Don't allow the end date to be defined without the start date
    if(!filtersToPass.startDate && filtersToPass.endDate){      
      // console.log("No Start date: ");
      // let mTemp = this.props.groupconfig.group.toLowerCase()==='reviewgroup_beirut'?moment().add(-2, 'days'):moment().add(-7, 'days');
      let mTemp = moment().subtract(30, 'days');
      filtersToPass.startDate = mTemp.toISOString();
    }

    let mStartDate = moment(filtersToPass.startDate);
    let mEndDate = null;

    //Sanity check the dates:
    if(filtersToPass.startDate && filtersToPass.endDate){
      mEndDate = moment(filtersToPass.endDate);

      if(!mStartDate.isBefore(mEndDate)){
        // console.log("Dates are wrong: ",filtersToPass);
        //swap the dates
        let tmpVal = filtersToPass.endDate;
        filtersToPass.endDate = filtersToPass.startDate;
        filtersToPass.startDate = tmpVal;

        //Update the start and end dates in the moments
        mStartDate = moment(filtersToPass.startDate);
        mEndDate = moment(filtersToPass.startDate);

      }
    }
    // console.log("Pass filters:", filtersToPass);
    //Reset the filters states:
    this.setState({ activeFilters: JSON.parse(JSON.stringify(filtersToPass)),
                    selectedFilters: JSON.parse(JSON.stringify(filtersToPass)),
                    startDate:moment(filtersToPass.startDate),
                    endDate:filtersToPass.endDate?moment(filtersToPass.endDate):null,
                  });


    let apiPromise =  Auth.currentSession().then(
      (auth) => {
          this.setState({currentUsername: auth.idToken.payload['cognito:username'], authToken: auth.idToken.jwtToken});
          //Get the top 10 drivers - with photos:
          let apiName = "TrifectaAPI";
          let path = "/handleRiskProfile";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  mode:'fetchDrivers',  
                  count: 20,
                  filters: filtersToPass,
                  clientid: this.props.groupconfig.group,
              }
          };
          const top10Query =  API.post(apiName, path, myInit);
          
          
          //Get the total count:
          apiName = "TrifectaAPI";
          path = "/handleRiskProfile";
          myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  mode:'fetchTotal',  
                  filters: filtersToPass,
                  clientid: this.props.groupconfig.group,
              }
          };
          API.post(apiName, path, myInit)
          .then( _data =>{
            // console.log("Total return: ",_data)
            this.setState({totalInfractions: _data.total})
          })
          .catch(err => {console.log("error on return: ",err)});
          
          //Get the Unavaialble count:
          apiName = "TrifectaAPI";
          path = "/handleRiskProfile";
          myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  mode:'fetchUnavailable',  
                  filters: filtersToPass,
                  clientid: this.props.groupconfig.group,
              }
          };
          API.post(apiName, path, myInit)
          .then( _data =>{
            // console.log("Unavailable return: ",_data)
            this.setState({totalUnavailable: _data.total})
          })
          .catch(err => {console.log("error on return: ",err)});



          //Get the driver count:
          apiName = "TrifectaAPI";
          path = "/handleRiskProfile";
          myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  mode:'fetchDriverCount',  
                  filters: filtersToPass,
                  clientid: this.props.groupconfig.group,
              }
          };
          API.post(apiName, path, myInit)
          .then( _data =>{
            // console.log("Total driver return: ",_data)
            this.setState({totalDriverCount: _data.total})
          })
          .catch(err => {console.log("error on return: ",err)});

          //Return one value to updateData()
          return top10Query;

      });
    return apiPromise;
  }

  getDriverPhotos(_inParams,_returnFn){
    // console.log("Download photo for: ",_inParams.row.driverid)
    let idx =0;
    //Dispatch the update to the SQL table:
    const fetchPromise =Auth.currentSession().then(
        (auth) => {
            let apiName = "TrifectaAPI";
            let path = "/handleDriverInfo";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    driverid: _inParams.row.driverid,
                    index: idx++,
                    mode: 'fetch',              
                }
            };
            return API.post(apiName, path, myInit);
    })
    fetchPromise.then((_data)=>{
      
      //update the table data: add the photo:
      try {
        let photoData = null;
        if(_data.driverinfo){
          photoData = _data.driverinfo.photo;
          if(!photoData.includes('data:image')){//handle partial data in SQL
            photoData = 'data:image/jpeg;base64,'+_data.driverinfo.photo;
          }
        }else{
          console.log("No driverinfo? ",_data, _inParams.row.driverid)
        }

        _inParams.row.photo = photoData;

        //Add the photo to the local database:
        let addedPromise= this.db.photos.add({
            driverid : _inParams.row.driverid,
            photo: photoData,
            date: new Date()
        })
        addedPromise.then( result=> {
          // console.log("Added photo to db: ",result)
          _returnFn(_inParams);
        })
        addedPromise.catch(error => {
          console.log("Failed to added with error: ",error);
        })

        _inParams.caller = 'getDriverPhotos'; //don't want to enable a loop
        
      } catch (error) {
        console.log("Error: ",error);
      }
    });
  }
  /*
  * @brief Fetch the Driver photo, first check if the photo is in the local storage.
  */
  fetchDriverPhotos(_inParams,_returnFn){
    //Make an API call to download some photos:
    //Call in parallel to allow for quicker update to the render?
    let isLocalPromise = this.db.photos.where('driverid').equals(_inParams.row.driverid).toArray();
    isLocalPromise.then(_data=>{
      // console.log("Photo request returned: ",_data,_inParams.row.driverid)
      if(_data && _data.length>0){
        //Update the date access:
        this.db.photos.where('driverid').equals(_inParams.row.driverid).modify({date: new Date()});

        _inParams.row.photo =_data[0].photo; 
        _returnFn(_inParams);
        return;
      }
      if(_inParams && _inParams.caller && _inParams.caller==='getDriverPhotos'){return;}
      this.getDriverPhotos(_inParams,_returnFn)
    });
    // return fetchPromise;
  }

  /*
  * @brief Common return function shared by the fetch/getDriverPhotos
  */
  handlePhotoReturn(_data){
    // console.log("Returned data: ",_data,new Date() - this.state.opened);
    
    if(!_data){return;}
    //Get the radial data
    switch(_data.flag){
      case null:
      default:{console.log("no flag specified: ",)}break;
      case "photoonly":{
        this.setState(prevState => {
          const updateSet = prevState.top20;          
          const loadState = prevState.loadState;
          updateSet[_data.row.driverid] = Object.assign(updateSet[_data.row.driverid] || {}, _data.row);
          if(!loadState[_data.idx]){ loadState[_data.idx] = true;}
          loadState[_data.idx] = true;
          return {top20:updateSet, loadState: loadState}
        }, 
        // ()=>{console.log("Photo state updated: ",this.state.loadState)}
        ); 
        return; //stop the load chain, don't allow fall through
      }
      case "fastphoto":{
        this.setState(prevState => {
          const updateSet = prevState.top20;          
          const loadState = prevState.loadState;
          updateSet[_data.row.driverid] = Object.assign(updateSet[_data.row.driverid] || {}, _data.row);
          if(!loadState[_data.index]){ loadState[_data.index] = true;}
          loadState[_data.idx] = true;
          return {top20:updateSet, loadState: loadState}
        });           
      }

    }
    //If fall through allowed,then continue the loading chain.
    this.fetchDriverNotes(_data);
    
  }
  
   /*
    * @brief Fetch the data from the database given the driverid
    */
  fetchDriverNotes(_inParams){
    //Make an API call to download some photos:
    //Call in parallel to allow for quicker update to the render?
    // console.log("Request photo: ",_inParams);
    let idx =0;
    //Dispatch the update to the SQL table:
    const fetchPromise =Auth.currentSession().then(
        (auth) => {
          let apiName = "TrifectaAPI";
          let path = "/handleNote";
          let myInit = {
              body: {
                  token: auth.idToken.jwtToken,
                  driverid: _inParams.row.driverid,
                  type: 'riskprofile',
                  mode: 'fetch',              
              }
          };
          return API.post(apiName, path, myInit);
    })
    fetchPromise.then((_data)=>{
      
      try {
        if(_data.notes){            
          //Iterate over the returned SQL data, format it to match the display
          let notes  = (_data.notes||[]).map(note_=>{
              return({
                  text:note_.text,
                  timestamp:note_.created,
                  username:note_.username,
                  noteid:note_.noteid,
              })
          })
          // console.log("Comments: ",notes);
          _inParams.row.interventions = notes;
          
      }
        // //Get the radial data
        // this.fetchRadialData(_inParams);



      } catch (error) {
        console.log("Error: ",error);
      }
      

    });
    // return fetchPromise;
  }
  

  fetchRadialData(_driverids){
    //Make an API call to download some photos:
    // console.log("Fetch for: ",_driverids);
    this.setState({radialStart: new Date()});
    
    let authPromise = Auth.currentSession();
    authPromise.then((auth) => {      
      let apiName = "TrifectaAPI";
      let path = "/handleRiskProfile";
      let myInit = {
          body: {
              token: auth.idToken.jwtToken,
              mode:'fetchRadial',  
              filters: this.state.activeFilters,
              clientid: this.props.groupconfig.group,
              driverids: _driverids,
          }
      };
      try {
        const apiPromise = API.post(apiName, path, myInit)
        //Record the promise in the list of waiting to complete
        this.setState(prevState => {
          const promises = prevState.unfulfilledPromises;
          promises.push(apiPromise);
          return{unfulfilledPromises:promises}
        });  

        apiPromise.then( _data =>{
          // console.log("Radial Response: ",_data);
          //remove the fulfilled promise from the list:
          try {
            this.setState(prevState => {
              // console.log("Promises: ",prevState.unfulfilledPromises)
              const promises = prevState.unfulfilledPromises;
              const index = (promises||[]).indexOf(apiPromise);
              if (index > -1) { // only splice array when item is found
                // console.log("Found the promise: ",index)
                promises.splice(index, 1);
              }
              return{unfulfilledPromises:promises}
            });  
          } catch (error) {
            console.log("Then error: ",error);
          }

          // console.info("Time to radial data: ",new Date() - this.state.radialStart);
          //  console.log("Radial return: ",_data,_driverids)
          try {
            if(_data.radials){
              let quantSize = 30;
              let quantStep = 60/quantSize; //60/10 -
              let xLabels = [];
              let xLabels2 = [];
              for(let i=0; i<24; i++){
                for(let j=0; j<quantStep; j++){
                    if(j === 0){ //set the label on the hour
                        xLabels.push(   String(i).padStart(2, '0')+':00');
                    }else{ //and space holder, need to match labels to data
                        xLabels.push('');
                    }
                    xLabels2.push('');
                }
              }    
              let hourSet = {};        
              let minutesVal =0;
              for(let i=0; i<24; i++){
                  for(let j=0; j<quantStep; j++){
                      minutesVal = i*60 + j*quantSize;
                      hourSet[minutesVal] = { count:0 }
                  }
              }
              let updateSet = {};
              // console.log("Split on driverids:" ,_driverids);
              //Split the returned data based on driverids:
              _driverids.forEach( driverid_ =>{
                let radialData = _data.radials.filter( entry_=>{ return entry_.driverid === driverid_});
                // if(!radialData){return;}
                try {
                  // console.log("Radial data: ",radialData,driverid_)
                  let radial= {labels:xLabels, thumblabels: xLabels2};
                  let infractionsByHour=[];
                  let maxCount = 0;
                  //cycle through each quantized row
                  (radialData||[]).forEach( radial_ => {
                    
                      // console.log("Process radial data: ",radial_)
                    //Create the labels, only set the label on the hour

                    //Handle the counts, Create an entry for every quantized point
                    try {
                      // console.log("Process the radial: ",radial_, radial_.timehour);
                      // for (const [type_, value] of Object.entries(radial_)) {
                        //  console.log("type,val: ",type_,value,value.timehour);
                          // Convert time to minutes:
                      let splitParts = radial_.timehour.split(":");
                      let typeVal = 0;
                      
                      typeVal = parseInt(splitParts[0],10)*60 + parseInt(splitParts[1],10); //convert to minutes
                      //  console.log("Time: ",typeVal,splitParts);
                      try {
                          hourSet[typeVal] = hourSet[typeVal] || { count:0 }
                          if(radial_ && radial_.countsum){
                              hourSet[typeVal].count=parseInt(radial_.countsum,10);
                              maxCount = Math.max(maxCount,radial_.countsum)
                          }
                          // console.log("Type and value: ",type_,typeVal,value,splitParts);    
                      } catch (error) {
                          console.log("Failed: ",error,radial_);
                      }
                      
                      // console.log("Max Value: ",_inParams.row.radial.maxValue, maxCount)
                    } catch (error) {
                      console.log("Failed on process: ",error);
                    }
                    
                  //  console.log("Updating: ",radial_.driverid, radial,updateSet[radial_.driverid]);
                  });
                  // }
                  //  console.log("Keys: ",Object.keys(hourSet))
                  //  console.log("HoursSet: ",hourSet);
                  let iCount = 0;
                  // let countMax = 0;
                  for (const key of Object.keys(hourSet)) {
                      infractionsByHour.push(hourSet[key].count)
                      iCount++;
                  }
                  // console.log("infraction by hour: ",infractionsByHour);
                  radial.infractionsbyhour = infractionsByHour;
                  radial.maxValue = maxCount;

                  if(maxCount > 100){   radial.maxValue = Math.round(maxCount / 10) * 10;}
                  if(maxCount > 150){   radial.maxValue = Math.round(maxCount / 50) * 50;}        
                  if(maxCount <= 100){  radial.maxValue = Math.round(maxCount / 5) * 5;}
                  if(maxCount < 10){  
                    radial.maxValue =Math.ceil(maxCount / 5) * 5; //force to a multiple of 5 to make sure we get no decimals            
                  }

                  // console.log("Row:", _inParams, _inParams.row.driverid, top20);
                  updateSet[driverid_] = Object.assign(updateSet[driverid_] || {}, {radial:radial});  
                } catch (error) {
                  console.log("Error: ",error);
                }
              });
              // console.log("Update set:" ,updateSet);
              //Add the radial data to the state variable
              this.setState(prevState => {
                const stateUpdate = prevState.top20;
                _driverids.forEach( driverid_ =>{
                  // console.log("Update driver: ",stateUpdate[driverid_])
                  stateUpdate[driverid_] = Object.assign(stateUpdate[driverid_] || {}, {radial:updateSet[driverid_].radial});  
                });
                return {top20: stateUpdate};
              }
              // , console.log("Updated state? ",this.state.top20)
              )//end set state call
            }
          } catch (error) {        }
        });//end then processing on promise
        apiPromise.catch(err => {
          if (API.isCancel(err)) {
            console.log("API canceled: ", err.message); // "my message for cancellation"
            // handle user cancellation logic
          }else{console.log("error on return: ",err)}
          return;
        });
        
      } catch (error) {
        console.log("error: ",error);
      }
        
    })
    authPromise.catch(err=>{
      console.log("Auth error2? ",err)
    });//end the auth return
  }

  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(data) {
    // console.log("Risk Data: ",data);
    // console.info("Top20 drivers returned: ",new Date() - this.state.opened);
    try {
      let driverids = [];
      if(data && data.drivers){ //process the list of top drivers
        //Iterate over each call from the SQL table
        let driverData = data.drivers.map((row,idx) => {
          //Add additional easy of use values to the driver data:
          row.infcount = parseInt(row.infcount,10);
          row.idx = idx;
          row.score = row.score || (data.drivers.length-idx);    
          // let percentage = 1- row.score/10;
          row.percentage = 1 - row.score/data.drivers.length;
          row.percentage+= 0.01;
          // console.log("Set percentage: ",row.driverid,row.percentage, row.score, data.drivers.length);
          row.key = row.driverid+'-'+row.infcount+'-'+idx;


          this.setState(prevState => {
            const updateSet = prevState.top20;
            // console.log("Row:", _inParams, _inParams.row.driverid, top20);
            updateSet[row.driverid] = Object.assign(updateSet[row.driverid] || {}, row);

            // console.log("Top 10: ",getTopNum(updateSet,10));
            // return {top20:updateSet};
             return {top20:updateSet,top10:getTopNum(updateSet,10)} //the top10 will keep the link to the top20, since we are doing shallow copies. 
            
          }); 

          // setTimeout(() => {
            this.fetchDriverPhotos({idx: idx, row: row,flag:'photoonly', updateName: 'top20'},this.handlePhotoReturn);  
          // }, 15*idx);

          return row;
        });
        let driverids = driverData.map(row=>{return row.driverid});
        
        let batchSize = 10;
        let driverIDGroups = Array.from({ length: Math.ceil(driverids.length / batchSize) }, (value, idx) => { return driverids.slice(idx * batchSize, idx * batchSize + batchSize)});
        // console.log("driverIdGroups: ",driverIDGroups)
        //Trigger a radial map query:
        // console.info("Start radial fetch: ",new Date() - this.state.opened);
        try {
          (driverIDGroups||[]).forEach(_group=>{
            // console.log("Trigger fetch radial",this.fetchRadialData(_group))
              this.fetchRadialData(_group);
            });
        } 
        catch (error) {console.log("Fail on group: ",error)}
      }

    } catch (error) {
      
    }
    // return;

    try {
      //Pass the data from to populate the available filters
      this.updateFilters(data.data);
    } catch (error) {
      // console.log("Failed to ",error);
    }
    this.setState({apiReturned:true})
  }
 
  updateFilters(_data) {
    // console.log("Load filter update: ",_data);
    const filters = {...this.state.filters}
    // console.log("Loaded filters: ",filters,this.state.filters,{...this.state.filters});
    try {
      // console.log("Returned sites: ",this.props,_data);
      filters['site'] = filters['site'] || new Set();      
      for (var row_ of (_data||[])) {        
          if(row_.site){
            //Match against the sitedetails list:
            try {
              if(this.props.possibleFilters.Sites){
                let siteDetail = (this.props.possibleFilters.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === row_.site.toLowerCase()})
                // console.log("Site detail: ",siteDetail,siteDetail[0].cloudtype);
                if(siteDetail ){
                  if(!siteDetail[0].cloudtype){                  filters['site'].add(siteDetail[0]);}
                  else if(siteDetail[0].cloudtype==='Trifecta'){ filters['site'].add(siteDetail[0]);} //if the cloudtype is avilable, only allow Trifecta sites
                }
              }  
              else{//Filters have not been supplied by the login, just add the ones from the results as a backup?
                filters['site'].add({site: row_.site, alias: row_.site});
              }
            } catch (error) {
            }
            
          }
          
      }
      //Add the infraction types:
      const INFRACTION_FILTER_FIXED_OPTIONS =
      [
          {text: "All", value: "no-infraction-selected"},
      ];
        
      const possibleInfractions = (this.props.groupconfig.infractionTags || []).map(tag_ => {
          return {text: tag_.type, value: tag_.type};
      });
      //Get the list of alerts to display from the groupconfig alerttypes
      const possibleAlerts = (this.props.groupconfig.alerttypes || []).map(tag_ => {
          return {text: tag_, value: tag_};
      });

      //Add the drivers?
      const possibleDrivers = (this.props.possibleFilters.DriverID || []).map(driverID => {
        return {text: driverID, value: driverID};
      });
      let driverOptions = DRIVER_FILTER_FIXED_OPTIONS.concat(possibleDrivers);
      //Check if the single driverid is set (this is for operator level access)
      if(this.props.groupconfig.driverid){
          driverOptions =[];
          driverOptions.push({text: this.props.groupconfig.driverid, value: this.props.groupconfig.driverid});
      }
      const filterKeys = this.state.filterKeys;
      filterKeys.driver.options = driverOptions;
 
      filters.driver= new Set((driverOptions||[]).map(elem_ => {return elem_.value}));

      // filters.site = (this.props.possibleFilters.Sites || []).map(site_ => {
      //   return {text: site_.alias, value: site_.site};
      // });
      
      if(this.props.possibleFilters.Sites){        
        this.props.possibleFilters.Sites.forEach( site_=>{
          if(!site_.cloudtype){                  filters['site'].add(site_);}
          else if(site_.cloudtype.includes('Trifecta')){ filters['site'].add(site_);} //if the cloudtype is avilable, only allow Trifecta or DriverID sites
          else if(site_.cloudtype.includes('DriverID')){ filters['site'].add(site_);} 
        });
      }else{filters.site = this.props.possibleFilters.Sites;}
      
      // console.log("Filters to update:",filters)
      this.setState({filters:filters, filterKeys: filterKeys});


    } catch (error) {
      console.log("Failed to fetch site? ",error);
    }
  }


  applyFilters(){
    // console.log("Apply button pressed: ",this.state.selectedFilters)
    //cancel the timeout:
    if(this.state.refreshTimeoutID){clearTimeout(this.state.refreshTimeoutID);}
    //set the selected filters to active:
    // console.log("Apply filters:", JSON.parse(JSON.stringify(this.state.selectedFilters)))

    //Trigger the API above on unfullfilledPromises:
    const promises = this.state.unfulfilledPromises;
    promises.forEach(promise_=>{
      if(API.cancel(promise_, "Filters updated, abort current queries")){ //did we cancel the promise, if yes, then remove it from the unfulfilled list
        this.setState(prevState => {          
          const promises = prevState.unfulfilledPromises; 
          const index = (promises||[]).indexOf(promise_);
          if (index > -1) { // only splice array when item is found
            promises.splice(index, 1);
          }
          return{unfulfilledPromises:promises}
        });  
      }
    });

    //Create the return object to return
    let stateObj = {
      activeFilters:JSON.parse(JSON.stringify(this.state.selectedFilters)),retryCount: this.state.retryCount+1, totalDriverCount:null,
      top10:{}, top20:{},totalInfractions:0,totalUnavailable:0,opened:new Date(),apiReturned:false, tabViews: this.state.tabViewsAll,
      loadState:Array(20).fill(false),
    }

    //Update the set of tabs based on the filter search
    if(this.state.selectedFilters.driver){ //if there is a driver seletected, then don't show the Statistics tab
      stateObj.tabViews = this.state.tabViews.filter( view_ => view_.name !== 'Statistics');
      stateObj.activeView = 'worst'; //reset the active tab view      
    }
    //Pass the object to the state
    this.setState(stateObj);
  }

  onFilterSelected(name, value){
    // console.log("Filter selected: ",name, value)
    try{
      let toSet = value.value;
      switch(name){
          case 'asset':{
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{asset: toSet})});
          }break;
          case 'infraction':{
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{infraction: toSet})});
          }break;
          case 'hwtags':{
            if(value.value === 'All'){toSet=null}
            this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{hwtags: toSet})});
          }break;
          case 'driver':{
              if(value.value === 'no-driver-selected'){toSet=null}
              this.setState({selectedFilters: Object.assign(this.state.selectedFilters,{driver: toSet})});
          }break;
          case 'date':{ //handle changes to either start or end
            let start_date = value.value.start;
            let end_date = value.value.end;
            let datesToSet = {startDate: start_date, endDate: end_date,selectedFilters: Object.assign(this.state.selectedFilters,{startDate: start_date, endDate: end_date})}
            this.setState(datesToSet);
          }break;
          
          case 'site':{
            if(value.value === 'All'){toSet=null}
            //Update the options in the driverid drop-down based on the site:
            let driverOptions = this.state.allDrivers;
            try {
              if(toSet){
                  driverOptions = [...this.state.allDrivers].filter(value_ => {
                                  try {
                                      if(!value_){return false;}
                                      if(!value_.site){return false;}
                                      return value_.site.toLowerCase() === toSet.toLowerCase()
                                    } catch (error) {return false;}
                                  });
              }  
            } catch (error) {
              // console.log("Failed to filter: ",error);
            }

            console.log("Drivers set: ",driverOptions.length)
            //Need a placeholder that can be removed in the render step
            if(driverOptions.length===0){
               driverOptions.push( {driverid: 'TOOBIG', });
            }

            let objToSet = {
              selectedFilters: Object.assign(this.state.selectedFilters,{site: toSet}),
              filters: Object.assign(this.state.filters,{driver: new Set(driverOptions)}),
              filterUpdateTime: new Date(),
            }
            // console.log("Filters update: ",driverOptions, this.state.allDrivers,objToSet)
            this.setState(objToSet);
          }break;              
      }//end switch
  }catch(e){
      console.log("Error with filters: ",e);            
  }
  }

  /* @brief The driver data was clicked, need to open the notecard with the data displayed large
  */
  onClick(_data){
    const driverCard={
      driverid: _data.driverid,
      photo: _data.photo,
      infractiontype: this.state.activeFilters.infraction,
    }
    if(_data.radial && _data.radial.infractionsbyhour&& _data.radial.labels){
      driverCard.infractionsbyhour= _data.radial.infractionsbyhour;
      driverCard.labels= _data.radial.labels;
    }
    // console.log("On click: ",_data,driverCard )
    this.setState({driverCard: driverCard});
  }

  
  
  
  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {

    let noDataMessage = false;
    if(this.state.top20){
      const tmpArray = Object.values(this.state.top20);      
      if(tmpArray.length===0 && this.state.apiReturned){
        noDataMessage = <div className='nodata'>No data available, please update the filter selection</div>;
      }
    }

    let bRenderStats = true;
    if(this.state.activeFilters.driver){
      bRenderStats = false;
    }
    if(this.state.totalInfractions === 0){
      bRenderStats = false;
    }


    // console.log("RenderPies: ",bRenderStats, this.state);
    
    return (
      <div className="riskprofile">
        {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} retryCount = {this.state.retryCount} />:null}

        <div className='toprow'>
          <div className="viewTabs">
              <Tabs className='viewSelection'
                  defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                  id='uncontrolled-tab-example' 
                      activeKey={this.state.activeView} 
                      onSelect={(k)=> this.setState({activeView:k})}
              >
                  {this.state.tabViews.map((type) => {
                      return(  <Tab key={type.key} eventKey={type.key} title={type.name}/> )
                  })} 
              </Tabs>
          </div>

          <CallinFiltersView  className = "callin-filters" filters={this.state.filters} onFilterSelected={this.onFilterSelected} onApply={this.applyFilters} 
                              // hasChanged={!this.state.waitingForDownload && this.state.filtersChanged} 
                              startDate={this.state.startDate} endDate={this.state.endDate} 
                              filterKeys={this.state.filterKeys} modifiedTime = {this.state.filterUpdateTime}
          />
        </div>
        <div className='riskcontent'>
          {this.state.activeView==='worst' && 
                <RiskWorst className = "riskworst"
                    driverData= {this.state.top10}
                    possibleFilters = {this.state.possibleFilters}
                    activeFilters = {this.state.activeFilters}
                    groupconfig = {this.props.groupconfig}
                    noteChange = {(_data)=>{
                      // console.log("Card closed: ",_data);
                      // this.setState({radialCard:null})      
                      this.setState(state => {
                        const top20 = state.top20;
                        top20[_data.driverid].interventions = _data.notes;
                        return {top20:top20}
                      });                                      
                    }}                  
                    onClick= {this.onClick}
                    apiReturned = {this.state.apiReturned}
                    noDataMessage = {noDataMessage}
                    loadState = {this.state.loadState}
                />
            }
            {this.state.activeView==='stats' && 
                <RiskStats className = "riskstats"
                    driverData= {this.state.top10}
                    possibleFilters = {this.state.possibleFilters}
                    activeFilters = {this.state.activeFilters}
                    groupconfig = {this.props.groupconfig}
                    noteChange = {(_data)=>{
                      // console.log("Card closed: ",_data);
                      // this.setState({radialCard:null})      
                      this.setState(state => {
                        const top20 = state.top20;
                        top20[_data.driverid].interventions = _data.notes;
                        return {top20:top20}
                      });                                      
                    }}                  
                    totalInfractions= {this.state.totalInfractions}
                    totalUnavailable = {this.state.totalUnavailable}
                    top20 = {this.state.top20}
                    top10 = {this.state.top10}
                    totalDrivers = {this.state.totalDriverCount}
                    onClick= {this.onClick}
                    apiReturned = {this.state.apiReturned}
                    noDataMessage = {noDataMessage}
                    loadState = {this.state.loadState}
                />
            }

            { this.state.driverCard &&
                <CombinedCard 
                    handleClose={(_data)=>{
                      // console.log("Card closed: ",_data);
                      // this.setState({radialCard:null})      
                      this.setState(state => {
                        // return {radialCard:null,refreshCount: state.refreshCount+1}
                        return {driverCard:null}
                      });                                      
                    }}
                    {...this.state.driverCard}
                    groupconfig = {this.props.groupconfig}
                    possibleFilters = {this.props.possibleFilters}
                    updatedRadial = {(Object.values(this.state.top20)||[]).filter(
                                          elem_ => {
                                            if(this.state.driverCard && elem_.driverid!==this.state.driverCard.driverid){return false}
                                            if(elem_.radial){return true}
                                            return false;
                                          })}
                    cardChange={this.props.cardChange}
                    eventNotify={this.props.eventNotify}                    
                />
            }

        </div>
        

        
        
       
      </div>
    );
  }
}

export {RiskProfile}
