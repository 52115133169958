
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';

import './UserManagementCard.css';

import { Multiselect} from 'react-widgets';
import { Dropdownlist } from '../Wrappers/Dropdownlist';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import 'react-widgets/styles.css';

//Add the icon files
import helpIcon from '../assets/help.svg'
import rolesBis  from '../assets/userroles-bis.PNG';
// import rolesPVM  from './assets/userroles-pvm.PNG';


// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;

/*
* @brief This is an modal dialog to be opened when addding or editing a user
*/
export class UserManagementCard extends PureComponent {
    constructor(props) {
        super(props);
        this.handleUsernameUpdate = this.handleUsernameUpdate.bind(this);
        this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        // this.handleCommentEdit = this.handleCommentEdit.bind(this, 'comment');

        this.onCheck = this.onCheck.bind(this);
        this.handleToggles = this.handleToggles.bind(this);  


        this.state = {
            shown: false,
            timestamp:null,
            // sitelist:(this.props.sites||[]).filter(site_=>{return site_.length>2?true:false}),
            // allowedSites:(this.props.possibleFilters.Sites||[]).map(site_=>{return site_.site}),
            sitelist:[],
            allowedSites:[],
            allowedRoles: [...this.props.groupconfig.roles].sort(),
            groupList: this.props.userInfo.allowedGroups,
            group:null,
            role:this.props.role,
            userName:this.props.Username,
            email:this.props.email,
            knownUserNames:[],
            usernameConflict: false,
            knownUserEmails:[],
            emailConflict:0,
            mode: this.props.Username?"edit":"create",
            showRolePopup:false,
            mInfractionList:[],
            mBehaviorList:[],
            mTelematicList:[],
            mNotificationList:[],
            tabViews: ['Behavior Analytics','Telematics'],
            activeView: 'Behavior Analytics',
            mBHasSpeedPolicy:false,
        };
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        console.log("Open user: ",this.props);
        window.setTimeout(() => {
            this.setState({shown: true});
        }, EXPANDED_CARD_SHOW_LATENCY);
        
        //Get the list of known users and email addresses
        let usernames = (this.props.knownUsers||[]).map(user_=>{
            return user_.Username.toLowerCase();
        })
        let emails = (this.props.knownUsers||[]).map(user_=>{
            return user_.email.toLowerCase();
        })

        //Get the set of infractions and alerts for emails
        const possibleInfractions = (this.props.groupconfig.infractionTags || []).map(tag_ => {
            return {text: tag_.type, value: tag_.type};
        });
        //Get the list of alerts to display from the groupconfig alerttypes
        const possibleAlerts = (this.props.groupconfig.alerttypes || []).map(tag_ => {
            return {text: tag_, value: tag_};
        });
        const infractionOptions = possibleInfractions.concat(possibleAlerts);
        // const infractionOptions = possibleInfractions;
        
        let infractionList = (infractionOptions||[]).map(value_=>{
            return value_.value;
        })
        infractionList = (infractionList||[]).filter(item => item !== 'Irrelevant')
        //Add new types for the telematics
        infractionList.push('Speeding+Time');
        infractionList.push('Speeding Categories');
        
        //Split the infractions into two groups:
        let reservedTelematics = ['OtherHighG', 'Sudden Stop', 'Speeding', 'Speeding+Time', "Speeding Categories","Speeding+Time","Speeding Categories"];
        let behaviorList = (infractionList||[]).filter(item => item !== 'Irrelevant' && !reservedTelematics.includes(item))
        let telematicList = (infractionList||[]).filter(item => item !== 'Irrelevant' && reservedTelematics.includes(item))
        

        
        // console.log("Options: ",infractionList,this.props.groupconfig.infractionTags)
        // console.log("Loaded names:" ,usernames);

        //Extract the notificationlist:
        let notificationlist = [];
        try {
            if(this.state.mode === 'create'){
                notificationlist.push('Severe Drowsiness');
            }else{ //editing an existing user, load the known notifications
                if(this.props.notifications){
                    if(this.props.notifications[0].notificationlist){

                        let inputList = this.props.notifications[0].notificationlist;
                        //Convert the notifications for speeding to the display format:
                        inputList = inputList.replace("SpeedingLimit","Speeding");
                        inputList = inputList.replace("SpeedingTime","Speeding+Time");
                        inputList = inputList.replace("SpeedingPolicy","Speeding Categories");
                        console.log("Input list: ",inputList,this.props.notifications[0].notificationlist)
                        notificationlist = inputList.split(',');
                        // notificationlist = this.props.notifications[0].notificationlist.split(',');
                        
                        
                    }
                    
                }    
            }
            
        } catch (error) {console.log("Error in list process: ",error)}
        
        // console.log("Load set: ",notificationlist);

        //Get possible sites:
        // let siteSet = new Set(['All',...(this.props.possibleFilters.Sites||[]).map(site_=>{return site_.site})]);
        let siteSet = new Set([{site:'All',alias:"all"},...(this.props.possibleFilters.Sites||[])]);
        
        //Get current sites
        let siteList = (this.props.sites||[]).filter(site_=>{return site_.length>2?true:false})
        if(siteList.length===0){
            siteList.push('All');
        }

        // console.log("Sitelist: ",siteList)
        //Compare the site set against the site details, check if any speed policies are set:
        let hasSpeedPolicy = (this.props.siteDetails||[]).filter( siteDetail_ =>{
            //Compare against the site set:
            if(siteList.includes(siteDetail_.site) || siteList.includes('All')){
                // console.log("Check site: ",siteDetail_);
                if(siteDetail_.speedpolicies && siteDetail_.speedpolicies.length>0){return true;}
                else{return false}
            }
            else{return false;}
        })
        // console.log("HasSpeedPolicy: ",hasSpeedPolicy);
        if(hasSpeedPolicy && hasSpeedPolicy.length>0){
            this.setState({mBHasSpeedPolicy:true})
        }
        


        console.log("Possible site: ",siteList,siteSet);
        this.setState({knownUserNames:usernames, knownUserEmails:emails,mInfractionList: infractionList,mBehaviorList: behaviorList, mTelematicList: telematicList,
            mNotificationList:notificationlist,sitelist:siteList,allowedSites:[...siteSet]})
    }
    
   
    /*
    * @brief handle the username field update request
    */
    handleUsernameUpdate(_data){
        if(this.state.mode=='edit'){return;}
        let updatedUserName = _data.target.value;
        //Test against the known users:
        let bConflict = false;
        if(this.state.knownUserNames.includes(updatedUserName.toLowerCase())){
            bConflict = true;
        }
        //update the tracked state:
        this.setState({userName:updatedUserName, usernameConflict:bConflict});
    }//end handleUsernameUpdate
    /*
    * @brief handle the email field update request
    */
    handleEmailUpdate(_data){
        // console.log("Update: ",_data);
        let iConflict = 0;
        if(_data===true){ //is the the validate once the field is done editing?
            //define a regexp to test for valid email addres, looking for a . following a @            
            const regExp = new RegExp(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/);
            if(!regExp.test(this.state.email)){
                this.setState({emailConflict:2});
            }
            
        }else{
            if(this.state.mode=='edit'){return;}
            let updatedEmail = _data.target.value;
            //Test against the known users:
            if(this.state.knownUserEmails.includes(updatedEmail.toLowerCase())){
                iConflict = 1;
            }
            //update the tracked state:
            this.setState({email:updatedEmail, emailConflict:iConflict});
        }
        
    }//end handleEmailUpdate
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){
        let returnData = {};        
        //collect the data from the card
        //package into object
        returnData.Username = this.state.userName;
        returnData.email = this.state.email;

        returnData.role = this.state.role? this.state.role: null;
        // let sites = (this.state.sitelist||[]).filter(site_=> site_!='All'); //return all the entries that don't include All

        let siteNoAll = (this.state.sitelist||[]).filter(site_=> site_!='All'); //return all the entries that don't include All
        let sites = (siteNoAll||[]).map(site_=> { return site_.site? site_.site: site_ }); //return the site names, not the alias names
        // console.log("Site to return: ",sites)

        //Convert the display names to expected type names:        
        // let notificationsString = Array.isArray(this.state.mNotificationList)?this.state.mNotificationList.join(','):this.state.mNotificationList;
        
        let notificationArray = this.state.mNotificationList.map(elem_=>{
            switch(elem_){
                case 'Speeding': return 'SpeedingLimit';
                case 'Speeding+Time':return 'SpeedingTime';
                case 'Speeding Categories':return 'SpeedingPolicy';
                default:
                    return elem_
            }
        })
        let notificationsString = notificationArray.join(',');
        // console.log("Submit: ",notificationsString,this.state.mNotificationList)


        returnData.sites = sites? sites.join(','):null; //return as a csv
        returnData.group = this.state.group;
        // returnData.notificationList = this.state.mNotificationList;
        returnData.notificationList = notificationsString.length>0?notificationsString.split(','):[];
        returnData.preferredemail = this.state.email;

        //return to the list
        if(this.props.handleSubmit){this.props.handleSubmit(returnData,this.state.mode);}

    }//end handleSubmit
    /*
    * @brief handle closing the card with submit
    */
    handleCancel(_data){
        //return to the list
        if(this.props.handleClose){this.props.handleClose();}

    }//end handleSubmit

    /*
    * @brief Helper method to handle the checkbox changin
    */
    onCheck(_data){

         console.log("Data: ",_data.target.value,this.state.mNotificationList);

        //Use a set to make sure that things don't get replicated:
        let notificationSet = new Set(this.state.mNotificationList);
        //Check if already marked:
        if(this.state.mNotificationList.includes(_data.target.value)){
            notificationSet.delete(_data.target.value);
        }else{ //add it to the list
            notificationSet.add(_data.target.value);
        }
        
        console.log("Notification list: ",[...notificationSet]);
        this.setState({mNotificationList: [...notificationSet]});
    }
    /*
    * @brief select/deselect all of the checkboxes
    */
    handleToggles(_data){
        // console.log("Data: ",_data);
        switch(_data){
            case 'enable':{
                this.setState({mNotificationList:this.state.mInfractionList});                
            }break;
            case 'disable':{
                this.setState({mNotificationList:[]});
            }break;
        }
    }//end handleToggles
    
    
    /*
    * @brief Render the content of the card:
    */
    render() {
        
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        
        //Test the fields for reserved values:
        let userNameClassName = "entry-field-wide";
        let userNameConflictMessage = null;
        if(this.state.usernameConflict){
            userNameClassName+="-conflict"
            userNameConflictMessage = "Username not unique";
        }
        let emailClassName = "entry-field-wide";
        let emailConflictMessage = null;
        if(this.state.emailConflict>0){
            console.log("Email conflict: ",this.state.emailConflict);
            emailClassName+="-conflict"
            if(this.state.emailConflict===2){
                emailConflictMessage = "Invalid email address";
            }else{
                emailConflictMessage = "Email address not unique";
            }
            
        }

        let roleConflictMessage = null;
        if(this.state.mode === 'create' && this.state.allowedRoles.length > 0 && (!this.state.role || (this.state.role && this.state.role.length < 3))){
            roleConflictMessage = "Please specify a role"
        }
        
        let bShowGroup = false;
        if(this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length > 0){bShowGroup = true;}


        //Add checkboxes for notifications:
        //---------------------------------------------
        //Split the boxes into two groups:
        // let behaviorList = (this.state.mInfractionList||[]).filter( elem_ =>{console.log("elem_: ",elem_)})


        let activeList = this.state.mBehaviorList;
        if(this.state.activeView==='Telematics'){
            activeList = this.state.mTelematicList;
        }


        const renderButton = (type) => {
            
            if(type==='Speeding Categories'){
                if(this.state.mBHasSpeedPolicy){
                    return(
                        <div className='notificationElem'> 
                            <input className= 'notifyCheck' type="checkbox"  value={type} onChange={this.onCheck} checked={this.state.mNotificationList.includes(type)}/>
                            <label className= 'notifyText'> {type} </label>
                        </div>
                    )
                }else{
                    return(
                        <div className='notificationElem'> 
                            <input className= 'notifyCheck' type="checkbox"  value={type} onChange={this.onCheck} checked={this.state.mNotificationList.includes(type)} disabled/>
                            <label className= 'notifyText' disabled> {type}  </label> 
                        </div>
                    )
                }
            }
            else{
                return(
                    <div className='notificationElem'> 
                        <input className= 'notifyCheck' type="checkbox"  value={type} onChange={this.onCheck} checked={this.state.mNotificationList.includes(type)}/>
                        <label className= 'notifyText'> {type} </label>
                    </div>
                )
            }
        };

        let notificationSet = 
            <div className='notificationSection'>

                <div className="title">Notification Settings</div>

                <div className="notifTabs">
                    <Tabs className='notificationTabs'
                            defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                            id='uncontrolled-tab-example' 
                                activeKey={this.state.activeView} 
                                onSelect={(k)=>{
                                    console.log("Selected: ",k);
                                    this.setState({activeView:k})}
                                }
                        >
                            {this.state.tabViews.map((type) => {
                                return(  <Tab key={type} eventKey={type} title={type}/> )
                            })} 
                    </Tabs>
                </div>


                
                <div className="toggles">
                    <button className="enableAll" 
                         onClick={()=>{this.handleToggles('enable')}}
                    >Enable All</button>
                    <button className="disableAll" 
                        onClick={()=>{this.handleToggles('disable')}}
                    >Disable All</button>

                </div>
                
                                    
                <div className='notificationlist'>
                    {activeList.map((type) => {
                        return( 
                            renderButton(type)
                        )
                    })} 
                </div>
            </div>
        return (
            <div className="usermanagement-card" style={style}>

                <ReactModal isOpen={this.state.shown} className="modal-dialog usermanagement-card-modal"
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="usermanagement-modal" style={style} >

                        <div className = 'um-title'>                                                  
                            {this.state.mode === 'create'?"Create New User":this.state.userName+" User Details"}
                        </div>

                        <div className="um-spec">
                            <div className="title-wide">Username</div>
                            <input type="text" id="usernameInput" autocomplete="off" className={userNameClassName}
                                value={this.state.userName}
                                onChange = {this.handleUsernameUpdate}
                                disabled={this.state.mode==='edit'?true:false}
                            />
                            {userNameConflictMessage && <div className="title-wide-error">{userNameConflictMessage}</div>}
                        </div>
                        <div className="um-spec">
                            <div className="title-wide">Contact</div>
                            <input type="text" id="emailInput" autocomplete="off" className={emailClassName}
                                value={this.state.email}
                                onChange = {this.handleEmailUpdate}
                                disabled={this.state.mode==='edit'?true:false}
                                onBlur={()=>this.handleEmailUpdate(true)}
                            />
                            {emailConflictMessage && <div className="title-wide-error">{emailConflictMessage}</div>}
                        </div>
                        
                        {(this.props.groupconfig.roles.length>0 && this.props.userInfo.role.includes("Admin")) &&
                            <div className="um-spec">
                                <img className='icon' src={helpIcon} onClick={()=>{
                                    console.log("Click on help");
                                    this.setState({ showRolePopup: true});
                                }}/>
                                <div className="title-wide">Role</div>                         
                                <Dropdownlist 
                                    className="entry-field-wide"
                                    data={this.state.allowedRoles}
                                    value={this.state.role}
                                    placeholder ='Choose Role...'
                                    onChange={(value) => { (value==='CLEAR'||!value)?this.setState({role: ' '}):this.setState({role: value.value})}}
                                />
                                {roleConflictMessage && <div className="title-wide-error">{roleConflictMessage}</div> }
                            </div>
                        } 

                        <div className="um-spec-tall">
                            <div className="title-wide">Sites</div>
                            <Multiselect className="entry-field-wide"
                                data={this.state.allowedSites}
                                textField='alias'
                                value={this.state.sitelist}
                                onChange = {(value)=>{
                                    // console.log("Value:" ,value);
                                    //If All was the last selected
                                    let lastSelected = null;
                                    try {
                                       lastSelected = value[value.length-1]; //get the last selected item
                                    } catch (error) {}
                                    // console.log("last selected: ",lastSelected);
                                    if(lastSelected === 'All' || value.length===0){
                                        // console.log("Set all")
                                        this.setState({sitelist: ['All']})
                                    }else{
                                        let sites = (value||[]).filter(site_=> site_!='All'); //return all the entries that don't include All                                        
                                        this.setState({sitelist: sites})
                                    }
                                }}
                                placeholder="ALL"
                            />
                        </div>

                        {notificationSet}

                        <div className="um-footer">
                            <div className="adding-buttons">
                                <button className="btn btn-danger" 
                                     onClick={this.handleCancel}
                                >Cancel</button>
                                 
                                {/* {enableButton} */}

                                {(this.state.emailConflict===0 && !this.state.usernameConflict && !roleConflictMessage) &&
                                    <button className="btn btn-primary" 
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                }
                            </div>
                        </div>
                              
                    </div>
                </ReactModal>

                { this.state.showRolePopup &&
                    <UserRoleHelpPopup 
                        handleClose={()=>{this.setState({showRolePopup:false})}}   
                        groupconfig = {this.props.groupconfig}
                    />
                }
            </div>
        );
    }
}//end UserManagementCard

/*
* @brief Modal popup to show the help img
*/
export class UserRoleHelpPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        }
    }
     /*
    * @brief Render the content of the card:
    */
     render() {
        return (
            <div className="usermanagement-popup" >
                
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog usermanagement-popup-modal"
                            shouldCloseOnOverlayClick={true}
                            onRequestClose={()=>{
                                this.props.handleClose()
                            }  }
                >
                    <div className="help-popup" >
                        <img className="roleimg" src={rolesBis} />
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end of UserRoleHelpPopup 
