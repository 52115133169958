import React, { Component } from 'react';
import { Auth, API } from 'aws-amplify';

import { ApiCaller } from '../ApiCaller.js';
import { displayStatus} from '../Util.js';
import { UserManagementCard } from './UserManagementCard.js';

// Bring in the React libraries for the bootstrap table
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { GrPowerReset } from "react-icons/gr";

import '../VideoLister.css';
import './AdminUserManagement.css';

//Define the formatters:
// this just displays a dash instead of empty cells when there's no data
const nullableFormatter = (thing, row, rowIndex, extraData) => {
    if (thing === undefined || thing === null || thing === '' || thing === ' ') {
        // return <span>&mdash;</span>;
        return "None";
    }
    return thing;
}

const siteFormatter = (_sites) => {
    if (_sites === undefined || _sites === null || _sites === '' || _sites === ' ') {
        // return <span>&mdash;</span>;
        return "ALL";
    }
    //is it a list: 
    if(_sites.includes(',')){
        //get the first element:
       let firstSite = _sites.split(',')[0];
       return firstSite +",..." ;
    }else{return _sites;}
}//end siteFormatter

const statusFormatter = (_status, row, rowIndex, extraData) => {
    if(_status === 'DISABLED'){
        return <span className = 'disabledAccount'>{_status}</span>;    
    }
    return _status;
}//end statusFormatter

//helper to format the attributes to send to AWS API
const formatAttributeArray = (_data) => {
    let attribArray=[];
    if(_data.role){ //push the custom role if found
    attribArray.push({
        Name: 'custom:Role', 
        Value: Array.isArray(_data.role)?_data.role[0]:_data.role
        })
    }
    if(_data.sites){ //push the custom sitelist if found
    attribArray.push({
        Name: 'custom:AllowedSites', 
        Value: _data.sites
        })
    }
    if(_data.email){/* required */
    attribArray.push({
        Name: 'email', 
        Value: _data.email
        })
    }
    return attribArray;
}


class AdminUserManagement extends Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);

        this.handleNewUser = this.handleNewUser.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);

        this.handleCardReturn = this.handleCardReturn.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.handleUpdateUser = this.handleUpdateUser.bind(this);
        this.resetUser = this.resetUser.bind(this);
        this.handleResetUser = this.handleResetUser.bind(this);

        this.handleSearch = this.handleSearch.bind(this);
        this.handleShowDisabledToggle = this.handleShowDisabledToggle.bind(this);
        
        
        this.state = {
            userList: [],
            baseUserList: [],
            columns:[],
            userCard:null,
            retryCount: 0,
            bShowDisabled: false, //by default - don't show the disabled users
            mNotificationList:null,
            mSiteLmSiteDetailsist:[]
        };
      // setupPerf(this, 'VideoLister', () => {return this.state.videos && this.state.videos.length > 0;});
    }
    onLoadingState(state) {
      this.setState({loadingState: state});
    }
    
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        //  console.log("Get user: ",this.props);
        //define the list of allowed sites to return:
        let siteList = [];
        if(this.props.possibleFilters && this.props.possibleFilters.Sites 
            && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
            //Extract the name of the sites from the site,gps pair
            (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
        } 
        const realPromise = Auth.currentSession().then(
        (auth) => {
            let apiName = "UserManagement";
            let path = "/listAllCurrentUsers";
            let myInit = {
                body: {
                    GroupName: this.props.groupconfig.group,                
                    Groups: (this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length>0)?this.props.userInfo.allowedGroups.join(','):null,
                    Sites: siteList.length>0?siteList.join(','):null,
                }
            };
           return API.post(apiName, path, myInit);
        }
        )
        .catch((error) => {
            console.error("UserManagent api call; ",error); 
        }); 

        //Trigger an API call to grab the notification list
        const notifListPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/getNotificationList";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        clientid: this.props.groupconfig.group,
                    }
                };                        
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("Failed on call to set group update; ",error); 
                })
        notifListPromise.then((data) => {       
            // console.log("Returned data: ",data);     
            // console.log("Returned data: ",data.users[0], data.users[0].notificationlist.split(','));     
            // this.props.handleClose({policy: this.state.speedpolicies});
            if(data && data.users){this.setState({mNotificationList:data.users});}

        });  

        //get the site data:
         //Trigger an API call to grab the notification list
        const sitePromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/handleSites";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'fetch',                
                    }
                };                     
                return API.post(apiName, path, myInit);
                })
            .catch((error) => {
                console.error("Failed on call to set group update; ",error); 
            })
        sitePromise.then((data) => {       
            if(data && data.sites){this.setState({mSiteDetails:data.sites});}
        });  
        
        return realPromise;
    }
  
   
    /*
    * @brief Takes care of updating the list with new data when we receive it
    */
    updateData(data) {
        // console.log("UM: ",data);

    //   console.log("VL: ",data.users[0].Username);
      if(!data.users){return;}

      let filteredUsers = (data.users||[]).filter(user_ => {
        if(this.state.bShowDisabled===false){
            return user_.Enabled;
        }else{return true;}
        
      })

      let updatedUsers = (filteredUsers||[]).map(user_ => {
        
        //Iterate over the attributes:
        for (const attrib_ of user_.Attributes) {
            //Break out the email address from the attributes:
            if(attrib_.Name === 'email'){
                user_.email = attrib_.Value;
            }
            //Break out the role from the attributes:
            if(attrib_.Name === 'custom:Role'){
                if(attrib_.Value === ' '){user_.role = null;} //Don't allow the empty to be displayed
                else{user_.role = attrib_.Value;}
                
            }
            //Break out the site list from the attributes:
            if(attrib_.Name === 'custom:AllowedSites'){
                user_.sites = attrib_.Value;
                //is it a list: 
                
                

                // if(_sites.includes(',')){
                //     //get the first element:
                // let firstSite = _sites.split(',')[0];
                // return firstSite +",..." ;
                // }else{
                //     return _sites;
                // }

                //Format with the SiteID
                if(this.props.possibleFilters){
                    let aliasmatched = [];
                    let sitelist = attrib_.Value.split(',');

                    try {
                        (sitelist||[]).forEach(elem_ =>{
                            //match each site to its alias:
                            let siteDetail = (this.props.possibleFilters.Sites||[]).filter(site_=>{ return site_.site.toLowerCase() === elem_.toLowerCase()})
                            //  console.log("Site detail: ",siteDetail,siteDetail[0].alias);
                                // console.log("Site detail: ",siteDetail);
                            if(siteDetail && siteDetail[0] && siteDetail[0].alias){
                                aliasmatched.push( siteDetail[0].alias);    
                            }  

                        });//end map
                        //place back into a comma separated list:
                        user_.sites = aliasmatched.join(',');

                    } catch (error) {
                            
                    }
                    
                    
                }else{
                    user_.sites = attrib_.Value;
                }



            }
            
        }

        //Overwrite the user status with the Enabled status if false:
        if(user_.Enabled === false){
            user_.UserStatus = "DISABLED";
        }

        user_.reactKey = user_.Username + '-' + user_.email;
        return user_;        
      });
      //User list is display, base user list is used to reset
      this.setState({userList: updatedUsers,baseUserList: updatedUsers});
    }
  
    
    /* @brief Run once when the class is leaving
    */
    componentWillUnmount(){
    }
    /*
    * @brief The runs once when the component first mounts
    */
    componentDidMount(){
        /* Describe the columns of the table
        * 'dataField' is the name of the key in 'data' list that it will read from
        * 'text' is what it will display on the webpage for the column header
        * 'formatter' is a custom formatting function it will use for that column to transform from
        *             the data in the list to what it displays
        * 'sort' set to true to allow the column to be sorted
        * 'hidden' is if the column should be hidden from view, this is useful so we can get a
        *          react 'key' from a column that we don't actually want to show to the user
        */

        //Check on the permissions, assume write permission for legacy support:
        let bPermissionHide = false;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.users ==='read'){bPermissionHide = true;}

        let cols = [
           
            {dataField: 'Username', text: 'User Name',editable:false, sort:true,headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'UserStatus', text: 'User Status',editable:false,sort:true, formatter: statusFormatter,headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'email', text: 'Email Address',editable:false,sort:true,headerStyle: () => {return { width: "20%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'sites', text: 'Site Access',editable:false, formatter: siteFormatter,sort:true,headerStyle: () => {return { width: "15%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'role', text: 'User Role', editable:false,hidden:false, formatter: nullableFormatter,sort:true
            ,headerStyle: () => {return { width: "12%",whiteSpace:"unset",textAlign:"center"};}
            },
            {dataField: 'reset', text: 'Reset Password',editable:false, hidden:bPermissionHide, formatter:this.resetUser, headerStyle: () => {return { width: "6%",whiteSpace:"unset",textAlign:"center"};}},
            {dataField: 'remove', text: 'Enable / Disable',editable:false, hidden:bPermissionHide, formatter:this.removeUser, headerStyle: () => {return { width: "6%",whiteSpace:"unset",textAlign:"center"};}},

          ];
          if(this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length > 0){
             cols.splice(1,0,
                {dataField: 'group', text: 'Group',editable:false,sort:true, formatter:this.nullableFormatter,headerStyle: () => {return { width: "10%",whiteSpace:"unset",textAlign:"center"};}},
             ) 
          }
          //set the classnames for each column:
          cols.map(col => {
            col.classes = 'um-' + col.dataField;
            return col;
          });
        this.setState({columns: cols})
    }

    /*
    * @brief Define what is rendered in the remove user column
    */
    removeUser(cell,row){
        if(row.UserStatus === 'DISABLED'){
            return  <div>
                        <button type="button" className="enableUser" onClick={(e)=>this.handleUpdateUser(e,row,{type:'enable'})}>
                            enable
                        </button>
                    </div>  
        }else{
            return  <div>
                        <button type="button" className="removeUser" onClick={(e)=>this.handleUpdateUser(e,row,{type:'disable'})}>
                            disable
                        </button>
                    </div>  
        }
    };
    /*@brief handle the click on the remove user button
    */
    handleUpdateUser(e,row,_options){
        try{
            e.stopPropagation(); 
            // console.log("Click on remove? ",e,cell,row);              
            if(!window.confirm("Please confirm that you wish to "+_options.type+" this account")){
                return;
            }
            
            if(!row.Username){
                // console.log("Failed to find username, abort",row);
                return;
            }

            //Switch the destination based on the action type:

            let path = null;
            switch(_options.type){
                case 'enable': path = "/adminEnableUser"; break;
                case 'disable':path = "/adminDisableUser"; break;
            }
            console.log("Set path: ",_options,path);

            if(!path){return;}
            
            //  //Dispatch the update to the SQL table:
            const removePromise =Auth.currentSession().then(
                (auth) => {
                    let apiName = "UserManagement";
                    // let path = "/adminDisableUser";
                    let myInit = {
                        body: {
                            token: auth.idToken.jwtToken,
                            UserName: row.Username,
                        }
                    };
                    return API.post(apiName, path, myInit);
                })
                .catch((error) => {
                    console.error("UserManagent RemoveUser Fail; ",error); 
                })
            removePromise.then((_data)=>{//wait for the promiste to complete
                console.log("Remove user returned success:" ,_data);
                //remove the user from the current list of users:
                // let updatedUsers = (this.state.userList||[]).filter(user_=>{return user_.Username !== row.Username})
                // // console.log("New users: ",updatedUsers);
                // this.setState({userList:updatedUsers});
                this.setState({retryCount:this.state.retryCount+1});
            });         
        }catch(e){
            // console.log("Click handle error: ",e);
        }
    }//end handleRemoveUser

    /*
    * @brief Define what is rendered in the reset password column
    */
    resetUser(cell,row){
        let displayVal =<div>
            <GrPowerReset className="resetUser" onClick={(e)=>this.handleResetUser(e,cell,row)}/>
        </div>        
        return displayVal
    };
    /*@brief handle the click on the reset password button
    */
    handleResetUser(e,cell,row){
        try{
            e.stopPropagation(); //console.log("Click on button",e,cell,row);          
            // console.log("Click on remove? ",e,cell,row);              
            if(!row.Username){
                console.log("Failed to find username, abort",row);
                return;
            }
            //  //Dispatch the update to the SQL table:
            const removePromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "UserManagement";
                let path = "/adminResetUserPassword";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        UserName: row.Username,
                    }
                };
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("UserManagent RemoveUser Fail; ",error); 
            })
            .then((_data)=>{//wait for the promiste to complete
                console.log("Reset user returned success:" ,_data);
                this.setState({retryCount:this.state.retryCount+1});
            });         

        }catch(e){
            // console.log("Click handle error: ",e);
        }
    }//end handleResetUser

    /*
    * @brief Handle the return from the card, did we create or edit?
    */
    handleCardReturn(_data,_mode){
        // console.log("Handle return: ",_mode);
        switch(_mode){
            case "create":
                this.handleNewUser(_data);
            break;
            case "edit":
                this.handleEditUser(_data);
            break;
            default:
                console.log("Error, no mode selected");
            break;
        }
    }
    /*
    * @brief Handle parsing and calling API to add new user
    */
    handleNewUser(_data){
        // console.log("New User added:" ,_data,_data.group,this.props.groupconfig.group);
        this.setState({userCard:null});

        //Define the attributes to set:
        let attribArray = formatAttributeArray(_data);
        attribArray.push({
            Name: 'email_verified', 
            Value: "true"
            })

        let notifications = null;
        try {
                console.log("Proces: ",_data.notificationList);
                notifications = Array.isArray(_data.notificationList)?_data.notificationList.join(','):_data.notificationList;
                console.log("List: ",notifications);
        } catch (error) {
        }
        
        //Dispatch the update to the SQL table:
        const addPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "UserManagement";
                let path = "/adminAddUser";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        UserName: _data.Username,
                        GroupName: (_data.group)?_data.group:this.props.groupconfig.group,
                        UserAttributes:attribArray,
                        notificationList: notifications,
                        preferredemail: _data.preferredemail,
                        sites: _data.sites?_data.sites:'all',
                     }
                };
                console.log("Pass: ",myInit.body)
                return API.post(apiName, path, myInit);
            })
            .catch((error) => {
                console.error("UserManagent AddUser Fail; ",error); 
            })
        addPromise.then((_return) => { //wait for the API return
            if(_return.error===true){ //did the API call return an error?
                console.error("UserManagent AddUser Fail; ",_return); 
                window.confirm("Failed to add the new user, please try again...");
            }else{
                // console.error("UserManagent AddUser Sucess; ",_return); 
                this.setState({retryCount:this.state.retryCount+1});
            }
            
        })
        ; 
    }
    /*
    * @brief Handle parsing and calling API to edit user attributes
    */
    handleEditUser(_data){
        console.log("Edit user:" ,_data,Array.isArray(_data.role)?_data.role[0]:_data.role);
        this.setState({userCard:null});
        //Define the attributes to set:
        let attribArray = formatAttributeArray(_data);
        attribArray.push({
            Name: 'email_verified', 
            Value: "true"
            })
        if(!_data.sites){ //if sites are empty send empty request, allows for clearing
            attribArray.push({
                Name: 'custom:AllowedSites', 
                Value: " "
            })
        }

        let notifications = null;
        try {
             console.log("Proces: ",_data.notificationList);
             notifications = Array.isArray(_data.notificationList)?_data.notificationList.join(','):_data.notificationList;
             console.log("List: ",notifications);
        } catch (error) {
        }
        
        //Dispatch the update to the SQL table:
        const addPromise =Auth.currentSession().then(
            (auth) => {
                let apiName = "UserManagement";
                let path = "/adminEditUser";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        UserName: _data.Username,     
                        clientid: this.props.groupconfig.group,                   
                        UserAttributes:attribArray,
                        notificationList: notifications,
                        preferredemail: _data.preferredemail,
                        sites: _data.sites?_data.sites:'all',
                     }
                };
                return API.post(apiName, path, myInit);
        })
        .catch((error) => {
            console.error("UserManagement Edit Fail; ",error); 
        })
        .then((_return) => { //wait for the API return
            if(_return.error===true){ //did the API call return an error?
                console.error("UserManagement Edit Fail; ",_return); 
                window.confirm("Failed to edit the user, please try again...");
            }else{
                // console.error("UserManagement Edit Sucess; ",_return); 
                this.setState({retryCount:this.state.retryCount+1});
            }
            
        })
        ; 
    }

  
    /*
    * @brief Called on update to the input search field, filter the listed user names:
    */
    handleSearch(_data){
        let updatedSearchName = _data.target.value;
        updatedSearchName = updatedSearchName.toLowerCase();
        // console.log("Name to filter: ",updatedSearchName);
        //Filter the list of users by the received value:
        let filteredUsers = (this.state.baseUserList || []).filter(user_=>{
            try {
                return user_.Username.toLowerCase().includes(updatedSearchName)    
            } catch (error) {
                return true;
            }
            
        });
        //also apply filter to the email
        let filteredEmail = (this.state.baseUserList || []).filter(user_=>{
            try {
                return user_.email.toLowerCase().includes(updatedSearchName)    
            } catch (error) {
                return true;
            }
            
        });
        //Merge the two list - use the set to remove duplicates, use the spread to convert to array:
        let merged = [...new Set([...filteredUsers ,...filteredEmail])];
        this.setState({userList: merged});
    }

    /*
    * @brief Called on update to the show disabled users checkbox
    */
    handleShowDisabledToggle(){
        this.setState({bShowDisabled: !this.state.bShowDisabled,retryCount:this.state.retryCount+1})
    }
    
    
    /*
    * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
    */
    render() {

        const userData = this.state.userList;
        const tableColumns = this.state.columns;
        
        //Define the table interaction callbacks:
        const rowEvents = {
            onClick: (e, row, rowIndex) => {

                if(!this.state.mNotificationList){return;}
                 console.log("Row clicked: ",e,row,rowIndex);
        //    this.props.videoClicked(row);
                const card = {
                    Username:row.Username,
                    email: row.email,
                    role: row.role? row.role.split(','):null,
                    sites: row.sites? row.sites.split(','):null,
                    status: row.UserStatus,
                    notifications: (this.state.mNotificationList||[]).filter(user_=>{ return user_.userid===row.Username?true:false}),
                    siteDetails: this.state.mSiteDetails
                };
                this.setState({userCard:card});
            }
        };
        //  {/* <ProgressBar className="testpb" striped variant="success" now={35} key={1} label={`30%`}  /> */}
        const rowClasses = (row, rowIndex) => {
            return 'user-lister-row row-status-' + displayStatus(row.status).toLowerCase();
        };
    
        /*
            Set style for the rows in the table     
        */
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){
             rowcolor = '#00afed20'
            }
            return{
             backgroundColor: rowcolor,
            }
        };

        
        let scale = 1;
        if (this.state.winWidth && this.state.divWidth) {
            scale = this.state.winWidth / this.state.divWidth;
        }

        //Pagination options:        
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{
              text: '10', value: 10
            }] // A numeric array is also available. the purpose of above example is custom the text
        };
        

        //Check on the permissions, assume write permission for legacy support:
        let bAllowEdits = true;
        if(this.props.groupconfig.permissions && this.props.groupconfig.permissions.admin && this.props.groupconfig.permissions.admin.users ==='read'){bAllowEdits = false;}
    //    console.log("UserData:" ,userData,tableColumns);
        return (
            <div className="user-lister">
                {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} retryCount = {this.state.retryCount}/>:null}
                <div className='topRow'>
                    
                    {bAllowEdits && 
                        <button className = {"addUser"} onClick={()=>{this.setState({userCard:{}})}} >
                        Add User
                        </button>
                    }
                    <div className='totalusers'>{'Total Users: '+userData.length}</div>
                    
                    <div className="show-disabled-users">
                        <input className= 'disabledCheck' type="checkbox"  value="Show Disabled" checked={this.state.bShowDisabled===true}  onChange={this.handleShowDisabledToggle} />
                        <label className= 'disabledText'>
                            {this.state.bShowDisabled?"Hide Disabled Users":"Show Disabled Users"} 
                        </label>
                    </div>
                        
                    <div className='search-title'>User Search: </div>
                    <input type="text" id="usernameSearch" className='usernameSearch'
                                    onChange = {this.handleSearch}
                    />
                </div>
                

                {tableColumns.length>0 && 
                    <BootstrapTable 
                                keyField='reactKey' // a react specific thing that sets the 'key' for each row in the table
                                                    // react uses keys to keep track of identity when things change
                                data={userData} // <-- IMPORTANT: this is the actual data being displayed
                                columns={tableColumns}
                                defaultSorted={[
                                                {dataField: 'Username', order: 'asc'}, // how things should be sorted by
                                                {dataField: 'email', order: 'asc'}
                                                ]} // default when first displayed
                                striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                // keep track of what data belongs to what row when scanning across
                                rowStyle={ rowStyle}
                                hover={true}   // sets a hover effect, so the background color of a row changes when the
                                //                  // mouse is over it. This signals to the user that the row is clickable
                                classes={"user-lister"} // sets a CSS class so we can style this table specifically
                                rowEvents={bAllowEdits?rowEvents:null} // set what events we want to happen on rows, right now just the click
                                rowClasses={rowClasses}
                                bootstrap4 = {true}
                                detailView={true}
                                detailViewIcon={false}
                                detailViewByClick={true}
                                pagination={ paginationFactory(paginationOptions) }
                    />
                }
                { this.state.userCard &&
                    <UserManagementCard 
                        handleClose={()=>{this.setState({userCard:null})}}                
                        handleSubmit={this.handleCardReturn}
                        {...this.state.userCard}
                        knownUsers={userData}
                        scale={scale}   
                        groupconfig = {this.props.groupconfig}
                        userInfo = {this.props.userInfo}
                        possibleFilters = {this.props.possibleFilters}

                    />
                }

            </div>
        );
    }//end of render
  }
  
  export { AdminUserManagement };