
import React, { PureComponent } from 'react';

import ReactModal from 'react-modal';

import { Auth, API } from 'aws-amplify';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import { Combobox } from 'react-widgets'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import EditableLabel from 'react-inline-editing';
import { SpeedPolicySettings } from '../SpeedPolicySettings';


//OpenLayers
import {Map,View } from 'ol'
import { Tile, Vector,Group } from "ol/layer";
import { OSM, Vector as VectorSource, XYZ as XYZSource} from "ol/source";
import { fromLonLat, transformExtent} from 'ol/proj';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import {Draw,Modify}  from 'ol/interaction';
import { Stroke, Style } from 'ol/style';
import {Polygon} from 'ol/geom';
import Feature from 'ol/Feature';
import LayerSwitcherImage from 'ol-ext/control/LayerSwitcherImage'

import './SiteOverviewCard.css';
import 'react-widgets/styles.css';
import "ol/ol.css";
import "ol-ext/dist/ol-ext.css";

import { getRegionDesc,gpsStringToCoords,gpsCoordsToString,useImperial,drawPolygon } from '../UtilOpenLayers.js';



const CARD_COMMENT_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
// Delay, in milliseconds, before showing the ExpandedCard
// (to hide latency once it is shown)
const EXPANDED_CARD_SHOW_LATENCY = 150;


const roleFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return "unassigned";
    }
    return _value;
}//end roleFormatter
const statusFormatter = (_value, row, rowIndex, extraData) => {
    if(!_value){
        return "enabled";
    }
    return _value;
}//end statusFormatter
/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/
export class SiteOverviewCard extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onLoadingState = this.onLoadingState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
        this.handleClear = this .handleClear.bind(this);
        this.openSpeedPolicy = this.openSpeedPolicy.bind(this);
        // this.handleCommentEdit = this.handleCommentEdit.bind(this, 'comment');
        this.state = {
            shown: true,
            timestamp:null,
            config:{
                speedlimit:null,
                speedtime:null,
            },
            assetList:[],
            userList:[],

            tabViews: ['map','assets','users','geofences'],
            mTableColumns:[],
            mTableData:{},
            mFenceList:[],
            activeView:'map',
            mPosition: {
                lat: 33.413733507261774,
                lon: -110.97229217401843
            },
            mBoundary:null,
            bImperial:false,
            region: this.props.region,
            mSiteName: this.props.alias,
            mSiteConflict: 0,
            mOverlaySource:null,
            mMap:null,
            speedPolicyCard:null,
            isEditing: false,
        };
    }
    /*
    * A handler used by the ApiCaller
    * 
    * Currently this state is not used
    */
    onLoadingState(state) {
        this.setState({loadingState: state});
    }
    componentDidMount() {
        // console.log("Open Site Config: ",this.props );
        if(this.props.config && this.props.config[0]){
            this.setState({config:this.props.config[0]});
        }        
        //Parse the default location of the site out of the selection
        if(this.props.location && this.props.location !== '0,0' ){
            let position = gpsStringToCoords(this.props.location);
            // this.setState({mPosition:position});
            this.setState({mPosition:position,bImperial:useImperial(position)})
        }else{
            console.log("Bad site GPS")
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(data=>{
                        this.setState({mPosition:{
                            lat:data.coords.latitude,
                            lon:data.coords.longitude
                        }})
                    });
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }    
            } catch (error) {
            }
        }

        // window.setTimeout(() => {
        //     this.setState({shown: true});
        // }, EXPANDED_CARD_SHOW_LATENCY);

        if(this.state.activeView==='map'){
            setTimeout(() => {
                // console.log("Map elem: ",this.state.mMap);
                this.loadMap();
            }, 500);
        }

        
        this.getApiCall();

        let allCols = {};
        //Define the table columns:
        let colsAssets = [
            {dataField: 'assetid', text: 'Asset ID',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'type', text: 'Asset Type',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'status', text: 'Status',editable:false, sort:true,formatter: statusFormatter,headerStyle: () => {return { width: "35%",whiteSpace:"unset",textAlign:"center"};}},
        ];
        //set the classnames for each column:
        colsAssets.map(col => {
            col.classes = 'soc-' + col.dataField;
            return col;
        });

        //Define the table columns:
        let colsUsers = [
            {dataField: 'name', text: 'User Name',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'role', text: 'User Role',editable:false, sort:true,formatter: roleFormatter, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'status', text: 'Status',editable:false, sort:true,formatter: statusFormatter,headerStyle: () => {return { width: "35%",whiteSpace:"unset",textAlign:"center"};}},
        ];
        //set the classnames for each column:
        colsUsers.map(col => {
            col.classes = 'soc-' + col.dataField;
            return col;
        });

        //Define the table columns:
        let colsFences = [
            {dataField: 'name', text: 'Geofence',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'group', text: 'Geofence Group',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'status', text: 'Status',editable:false,sort:true,formatter: statusFormatter, headerStyle: () => {return { width: "35%",whiteSpace:"unset",textAlign:"center"};}},
        ];
        //set the classnames for each column:
        colsFences.map(col => {
            col.classes = 'soc-' + col.dataField;
            return col;
        });

        
        allCols['assets'] = colsAssets;
        allCols['users'] = colsUsers;
        allCols['geofences'] = colsFences;

        // console.log("cols:",allCols);
        this.setState({mTableColumns: allCols, })
       
    }

    handleTab(_data){
        // console.log("Handle Tab:",_data)
        this.setState({activeView:_data})

        if(_data === 'map'){
            setTimeout(() => {
                // console.log("Map elem: ",this.state.mMap);
                this.loadMap();
            }, 500);
        }else{
            try {
                if(this.state.mMap){
                    this.state.mMap.setTarget(null);
                    this.setState({mMap:null});
                }
            } catch (error) {
                // console.log("Failed to release: ",this.state.mMap,error);
            }
        }
    }//end handleTab
    /*
    * @brief run once on startup
    */
    loadMap() {
        // console.log("Open site: ",this.props);
        // window.setTimeout(() => {
        //     this.setState({shown: true});
        // }, EXPANDED_CARD_SHOW_LATENCY);


        let centerLat = this.state.mPosition.lat;
        let centerLon = this.state.mPosition.lon;
        // console.log("Load map at: ",centerLat,centerLon);

        //DEFINE the map tile sources
        //================================
        //Get data from HERE, the extent and minZoom constrain the region that is pulled from HERE
        var hereLayer = new Tile({
            source: new XYZSource({
                url: 'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/png8?apiKey=YaibLoQqb4TyE5urE7yIl2JS4dnR8P0zbWMFrgFWGs8'
            }),
            extent :  transformExtent([119.72217825630148,-22.604643313893195, 120.08187458098014,-22.34583588618293], 'EPSG:4326', 'EPSG:3857'),
            minZoom: 13.5,
            // extent: '[13325019.32213908, -2580963.0505704763, 13371550.148508567, -2555194.983377812]',
            visible:true,            
        });
        //Get data from Google, the default source for all the tiles
        var googleLayer = new Tile({
            source: new XYZSource({
                url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', //satellite hybrid (? why no api key?)
            }),
            visible:true,
        });

        var topoMapLayer = new Tile({
            title: 'Topo',
            source: new XYZSource({
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.jpg', 
                // url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}.jpg',
                //  projection: 'EPSG:4326'
                // projection: 'EPSG:3857'
            }),
            baseLayer: true,
            visible:false,
        });
        

        //Create a road layer
        var osmLayer = new Tile({
            title: 'OSM',
            type: 'base',
            visible: false,
            source: new OSM(),
            baseLayer: true,
        });

        //Combine the google and here layers into a single view
        let satelliteGroup = new Group({
             layers:[  googleLayer,hereLayer ],
            // layers:[ hereLayer],
            title: 'Satellite',
            baseLayer: true,
            visible: true,
        });

        //--------------------------------------------------------
        //Create layers to show geofenced regions:
        var overlaySource = new VectorSource({});
        var overlayLayer = new Vector({
            source: overlaySource,
            style: new Style({
                // fill: new Fill({color: 'rgba(200, 0, 0, 0.75)'}),
                // fill: new Fill({color: 'rgba(0, 174, 239, 0.20)'}),
                // stroke: new Stroke({
                // color: 'white'
                // })
                stroke: new Stroke({
                    color: 'white',
                    lineDash: [8],
                    width: 4,
                })
            }), 
            visible:true,  
            displayInLayerSwitcher: false,       
        });
    
        var mousePositionControl = new MousePosition({
            coordinateFormat: createStringXY(6),
            projection: 'EPSG:4326',
            // projection: 'EPSG:3857',
        });

    

        //Define the map:
        const map = new Map({
            //  Display the map in the div with the id of map
            target: 'siteMap',            
            layers: [
                osmLayer,
                topoMapLayer,
                satelliteGroup,
                overlayLayer,
            ],
            // Add in the following map controls
            // controls: defaultControls().extend([mousePositionControl]),
            // Render the tile layers in a map view with a Mercator projection
            view: new View({
                // projection: 'EPSG:3857',
                // projection: 'EPSG:4326',
                // center: [0,0],
                center: fromLonLat([ centerLon,centerLat]),
                zoom: 13
            })
        })
        map.addControl(new LayerSwitcherImage()); //enable switching between map layers


        let interactions =[];
        let drawPoly = new Draw({
            source: overlaySource,
            type: 'Polygon',            
        });
        drawPoly.setActive(false);
        drawPoly.on('drawstart', function(evt){
            // console.log("Start: ",evt);
        });
        drawPoly.on('drawend', evt => {
            drawPoly.setActive(false);
            console.log("Completed",evt.feature)
            let regionDesc = getRegionDesc(evt.feature,'polygon');
            console.log("Extracted: ",regionDesc);
            this.setState({
                mPosition:{lat:regionDesc.center.lat, lon:regionDesc.center.lon},
                mBoundary: regionDesc.coordinates
            })
            overlaySource.clear();
            overlaySource.addFeature(drawPolygon(JSON.parse(regionDesc.coordinates)));
        });
        interactions['drawpoly'] = drawPoly;
        map.addInteraction(drawPoly);
        

        let modifyRegions = new Modify({source: overlaySource});
        //Handle the event that is fired when the modification stops:
        modifyRegions.on('modifyend',evt=>{
            //Extract the feature from the event
            let feature = null;
            try{
                if(evt.feature){feature = evt.feature;  }
                else{
                    let features =  evt.features.getArray();
                    feature = features[0];
                }
            }catch(e){}
            //get the new region definition from the modified feature
            try {
                if(feature){
                    let regionDesc = getRegionDesc(feature,'polygon');
                    console.log("Extracted Modified: ",regionDesc);
                    this.setState({
                        mPosition:{lat:regionDesc.center.lat, lon:regionDesc.center.lon},
                        mBoundary: regionDesc.coordinates
                    })    
                }else{
                    console.log("No feature?: ",evt,evt.features.getArray());
                }
            } catch (error) {}
            
        })
        map.addInteraction(modifyRegions);

        //Check if the boundary is set:
        try {
            if(this.props.boundary){
                // console.log("Load boundary: ",this.props.boundary, );
                this.setState({mBoundary:this.props.boundary});
                let transformedCoords = [];

                // map.getView().fit(ol.proj.transformExtent([swLong, swLat, neLong, neLat], 'EPSG:4326', map.getView().getProjection()), { size: map.getSize() });
                let bl={lat:null, lon:null}
                let tr={lat:null, lon:null}
                for(const coord_ of JSON.parse(this.props.boundary)){
                    let transformed = fromLonLat([coord_.lon,coord_.lat]);
                    transformedCoords.push(transformed);
                    //Get the bounding arear of the boundary
                    //find the corners:
                    if(!bl.lat){bl.lat = coord_.lat};
                    if(!bl.lon){bl.lon = coord_.lon};
                    if(!tr.lat){tr.lat = coord_.lat};
                    if(!tr.lon){tr.lon = coord_.lon};
                    //Find the min max of the bounding region:
                    if(coord_.lon < bl.lon){bl.lon = coord_.lon}
                    if(coord_.lon > tr.lon){tr.lon = coord_.lon}
                    if(coord_.lat < bl.lat){bl.lat = coord_.lat}
                    if(coord_.lat > tr.lat){tr.lat = coord_.lat}
                }
                let test = [];
                test.push(transformedCoords);
                // console.log("Boundary: ",test);
                //Draw the boundary onto the map:
                let tmpPolygon = new Polygon(test);                
                let newFeature=new Feature(tmpPolygon);
                //Add to the map to render
                overlaySource.addFeature(newFeature);

                //'EPSG:4326', 'EPSG:3857'
                // transformExtent([119.72217825630148,-22.604643313893195, 120.08187458098014,-22.34583588618293], 'EPSG:4326', 'EPSG:3857'),
                if(bl.lat && bl.lon && tr.lon && tr.lat){
                    // console.log("Fit to: ",bl,tr);
                    let fittedExtent = transformExtent([bl.lon, bl.lat, tr.lon, tr.lat], 'EPSG:4326', 'EPSG:3857')
                    map.getView().fit(fittedExtent, { size: map.getSize() });
                    //Get the zoom of the map bound to the region:
                    let currentZoom = map.getView().getZoom();
                    //Zoom out by 1 level to show the larger area:
                    map.getView().setZoom(currentZoom - 1);
                }
            }else{ //no boundary defined, enable drawing
                drawPoly.setActive(true);
            }
        } catch (error) {
            
        }
        // console.log("Map: ",map);
        this.setState({mMap:map, mOverlaySource: overlaySource, mInteractions:interactions});
    }

    /*
    * @brief Callback to remove the boundary
    */
    handleClear(){
        this.state.mInteractions['drawpoly'].setActive(true);
        this.state.mOverlaySource.clear();
        this.setState({mBoundary:null,mPosition:null});
    }
    openSpeedPolicy(){
        console.log("Clicked on button", this.state);
        const card = {
            speedpolicies: this.state.config.speedpolicies,            
        };
        this.setState({speedPolicyCard:card});
    }
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        // console.log("Fetch overview: ",this.props);
        //Get the list of geofences
        const regionPromise = Auth.currentSession().then(
        (auth) => {
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    apiName: "handleGeoFence",
                    mode: "fetch",
                    site: this.props.sitename,
                }
            };
            return API.post("AuthLambda", "/apiRouter", myInit);
            })
            .catch((error) => {
            console.error("Failed on call to get regions; ",error); 
            });  //end region promise:
        regionPromise.then(data => {
            this.updateData(data);
        });

        //Get the list of users:
        const userListPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "UserManagement";
                let path = "/listAllCurrentUsers";
                let myInit = {
                    body: {
                        GroupName: this.props.groupconfig.group,                                        
                        Sites: this.props.sitename,
                        AllowAllSite: true,
                    }
                };
               return API.post(apiName, path, myInit);
            }
        );
        userListPromise.then(data => {
            this.updateData(data);
        });

        //Get the list of assets:
        const assetPromise = Auth.currentSession().then(
            (auth) => {
                let apiName = "TrifectaCoreAPI";
                let path = "/handleSiteAssets";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: 'fetch',                
                        site: this.props.sitename,
                        clientid: this.props.groupconfig.group,
                    }
                };
               return API.post(apiName, path, myInit);
        });
        assetPromise.then(data => {
            this.updateData(data);
        });

        
       
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        // console.log("Site overview: ",data,this.props);

        const tableData = this.state.mTableData;

        if(data.regions){ //handle the geofence regions return
            let fenceList = (data.regions||[]).map(fence_=>{
                return{
                    name:fence_.region,
                    group:fence_.groupname,
                    id:fence_.id,
                }
            })
            tableData['geofences'] = [...fenceList];
            this.setState({mTableData:tableData});
        }
        if(data.users){ //handle the users list
            let userList = (data.users||[]).map(user_=>{
                //Iterate over the attributes:
                user_.role = null;
                for (const attrib_ of user_.Attributes) {
                    //Break out the email address from the attributes:
                    if(attrib_.Name === 'email'){
                        user_.email = attrib_.Value;
                    }
                    //Break out the role from the attributes:
                    if(attrib_.Name === 'custom:Role'){
                        if(attrib_.Value === ' '){user_.role = null;} //Don't allow the empty to be displayed
                        else{user_.role = attrib_.Value;}
                        
                    }
                    //Break out the site list from the attributes:
                    if(attrib_.Name === 'custom:AllowedSites'){
                        user_.sites = attrib_.Value;
                    }
                    
                }
                return{
                    name:user_.Username,
                    role:user_.role,
                    Enabled: user_.Enabled,
                    sites: user_.sites,
                }
            })
            //Remove the disabled users:
            let allowedUsers = (userList||[]).filter(user_=>{return user_.Enabled})

            //Sort by user name
            const nameCompare = (a, b) => {
                if (a.name && b.name) {
                    if (a.name > b.name) { return 1;}
                    return -1;
                }
                return 0;
            };
            allowedUsers.sort(nameCompare);
            
            // console.log("Users: ",allowedUsers,userList);

            tableData['users'] = [...allowedUsers];
            this.setState({mTableData:tableData});
        }
        if(data.assets){ //handle the asset list
            let assetList = (data.assets||[]).map(asset_=>{
                return{
                    assetid:asset_.assetid,
                    type:asset_.type,
                    status:asset_.status,
                }
            })
            tableData['assets'] = [...assetList];
            this.setState({mTableData:tableData});
        }

 
    }
    
    //callback when a new action has been added
    handleSubmit(){
        console.log("actions submitted: ",this.state,this.props);
        let returnData = {};        
        //collect the data from the card
        //package into object
        returnData.name = this.props.sitename;
        returnData.newName = this.state.mSiteName;
        //Map data
        returnData.location = gpsCoordsToString(this.state.mPosition);
        returnData.boundary = this.state.mBoundary;

        //Speed dadta:
        let speedLimit = this.state.config.speedlimit;
        if(speedLimit && this.state.bImperial){
            speedLimit *= 1.609343502101025;
        }
        returnData.speedlimit = speedLimit;
        returnData.speedtime = this.state.config.speedtime;
        returnData.speedpolicy = this.state.config.speedpolicy;

        returnData.region = this.state.region;
        
        // returnData.id = generateUniqueID();

        //return to the list
        if(this.props.handleSubmit){this.props.handleSubmit(returnData,'editsite');}
        //Release the map
        try {
            if(this.state.mMap){
                this.state.mMap.setTarget(null);
                this.setState({mMap:null});
            }
        } catch (error) {
            // console.log("Failed to release: ",this.state.mMap,error);
        }
    }

    //Callback triggered once changes on the EditableLabel are complete
    handleFocusOut(text) {
        console.log("Change on text: ",text, this.props.knownSites);
        //Check if the value changed, and if so then check the known list:
        try {
            if(this.state.mSiteName!==text){ //Were changes made
                //Check if the values is in the list of known sites:
                if(this.props.knownSites.includes(text.toLowerCase())){
                    console.log("Found in list");
                    this.setState({mSiteConflict:1}); //Indicate that we are in conflict on the site name
                    return;
                }
                if(text.length > 1){
                    // console.log("Setting new title: ",text);
                    this.setState({mSiteName:text,mSiteConflict:0,isEditing:false});            
                }
            }
            this.setState({mSiteConflict:0,isEditing:false}); // reset the warning message
        } catch (error) {
            
        }
    }
    
    /*
    * @brief Render the content of the card:
    */
    render() {

        
        //=================Define the table formatting==================
        const rowEvents = {
            onClick: (e, column, columnIndex, row, rowIndex) => {  /* console.log("Row clicked: ",e,row,rowIndex, column, columnIndex); */     }
        };
        const rowClasses = (row, rowIndex) => { return 'soc-lister'; };
        // Set style for the rows in the table   
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){ rowcolor = '#00afed20' }
            return{
                backgroundColor: rowcolor,
             
            }
        };
      
        
        //Handle what happens when the user click off the card
        const confirmClose = () => {
            this.props.handleClose(this.state,false);
            //Release the map
            try {
                if(this.state.mMap){
                    this.state.mMap.setTarget(null);
                    this.setState({mMap:null});
                }
            } catch (error) {
                // console.log("Failed to release: ",this.state.mMap,error);
            }
        }//end confirmClose
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

     
        //Pagination options:       
        const paginationOptions = {
            paginationSize: 4,
            pageStartIndex: 1,
            alwaysShowAllBtns: true, // Always show next and previous button
            withFirstAndLast: true, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: '<-',
            nextPageText: '->',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: (from, to, size)=>{
                if(size ===0){return null;}
                return(<span className="react-bootstrap-table-pagination-total">Showing { from } to { to } of { size } Results </span>)
            },
            disablePageTitle: true,
            sizePerPageList: [{
              text: '4', value: 4
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        let speedUnits = 'km/h';
        //If the site is specified, change the units
        try {
            // if(this.state.config.site){
            //     //Display the speed as MPH in tempe of pintovalley
            //     if( this.state.config.site.toLowerCase().includes("pintovalley") ||this.state.config.site.toLowerCase().includes("tempe")||this.state.config.site.toLowerCase().includes("demo_group") ){
            //         speedUnits = 'mph'
            //     }  
            // }
            if(this.state.bImperial) {
                speedUnits = 'mph'
            }
        } catch (error) {}

       
        
        let bAllowEdits = true;
        let tableColumns=[];
        let tableData = [];
        try {
            tableColumns = this.state.mTableColumns[this.state.activeView] || [];
            tableData = this.state.mTableData[this.state.activeView] || [];
            // console.log("Tables:" ,this.state.mTableData,tableData);
        } catch (error) {
            
        }
        
       
        return (
            <div className="siteoverview" style={style}>
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className="modal-dialog siteoverview-modal"
                            shouldCloseOnOverlayClick={false}
                            // onRequestClose={confirmClose}
                >
                    <div className="siteoverview-modal-content" style={style} >
                        {/* <div className='c'>Card Data</div> */}
                        <div className='sc-title'>
                            <EditableLabel 
                                    text={this.state.mSiteName }
                                    labelClassName='title2'
                                    inputClassName='title2'
                                    inputWidth='100%'
                                    inputHeight='auto'
                                    inputMaxLength={50}
                                    labelFontWeight='bold'
                                    inputFontWeight='bold'
                                    onFocus={()=>{
                                        console.log("Entered focus")
                                        this.setState({isEditing:true})
                                    }}
                                    onFocusOut={this.handleFocusOut}
                            />                             
                            {this.state.mSiteConflict===1 && <div className = 'sc-title-warning'>Duplicate site name, please change</div>}
                        </div>
                        <div className='siteTop'>
                            <div className="settings-grid">
                                    <div className="title-type">Site Level Speed Limit</div>
                                    <input type="text" className="entry-field" value={this.state.config.speedlimit}  placeholder="Speed"
                                        onChange={(e) => {
                                            console.log("onChange: ",e.target.value);
                                            const config_ = Object.assign({},this.state.config);
                                            if(e.target.value ==''){config_.speedlimit = null;}
                                            else{config_.speedlimit = e.target.value;}                                                                 
                                            this.setState({config:config_})
                                            // this.setState({config:{ speedlimit: e.target.value}})
                                        }}
                                    /> 
                                    <div className="title-units">{speedUnits}</div>  
                                    <input type="text" className="entry-field" value={this.state.config.speedtime}  placeholder="Time"
                                        onChange={(e) => {
                                            console.log("onChange: ",e.target.value);
                                            const config_ = Object.assign({},this.state.config);
                                            if(e.target.value ==''){config_.speedtime = null;}
                                            else{config_.speedtime = e.target.value;}
                                            // config_.speedtime = e.target.value;                         
                                            this.setState({config:config_})
                                            // this.setState({config:{ speedlimit: e.target.value}})
                                        }}
                                    /> 
                                    <div className="title-units">seconds</div>  
                                    
                                        <button className="speed-button" 
                                                onClick={this.openSpeedPolicy}
                                        >
                                            <span>Edit Speed Policy</span>
                                        </button>
                                    
                                    
                                    
                            </div>
                            <div className="settings-grid">
                                <div className="title-type">Region</div>
                                <Combobox className="entry-field-wide"
                                    hideEmptyPopup={true}
                                    data={this.props.regionNames}
                                    value={this.state.region}
                                    onChange={(value) => {this.setState({region: value})}}
                                />                          
                            </div>
                        </div>
                        <div className='siteBottom'>
                            <div className="viewTabs">
                                <Tabs className='viewSelection'
                                    defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                                    id='uncontrolled-tab-example' 
                                        activeKey={this.state.activeView} 
                                        onSelect={(k)=>this.handleTab(k)}
                                >
                                    {this.state.tabViews.map((type) => {
                                        return(  <Tab key={type} eventKey={type} title={type}/> )
                                    })} 
                                </Tabs>
                            </div>

                            { (this.state.speedPolicyCard) &&
                                <SpeedPolicySettings 
                                    handleClose={(data)=>{
                                        console.log("on close: ",data,this.state);
                                        let localConfig = this.state.config;                                    
                                        try {
                                            // let policyToSubmit = (data.policy || []).filter(elem_=> elem_.deltaspeed);    
                                            // console.log("Filtered: ",policyToSubmit);
                                            localConfig.speedpolicies = data.policy; //update the site notecards speed policy record
                                            // localConfig.speedpolicies = policyToSubmit; //update the site notecards speed policy record
                                        } catch (error) {
                                        }
                                        this.setState({config:localConfig,speedPolicyCard:null});                                    
                                    }}                   
                                    {...this.state.speedPolicyCard}                
                                    type = 'site'
                                    group= {this.props.groupconfig.group}
                                    site= {this.props.sitename}
                                />
                            }

                            {this.state.activeView ==='map'?
                                <div id='siteMap' className = "site-olMap" ></div>
                            :
                                <div className="fenceTable">
                                    {tableColumns.length>0 && 
                                        <BootstrapTable 
                                                    keyField='id' // a react specific thing that sets the 'key' for each row in the table
                                                                        // react uses keys to keep track of identity when things change
                                                    data={tableData} // <-- IMPORTANT: this is the actual data being displayed
                                                    columns={tableColumns}
                                                    defaultSorted={[
                                                                    {dataField: 'assetid', order: 'asc'}, // how things should be sorted by
                                                                    {dataField: 'name', order: 'asc'}
                                                                    ]} // default when first displayed
                                                    striped={false} // sets every other row a different shade, makes it easier for the eye to
                                                                    // keep track of what data belongs to what row when scanning across
                                                    rowStyle={ rowStyle}
                                                    hover={true}   // sets a hover effect, so the background color of a row changes when the
                                                    //                  // mouse is over it. This signals to the user that the row is clickable
                                                    classes={"geofence-lister"} // sets a CSS class so we can style this table specifically
                                                    rowEvents={bAllowEdits?rowEvents:null} // set what events we want to happen on rows, right now just the click
                                                    rowClasses={rowClasses}
                                                    bootstrap4 = {true}
                                                    detailView={true}
                                                    detailViewIcon={false}
                                                    detailViewByClick={true}
                                                    pagination={ paginationFactory(paginationOptions) }
                                                    // selectRow={ selectRow }
                                        />
                                    }
                                </div>
                            }
                        </div>
                        
                        
                        <div className="sc-footer">
                                <button className="btn btn-danger" onClick={
                                    confirmClose}>Cancel</button>

                                {this.state.activeView ==='map'&&                                 
                                    <button className="btn btn-warning" 
                                        onClick={this.handleClear}
                                    >Clear Boundary</button>
                                }
                                {(this.state.mSiteConflict===0 && !this.state.isEditing) ?
                                    <button className="btn btn-primary" 
                                        onClick={this.handleSubmit}
                                    >Submit</button>
                                    :
                                    <div className='submitPlaceholder'>
                                        Submit
                                    </div>
                                    // :<button className="btn btn-secondary" disabled
                                    //     onClick={(e)=>{ e.stopPropagation();
                                    //         console.log("Disabled submit")
                                    //     }}
                                    //     // onClick={this.handleSubmit}
                                    // >Submit</button>
                                }
                                
                            {/* {this.state.addFormOpen?null:<div className="blank-spacer"  />} */}
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    }
}
