import React, { Component } from 'react';

import { AnalyticsView } from './AnalyticsView.js';

/*
* @brief Page that allows you to view a filtered list of videos
*
* This page is reachable by clicking on a date in the Infraction Graph on the Dashboard
*/
class VideoDrilldownView extends Component {
    componentDidMount() {
        // console.log(this.constructor.name + " mounting time:", Date.now());
    }
    render() {
        return (
            <AnalyticsView {...this.props} mainView="video-drilldown" />
        );
    }
}

export { VideoDrilldownView };