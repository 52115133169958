import React, { PureComponent } from 'react';
import * as moment from 'moment';
import { motion } from "framer-motion"
import { Filters } from '../BaseComponents.js';
import DatePicker from 'react-datepicker';
import { StatefulButton } from '../VideoReview/vid-util.js';



// export default MapDisplayView;
const filterKeys = {
    
    'infractions': {
        'title': 'Clip',
        // 'defaultValue': 'None'
        // 'defaultValue': 'Severe Drowsiness'
    },
    'vehicle': {
        'title': 'Asset',
    },
    'drivers': {
        'title': 'Driver',
    },
    'sites':{
        'title': 'Site',
    },
    'regions':{
        'title': 'Region',
    },
    'groups':{
        'title': 'Group',
    },
    'journeys':{
        'title': 'Journey',
    }
};
const UNSET = 'unset';

export const JourneyFiltersView = ({...props}) => {
    // constructor(props) {
    //     super(props);
    //     // keep track of the start and end date specially because the DatePickers
    //     // need to know (so they can display a range)
    //     this.state = {
    //         'startDate': null,
    //         'endDate': null,
    //         startedOnce: false,
    //         currentSite:null,
    //     };
    // }

    const [applyDisabled, toggleApply] = React.useState(false);
    const [filterData, setFilterData] = React.useState({});
    const [journeyCountDisplay, setJourneyCount] = React.useState(null);
    

    React.useEffect(()=>{
        filterUpdated();
     },[])
     React.useEffect(()=>{
        // console.log("Date changes: ", props.startDate, props.endDate);
        toggleApply(false);
    },[props.startDate, props.endDate])
     React.useEffect(()=>{
         filterUpdated();
     },[props.filters])
     React.useEffect(()=>{
        filterUpdated();
    },[props.activeFilters])

    React.useEffect(()=>{
        toggleApply(props.allowApply);
    },[props.allowApply])
    React.useEffect(()=>{
        journeyUpdated(props.selectedJourney);
    },[props.selectedJourney])

    // UNSAFE_componentWillReceiveProps(newProps) {
    //     let journeyCountMatch = true;
    //     if(newProps.filters && newProps.filters.journeys){
    //         if(!this.state.journeyCount && newProps.filters.journeys.length === 0){
    //             // console.log("Empty set")
    //         }else{
    //             if(this.state.journeyCount !== newProps.filters.journeys.length){
    //                 journeyCountMatch= false;
    //                 // console.log("Mismatch on journey count",this.state.journeyCount,newProps.filters.journeys.length)
    //             }
    //         }
    //     }
    //     if (    JSON.stringify(this.props) === JSON.stringify(newProps) 
    //         && this.state.startedOnce
    //         && journeyCountMatch
    //     ){
    //         // console.log("Not new");
    //         return;
    //     }        


    //     const filters = this.props.filters || {};
    //     console.log("Journey active: ",this.props.activeFilters,this.props.filters)

    //     const filterData = {};
    //     Object.entries(filters).forEach(([name, elementSet]) => {
            
    //         try {
    //             let elements = Array.from(elementSet.values());
                
                
    //             //Current site has been chosen:
    //             if(this.props.currentSite !== 'None'){
    //                 //Apply a site filter to the vehicle dropdown:
    //                 // if(this.props.currentSite && name === 'vehicle'){
    //                 //     //Filter the list of elements, only return ones where the asset's site matches the selected site
    //                 //     elements = elements.filter(value_ => {
    //                 //         return value_.site.toLowerCase() === this.props.currentSite.toLowerCase()
    //                 //     });
    //                 // }
    
    //                 //Apply a site filter to the driver dropdown:
    //                 if(this.props.currentSite && name === 'drivers'){
    //                     //Filter the list of elements, only return ones where the asset's site matches the selected site
    //                     elements = elements.filter(value_ => {
    //                         try {
    //                             return value_.site.toLowerCase() === this.props.currentSite.toLowerCase()    
    //                         } catch (error) {
    //                             return false;
    //                         }
                            
    //                     });
    //                 }
    //             }
                
    //             // console.log("elements:" ,elements,elements.length);
    //             if (elements.length > 0) {
    //                 // console.log("past 1");
    //                 const { display, title, sortKey, defaultValue } = filterKeys[name];
    //                 let options = elements.map(element => {
    //                     // create the 'text' and 'value' for each option in the dropdown
    //                     // The 'text' is what is displayed, the value is an implementation detail in HTML
    //                     switch(name){
    //                         case 'vehicle':{
    //                             let text = (display || ((x) => x))(element === null ? UNSET : element.asset);
    //                             let value = element === null ? UNSET : element.asset;
                                
    //                             return {
    //                                 text: text,
    //                                 value: value,
    //                             };
    //                         }
    //                         case 'drivers':{
    //                             let text = (display || ((x) => x))(element === null ? UNSET : element.driverid);
    //                             let value = element === null ? UNSET : element.driverid;
    //                             return {
    //                                 text: text,
    //                                 value: value,
    //                             };
    
    //                         }
    //                         default:{
    //                             let text = (display || ((x) => x))(element === null ? UNSET : element);
    //                             let value = element === null ? UNSET : element;
        
    //                             return {
    //                                 text: text,
    //                                 value: value,
    //                             }; 
    //                         }
    
    //                     }
                        
    //                 });
    //                 // Sort the options in the desired order by the sortKey, if any is specified
    //                 options.sort((a, b) => {
    //                     const sortBy = (x) => (sortKey || ((a) => a))(x.value);
    //                     if (sortBy(a) < sortBy(b)) {
    //                         return -1;
    //                     }
    //                     if (sortBy(a) > sortBy(b)) {
    //                         return 1;
    //                     }
    //                     return 0;
    //                 });
    //                 if(name === 'infractions'){
    //                     options.unshift({text: 'Clear Clips', value: 'Clear',});
    //                     options.unshift({text: 'All', value: 'All',});
    //                     options.unshift({text: 'None', value: 'None',});
    //                 }else{
    //                     if(name !== 'journeys'){options.unshift({text: 'All', value: 'All',});}
    //                 }
                    
                    
    //                 filterData[name] = {
    //                     options: options,
    //                     default: options[0],
    //                     title: title || name,
    //                 }
    //                 if(defaultValue){
    //                     let tmpDefault = options.filter(item => item.text === defaultValue)
    //                     // console.log("tmpDefault: ",tmpDefault);
    //                     if(tmpDefault && tmpDefault.length>0){
    //                         filterData[name].default= tmpDefault[0].text;
    //                     }
    //                 }
                    
    //             }
    //         } catch (error) {
    //             console.log("Failed in filter: ",name,elementSet,error)
    //         }
    //     });


    //     //Apply the default values:
    //     if(this.props.activeFilters){
    //         // console.log("Active filters: ",Object.keys(this.props.activeFilters))
    //         for(const key_ of Object.keys(this.props.activeFilters)){
    //             // console.log("Value: ",this.props.activeFilters[key_])
    //             if(filterData[key_]){
    //                 filterData[key_].default = this.props.activeFilters[key_];
    //             }
    //             // switch(key_){

    //             // }
    //         }
    //     }
      
    //     let journeyCount = null;
    //     try {
    //         if(filterData['journeys']){
    //             journeyCount = filterData['journeys'].options.length;
    //             filterData['journeys'].options.push({text: 'Clear Journeys', value: 'Clear',});
    //             filterData['journeys'].options.unshift({text: 'All', value: 'All',});

    //         }    
    //     } catch (error) {}


    //     if(this.props.selectedJourney !== newProps.selectedJourney){
    //         if(filterData['journeys']){
    //             if(newProps.selectedJourney){

    //                 // console.log("SelectedJourney: ", newProps.selectedJourney,newProps.selectedJourney.get("journeyname"));
    //                 filterData['journeys'].autoset = moment();
    //                 filterData['journeys'].autosetTimeout = 1.25;
    //                 filterData['journeys'].iteration += 1;
    //                 filterData['journeys'].default = newProps.selectedJourney.get("journeyname");
    //             }else{
    //                 filterData['journeys'].autoset = moment();
    //                 filterData['journeys'].autosetTimeout = 1.25;
    //                 filterData['journeys'].iteration += 1;
    //                 filterData['journeys'].default = null;
    //             }
    //         }
            
    //     }
    //     //  console.log('filterdata', filters, filterData);
    //     //  console.log('filter', filters,filters.vehicle);
    //     //  console.log('props', this.props);
    //     this.setState({filterData:filterData, journeyCount:journeyCount,startedOnce: true})

    // }//end will receive props


    const journeyUpdated=(_selectedJourney)=>{
        // console.log("Enter selected journey:" ,_selectedJourney,filterData);
        const filterDataLocal = filterUpdated(true);
        if(filterDataLocal['journeys']){
            if(_selectedJourney){
                console.log("SelectedJourney: ", _selectedJourney,_selectedJourney.get("journeyname"));
                filterDataLocal['journeys'].autoset = moment();
                filterDataLocal['journeys'].autosetTimeout = 1.25;
                filterDataLocal['journeys'].iteration += 1;
                filterDataLocal['journeys'].default = _selectedJourney.get("journeyname");
            }else{
                console.log("Update journey name to null");
                filterDataLocal['journeys'].autoset = moment();
                filterDataLocal['journeys'].autosetTimeout = 1.25;
                filterDataLocal['journeys'].iteration += 1;
                filterDataLocal['journeys'].default = null;
            }
            setFilterData(filterDataLocal);
            // console.log("Update: ",filterDataLocal)
        }
        
    }

    const filterUpdated=(_getReturn)=>{
        // console.log("Enter filter updated")
        const filters = props.filters || {};
        // console.log("Journey active: ",props.activeFilters,props.filters)

        const filterData = {};
        Object.entries(filters).forEach(([name, elementSet]) => {
            
            try {
                let elements = Array.from(elementSet.values());
                
                
                //Current site has been chosen:
                if(props.currentSite !== 'None'){
                    //Apply a site filter to the driver dropdown:
                    if(props.currentSite && name === 'drivers'){
                        //Filter the list of elements, only return ones where the asset's site matches the selected site
                        elements = elements.filter(value_ => {
                            try {
                                return value_.site.toLowerCase() === props.currentSite.toLowerCase()    
                            } catch (error) {return false; }
                        });
                    }
                }
                
                // console.log("elements:" ,elements,elements.length);
                if (elements.length > 0) {
                    // console.log("past 1");
                    const { display, title, sortKey, defaultValue } = filterKeys[name];
                    let options = elements.map(element => {
                        // create the 'text' and 'value' for each option in the dropdown
                        // The 'text' is what is displayed, the value is an implementation detail in HTML
                        switch(name){
                            case 'vehicle':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.alias||element.asset);
                                let value = element === null ? UNSET : element.asset;
                                
                                return {
                                    text: text,
                                    value: value,
                                };
                            }
                            case 'drivers':{
                                let text = (display || ((x) => x))(element === null ? UNSET : element.driverid);
                                let value = element === null ? UNSET : element.driverid;
                                return {
                                    text: text,
                                    value: value,
                                };
    
                            }
                            default:{
                                let text = (display || ((x) => x))(element === null ? UNSET : element);
                                let value = element === null ? UNSET : element;
        
                                return {
                                    text: text,
                                    value: value,
                                }; 
                            }
    
                        }
                        
                    });
                    // Sort the options in the desired order by the sortKey, if any is specified
                    options.sort((a, b) => {
                        const sortBy = (x) => (sortKey || ((a) => a))(x.value);
                        if (sortBy(a) < sortBy(b)) {
                            return -1;
                        }
                        if (sortBy(a) > sortBy(b)) {
                            return 1;
                        }
                        return 0;
                    });
                    if(name === 'infractions'){
                        options.unshift({text: 'Clear Clips', value: 'Clear',});
                        options.unshift({text: 'All', value: 'All',});
                        options.unshift({text: 'None', value: 'None',});
                    }else{
                        if(name !== 'journeys'){options.unshift({text: 'All', value: 'All',});}
                    }
                    
                    
                    filterData[name] = {
                        options: options,
                        default: options[0],
                        title: title || name,
                    }
                    if(defaultValue){
                        let tmpDefault = options.filter(item => item.text === defaultValue)
                        // console.log("tmpDefault: ",tmpDefault);
                        if(tmpDefault && tmpDefault.length>0){
                            filterData[name].default= tmpDefault[0].text;
                        }
                    }
                    
                }
            } catch (error) {
                console.log("Failed in filter: ",name,elementSet,error)
            }
        });


        //Apply the default values:
        if(props.activeFilters){
            // console.log("Active filters: ",Object.keys(this.props.activeFilters))
            for(const key_ of Object.keys(props.activeFilters)){
                // console.log("Value: ",this.props.activeFilters[key_])
                if(filterData[key_]){
                    filterData[key_].default = props.activeFilters[key_];
                }
            }
        }

        let journeyCount = null;
        try {
            if(filterData['journeys']){
                journeyCount = filterData['journeys'].options.length;
                filterData['journeys'].options.push({text: 'Clear Journeys', value: 'Clear',});
                filterData['journeys'].options.unshift({text: 'All', value: 'All',});

            }    
        } catch (error) {}

        //  console.log('filterdata', filters, filterData);
        //  console.log('filter', filters,filters.vehicle);
        //  console.log('props', this.props);
        // this.setState({filterData:filterData, journeyCount:journeyCount,startedOnce: true})
        if(_getReturn){
            return filterData;
        }
        //Update the states:
        setFilterData(filterData);
        setJourneyCount(journeyCount);
        toggleApply(false);
    }

    function applyClicked(){
        if(props.onApply){props.onApply();}
        toggleApply(true);
    }


    /**
     * 
     */
    const render=()=> {
        
        return (
            <div className={props.className}>

                <StatefulButton className="apply-filters"  onClick={applyClicked} 
                    disabled={applyDisabled}> 
                    {"Apply"} 
                </StatefulButton> 
                

                {filterData && <Filters filters={filterData} 
                            onFilterSelected={props.onFilterSelected} sort={false} />
                }

                
                {journeyCountDisplay && <div className = "journey-count">
                                    <div>Journeys: </div>
                                    {journeyCountDisplay}
                                 </div>        
                }

                
                
            </div>
        );
    }

    return render(); //return from the component the rendered data
}