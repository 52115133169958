
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';

import './NoteCard-TagConfirm.css';

//Load the icons:
import iconSevereDrowsy     from '../assets/infractions/severedrowsiness-red.png';
import iconSeatbelt     from '../assets/infractions/seatbelt-blue.png';
import iconSmartWatch     from '../assets/infractions/smartwatch-blue.png';
import iconDrowsy     from '../assets/infractions/drowsy-blue.png';
import iconEgress     from '../assets/infractions/egress-blue.png';
import iconCellphone     from '../assets/infractions/cellphone-blue.png';

/*
* @brief This is an modal dialog to be opened when adding an infraction tag to a notecard
*/
export class TagConfirm extends PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.state = {
            shown: false,
            timestamp:null,
            selected: null,     
            secondConfirm: false,       
        };
    }
       
    /*
    * @brief run once on startup
    */
    componentDidMount() {
        // console.log("Open tag confirm: ",this.props);
        this.setState({shown:true})
    }

  
    /*
    * @brief handle closing the card with submit
    */
    handleSubmit(_data){

        //Check if severe drowsines, and which confirmation we are on:
        if(this.props.tag === 'Severe Drowsiness' && this.state.secondConfirm===false){
            this.setState({secondConfirm:true});
        }else{
            //Otherwise return success to the parent caller
            if(this.props.onSubmit){this.props.onSubmit({value:this.props.tag,confirmed:true});}
            if(this.props.onClose){this.props.onClose();}
        }
    }//end handleSubmit
    /*
    * @brief handle closing the card with submit
    */
    handleCancel(_data){
        // console.log("Cancel clicked")
         if(this.props.onClose){this.props.onClose();}
    }//end handleSubmit

    /*
    * @brief Render the content of the card:
    */
    render() {
        
        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        let drivers = [];

        let tagIcon = <div className='icon'></div>
        //Swith on the tag, add the icons based on the input tag:
        switch(this.props.tag){
            case 'Severe Drowsiness':{
                tagIcon = [];
                //Create an array of icons to show
                //Add the blink2s name to start a infinite 2seconds blink on the icon
                for (let i = 0; i < 5; i++) {  //add 5 icons
                    tagIcon.push(   <div className='icon blink2s' key={'severes-tags-'+i}>
                                        <img  src={iconSevereDrowsy}/>
                                    </div>
                                )
                }//end for loop
            }break;
            case 'Smartwatch':{                
                tagIcon = <div className='icon' ><img className ='icon' src={iconSmartWatch}/></div>
            }break;
            case 'Seatbelt':{
                tagIcon = <div className='icon' ><img className ='icon' src={iconSeatbelt}/></div>
            }break;
            case 'Drowsiness':{
                tagIcon = <div className='icon' ><img className ='icon' src={iconDrowsy}/></div>
            }break;
            case 'Cellphone':{
                tagIcon = <div className='icon' ><img className ='icon' src={iconCellphone}/></div>
            }break;
            case 'Egress':{
                tagIcon = <div className='icon' ><img className ='icon' src={iconEgress}/></div>
            }break;
        }
        
        
        // console.log("Submit: ",this.state.emailConflict,this.state.usernameConflict);
        //  console.log("Show icon: ",this.props.tag,tagIcon)
        let cardClassName = 'tagconfirm-card-modal'
        if(this.state.secondConfirm){cardClassName+="-second"}
        return (
            <div className={"tagconfirm-card"} style={style}>
                {/* {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} />:null}                 */}
                <ReactModal isOpen={this.state.shown} className={"modal-dialog "+ cardClassName}
                            shouldCloseOnOverlayClick={false}
                >
                    <div className="tagconfirm-modal" style={style} >
                        {/* <div className='c'>Card Data</div> */}

                        <div className = 'title'></div>

                        <div className='topDisplay'>
                            <div className='iconset'>
                                {tagIcon}
                            </div>
                            <div className='tagtype'>{this.props.tag}</div>
                        </div>

                        <div className="footer">
                                <button className="btn btn-danger" 
                                     onClick={this.handleCancel}
                                >Cancel</button>
                             
                                <button className="btn btn-primary" 
                                    onClick={this.handleSubmit}
                                >Confirm</button>
                             
                        </div>
                              
                    </div>
                </ReactModal>
            </div>
        );
    }
}//end TagConfirm
