
import React, { Component, PureComponent } from 'react';

import { Auth, API } from 'aws-amplify';
import { ApiCaller } from './ApiCaller.js';
import './Monitor.css';

import { motion } from "framer-motion"

import * as moment from 'moment';
import {  delayPromise, formatSecondsTime } from './Util.js';
// Debug helpers for testing the API call logic
const DEBUG_DELAY_API_RESPONSE = false;
const DEBUG_DELAY = 7; // in seconds

/*
* @brief Display the overall assets and handle API interactions
*/
class MonitorView extends Component {
  constructor(props) {
    super(props);
    this.onLoadingState = this.onLoadingState.bind(this);
    this.updateData = this.updateData.bind(this);
    this.getApiCall = this.getApiCall.bind(this);
    this.onReset = this.onReset.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
        loadingState: null,
        retryCount: 0,
        bAPIReturned: false,
        assetSet:[],
    };
    // console.log("AnalyticsView filter:", this.props.filter);
    // console.log("AnalyticsView props:", this.props);
    this.props.navEvents && this.props.navEvents(this.onNavEvents, "Monitor");
  }


  UNSAFE_componentWillReceiveProps(newProps) {
  }

  /*
  * @brief Method called when the page is loaded
  */
  componentDidMount() {
    //Start a recurring 15second tier to update the page
    this.refreshSQLInterval = setInterval(() => {
        const { retryCount } = this.state
        this.setState({retryCount:retryCount+1})
    }, 15000) //refresh every 15 seconds?   
  } //end compenentDidMount

  componentWillUnmount(){
      //clear the timer on close
      clearInterval(this.refreshSQLInterval);
  }

  onLoadingState(state) {
    this.setState({loadingState: state});
  }

  getApiCall() {
    
    // console.log("Get Monitor: ",this.props.possibleFilters.Sites)
    //define the list of allowed sites to return:
    let siteList = [];
    if(this.props.possibleFilters && this.props.possibleFilters.Sites){
        //Extract the name of the sites from the site,gps pair
        (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } 

    // console.log("Calling API as: ",this.props, this.props.groupconfig.group, siteList)

    const realPromise = Auth.currentSession().then(
      (auth) => {
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            apiName: "getMonitorState",
            mode: "fetch",
            group: this.props.groupconfig.group,
            sites: siteList.length>0?siteList.join(','):null,
          }
        };

        try {
          this.setState({currentUsername: auth.idToken.payload['cognito:username']});
        } catch (error) {
        }

        return API.post("AuthLambda", "/apiRouter", myInit);
      });
    if (DEBUG_DELAY_API_RESPONSE) {
      return delayPromise(realPromise, DEBUG_DELAY * 1000);
    } else {
      return realPromise;
    }
  }

 
  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  updateData(_result) {
    //  console.log("Data: ",_result.data);
    //  _result.data.push({
    //     assetid:'TK999',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })

    //  _result.data.push({
    //     assetid:'TK503',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    //  _result.data.push({
    //     assetid:'TK998',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    //  _result.data.push({
    //     assetid:'TK997',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    //  _result.data.push({
    //     assetid:'TK996',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    //  _result.data.push({
    //     assetid:'TK995',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    // _result.data.push({
    //     assetid:'TK994',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    // _result.data.push({
    //     assetid:'TK993',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    // _result.data.push({
    //     assetid:'TK992',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    // _result.data.push({
    //     assetid:'TK991',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })
    // _result.data.push({
    //     assetid:'TK990',
    //     state: 1,
    //     timer: null,
    //     timerexpire:null
    // })

    if(!_result.data){return;}


      //Declare a helper to sort the return by the assetid
      const assetcompare = (a, b) => {
        if (a.assetid && b.assetid) {
            // console.log("Compare:" ,a,b)
            if (a.assetid < b.assetid) { return -1;}
            return 1;
        }
        return 0;
      };
      //Iterate over the types of the infractions and sort their lists:
      // console.log("Type: ",byType);
      _result.data.sort(assetcompare);      


    
    // console.log("Data2: ",_result.data);
    // this.setState({videos: zeroedPendingInfractions, reviewers: reviewers});
    this.setState({assetSet: _result.data, bAPIReturned: true});
  }



  /*
  * @brief Handle what happens when a click is pressed on a stoplight
  */
    handleSelectChange(_data){
        // console.log("HandleSelectChange clicked: ",_data)
       
        //Update the asset data:
        try {
            //Get the values from the event data:
            let updateValue = parseInt(_data.value,10);
            let assetid = _data.name;

            const assetSet_ = this.state.assetSet;
            const updatedAsset = assetSet_.find( (asset_) => {
                if(asset_.assetid === assetid){
                return asset_;
                }           
            });
            
            let bSetTimer = false;
            // console.log("Update asset: ",updatedAsset);
            if(updatedAsset){ //did we find it?
                //are we tranistioning from yellow to red:
                switch(updateValue){
                    case 3: //yellow case
                        if(updatedAsset.state !== updateValue || _data.type==='TimerReset'){
                            bSetTimer = true;
                            updatedAsset.timerexpire = moment().add(7200,'seconds').toISOString();       //2hours 
                        }
                        break;
                    case 4: //yellow red case
                        if(updatedAsset.state !== updateValue || _data.type==='TimerReset'){
                            bSetTimer = true;
                            updatedAsset.timerexpire = moment().add(3600,'seconds').toISOString();     //1hour
                        }
                        break;
                    default:
                        break;
                }
                //Update the state:
                updatedAsset.state = updateValue;
                
            }
            // console.log("Call update with: ",bSetTimer,updatedAsset.timerexpire)
            const realPromise = Auth.currentSession().then(
                (auth) => {
                    let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        apiName: "updateMonitorState",
                        // assetid: 'EDGE3-3',//Debugging - send the alert to NUC,
                        assetid: assetid,
                        state:  updateValue,
                        timerexpire: bSetTimer?updatedAsset.timerexpire:null,
                    }
                };
                return API.post("AuthLambda", "/apiRouter", myInit);
            });//End promise definition

            //Handle the response from the lambda call:
            realPromise.catch(error =>{
                console.log("Error caught in api update: ",error);
            });
            realPromise.then(data => {
                 console.log("Promise return: ",data);
                if(data.error){ //Did we receive an error message: failed to send the email?
                }
                //Succcess
                if(!data.error ){//No error mesage: message was sent:
                }
            });//end handle lambda call response

            // Update the state of the asset: this will affect the re-render of the radio button
            this.setState({assetSet:assetSet_});
        } catch (error) {
            console.log("Failed to handle radio button click ",error);
        }
        
    }
    /*
    * @brief Reset the state of all assets
    */
    onReset(){
        if(this.props.groupconfig.group.toLowerCase() !== 'pintovalley'){return;}
        
        if(window.confirm("Reset all Trucks?")){
            const assetList = this.state.assetSet;
            //Iterate over all the assets:
            (assetList||[]).forEach(asset_=>{
                this.handleSelectChange({
                    name:asset_.assetid,
                    value:1
                })
            })
        }
        
    }

    /*
    * @brief Called to generate the visual elements on the page. Items declared inside the return() are displayed.
    */
    render() {
    

        let monitoredAssets = null;
        //  console.log("AssetSet: ", this.state.assetSet, this.state.bAPIReturned)
        if(this.state.assetSet.length>0 || !this.state.bAPIReturned){
            monitoredAssets = <div className = 'monitored-sets'>
                <div className = 'monitored-set'>
                {
                    this.state.assetSet.map( (asset_,idx_) => { // Set video for restored cards by their infraction ID
                    //    console.log("Asset: ",asset_);
                        if(idx_ >= this.state.assetSet.length/2){return;}
                        return (
                            <MonitoredAsset className={"monitored-asset"}
                                            {...asset_}
                                            key ={'monitored-asset'+idx_}
                                            handleSelectChange = {this.handleSelectChange}
                                            groupconfig = {this.props.groupconfig}
                            />  
                        )
                    })                 
                }
                </div>
                <div className = 'monitored-set'>
                {
                    this.state.assetSet.map( (asset_,idx_) => { // Set video for restored cards by their infraction ID
                    //    console.log("Asset: ",asset_);
                        if(idx_ < this.state.assetSet.length/2){return;}
                        return (
                            <MonitoredAsset className={"monitored-asset"}
                                            {...asset_}
                                            key ={'monitored-asset'+idx_}
                                            handleSelectChange = {this.handleSelectChange}
                                            groupconfig = {this.props.groupconfig}
                            />  
                        )
                    })                 
                }
                </div>
            </div>
        }else{
            monitoredAssets = <div className="no-map-message">No Assets registered under the Monitor tab</div>
        }
        
        
        

        //console.log("Show: ",this.state.showHighlight);
        return (
        <div>
            {this.state.assetSet.length>0? <button  className='reset-all' onClick={()=>this.onReset()} >New Shift</button>:null}
            {this.props.groupconfig.bLoaded? <ApiCaller apiCall={this.getApiCall} onApiResult={this.updateData} onLoadingState={this.onLoadingState} retryCount = {this.state.retryCount}/>:null}
            {monitoredAssets}
        </div>
        )
    
    //} //end if current video
    }
}


/*
* @brief Class to handle the rendering and interaction of single stoplight monitor
*/
class MonitoredAsset extends PureComponent {
    constructor(props) {
        super(props);        
        this.onChange = this.onChange.bind(this);
        this.onTimerClick = this.onTimerClick.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.state = {
            timerSeconds:0,
            bAlarm: false,
        }
    }
    /*
    * @brief Called once when the class is first instantiated
    */
    componentDidMount(){
        //Get the time from the SQL table: timer, timerexpire
        //Check the state on load, does the red button need to be flashing?
        let secondsRemaining = moment(this.props.timerexpire).diff(moment.now(),'seconds');
        
        if( (this.props.state ===3 || this.props.state ===4) && secondsRemaining < 0){
            this.setState({bAlarm:true});
        }else{
            this.setState({bAlarm:false});
        }
        //pass the expire time to the startTimer, chech if we need to have a countdown enabled.
        this.startTimer(this.props.timerexpire);
    }
    /*
    * @brief Called once when the class is shutdown
    */
    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    /*
    * @brief Called when the properties of the class are modified
    */
    UNSAFE_componentWillReceiveProps(_newProps){

        if (JSON.stringify(this.props) !== JSON.stringify(_newProps)){
            // console.log("New props? ",_newProps,this.props);
            this.startTimer(_newProps.timerexpire);
        }
        
    }

    /*
    * @brief Helper class to start/restart the timer countdown, has a recurring call every second to update
    */
    startTimer(_timerexpire){
        // Get the time from the SQL table: timer, timerexpire
        // Compute the seconds left on the timer:
        if(!_timerexpire){return;} //no timer
        clearInterval(this.myInterval);

        let secondsRemaining = moment(_timerexpire).diff(moment.now(),'seconds');
        // console.log("Time remaining: ",secondsRemaining);
        this.setState({timerSeconds:secondsRemaining});
        //Set up a countdown timer:
        if(secondsRemaining > 0){
            this.myInterval = setInterval(() => {
                const { timerSeconds } = this.state

                if (timerSeconds > 0) {
                    let bSetAlarm = false;
                    //Should the alarm be turned on?
                    if(timerSeconds < 5){
                        bSetAlarm = true;
                    }
                    this.setState({timerSeconds:timerSeconds-1,bAlarm:bSetAlarm})
                }
                if (timerSeconds === 0) {
                    clearInterval(this.myInterval);
                } 
            }, 1000) //Trigger every second
        }
        
    }
    
    /*
    * @brief Helper method to validate the state of the radio buttons change
    */
    onChange(_data){
        // Log the the attempted state transition from "Props" to "Data"
        // console.log("Data: ",_data.value,", Props: ",this.props.state);

        // Make sure the change applies to the pinto valley group 
        if(this.props.groupconfig.group.toLowerCase() !== 'pintovalley'){
            console.log("returned group error: ",this.props.groupconfig.group);
            return; 
        }
        
        
         

        // Make sure this is an allowed state transition
        // New State = Old State + 1 or (New State = 1 if Old State != 1)
        if(_data.value != ( this.props.state + 1) && (_data.value != 1 || this.props.state == 1)) {
            console.log("Failed data check: ", _data.value !== ( this.props.state + 1), _data.value !== 1, this.props.state == 1);
            return;
        }

        // If allowed, confirm the state change with a popup window
        if(window.confirm("Confirm fatigue state update?")){
            // Make sure the alarm is cleared out
            this.setState({bAlarm:false});
            // Propagate the new state
            this.props.handleSelectChange(_data);
        } 
    }

    /*
    * @brief Helper method to validate positive contact established
    */
    onTimerClick(_data){
        // Make sure the change applies to the pinto valley group 
        if(this.props.groupconfig.group.toLowerCase() !== 'pintovalley'){return;}

        // Confirm the state change with a popup window
        if(window.confirm("Positive Contact Established?")){
            this.props.handleSelectChange(_data);
        }
        
    }

    /*
    * @brief Called whenever the state is changed of the class, this returns what should be rendered for the element
    */
    render() {
        // console.log("Status:" ,this.props); 
        // console.log("Timer:" ,this.state.timerSeconds)

        // Style the buttons, set up three buttons with different colors
        const styles = {
            green: {
              border: "5px solid green",
              backgroundColor: this.props.state===1||this.props.state===2?'green':'#77777780',
            },
            yellow: {
              border: "5px solid yellow",
              backgroundColor: this.props.state===2||this.props.state===3 || this.props.state===4?'yellow':'#77777780',
            },
            red: {
              border: "5px solid red",
              backgroundColor: this.props.state===4||this.props.state===5?' red':'#77777780',
            }
        };
        
        // Declare the body of the rendered element to return
        let returnDiv = 
         <div className="monitored-asset" >
       
            <div className = 'asset-name'>
                {this.props.assetid}
            </div>

            <div className="stoplight" onChange={ event => this.props.handleSelectChange(event)}>
                <div className = 'stoplight-background'></div>

                <button  style = {styles.green} onClick={()=>this.onChange({name:this.props.assetid, value:'1'})} ></button>
                <button  style = {styles.yellow}  onClick={()=>this.onChange({name:this.props.assetid, value: this.props.state===2?'3':this.props.state===3?'3':'2'})} ></button>
                <button  style = {styles.red}  onClick={()=>this.onChange({name:this.props.assetid, value:this.props.state===5?5:(this.props.state+1)})}  ></button>
            </div>
            
             {(this.props.state ===3 || this.props.state ===4) && this.state.bAlarm ?  
                
                    <motion.button  className= 'countdown' onClick={()=>this.onTimerClick({type: 'TimerReset',name:this.props.assetid,value:this.props.state.toString()})}  
                        
                        animate={{ backgroundColor: "#ffffff" }}
                        transition={{ yoyo: Infinity, delay: 0.75, duration: 1.0 }}
                        // onAnimationComplete={() => console.log('Completed animation')}
                        onHoverStart={() => console.log('Hover starts')}
                        // whileHover ={{scale:1.05}}
                    > Positive Contact Required </motion.button> 
                :
                (this.props.state ===3 || this.props.state ===4) && this.state.timerSeconds>0?
                    <button className = 'countdown'>
                        {formatSecondsTime(this.state.timerSeconds)}
                    </button>
                :
                    <div className = 'countdown'> </div>
            }

        </div>

        // Return the rendered element we just created
        return returnDiv;
    }
}


export { MonitorView };
